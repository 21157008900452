export const title18700 = {
  fontSize: 18,
  fontWeight: 700,
};
export const title18500 = {
  fontSize: 18,
  fontWeight: 500,
};
export const title16500 = {
  fontSize: 16,
  fontWeight: 500,
};
export const title16600 = {
  fontSize: 16,
  fontWeight: 600,
};
export const title16700 = {
  fontSize: 16,
  fontWeight: 700,
};
export const body14400 = {
  fontSize: 14,
  fontWeight: 400,
};
export const body14500 = {
  fontSize: 14,
  fontWeight: 500,
};
export const body14600 = {
  fontSize: 14,
  fontWeight: 600,
};
export const body14700 = {
  fontSize: 14,
  fontWeight: 700,
};
export const body12400 = {
  fontSize: 12,
  fontWeight: 400,
};
export const profileTitle = {
  fontSize: 14,
  color: "#646D78",
  fontWeight: 400,
};
export const title10Tab = {
  fontSize: 16,
  color: "#098484",
  fontWeight: 600,
};
export const spacingForm = 1 / 2;
export const labelForm = body14400;
export const DialogStyle = {
  PaperProps: {
    style: {
      borderRadius: "8px",
    },
  },
};
export const tootlipPlacement = "right";
