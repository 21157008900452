import {
  Container,
  Box,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import "./LoginScreen.css";
import HeaderMenuBar from "../HeaderMenuBar";
import Footer from "../Footer";
import loginLogo from "../asset/logoApp.png";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import googleIcon from "../asset/google.png";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { useState } from "react";
import { GoogleLogin, useGoogleLogin } from "react-google-login";
import { userManagementService } from "../service/userManagementService";
import { UserDetail } from "./UserProfile/UserProfile";
import { useDispatch, useSelector } from "react-redux";

export const LoadingModal = ({ open }: { open: boolean }) => (
  <Modal
    open={open}
    aria-labelledby="loading-modal"
    aria-describedby="loading-modal-description"
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      bgcolor="rgba(0, 0, 0, 0.5)"
    >
      <CircularProgress />
    </Box>
  </Modal>
);

export const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isUserRestricted, setIsUserRestricted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      setLoading(true);
      const loginResponse = await userManagementService.login(email, password);
      console.log("Login Success:");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
      if (loginResponse.userId !== "1") {
        const userDetail = await userManagementService.getUserDetail(
          loginResponse.userId
        );
        localStorage.setItem("userDetail", JSON.stringify(userDetail));

        const userPermissions = await userManagementService.getUserPermission();
        const matchedPermission = userPermissions.find(
          (userPermission: any) =>
            userPermission.id.toString() === userDetail.siteRoleId.toString()
        );

        if (matchedPermission) {
          // dispatch(setUserPermission(matchedPermission));
          localStorage.setItem(
            "userPermission",
            JSON.stringify(matchedPermission)
          );
        }
      }
      navigate("/home");
    } catch (error: any) {
      console.error("Login Failed:", error);
      if (error.response.data.status === "password_reset") {
        navigate("/resetPassword", {
          state: { status: "password_reset", email: email },
        });
      } else if (error.response.data.status === "consent_required") {
        navigate("/termOfService", {
          state: {
            status: "consent_required",
            email: email,
            password: password,
            userId: error.response.data.userId,
          },
        });
      } else if (
        error.response.data.message ===
        "User is not activated please contract admin"
      ) {
        setIsWaitingForApproval(true);
      } else if (
        error.response.data.message ===
        "User is restricted please contract admin"
      ) {
        setIsUserRestricted(true);
      } else {
        setIsTokenExpired(true);
      }
    }
  };

  const handleClick = (renderProps: any) => {
    setLoading(true);
    renderProps.onClick();
  };

  const handleLoginSuccess = async (response: any) => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Login SSO Success:");
      const ssoResponse = await userManagementService.loginWithSSO(
        response.tokenId
      );
      console.log("Login with SSO Success:");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);

      if (ssoResponse.userId !== "1") {
        const userDetail = await userManagementService.getUserDetail(
          ssoResponse.userId
        );
        localStorage.setItem("userDetail", JSON.stringify(userDetail));

        const userPermissions = await userManagementService.getUserPermission();
        const matchedPermission = userPermissions.find(
          (userPermission: any) =>
            userPermission.id.toString() === userDetail.siteRoleId.toString()
        );

        if (matchedPermission) {
          // dispatch(setUserPermission(matchedPermission));
          localStorage.setItem(
            "userPermission",
            JSON.stringify(matchedPermission)
          );
        }
      }
      navigate("/home");
    } catch (error: any) {
      setLoading(false);
      if (error.response?.data?.status === "unregistered") {
        navigate("/Register", {
          state: {
            status: "unregisteredSSO",
            email: response.profileObj.email,
          },
        });
      } else if (error.response?.data?.status === "consent_required") {
        navigate("/termOfService", {
          state: {
            status: "consent_required_SSO",
            tokenId: response.tokenId,
            userId: error.response.data.userId,
          },
        });
      } else if (
        error.response.data.message ===
        "User is not activated please contract admin"
      ) {
        setIsWaitingForApproval(true);
      } else if (
        error.response.data.message ===
        "User is restricted please contract admin"
      ) {
        setIsUserRestricted(true);
      } else {
        console.error("Login Failed:", error);
        setIsTokenExpired(true);
      }
    }
  };

  const handleLoginFailure = (error: any) => {
    setLoading(false);
    console.error("Login Failed:", error);
  };

  return (
    <div
      style={{
        overflowY: "auto",
      }}
    >
      <LoadingModal open={loading} />
      <div className="login-container">
        <HeaderMenuBar />
        <Container maxWidth="xs">
          <Box
            className="login-form"
            sx={{
              mt: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={loginLogo}
              style={{ width: "100px", height: "auto" }}
              alt="Login Logo"
            />{" "}
            {/* Adjust the width and height as necessary */}
            <LoginForm onSubmit={handleLogin} />
            <Box display="flex" alignItems="center" width="100%">
              <hr style={{ flexGrow: 1, color: "#E3E7F0" }} />
              <Typography
                variant="body1"
                style={{ margin: "0 10px", color: "#A0ABBA" }}
              >
                or
              </Typography>
              <hr style={{ flexGrow: 1, color: "#E3E7F0" }} />
            </Box>
            <div>
              <GoogleLogin
                clientId="1074184698174-anqbrehtq1hro1h410un1mfo50n81s74.apps.googleusercontent.com"
                render={(renderProps) => (
                  <div
                    onClick={() => handleClick(renderProps)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={googleIcon}
                      style={{ padding: "20px" }}
                      alt="Google Sign-In"
                    />
                  </div>
                )}
                onSuccess={handleLoginSuccess}
                onFailure={handleLoginFailure}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            <Typography>
              ยังไม่มีบัญชีผู้ใช้งาน ?{" "}
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/Register");
                }}
                style={{ color: "#DF5C8E" }}
              >
                ลงทะเบียน
              </a>
            </Typography>
          </Box>
        </Container>
      </div>
      <Footer />
      <ErrorDialog
        title="การเข้าสู่ระบบล้มเหลว"
        content="กรุณาตรวจสอบอีเมลและรหัสผ่านอีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
          setLoading(false);
        }}
      />
      <ErrorDialog
        title="การเข้าสู่ระบบล้มเหลว"
        content="รอการอนุมัติจากผู้ดูแลระบบ"
        openDialog={isWaitingForApproval}
        handleCloseDialog={() => {
          navigate("/");
          setIsWaitingForApproval(false);
          setLoading(false);
        }}
      />
      <ErrorDialog
        title="การเข้าสู่ระบบล้มเหลว"
        content="ผู้ใช้งานถูกจำกัดสิทธิ์การเข้าใช้งาน กรุณาติดต่อผู้ดูแลระบบ"
        openDialog={isUserRestricted}
        handleCloseDialog={() => {
          navigate("/");
          setIsWaitingForApproval(false);
          setIsUserRestricted(false);
          setLoading(false);
        }}
      />
    </div>
  );
};

export default LoginScreen;
