import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import InfoIcon from "../asset/InfoIcon.png";
import "./UserFiltering.css"; // Import a CSS file for styling
import { userManagementService } from "../service/userManagementService";
import { useEffectOnce } from "react-use";
import { title16500 } from "../style";

interface UserFilteringProps {
  handleSearch: (data: {
    name: string;
    userPermission: string;
    affiliatedHospital: string;
    status: string;
  }) => void;
  handleClearFiltering: () => void;
}
const UserFiltering: React.FC<UserFilteringProps> = ({
  handleSearch,
  handleClearFiltering,
}) => {
  // State for storing the values of the three filter fields
  const [affiliatedHospital, setAffiliatedHospital] = useState("");
  const [userPermission, setUserPermission] = useState("");
  const [affiliatedHospitalList, setAffiliateHospitalHospitalList] = useState<
    any[]
  >([]);
  const [name, setName] = useState("");
  const [userPermissionList, setUserPermissionList] = useState<any[]>([]);
  const [status, setStatus] = useState("");

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const hospitalList =
          await userManagementService.getAffiliatedHospital();
        setAffiliateHospitalHospitalList(hospitalList);
        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });

  const handleClickSearch = () => {
    handleSearch({ name, userPermission, affiliatedHospital, status });
  };

  const handleClickClearFiltering = () => {
    return () => {
      setName("");
      setUserPermission("");
      setAffiliatedHospital("");
      setStatus("");
      handleClearFiltering();
    };
  };
  return (
    <div style={{ overflow: "auto" }}>
      <Box sx={{ py: 2 }}>
        <Typography component={"span"} style={title16500}>
          ผู้ใช้งานและสิทธิ์
        </Typography>
        <Typography component={"span"} color={"#7A8688"} style={title16500}>
          {` / รายการผู้ใช้งาน`}
        </Typography>
      </Box>

      <Box sx={{ py: 3, px: 3, bgcolor: "white" }}>
        <p
          style={{
            ...title16500,
            borderBottom: "2px solid #D8D8D8",
            marginTop: "0px",
          }}
        >
          <span style={{ borderBottom: "3px solid #DF5C8E", fontSize: "18px" }}>
            รายการผู้ใช้งาน
          </span>
        </p>
        <Box>
          <Grid container columnGap={3} sx={{ my: 2 }} alignItems="stretch">
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                ค้นหา ชื่อ-นามสกุล{" "}
                <img src={InfoIcon} style={{ width: "15px", height: "15px" }} />
              </Typography>
              <TextField
                label="-- ระบุคำที่ต้องการค้นหา --"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                สิทธิ์การใช้งาน
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกสิทธิ์การใช้งาน --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={userPermission}
                  onChange={(e) => setUserPermission(e.target.value)}
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  {userPermissionList.map((userPermission) => (
                    <MenuItem value={userPermission.id} sx={{ fontSize: 14 }}>
                      {userPermission.nameThai}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                สังกัด
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกสังกัด --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={affiliatedHospital}
                  onChange={(e) => setAffiliatedHospital(e.target.value)}
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  {affiliatedHospitalList.map((hospital) => (
                    <MenuItem value={hospital.id} sx={{ fontSize: 14 }}>
                      {hospital.nameThai}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={2}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                สถานะ
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกสถานะ --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  MenuProps={{
                    sx: {
                      zoom: 100 * (100 / 85) + "%",
                      top: "0px",
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: "250px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  <MenuItem value="active" sx={{ fontSize: 14 }}>
                    ใช้งานได้
                  </MenuItem>
                  <MenuItem value="inactive" sx={{ fontSize: 14 }}>
                    ปิดการใช้งาน
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#DF5C8E",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClickSearch}
              >
                ค้นหา
              </Button>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#7A8688",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClickClearFiltering()}
              >
                ล้างการค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default UserFiltering;
