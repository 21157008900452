import { Typography, Box, Stack } from "@mui/material";

export const VisitStatusBox = () => {
  return (
    <Stack
      sx={{
        width: "70%",
        maxWidth: "600px",
        border: "1px solid #E5E9F2",
        p: "20px",
        borderRadius: 1,
        rowGap: 2,
      }}
    >
      <Typography fontSize={18} color={"#31394D"} fontWeight={500}>
        Status
      </Typography>
      <Stack direction={"row"} spacing={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            bgcolor="#FFFFFF"
            border="1px solid #DDDFE7"
            width={20}
            height={20}
            borderRadius={1}
          />
          <Typography>System Default</Typography>
        </Box>{" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            bgcolor="#FBC235"
            border="1px solid #DDDFE7"
            width={20}
            height={20}
            borderRadius={1}
          />
          <Typography>Unverified</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            bgcolor="#26B465"
            border="1px solid #DDDFE7"
            width={20}
            height={20}
            borderRadius={1}
          />
          <Typography>Complete</Typography>
        </Box>
      </Stack>
    </Stack>
  );
};
