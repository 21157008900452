import { configureStore } from "@reduxjs/toolkit";
import patientFilterReducer from "./features/patientFilter-slice";
import patientUser from "./features/patientSlice";
import loginUserReducer from "./features/loginUserSlice";


export const store = configureStore({
  reducer: {
    patientFilter: patientFilterReducer,
    patientUser: patientUser,
    loginUser: loginUserReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
