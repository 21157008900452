import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { GynecologicResponse, PregnancyDetail } from "./Gynecologic";
import { masterDataService } from "../../../service/masterDataService";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};
interface MenstrualStatus {
  stillHaveMenstruation: { value: string; label: string };
  regularMenstruation: { value: string; label: string };
  menstruationGap: { value: string; label: string };
  menstruationDuration: { value: string; label: string };
}
interface PregnancyIntention {
  pregnancyIntention: { value: string; label: string };
  pregnancyPlan: { value: number; label: string };
}
const frequencyOfSexualIntercourseLabel = {
  1: "< 1 ครั้งต่อเดือน",
  2: "1 ครั้งต่อเดือน",
  3: "1 ครั้งต่อสัปดาห์",
  4: ">= 2 ครั้งต่อสัปดาห์",
};
const contraptionMethodLabel = {
  combinedOralContraceptivePill:
    "Combined oral contraceptive pills (ยาเม็ดคุมกำเนิดฮอร์โมนรวม)",
  combinedOralContraceptivePillTargeted:
    "Combined oral contraceptive pills (ยาเม็ดคุมกำเนิดฮอร์โมนเดี่ยวหรือยาเม็ด คุมกำเนิดสำหรับให้นมบุตร)",
  contraceptiveImplant: "Contraceptive implant (ยาฝังคุมกำเนิด)",
  copperIudซ: "Copper IUD (ห่วงอนามัยทองแดง)",
  dmpa: "DMPA (ยาฉีดคุมกำเนิด)",
  fertilityAwareness: "Fertility awareness (นับวันปลอดภัย)",
  levonorgestrelIud: "Levonorgestrel IUD (ห่วงอนามัยฮอร์โมน)",
  maleCondom: "Male condom (ถุงยางอนามัยชาย)",
  withdrawal: "Withdrawal (หลั่งนอก)",
};
interface Gynecologic {
  visitDate?: string;
  createDateTime?: string;
  sexuallyActive: string;
  sexFrequency: string;
  contraception: number | string | string[];
  pregnancyIntention: PregnancyIntention | string;
  regularMenstruationPastThreeMonth: string;
  menstrualStatus: MenstrualStatus | null;
  pregnancyHistory: string;
  pregnancyDetail: PregnancyDetail[] | null;
}
const gynecologicLabels: { [key in keyof Gynecologic]: string } = {
  sexuallyActive: "Sexually active (ever had sexual intercourse)",
  sexFrequency: "frequency of sexual intercourse",
  contraception: "Contraception",
  pregnancyIntention: "Pregnancy intention (ความต้องการมีบุตรในระยะยาว)",
  regularMenstruationPastThreeMonth:
    "Regular menstruation in past 3 months (ประจำเดือนมาห่าง 21-35 วัน)",
  menstrualStatus: "Menstrual Status",
  pregnancyHistory: "เคยตั้งครรภ์",
  pregnancyDetail: "ข้อมูลการตั้งครรภ์",
};

export const GynecologicVisitLogDialog = ({
  open,
  handleCloseDialog,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [gynecologicHistoryResponse, setGynecologicHistoryResponse] = useState<
    GynecologicResponse[]
  >([]);
  const [gynecologicHistory, setGynecologicHistory] = useState<Gynecologic[]>(
    []
  );
  const gynecologicKeys = Object.keys(
    gynecologicLabels
  ) as (keyof Gynecologic)[];

  useEffect(() => {
    const fetchData = async () => {
      const patientId = store.getState().patientUser.patientUser?.id;
      const visitId = store.getState().patientUser.selectedVisitId;
      if (visitId) {
        try {
          const gynecologicHistoryList: GynecologicResponse[] =
            await visitDataService.getGynecologicListLog(visitId);
          const response = await masterDataService.getContraceptionMasterData();

          if (gynecologicHistoryList && response) {
            setGynecologicHistoryResponse(gynecologicHistoryList);

            const contraceptionMasterData = response.reduce(
              (
                acc: Record<number, string>,
                item: { id: number; name: string }
              ) => {
                acc[item.id] = item.name;
                return acc;
              },
              {} as Record<number, string>
            );

            const gynecologicHistory: Gynecologic[] =
              gynecologicHistoryList.map((entry) => {
                if (
                  store.getState().patientUser.patientUser?.sex === "female"
                ) {
                  return {
                    createDateTime: entry.createDateTime,
                    sexuallyActive: entry.sexuallyActive ? "Yes" : "No", // Convert boolean to string
                    sexFrequency: entry.sexuallyActive
                      ? frequencyOfSexualIntercourseLabel[
                          entry.sexFrequency as keyof typeof frequencyOfSexualIntercourseLabel
                        ]
                      : "-",
                    contraception:
                      entry.sexuallyActive && entry.contraception
                        ? [
                            ...entry.contraception.map(
                              (id) => contraceptionMasterData[id]
                            ),
                            ...(entry.contraptionMethodOther
                              ? [entry.contraptionMethodOther]
                              : []),
                          ].join(", ")
                        : "-",
                    pregnancyIntention: {
                      pregnancyIntention: {
                        value: entry.sexuallyActive
                          ? entry.pregnancyIntention
                            ? "Yes"
                            : "No"
                          : "-",
                        label:
                          "Pregnancy intention (ความต้องการมีบุตรในระยะยาว)",
                      },
                      pregnancyPlan: {
                        value: entry.pregnancyPlan,
                        label: "วางแผนมีบุตรในระยะเวลากี่ปี",
                      },
                    },
                    regularMenstruationPastThreeMonth: entry.sexuallyActive
                      ? entry.regularMenstruationPastThreeMonth
                        ? "Yes"
                        : "No"
                      : "-",
                    pregnancyHistory: entry.sexuallyActive
                      ? entry.pregnancyHistory
                        ? "Yes"
                        : "No"
                      : "-",
                    pregnancyDetail: entry.pregnancyDetail,
                    menstrualStatus: {
                      stillHaveMenstruation: {
                        value: entry.sexuallyActive
                          ? entry.stillHaveMenstruation
                            ? "Yes"
                            : "No"
                          : "-",
                        label: "menstrualStatus",
                      },
                      regularMenstruation: {
                        value: entry.stillHaveMenstruation
                          ? entry.regularMenstruation
                            ? "Yes"
                            : "No"
                          : "-",
                        label: "ความสม่ำเสมอของการมีประจำเดือน",
                      },
                      menstruationGap: {
                        value: entry.menstruationGap.toString(),
                        label: "ระยะห่างรอบประจำเดือน (วัน)",
                      },
                      menstruationDuration: {
                        value: entry.menstruationDuration.toString(),
                        label: "ระยะเวลาการเป็นประจำเดือน(วัน)",
                      },
                    },
                  };
                } else {
                  return {
                    createDateTime: entry.createDateTime,
                    sexuallyActive: entry.sexuallyActive ? "Yes" : "No",
                    sexFrequency: entry.sexuallyActive
                      ? frequencyOfSexualIntercourseLabel[
                          entry.sexFrequency as keyof typeof frequencyOfSexualIntercourseLabel
                        ]
                      : "-",
                    contraception:
                      entry.sexuallyActive && entry.contraception
                        ? [
                            ...entry.contraception.map(
                              (id) => contraceptionMasterData[id]
                            ),
                            ...(entry.contraptionMethodOther
                              ? [entry.contraptionMethodOther]
                              : []),
                          ].join(", ")
                        : "-",
                    pregnancyIntention: "-",
                    regularMenstruationPastThreeMonth: "-",
                    pregnancyHistory: "-",
                    pregnancyDetail: null,
                    menstrualStatus: null,
                  };
                }
              });
            setGynecologicHistory(gynecologicHistory);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const DetailBox = ({ label, value }: { label: string; value: string }) => (
    <Box
      display={"grid"}
      gridTemplateColumns={"1fr 1fr"}
      gap={2}
      sx={{ pb: 1 }}
    >
      <Typography
        sx={{
          textAlign: "right",
          fontSize: 14,
          color: "#646D78",
          minWidth: 430,
        }}
      >
        {label}
      </Typography>
      <Typography sx={{ textAlign: "left", fontSize: 14, minWidth: 250 }}>
        {value}
      </Typography>
    </Box>
  );

  const HeaderCell = ({ children }: { children: React.ReactNode }) => (
    <TableCell
      variant="head"
      sx={{
        minWidth: 100,
        bgcolor: "white",
        fontWeight: 700,
        textAlign: "center",
        position: "sticky",
        top: 0,
        zIndex: 2,
      }}
    >
      {children}
    </TableCell>
  );

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>History log</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table
            stickyHeader
            sx={{ maxHeight: 500, overflowY: "auto", overflowX: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Edit Date
                </TableCell>

                {gynecologicHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.createDateTime?.toString()).format(
                        "DD/MM/BBBB"
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {gynecologicKeys.map((key) => {
                if (
                  store.getState().patientUser.patientUser?.sex !== "male" ||
                  key === "sexuallyActive" ||
                  key === "sexFrequency" ||
                  key === "contraception"
                ) {
                  return (
                    <TableRow key={key}>
                      <TableCell
                        sx={{
                          minWidth: 200,
                          fontWeight: 700,
                          textAlign: "center",
                          borderRight: "1px solid #E5EBEB",
                          position: "sticky",
                          left: 0,
                          zIndex: 2,
                          bgcolor: "white",
                        }}
                      >
                        {gynecologicLabels[key]}
                      </TableCell>
                      {gynecologicHistory
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((entry, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              textAlign: "center",
                              alignContent: "baseline",
                            }}
                          >
                            {(() => {
                              switch (key) {
                                case "menstrualStatus":
                                  return (
                                    entry.menstrualStatus &&
                                    Object.values(entry.menstrualStatus).map(
                                      (item) => (
                                        <DetailBox
                                          label={item?.label || ""}
                                          value={item.value}
                                        />
                                      )
                                    )
                                  );
                                case "pregnancyDetail":
                                  return entry.pregnancyDetail ? (
                                    Object.values(entry.pregnancyDetail).map(
                                      (item, index) => (
                                        <DetailBox
                                          label={`ครั้งที่ ${index + 1} `}
                                          value={`${item.method}, ${
                                            item.year
                                          }, ${
                                            item.complication ? "มี" : "ไม่มี"
                                          } `}
                                        />
                                      )
                                    )
                                  ) : (
                                    <DetailBox
                                      label={`ข้อมูลการตั้งครรภ์`}
                                      value={`-`}
                                    />
                                  );
                                case "pregnancyIntention":
                                  return (
                                    entry.pregnancyIntention &&
                                    Object.values(entry.pregnancyIntention).map(
                                      (item) => (
                                        <DetailBox
                                          label={item?.label || ""}
                                          value={item.value}
                                        />
                                      )
                                    )
                                  );
                                default:
                                  return (
                                    <Table>
                                      <DetailBox
                                        label={gynecologicLabels[key] || ""}
                                        value={String(
                                          (entry as any)[
                                            key as keyof Gynecologic
                                          ]
                                        )}
                                      />
                                    </Table>
                                  );
                              }
                            })()}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={gynecologicHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};
