import { Box, Paper, Stack, Typography } from "@mui/material";
import { body14400, body14600, title16700 } from "../../../style";
import { FoodItem } from "../../../type";
import { getTotalCalorieFood } from "../../../utils/getTotalCalorie";

type Props = {
  meal: "อาหารเช้า" | "อาหารเที่ยง" | "อาหารเย็น" | "มื้ออื่นๆ";
  food: FoodItem[];
};

export const HistoryMealCard = ({ meal, food }: Props) => {
  return (
    <Paper
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography style={title16700}>{meal}</Typography>
        <Stack spacing={1} sx={{ mt: 2, pb: 3, mb: 5 }}>
          <Box
            display={"grid"}
            gridTemplateColumns={"2fr 1fr 1fr"}
            sx={{ pb: 1, borderBottom: "4px solid #D7DBEC" }}
          >
            <Typography style={body14400} sx={{ color: "#5A607F" }}>
              รายการ
            </Typography>
            &nbsp;
            <Box>
              <Typography
                component={"span"}
                style={body14400}
                sx={{ color: "#5A607F" }}
              >
                kcal
              </Typography>
            </Box>
          </Box>
          {food.map((food, idx) => {
            return (
              <Box
                key={idx}
                display={"grid"}
                gridTemplateColumns={"2fr 1fr 1fr"}
                gap={1}
                sx={{ pb: 1, borderBottom: "1px solid #D7DBEC" }}
              >
                <Typography
                  component={"span"}
                  style={body14400}
                  sx={{ color: "#131523" }}
                >
                  {food.nameThai}
                </Typography>
                <Typography
                  component={"span"}
                  style={body14400}
                  sx={{ color: "#7A8688" }}
                >
                  {`x${food.servingCount}`}
                </Typography>
                <Box>
                  <Typography
                    component={"span"}
                    style={body14400}
                    sx={{ color: "#131523" }}
                  >
                    {food.kcal}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Box
          display={"grid"}
          gridTemplateColumns={"2fr 1fr 1fr"}
          sx={{
            width: "calc(100% - 30px)",
            position: "absolute",
            bottom: 10,
            pt: 1,
            borderTop: "4px solid #D7DBEC",
          }}
        >
          <Typography style={body14400} sx={{ color: "#5A607F" }}>
            Total kcal
          </Typography>
          &nbsp;
          <Box>
            <Typography
              component={"span"}
              style={body14600}
              sx={{ color: "#5A607F" }}
            >
              {`${getTotalCalorieFood(food)} kcal`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
