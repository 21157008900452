export const convertDate = (inputDate: string) => {
  const [day, month, year] = inputDate.split("/");
  const dateObject = new Date(`${year}-${month}-${day}`);
  const monthName = new Intl.DateTimeFormat("th", { month: "long" }).format(
    dateObject
  );
  const dayOfMonth = dateObject.getDate();
  const fullYear = dateObject.getFullYear();

  const formattedDate = `${dayOfMonth} ${monthName} ${fullYear}`;

  return formattedDate;
};
