import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  DialogActions,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import infoIcon from "../../../asset/icn.png";
import { useEffect, useMemo, useState } from "react";
import { store } from "../../../redux/store";
import { Info } from "../../../asset/iconSvg";
import { tootlipPlacement } from "../../../style";

interface VitaminAndSupplement {
  vitaminD: string;
  vitaminB12: string;
  folate: string;
  ferritin: string;
  serumIron: string;
  TIBC: string;
}

interface VitaminAndSupplementProps extends VitaminAndSupplement {
  open: boolean;
  onClose: (data: VitaminAndSupplement) => void;
  onSave: (data: VitaminAndSupplement) => void;
}
export const VitaminAndSupplementDialog: React.FC<
  VitaminAndSupplementProps
> = ({
  open,
  onClose,
  onSave,
  vitaminD,
  vitaminB12,
  folate,
  ferritin,
  serumIron,
  TIBC,
}) => {
  const initialValues = useMemo(
    () => ({ vitaminD, vitaminB12, folate, ferritin, serumIron, TIBC }),
    [vitaminD, vitaminB12, folate, ferritin, serumIron, TIBC]
  );
  const [vitaminAndSupplementValues, setVitaminAndSupplementValues] =
    useState<VitaminAndSupplement>(initialValues);
  const [vitaminAndSupplementValuesTemp, setVitaminAndSupplementValuesTemp] =
    useState<VitaminAndSupplement>(initialValues);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffect(() => {
    setVitaminAndSupplementValues(initialValues);
    setVitaminAndSupplementValuesTemp(initialValues);
  }, [initialValues]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVitaminAndSupplementValues({
      ...vitaminAndSupplementValues,
      [event.target.name]: event.target.value,
    });
  };
  const handleClickSave = () => {
    if(patientDataPermissionEditAble === true){
      onSave(vitaminAndSupplementValues);
    } else {
      handleCloseDialog();
    }
  };
  const handleCloseDialog = () => {
    setVitaminAndSupplementValues(vitaminAndSupplementValuesTemp);
    onClose(vitaminAndSupplementValuesTemp);
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Vitamin and Supplement</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Vitamin D {" "}
              <Tooltip
                title="Vitamin D"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="vitaminD"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={vitaminAndSupplementValues.vitaminD}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>ng/mL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Vitamin B12 {" "}
              <Tooltip
                title="Vitamin B12"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="vitaminB12"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={vitaminAndSupplementValues.vitaminB12}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>pg/mL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Folate {" "}
              <Tooltip
                title="Folate"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="folate"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={vitaminAndSupplementValues.folate}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>pg/mL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Ferritin {" "}
              <Tooltip
                title="Ferritin"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="ferritin"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={vitaminAndSupplementValues.ferritin}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>pg/mL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Serum Iron {" "}
              <Tooltip
                title="Serum Iron"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="serumIron"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={vitaminAndSupplementValues.serumIron}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mcg/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              TIBC {" "}
              <Tooltip
                title="TIBC"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="TIBC"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={vitaminAndSupplementValues.TIBC}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mcg/dL</span>
            </div>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};
