import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PatientFilter, PatientUser, PatientUserProfile } from "../../type";

const initialState: PatientFilter = {
  hospital: "",
  hn: "",
  name: "",
  status: [],
  unverifiredVisits: false,
  selectedPatientUser: undefined,
  selectedPatientUserProfile: undefined,
};

interface PatientFilterPayload {
  hospital?: string;
  hn?: string;
  name?: string;
  status?: string[];
  unverifiredVisits?: boolean;
  selectedPatientUser?: PatientUser;
  selectedPatientUserProfile?: PatientUserProfile;
}

export const patientFilterSlice = createSlice({
  name: "patientFilter",
  initialState,
  reducers: {
    updatePatientFilter: (
      state,
      action: PayloadAction<PatientFilterPayload>
    ) => {
      state.hospital = action.payload.hospital ?? state.hospital;
      state.hn = action.payload.hn ?? state.hn;
      state.name = action.payload.name ?? state.name;
      state.status = action.payload.status ?? state.status;
      state.unverifiredVisits =
        action.payload.unverifiredVisits ?? state.unverifiredVisits;
      state.selectedPatientUser =
        action.payload.selectedPatientUser ?? state.selectedPatientUser;
      state.selectedPatientUserProfile =
        action.payload.selectedPatientUserProfile ??
        state.selectedPatientUserProfile;
    },
  },
});

export const { updatePatientFilter } = patientFilterSlice.actions;

export default patientFilterSlice.reducer;
