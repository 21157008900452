import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Grid,
  Button,
  FormHelperText,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import "./AddUser.css";
import { userManagementService } from "../../service/userManagementService";
import { useEffectOnce } from "react-use";
import { Layout } from "../../Layout";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import DropZoneTest, { RegisterForm } from "../../shared/DropZoneTest";
import { CreateUser, UserPermission } from "../../type";
import { set } from "date-fns";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { withStyles } from "@material-ui/core";
import { id } from "date-fns/locale";
import { WarningDialog } from "../../components/dialog/WarningDialog";

const AddUser = () => {
  const nameTitleList = [
    {
      value: "นาย",
      label: "นาย",
    },
    {
      value: "นาง",
      label: "นาง",
    },
    {
      value: "นางสาว",
      label: "นางสาว",
    },
  ];

  const GreenSwitch = withStyles({
    switchBase: {
      color: "white", // Pink color for the unchecked state
      "&$checked": {
        color: "#4caf50", // Green color for the checked state
      },
      "&$checked + $track": {
        backgroundColor: "#4caf50", // Green color for the track when checked
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const navigate = useNavigate();
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [userPermission, setUserPermission] = useState("");
  const [affiliatedHospitalList, setAffiliateHospitalHospitalList] = useState<
    any[]
  >([]);
  const [userDetail, setUserDetail] = useState<any>({});
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [registerForm, setRegisterForm] = useState<RegisterForm | null>(null);
  const [isClickSave, setIsClickSave] = useState(false);
  const [isSubmittedApproval, setIsSubmittedApproval] = useState(false);
  const [dialog, setDialog] = useState({ title: "", content: "" });
  const [openUnsaveDataDialog, setOpenUnsaveDataDialog] = useState(false);
  const [createUser, setCreateUser] = useState<CreateUser>({
    email: "",
    prefix: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    medicalLicenseNumber: "",
    jobPosition: "",
    affiliatedHospitalId: 0,
    permissionId: 0,
    medicalDocument: {
      documentName: "",
      documentType: "",
      documentData: null,
    },
    otherDocument: [
      {
        documentName: "",
        documentType: "",
        documentData: null,
      },
      {
        documentName: "",
        documentType: "",
        documentData: null,
      },
    ],
    userRestricted: false,
  });

  const handleUserPermissionDropdownChange = (event: {
    target: { value: string };
  }) => {
    console.log("event.target.value", event.target.value);
    // if (
    //   String(event.target.value) === "2" ||
    //   String(event.target.value) === "3"
    // ) {
    //   setCreateUser({ ...createUser, appliedPermission: "doctor" });
    // } else if (String(event.target.value) === "4") {
    //   setCreateUser({ ...createUser, appliedPermission: "staff" });
    // } else {
    //   setCreateUser({ ...createUser, appliedPermission: "med_student" });
    // }
    setCreateUser({
      ...createUser,
      permissionId: parseInt(event.target.value),
    });
    userPermissionList.map((userPermission) => {
      if (userPermission.id === parseInt(event.target.value)) {
        setUserPermission(event.target.value);
      }
    });
  };

  const handleAffiliatedHospitalDropdownChange = (event: {
    target: { value: string };
  }) => {
    setCreateUser({
      ...createUser,
      affiliatedHospitalId: parseInt(event.target.value),
    });
  };

  const handleRegisterFormChange = (form: RegisterForm) => {
    setRegisterForm(form);

    createUser.medicalDocument = {
      documentName: form.fileDoctorName,
      documentType: form.fileDoctorType,
      documentData: form.fileDoctor,
    };

    createUser.otherDocument = [
      {
        documentName:
          form.fileOther1Name && form.fileOther1Name !== ""
            ? form.fileOther1Name
            : "",
        documentType:
          form.fileOther1Type && form.fileOther1Type !== ""
            ? form.fileOther1Type
            : "",
        documentData:
          form.fileOther1 && form.fileOther1 !== "" ? form.fileOther1 : "",
      },
      {
        documentName:
          form.fileOther2Name && form.fileOther2Name !== ""
            ? form.fileOther2Name
            : "",
        documentType:
          form.fileOther2Type && form.fileOther2Type !== ""
            ? form.fileOther2Type
            : "",
        documentData:
          form.fileOther2 && form.fileOther2 !== "" ? form.fileOther2 : "",
      },
    ];
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const affiliatedHospitalListResponse =
          await userManagementService.getAffiliatedHospital();
        setAffiliateHospitalHospitalList(affiliatedHospitalListResponse);
        const userPermissionList =
          await userManagementService.getUserPermission();
        const activeUserPermissionList = userPermissionList.filter(
          (permission: UserPermission) => permission.permissionActive !== false
        );
        setUserPermissionList(activeUserPermissionList);
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });

  const handleSubmitData = async () => {
    try {
      setIsClickSave(true);
      if (
        !createUser.email ||
        !createUser.prefix ||
        !createUser.firstName ||
        !createUser.lastName ||
        !createUser.nationalId ||
        !createUser.mobilePhoneNumber ||
        !createUser.affiliatedHospitalId ||
        !createUser.medicalLicenseNumber ||
        !createUser.permissionId ||
        !createUser.medicalDocument.documentData ||
        !validatePhoneNumber(createUser.mobilePhoneNumber) ||
        !validateNationalId(createUser.nationalId) ||
        !validateEmail(createUser.email)
      ) {
        enqueueSnackbar("กรุณากรอกข้อมูลให้ครบถ้วน", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        return;
      }
      await submitDataAsync();
    } catch (error) {}
  };

  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await userManagementService.createWebUser(createUser);
      return response;
    },
    onSuccess(data) {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      setIsSubmittedApproval(true);
      // setIsShowCancelApprovalDialog(false);
    },
    onError(error: any) {
      if (error.response.data.message === "User is already in the system") {
        setDialog({
          title: "สมัครสมาชิกไม่สำเร็จ",
          content: "อีเมลนี้มีผู้ใช้งานแล้ว",
        });
      } else {
        setDialog({
          title: "สมัครสมาชิกไม่สำเร็จ",
          content: error.response.data.message,
        });
      }
      setOpenUnsaveDataDialog(true);
      console.log(error);
    },
  });

  const handleCloseDialog = () => {
    setOpenUnsaveDataDialog(false);
  };

  const validateNationalId = (nationalId: string) => {
    if (nationalId.length < 13 || !/^\d+$/.test(nationalId)) {
      return false;
    } else {
      return true;
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length < 10 || !/^\d+$/.test(phoneNumber)) {
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = (email: string) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Layout activeTab="userPermission">
      <div className="add-user-tab-content">
        <div className="add-user-filtering-container">
          <div>
            <Typography style={{ fontSize: "16px", padding: "16px" }}>
              จัดการผู้ใช้งาน / รายการผู้ใช้งาน / เพิ่มผู้ใช้งาน
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                p={2}
                bgcolor={"white"}
                style={{
                  width: "100%",
                  paddingTop: "0px",
                  height: "calc(100vh - 100px)",
                  overflow: "auto",
                  scrollbarWidth: "thin",
                }}
                sx={{ height: "inherit" }}
              >
                <p
                  style={{ borderBottom: "2px solid #D8D8D8", padding: "5px" }}
                >
                  <span
                    style={{
                      borderBottom: "3px solid #DF5C8E",
                      fontWeight: "bold",
                      padding: "5px",
                    }}
                  >
                    รายการผู้ใช้งาน
                  </span>
                </p>
                <p style={{ color: "#646D78" }}>
                  กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "70px",
                  }}
                >
                  <Box p={1}>
                    <Typography style={{ color: "#646D78" }}>
                      Email Address <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      label="-- โปรดระบุข้อมูล --"
                      variant="outlined"
                      value={createUser.email}
                      onChange={(e) => {
                        setCreateUser({ ...createUser, email: e.target.value });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={!createUser.email && isClickSave}
                    />
                    {(!createUser.email ||
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        createUser.email
                      )) && (
                      <FormHelperText error style={{ color: "grey" }}>
                        กรุณากรอกอีเมลให้ถูกต้อง
                      </FormHelperText>
                    )}
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      เปิดการใช้งาน <span style={{ color: "red" }}>*</span>
                    </p>
                    <Stack spacing={1} direction={"row"} alignItems={"center"}>
                      <Switch
                        checked={!createUser.userRestricted}
                        color="success"
                        onChange={(e) => {
                          setCreateUser({
                            ...createUser,
                            userRestricted: !e.target.checked,
                          });
                        }}
                      />
                      {/* <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography> */}
                    </Stack>
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "70px",
                  }}
                >
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      คำนำหน้า <span style={{ color: "red" }}>*</span>
                    </p>
                    <FormControl variant="outlined" style={{ width: "296px" }}>
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        id="prefix"
                        name="prefix"
                        value={createUser.prefix}
                        onChange={(e) => {
                          setCreateUser({
                            ...createUser,
                            prefix: e.target.value,
                          });
                        }}
                        size="small"
                        error={!createUser.prefix && isClickSave}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {nameTitleList.map((nameTitle) => (
                          <MenuItem
                            value={nameTitle.label}
                            sx={{ fontSize: 14 }}
                          >
                            {nameTitle.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      ชื่อ <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      label={createUser.firstName ? "" : "-- โปรดระบุข้อมูล --"}
                      variant="outlined"
                      value={createUser.firstName}
                      onChange={(e) => {
                        setCreateUser({
                          ...createUser,
                          firstName: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={!createUser.firstName && isClickSave}
                    />
                  </Box>

                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      นามสกุล <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      label={createUser.lastName ? "" : "-- โปรดระบุข้อมูล --"}
                      variant="outlined"
                      value={createUser.lastName}
                      onChange={(e) => {
                        setCreateUser({
                          ...createUser,
                          lastName: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={!createUser.lastName && isClickSave}
                    />
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid
                    container
                    rowGap={3}
                    alignItems="stretch"
                    sx={{ my: 2 }}
                  >
                    <Box
                      p={1}
                      style={{
                        paddingRight:
                          createUser.nationalId.length === 13 ? "70px" : "50px",
                      }}
                    >
                      <p style={{ color: "#646D78" }}>
                        เลขที่บัตรประชาชน{" "}
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <TextField
                        variant="outlined"
                        label={
                          createUser.nationalId ? "" : "-- โปรดระบุข้อมูล --"
                        }
                        value={createUser.nationalId}
                        onChange={(e) => {
                          setCreateUser({
                            ...createUser,
                            nationalId: e.target.value,
                          });
                        }}
                        size="small"
                        style={{ width: "296px" }}
                        error={!createUser.nationalId && isClickSave}
                      />
                      {(createUser.nationalId.length < 13 ||
                        !/^\d+$/.test(createUser.nationalId)) && (
                        <FormHelperText error style={{ color: "grey" }}>
                          กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก
                          และเป็นตัวเลขเท่านั้น
                        </FormHelperText>
                      )}
                    </Box>
                    <Box p={1} style={{ paddingRight: "10px" }}>
                      <p style={{ color: "#646D78" }}>เบอร์โทรศัพท์</p>
                      <TextField
                        variant="outlined"
                        value={createUser.phoneNumber}
                        onChange={(e) => {
                          setCreateUser({
                            ...createUser,
                            phoneNumber: e.target.value,
                          });
                        }}
                        size="small"
                        style={{ width: "168px" }}
                      />
                    </Box>
                    <Box p={1} style={{ paddingRight: "70px" }}>
                      <p style={{ color: "#646D78" }}>Ext</p>
                      <TextField
                        variant="outlined"
                        value={createUser.phoneNumberExtension}
                        onChange={(e) => {
                          setCreateUser({
                            ...createUser,
                            phoneNumberExtension: e.target.value,
                          });
                        }}
                        size="small"
                        style={{ width: "114px" }}
                      />
                    </Box>
                    <Box p={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ color: "#646D78" }}>
                          เบอร์มือถือ (รหัสผ่านตั้งต้นสำหรับเข้าสู่ระบบครั้งแรก){" "}
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <TextField
                          variant="outlined"
                          label={
                            createUser.mobilePhoneNumber
                              ? ""
                              : "-- โปรดระบุข้อมูล --"
                          }
                          value={createUser.mobilePhoneNumber}
                          onChange={(e) => {
                            setCreateUser({
                              ...createUser,
                              mobilePhoneNumber: e.target.value,
                            });
                          }}
                          size="small"
                          style={{ width: "296px" }}
                          error={!createUser.mobilePhoneNumber && isClickSave}
                        />
                        {(createUser.mobilePhoneNumber.length < 10 ||
                          !/^\d+$/.test(createUser.mobilePhoneNumber)) && (
                          <FormHelperText error style={{ color: "grey" }}>
                            กรุณากรอกเบอร์มือถือให้ครบ 10 หลัก
                            และเป็นตัวเลขเท่านั้น
                          </FormHelperText>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </div>

                <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "70px",
                  }}
                >
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      ชื่อโรงพยาบาล <span style={{ color: "red" }}>*</span>
                    </p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        value={userDetail.affiliatedHospital}
                        onChange={handleAffiliatedHospitalDropdownChange}
                        size="small"
                        style={{ width: "296px" }}
                        error={!createUser.affiliatedHospitalId && isClickSave}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {affiliatedHospitalList.map((hospital) => (
                          <MenuItem value={hospital.id} sx={{ fontSize: 14 }}>
                            {hospital.nameThai}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      เลขที่ใบอนุญาตใบประกอบวิชาชีพ{" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      variant="outlined"
                      label={
                        createUser.medicalLicenseNumber
                          ? ""
                          : "-- โปรดระบุข้อมูล --"
                      }
                      value={createUser.medicalLicenseNumber}
                      onChange={(e) => {
                        setCreateUser({
                          ...createUser,
                          medicalLicenseNumber: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={!createUser.medicalLicenseNumber && isClickSave}
                    />
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>ตำแหน่ง</p>
                    <TextField
                      variant="outlined"
                      label={
                        createUser.jobPosition ? "" : "-- โปรดระบุข้อมูล --"
                      }
                      value={createUser.jobPosition}
                      onChange={(e) => {
                        setCreateUser({
                          ...createUser,
                          jobPosition: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                    />
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "50px",
                  }}
                >
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      สิทธิ์การใช้งาน{" "}
                      <span style={{ color: "red" }}>
                        * (เลือกได้เพียง 1 ประเภทเท่านั้น)
                      </span>
                    </p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        value={userPermission}
                        onChange={handleUserPermissionDropdownChange}
                        size="small"
                        style={{ width: "296px" }}
                        error={!createUser.permissionId && isClickSave}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {userPermissionList.map((userPermission) => (
                          <MenuItem
                            value={userPermission.id}
                            sx={{ fontSize: 14 }}
                          >
                            {userPermission.nameThai}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>
                <div>
                  <Typography style={{ color: "#212121", fontWeight: "bold" }}>
                    เอกสารแนบ
                  </Typography>
                  <Typography style={{ color: "#646D78" }}>
                    รูปแบบไฟล์ที่รองรับ: PDF ขนาดไม่เกิน 3 MB ต่อไฟล์
                  </Typography>
                </div>
                <DropZoneTest onRegisterFormChange={handleRegisterFormChange} />
              </Box>
            </div>
            <br />
            <Box p={2} bgcolor={"white"} style={{ height: "60px" }}>
              {isSubmittedApproval ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#7A8688",
                      width: "90px",
                      height: "35px",
                      marginRight: "10px",
                    }}
                    onClick={() => navigate("/UserTab")}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#7A8688",
                      width: "90px",
                      height: "35px",
                      marginRight: "10px",
                    }}
                    onClick={() => navigate("/UserTab")}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#DF5C8E",
                      width: "90px",
                      height: "35px",
                    }}
                    onClick={() => handleSubmitData()}
                  >
                    บันทึก
                  </Button>
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
      <WarningDialog
        openDialog={openUnsaveDataDialog}
        title={dialog.title}
        content={dialog.content}
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
    </Layout>
  );
};
export default AddUser;
