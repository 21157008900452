import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { WarningDialog } from "../../components/dialog/WarningDialog";
import { updateTabsIsDirty } from "../../redux/features/patientSlice";
import { useAppDispatch } from "../../redux/hook";
import { store } from "../../redux/store";
import { BariatricSurgery } from "./BariaticSurgery/BariatricSurgery";
import { BodyAndCompositionAndLab } from "./BodyAndComposition/BodyAndCompositionAndLab";
import { ComorbiditiesAndComplications } from "./ComorbiditiesAndComplications/ComorbiditiesAndComplications";
import { Demographic } from "./Demographic/Demographic";
import { EndOfStudy } from "./EndOfStudy/EndOfStudy";
import { Gynecologic } from "./Gynecologic/Gynecologic";
import { Investigation } from "./Investigation/Investigation";
import { LateComplication } from "./LateComplication/LateComplication";
import { MedAndNutritional } from "./MedAndNutrition/MedAndNutritional";
import { Pathology } from "./Pathology/Pathology";

interface VisitTabProps {
  selectedTabIndex: number;
  handleCancelClick: () => void;
}

export const VisitTab: React.FC<VisitTabProps> = ({
  selectedTabIndex,
  handleCancelClick,
}) => {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex);
  const [selectedTabTemp, setSelectedTabTemp] = useState(selectedTabIndex);
  const [openUnsaveDataDialog, setOpenUnsaveDataDialog] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (store.getState().patientUser.tabsIsDirty) {
      setOpenUnsaveDataDialog(true);
      setSelectedTabTemp(newValue);
    } else {
      setSelectedTab(newValue);
    }
  };
  const handleCloseDialog = () => {
    setOpenUnsaveDataDialog(false);
  };
  const handleConfirmDialog = () => {
    setSelectedTab(selectedTabTemp);
    dispatch(updateTabsIsDirty(false));
    setOpenUnsaveDataDialog(false);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 5, borderColor: "divider", overflow: "hidden" }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              bgcolor: "unset",
            },
          }}
        >
          <Tab
            label="Demographic"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 0 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="Comorbidities & Complications"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 1 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="Body composition & Lab"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 2 ? "4px solid #DF5C8E" : "",
            }}
          />

          <Tab
            label="Gynecologic"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 3 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="Investigation"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 4 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="Pathology"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 5 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="Med And Nutritional"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 6 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="Bariatric Surgery"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 7 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="Late complication"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 8 ? "4px solid #DF5C8E" : "",
            }}
          />
          <Tab
            label="End of study"
            sx={{
              p: "12px",
              borderBottom: selectedTab === 9 ? "4px solid #DF5C8E" : "",
            }}
          />
        </Tabs>
      </Box>
      <Box>
        {selectedTab === 0 && (
          <Demographic handleCancelClick={handleCancelClick} />
        )}
        {selectedTab === 1 && (
          <ComorbiditiesAndComplications
            handleCancelClick={handleCancelClick}
          />
        )}
        {selectedTab === 2 && (
          <BodyAndCompositionAndLab handleCancelClick={handleCancelClick} />
        )}

        {selectedTab === 3 && (
          <Gynecologic handleCancelClick={handleCancelClick} />
        )}
        {selectedTab === 4 && (
          <Investigation handleCancelClick={handleCancelClick} />
        )}
        {selectedTab === 5 && (
          <Pathology handleCancelClick={handleCancelClick} />
        )}
        {selectedTab === 6 && (
          <MedAndNutritional handleCancelClick={handleCancelClick} />
        )}
        {selectedTab === 7 && (
          <BariatricSurgery handleCancelClick={handleCancelClick} />
        )}
        {selectedTab === 8 && (
          <LateComplication handleCancelClick={handleCancelClick} />
        )}
        {selectedTab === 9 && (
          <EndOfStudy handleCancelClick={handleCancelClick} />
        )}
      </Box>
      <WarningDialog
        openDialog={openUnsaveDataDialog}
        title="ยังไม่บันทึกข้อมูล"
        content="ข้อมูลมีการเปลี่ยนแปลง หากคุณต้องการเปลี่ยนหน้าข้อมูลทั้งหมดจะหายไป ต้องการเปลี่ยนหน้าใช่หรือไม่"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleConfirmDialog}
      />
    </Box>
  );
};
