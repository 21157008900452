import { Box, CircularProgress } from "@mui/material";

export const LoadingComponent = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      gap={1}
      justifyContent={"center"}
      height={1}
      width={1}
    >
      <CircularProgress size={20} />
      {` กำลังโหลดข้อมูล...`}
    </Box>
  );
};
