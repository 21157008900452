import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { VitaminAndSupplementDialog } from "./VitaminAndSupplementDialog";
import { BodyLabRow } from "../../../components/BodyLabRow";
import { useEffectOnce } from "react-use";
import { BodyLabResponse } from "./BodyAndCompositionAndLab";
import { visitDataService } from "../../../service/visitDataService";
import { title10Tab } from "../../../style";
import { store } from "../../../redux/store";
import { masterDataService } from "../../../service/masterDataService";
import { LabItem } from "../../../Pages/MaterData/LabMaster";

export type VitaminAndSupplementValues = {
  vitaminD: string;
  vitaminB12: string;
  folate: string;
  ferritin: string;
  serumIron: string;
  TIBC: string;
};
type Props = {
  handleSaveVitaminAndSupplementValues: (
    value: VitaminAndSupplementValues
  ) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
};
export const VitaminAndSupplement = ({
  handleSaveVitaminAndSupplementValues,
  latestEntryId,
  currentEntryId,
}: Props) => {
  const [values, setValues] = useState<VitaminAndSupplementValues>({
    vitaminD: "",
    vitaminB12: "",
    folate: "",
    ferritin: "",
    serumIron: "",
    TIBC: "",
  });
  const [openVitaminAndSupplementDialog, setOpenVitaminAndSupplementDialog] =
    useState(false);
  const [vitaminAndSupplementIsDirty, setVitaminAndSupplementIsDirty] =
    useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  const handleClickVitaminAndSupplement = () => {
    setOpenVitaminAndSupplementDialog(true);
  };
  const handleVitaminAndSupplementDialogClose = (
    newValues: VitaminAndSupplementValues
  ) => {
    setValues(newValues);
    setOpenVitaminAndSupplementDialog(false);
  };
  const handleVitaminAndSupplementDialogSave = (
    newValues: VitaminAndSupplementValues
  ) => {
    if(patientDataPermissionEditAble === true){
      setValues(newValues);
      handleSaveVitaminAndSupplementValues(newValues);
      setOpenVitaminAndSupplementDialog(false);
      setVitaminAndSupplementIsDirty(true);
    } else {
      setOpenVitaminAndSupplementDialog(false);
    }
  };
  const [labList, setLabList] = useState<LabItem[]>([]);
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const bodyLabData: BodyLabResponse =
          await visitDataService.getBodyLabFromEntryId(currentEntryId);
        setValues({
          vitaminD: bodyLabData.vitaminD,
          vitaminB12: bodyLabData.vitaminB12,
          folate: bodyLabData.folate,
          ferritin: bodyLabData.ferritin,
          serumIron: bodyLabData.serumIron,
          TIBC: bodyLabData.tib,
        });
      }
      const masterData = await masterDataService.getLabMasterData();
      setLabList(masterData);
      // else if (latestEntryId) {
      //   const bodyLabData: BodyLabResponse =
      //     await visitDataService.getBodyLabFromEntryId(latestEntryId);
      //   setValues({
      //     vitaminD: bodyLabData.vitaminD,
      //     vitaminB12: bodyLabData.vitaminB12,
      //     folate: bodyLabData.folate,
      //     ferritin: bodyLabData.ferritin,
      //     serumIron: bodyLabData.serumIron,
      //     TIBC: bodyLabData.tib,
      //   });
      // }
    };
    fetchData();
  });
  const getTitleAndUnit = (id: number) => {
    const data = labList.find((item) => item.id === id);
    if (data) {
      return {
        title: data.name,
        unit: data.measurementUnit,
        maximum: data.maximum,
        minimum: data.minimum,
        maximumWarning: data.maximumWarning,
        minimumWarning: data.minimumWarning,
      };
    }
    return { title: "Unknown", unit: "" };
  };
  const vitaminDData = getTitleAndUnit(22);
  const vitaminB12Data = getTitleAndUnit(23);
  const folateData = getTitleAndUnit(24);
  const ferritinData = getTitleAndUnit(25);
  const serumIronData = getTitleAndUnit(26);
  const TIBCData = getTitleAndUnit(27);

  const vitaminAndSupplementList = [
    {
      title: vitaminDData.title !== "Unknown" ? vitaminDData.title : "Vitamin D",
      value: values.vitaminD,
      unit: vitaminDData.unit ? vitaminDData.unit : "ng/mL",
      maximum: vitaminDData.maximum,
      minimum: vitaminDData.minimum,
      maximumWarning: vitaminDData.maximumWarning,
      minimumWarning: vitaminDData.minimumWarning,
    },
    {
      title: vitaminB12Data.title !== "Unknown" ? vitaminB12Data.title : "Vitamin B12",
      value: values.vitaminB12,
      unit: vitaminB12Data.unit ? vitaminB12Data.unit : "pg/mL",
      maximum: vitaminB12Data.maximum,
      minimum: vitaminB12Data.minimum,
      maximumWarning: vitaminB12Data.maximumWarning,
      minimumWarning: vitaminB12Data.minimumWarning,
    },
    {
      title: folateData.title !== "Unknown" ? folateData.title : "Folate",
      value: values.folate,
      unit: folateData.unit ? folateData.unit : "ng/mL",
      maximum: folateData.maximum,
      minimum: folateData.minimum,
      maximumWarning: folateData.maximumWarning,
      minimumWarning: folateData.minimumWarning,
    },
    {
      title: ferritinData.title !== "Unknown" ? ferritinData.title  : "Ferritin",
      value: values.ferritin,
      unit: ferritinData.unit ? ferritinData.unit :"ng/mL",
      maximum: ferritinData.maximum,
      minimum: ferritinData.minimum,
      maximumWarning: ferritinData.maximumWarning,
      minimumWarning: ferritinData.minimumWarning,
    },
    {
      title: serumIronData.title !== "Unknown" ? serumIronData.title : "Serum Iron",
      value: values.serumIron,
      unit: serumIronData.unit ? serumIronData.unit : "µg/dL",
      maximum: serumIronData.maximum,
      minimum: serumIronData.minimum,
      maximumWarning: serumIronData.maximumWarning,
      minimumWarning: serumIronData.minimumWarning,
    },
    { title: TIBCData.title !== "Unknown" ? TIBCData.title : "TIBC", 
      value: values.TIBC, 
      unit: TIBCData.unit ? TIBCData.unit : "µg/dL", 
      maximum: TIBCData.maximum,
      minimum: TIBCData.minimum,
      maximumWarning: TIBCData.maximumWarning,
      minimumWarning: TIBCData.minimumWarning,
    },
  ];
  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height={"100%"} sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>Vitamin and Supplement</Typography>
          <Typography
            sx={{ color: "#646D78", cursor: "pointer" }}
            onClick={handleClickVitaminAndSupplement}
          >
            แก้ไข
          </Typography>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          {vitaminAndSupplementList.map((VitaminAndSupplement, index) => (
            <BodyLabRow
              key={index}
              title={VitaminAndSupplement?.title ?? ""}
              value={
                currentEntryId || vitaminAndSupplementIsDirty
                  ? VitaminAndSupplement?.value ?? undefined
                  : "-"
              }
              detail={VitaminAndSupplement?.unit ?? undefined}
              maximum={VitaminAndSupplement?.maximum ?? undefined}
              minimum={VitaminAndSupplement?.minimum ?? undefined}
              maximumWarning={VitaminAndSupplement?.maximumWarning ?? undefined}
              minimumWarning={VitaminAndSupplement?.minimumWarning ?? undefined}
            />
          ))}
        </Box>
      </Box>
      <VitaminAndSupplementDialog
        open={openVitaminAndSupplementDialog}
        onClose={handleVitaminAndSupplementDialogClose}
        onSave={handleVitaminAndSupplementDialogSave}
        {...values}
      />
    </Box>
  );
};
