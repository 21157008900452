import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { InvestigationResponse } from "./Investigation";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};
interface Investigation {
  fattyLiver: boolean,
  gallstone: boolean,
  cirrhosis: boolean,
  ascites: boolean,
  fibro: boolean,
  hiatalHernia: boolean,
  refluxEsophagitis: boolean,
  gastritis: boolean,
  gastric: boolean,
  hpyroli: boolean,
  other: boolean,
  otherText?: string[];
}
const investigationLabels: { [key in keyof Investigation]: string } = {
  fattyLiver: "โรคความดันโลหิตสูง (HT)",
  gallstone: "โรคเบาหวาน (DM)",
  cirrhosis: "โรคไขมันในเลือดสูง (DLP)",
  ascites: "ภาวะไขมันสะสมในตับ (NAFLD/NASH)",
  fibro: "โรคหลอดเลือดหัวใจ (CAD)",
  hiatalHernia: "โรคหลอดเลือดสมอง (CVA)",
  refluxEsophagitis: "โรคนิ่วในถุงน้ำดี (Gallstones)",
  gastritis: "โรคถุงน้ำผิดปกติที่รังไข่ (PCOS)",
  gastric: "โรคข้อเข่าเสื่อม",
  hpyroli: "โรคกระดูกสันหลัง",
  other: "โรคอื่นๆ ",
};
export const InvestigationHistoryDialog = ({
  open,
  handleCloseDialog,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [investigationHistory, setInvestigationHistory] = useState<
    InvestigationResponse[]
  >([]);
  const investigationKeys = Object.keys(
    investigationLabels
  ) as (keyof Investigation)[];

  useEffect(() => {
    const fetchData = async () => {
      const patientId = store.getState().patientUser.patientUser?.id;
      if (patientId) {
        try {
          const investigationHistory: InvestigationResponse[] =
            await visitDataService.getInvestigationHistory(patientId);
          if (investigationHistory) {
            setInvestigationHistory(investigationHistory);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>Visit History</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Visit Date
                </TableCell>

                {investigationHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.visitDate).format("DD/MM/BBBB")}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {investigationKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "1px solid #E5EBEB",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      bgcolor: "white",
                    }}
                  >
                    {investigationLabels[key]}
                  </TableCell>

                  {investigationHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          alignContent: "baseline",
                        }}
                      >
                        {entry[key] ? "Yes" : "No"}
                        {key === "other" && entry.other
                          ? entry.otherText?.map((item) => (
                              <Typography>{item}</Typography>
                            ))
                          : ""}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={investigationHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};
