import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";
import {
  FieldValues,
  FormContainer,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useNavigate, useParams } from "react-router";
import defaultFoodImg from "../../../asset/default_food.jpg";
import { DeleteDialog } from "../../../components/dialog/DeleteDialog";
import {
  AddIcon,
  CancelOutlinedIcon,
  DeleteIcon,
  EditIcon,
} from "../../../icon";
import { Layout } from "../../../Layout";
import { masterDataService } from "../../../service/masterDataService";
import { labelForm, spacingForm, title16500 } from "../../../style";
import { theme2 } from "../../../theme";
import { FoodMasterData, FoodServingMasterData } from "../../../type";
import {
  convertBase64ToBytes,
  generateImageUrlFromBase64,
  generateImageUrlFromDocumentData,
} from "../../../utils/image-utils";
import { imageToBase64 } from "../../../utils/imageToBase64";
import { AxiosError } from "axios";
import { ErrorDialog } from "../../../components/dialog/ErrorDialog";
import { LoadingButton } from "@mui/lab";
import { store } from "../../../redux/store";

type Params = {
  foodId: string;
};

type CreateUpdateServingPayload = {
  servingId?: number;
  servingSize: string;
  protein: string;
  carbohydrate: string;
  sodium: string;
  fat: string;
  kcal: string;
  activated: boolean;
};

type UpdateFoodPayload = {
  nameThai: string;
  measurementUnit: string;
  description: string;
  picture: {
    documentType: string;
    documentName: string;
    documentData: any;
  } | null;
};

export function EditFoodMaster() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { foodId } = useParams<Params>();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openEditServingDialog, setOpenEditServingDialog] = useState(false);
  const [openEditFoodDialog, setOpenEditFoodDialog] = useState(false);
  const [openAddServingDialog, setOpenAddServingDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteServingIndex, setDeleteServingIndex] = useState(0);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imagePreviewTemp, setImagePreviewTemp] = useState<string | null>(null);
  const [currentFood, setCurrentFood] = useState<FoodMasterData>();
  const [currentServing, setCurrentServing] = useState<FoodServingMasterData>();
  const [activeStatus, setActiveStatus] = useState(true);
  const [currentServingIndex, setCurrentServingIndex] = useState(0);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isFetchData, setIsFetchData] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [messageError, setMessageError] = useState(
    "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
  );
  const [protein, setProtein] = useState(0);
  const [servingSize, setServingSize] = useState<number | undefined>();
  const [kcal, setKcal] = useState<number | undefined>();
  const [carbohydrate, setCarbohydrate] = useState(0);
  const [sodium, setSodium] = useState(0);
  const [fat, setFat] = useState(0);
  const [imageData, setImageData] = useState({
    documentName: "",
    documentType: "",
    documentData: [] as number[],
  });
  const tableCellValues = [
    { name: "ลำดับ", width: "5%", align: "center" },
    {
      name: `ปริมาณที่บริโภค (${currentFood?.measurementUnit ?? ""})`,
      width: "15%",
      align: "center",
    },
    { name: "Protein", width: "10%", align: "center" },
    { name: "Carbohydrate", width: "10%", align: "center" },
    { name: "Sodium", width: "10%", align: "center" },
    { name: "Fat", width: "10%", align: "center" },
    { name: "จำนวนที่เผาผลาญ (kcal)", width: "15%", align: "center" },
    { name: "สถานะ", width: "10%", align: "left" },
    { name: "เครื่องมือ", width: "10%", align: "center" },
  ];

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const masterDataPermissionAddAble =
    userId === "1" ? true : userPermission?.masterDataPermission.addAble;
  const masterDataPermissionEditAble =
    userId === "1" ? true : userPermission?.masterDataPermission.editAble;
  const masterDataPermissionDeleteAble =
    userId === "1" ? true : userPermission?.masterDataPermission.deleteAble;

  const queryFunction = async () => {
    const response = await masterDataService.getFoodMasterDataDetail(
      Number(foodId)
    );
    return response as FoodMasterData;
  };
  const {
    data: foodData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["foodMasterData"],
    queryFn: queryFunction,
    enabled: false,
  });
  const { mutateAsync: createServingAsync, isPending: isPendingCreateServing } =
    useMutation({
      mutationFn: async ({
        servingSize,
        protein,
        carbohydrate,
        sodium,
        fat,
        kcal,
        activated,
      }: CreateUpdateServingPayload) => {
        const response = await masterDataService.createServing({
          foodId: Number(foodId),
          servingSize: servingSize,
          protein: protein,
          carbohydrate: carbohydrate,
          sodium: sodium,
          fat: fat,
          kcal: kcal,
          activated: activated,
        });
        return response;
      },
      onError(error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            console.log(error.response);
          }
        }
      },
    });
  const { mutateAsync: updateServingAsync, isPending: isPendingUpdateServing } =
    useMutation({
      mutationFn: async ({
        servingId,
        servingSize,
        protein,
        carbohydrate,
        sodium,
        fat,
        kcal,
        activated,
      }: CreateUpdateServingPayload) => {
        const response = await masterDataService.updateServing({
          servingId: servingId,
          servingSize: servingSize,
          protein: protein,
          carbohydrate: carbohydrate,
          sodium: sodium,
          fat: fat,
          kcal: kcal,
          activated: activated,
        });
        return response;
      },
      onError(error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            setIsTokenExpired(true);
          } else {
            setIsShowError(true);
          }
        }
      },
    });
  const { mutateAsync: updateFoodAsync, isPending: isPendingUpdateFood } =
    useMutation({
      mutationFn: async ({
        nameThai,
        measurementUnit,
        description,
        picture: document,
      }: UpdateFoodPayload) => {
        const response = await masterDataService.updateFoodMaster({
          foodId: Number(foodId),
          nameThai: nameThai,
          measurementUnit: measurementUnit,
          description: description,
          picture: document,
        });
        return response;
      },
      onError(error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 401) {
            setIsTokenExpired(true);
          } else {
            setIsShowError(true);
          }
        }
      },
    });
  const { mutateAsync: deleteServingAsync } = useMutation({
    mutationFn: async (servingId: string) => {
      const response = await masterDataService.deleteServing(servingId);
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          if (
            error.response?.data.message ===
            "Cannot perform operation, data is in use"
          ) {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล เนื่องจากมีการใช้ข้อมูลทำให้ไม่สามารถลบได้"
            );
          } else {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }
          setIsShowError(true);
        }
      }
    },
  });
  useEffect(() => {
    refetch();
  }, [isFetchData]);
  useEffect(() => {
    if (foodData) {
      setCurrentFood(foodData);
      if (foodData.picture) {
        setImagePreview(
          generateImageUrlFromBase64(
            foodData.picture.documentType,
            foodData.picture.documentData
          )
        );
      } else {
        setImagePreview(null);
      }
    } else {
      setCurrentFood(undefined);
    }
  }, [foodData]);
  useEffect(() => {
    setActiveStatus(currentServing?.activated ?? false);
  }, [currentServing]);
  const handleCloseDialog = () => {
    setOpenEditServingDialog(false);
    setOpenAddServingDialog(false);
    setOpenDeleteDialog(false);
    setOpenEditFoodDialog(false);
    setProtein(0);
    setCarbohydrate(0);
    setSodium(0);
    setFat(0);
  };
  const handleDeleteImage = () => {
    setImagePreviewTemp(null);
  };
  const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onloadend = () => {
    //     setImagePreview(reader.result as string);
    //   };
    // }
    if (file) {
      const reader = new FileReader();

      setImageData((prev) => ({
        ...prev,
        documentName: file.name,
        documentType: file.type,
      }));

      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        const arrayBuffer = reader.result as ArrayBuffer;

        const byteArray = new Uint8Array(arrayBuffer);

        setImageData((prev) => ({
          ...prev,
          documentData: Array.from(byteArray),
        }));

        const base64String = URL.createObjectURL(file);
        setImagePreviewTemp(base64String);
      };
    }
  };
  const handleDeleteServingClick = (index: number) => {
    setDeleteServingIndex(index);
    setOpenDeleteDialog(true);
  };
  const handleEditServingClick = (
    data: FoodServingMasterData,
    index: number
  ) => {
    setCurrentServing(data);
    setOpenEditServingDialog(true);
    setCurrentServingIndex(index);
    setProtein(Number(data.protein) ?? 0);
    setCarbohydrate(Number(data.carbohydrate) ?? 0);
    setSodium(Number(data.sodium) ?? 0);
    setFat(Number(data.fat) ?? 0);
    setServingSize(Number(data.servingSize));
    setKcal(Number(data.kcal));
  };
  const handleActiveStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatus(event.target.checked);
  };
  const handleAddServing = async (data: FieldValues) => {
    await createServingAsync({
      servingSize: servingSize ? servingSize.toString() : "0",
      protein: data.protein ? protein.toString() : "0",
      carbohydrate: data.carbohydrate ? carbohydrate.toString() : "0",
      sodium: data.sodium ? sodium.toString() : "0",
      fat: data.fat ? fat.toString() : "0",
      kcal: kcal ? kcal.toString() : "0",
      activated: activeStatus,
    });
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleEditServing = async (data: FieldValues, servingId: number) => {
    await updateServingAsync({
      servingId: servingId,
      servingSize: servingSize ? servingSize.toString() : "0",
      protein: data.protein ? protein.toString() : "0",
      carbohydrate: data.carbohydrate ? carbohydrate.toString() : "0",
      sodium: data.sodium ? sodium.toString() : "0",
      fat: data.fat ? fat.toString() : "0",
      kcal: kcal ? kcal.toString() : "0",
      activated: activeStatus,
    });
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleEditFood = async (data: FieldValues) => {
    await updateFoodAsync({
      nameThai: data.name,
      measurementUnit: data.unit,
      description: data.description,
      picture: imagePreviewTemp
        ? {
            documentName: imageData.documentName,
            documentType: imageData.documentType,
            documentData: imageData.documentData,
          }
        : null,
    });
    setImagePreview(
      generateImageUrlFromDocumentData(
        imageData.documentData,
        imageData.documentType
      )
    );
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleAddClick = () => {
    setActiveStatus(true);
    setOpenAddServingDialog(true);
  };
  const handleConfirmDeleteDialog = async () => {
    await deleteServingAsync(deleteServingIndex.toString());
    setIsFetchData((prev) => !prev);
    setOpenDeleteDialog(false);
  };
  const handleEditFoodClick = () => {
    setOpenEditFoodDialog(true);
    if (currentFood && currentFood.picture) {
      setImagePreviewTemp(
        generateImageUrlFromBase64(
          currentFood.picture.documentType,
          currentFood.picture.documentData
        )
      );
      setImageData({
        documentName: currentFood.picture.documentName,
        documentType: currentFood.picture.documentType,
        documentData: convertBase64ToBytes(currentFood.picture.documentData),
      });
    }
  };
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1, overflow: "auto" }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Box
                  onClick={() => navigate("/masterData/food")}
                  sx={{ cursor: "pointer" }}
                >
                  อาหาร
                </Box>
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {` / แก้ไข`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: "white",
              height: "calc(100% - 40px)",
              overflow: "auto",
              scrollbarWidth: "thin",
              boxSizing: "border-box",
            }}
          >
            <Box sx={{ borderBottom: "2px solid #D8D8D8" }}>
              <Box
                component={"span"}
                sx={{
                  display: "inline-block",
                  minWidth: "185px",
                  borderBottom: "4px solid #098484",
                  p: 1.5,
                  fontSize: 18,
                  fontWeight: 600,
                  ml: 1.5,
                  boxSizing: "border-box",
                }}
              >
                แก้ไข
              </Box>
            </Box>
            <Box sx={{ p: "20px" }}>
              <FormContainer
                defaultValues={{
                  nameTh: currentFood?.nameThai,
                  unit: currentFood?.measurementUnit,
                  description: currentFood?.description,
                }}
              >
                <Grid container rowGap={2}>
                  <Grid item sm={12} md={9} lg={9} sx={{ pr: 4 }}>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "#098484",
                        textAlign: "right",
                        cursor: "pointer",
                        fontSize: 16,
                      }}
                    >
                      <span onClick={handleEditFoodClick}>
                        <u>(คลิกที่นี่เพื่อแก้ไข)</u>
                      </span>
                    </Typography>
                    <Stack spacing={3} sx={{ ...labelForm }}>
                      <Stack spacing={5} direction={"row"}>
                        <Grid item sm={6} md={6} lg={8}>
                          <Stack spacing={spacingForm}>
                            <Typography color={"#646D78"}>
                              ชื่ออาหาร <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextFieldElement
                              name="nameTh"
                              required
                              variant="outlined"
                              size="small"
                              InputProps={{
                                value: currentFood?.nameThai,
                                sx: {
                                  width: 1,
                                  bgcolor: "#E3E7F0",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#3C4142",
                                  },
                                },
                              }}
                              disabled
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} md={6} lg={4}>
                          <Stack spacing={spacingForm}>
                            <Typography color={"#646D78"}>
                              หน่วยบริโภค{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextFieldElement
                              name="unit"
                              required
                              variant="outlined"
                              size="small"
                              InputProps={{
                                value: currentFood?.measurementUnit,
                                sx: {
                                  width: 1,
                                  bgcolor: "#E3E7F0",
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#3C4142",
                                  },
                                },
                              }}
                              disabled
                            />
                          </Stack>
                        </Grid>
                      </Stack>
                      <Stack spacing={spacingForm}>
                        <Typography color={"#646D78"}>คำอธิบาย</Typography>
                        <TextFieldElement
                          name="description"
                          variant="outlined"
                          size="small"
                          multiline
                          rows={3}
                          disabled
                          InputProps={{
                            value: currentFood?.description,
                            sx: {
                              width: 1,
                              bgcolor: "#E3E7F0",
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#3C4142",
                              },
                            },
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={3}
                    lg={3}
                    display={"flex"}
                    justifyContent={onlySmallScreen ? "flex-start" : "flex-end"}
                  >
                    <Box
                      id="box-outer"
                      sx={{
                        border: "2px dashed #CBD0DC",
                        width: 1,
                        height: 1,
                        maxWidth: "250px",
                        minHeight: "200px",
                        borderRadius: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {imagePreview ? (
                        <Box
                          id="box-preview"
                          sx={{
                            position: "relative",
                            height: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: "20px",
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          id="box-default"
                          sx={{
                            border: "2px dashed #CBD0DC",
                            width: 250,
                            height: 1,
                            minHeight: "200px",
                            borderRadius: 5,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundImage: `url(${defaultFoodImg})`,
                            backgroundSize: "cover",
                          }}
                        ></Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ mt: 4 }}
                >
                  <Stack>
                    <Typography fontSize={18} fontWeight={600}>
                      ข้อมูลอาหาร
                    </Typography>
                    <Typography fontSize={14}>
                      สามารถเพิ่มได้มากที่สุด 5 รายการ
                    </Typography>
                  </Stack>
                  {currentFood && currentFood.foodServingList?.length < 5 && (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#FF366B",
                        float: "right",
                        height: "40px",
                        fontSize: "14px",
                        marginBottom: "20px",
                        minWidth: "90px",
                      }}
                      startIcon={<AddIcon />}
                      onClick={handleAddClick}
                    >
                      เพิ่ม
                    </Button>
                  )}
                </Stack>
                <Box sx={{ mt: 2 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableCellValues.map((item, index) => (
                            <TableCell
                              key={index}
                              sx={{
                                fontSize: 16,
                                fontWeight: 700,
                                width: item.width,
                                textAlign: item.align,
                              }}
                            >
                              {item.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {currentFood &&
                        currentFood.foodServingList?.length > 0 && (
                          <TableBody>
                            {currentFood.foodServingList?.map((nutr, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {nutr.servingSize}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {nutr.protein}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {nutr.carbohydrate}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {nutr.sodium}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {nutr.fat}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                  {nutr.kcal}
                                </TableCell>
                                <TableCell>
                                  {nutr.activated ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginRight: "5px",
                                          height: "10px",
                                          width: "10px",
                                          borderRadius: "50%",
                                          backgroundColor: "green",
                                        }}
                                      />
                                      ใช้งานได้
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginRight: "5px",
                                          height: "10px",
                                          width: "10px",
                                          borderRadius: "50%",
                                          backgroundColor: "red",
                                        }}
                                      />
                                      ปิดการใช้งาน
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Stack
                                    spacing={1}
                                    direction={"row"}
                                    justifyContent={"center"}
                                  >
                                    <Box
                                      sx={{
                                        border: 1,
                                        borderRadius: 2,
                                        borderColor: "secondary.main",
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        fontSize: 14,
                                      }}
                                      onClick={() =>
                                        handleEditServingClick(nutr, nutr.id)
                                      }
                                    >
                                      <EditIcon
                                        fontSize="inherit"
                                        color="secondary"
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        border: "1px solid #979797",
                                        borderRadius: 2,
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "#7A8688",
                                        fontSize: 14,
                                        cursor: masterDataPermissionDeleteAble
                                          ? "pointer"
                                          : "not-allowed",
                                        pointerEvents:
                                          masterDataPermissionDeleteAble
                                            ? "auto"
                                            : "none",
                                      }}
                                      onClick={() => {
                                        if (masterDataPermissionDeleteAble) {
                                          handleDeleteServingClick(nutr.id);
                                        }
                                      }}
                                    >
                                      <DeleteIcon
                                        fontSize="inherit"
                                        color="inherit"
                                      />
                                    </Box>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                    </Table>
                  </TableContainer>
                  {(!currentFood ||
                    currentFood?.foodServingList?.length === 0) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 20,
                        color: "#9AA1AE",
                        py: 2,
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        height: "100px",
                      }}
                    >
                      {isLoading ? (
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <CircularProgress size={20} />
                          {` กำลังโหลดข้อมูล...`}
                        </Box>
                      ) : (
                        "--ไม่พบข้อมูล--"
                      )}
                    </Box>
                  )}
                </Box>
              </FormContainer>
            </Box>
          </Box>
        </Box>
        {/* Add Serving Dialog */}
        <Dialog
          open={openAddServingDialog}
          onClose={handleCloseDialog}
          fullWidth
        >
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddServing(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>หน่วยบริโภค</Typography>
                  <Typography>{currentFood?.measurementUnit ?? "-"}</Typography>
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    {`ปริมาณที่บริโภค (${currentFood?.measurementUnit})`}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="amount"
                    required
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{
                      value: servingSize?.toLocaleString(),
                    }}
                    onChange={(e) =>
                      Number(e.target.value) >= 0 &&
                      setServingSize(Number(e.target.value))
                    }
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ข้อมูลอาหาร</Typography>
                  <Box sx={{ border: "1px solid #E5EBEB", borderRadius: 2 }}>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2 }}
                    >
                      <Typography fontWeight={600} textAlign={"center"}>
                        Protein
                      </Typography>
                      <Typography fontWeight={600} textAlign={"center"}>
                        Carbohydrate
                      </Typography>
                      <Typography fontWeight={600} textAlign={"center"}>
                        Sodium
                      </Typography>
                      <Typography fontWeight={600} textAlign={"center"}>
                        Fat
                      </Typography>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2, borderTop: "1px solid #E5EBEB" }}
                    >
                      <TextFieldElement
                        name="protein"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: protein.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setProtein(Number(e.target.value))
                        }
                      />
                      <TextFieldElement
                        name="carbohydrate"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: carbohydrate.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setCarbohydrate(Number(e.target.value))
                        }
                      />
                      <TextFieldElement
                        name="sodium"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: sodium.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setSodium(Number(e.target.value))
                        }
                      />
                      <TextFieldElement
                        name="fat"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: fat.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setFat(Number(e.target.value))
                        }
                      />
                    </Box>
                  </Box>
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ปริมาณที่เผาผลาญ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="kcal"
                    required
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{
                      value: kcal?.toLocaleString(),
                    }}
                    onChange={(e) =>
                      Number(e.target.value) >= 0 &&
                      setKcal(Number(e.target.value))
                    }
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={activeStatus ? true : false}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={isPendingCreateServing}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Edit Serving Dialog */}
        <Dialog
          open={openEditServingDialog}
          onClose={handleCloseDialog}
          fullWidth
        >
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              defaultValues={{
                amount: currentServing?.servingSize,
                protein: currentServing?.protein,
                carbohydrate: currentServing?.carbohydrate,
                sodium: currentServing?.sodium,
                fat: currentServing?.fat,
                kcal: currentServing?.kcal,
              }}
              onSuccess={(data) => handleEditServing(data, currentServingIndex)}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>หน่วยบริโภค</Typography>
                  <Typography>{currentFood?.measurementUnit}</Typography>
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    {`ปริมาณที่บริโภค (${currentFood?.measurementUnit})`}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="amount"
                    required
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{
                      value: servingSize?.toLocaleString(),
                    }}
                    onChange={(e) =>
                      Number(e.target.value) >= 0 &&
                      setServingSize(Number(e.target.value))
                    }
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ข้อมูลอาหาร</Typography>
                  <Box sx={{ border: "1px solid #E5EBEB", borderRadius: 2 }}>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2 }}
                    >
                      <Typography fontWeight={600} textAlign={"center"}>
                        Protein
                      </Typography>
                      <Typography fontWeight={600} textAlign={"center"}>
                        Carbohydrate
                      </Typography>
                      <Typography fontWeight={600} textAlign={"center"}>
                        Sodium
                      </Typography>
                      <Typography fontWeight={600} textAlign={"center"}>
                        Fat
                      </Typography>
                    </Box>
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                      gap={3}
                      sx={{ p: 2, borderTop: "1px solid #E5EBEB" }}
                    >
                      <TextFieldElement
                        name="protein"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: protein.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setProtein(Number(e.target.value))
                        }
                      />
                      <TextFieldElement
                        name="carbohydrate"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: carbohydrate.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setCarbohydrate(Number(e.target.value))
                        }
                      />
                      <TextFieldElement
                        name="sodium"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: sodium.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setSodium(Number(e.target.value))
                        }
                      />
                      <TextFieldElement
                        name="fat"
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{
                          value: fat.toLocaleString(),
                        }}
                        onChange={(e) =>
                          Number(e.target.value) >= 0 &&
                          setFat(Number(e.target.value))
                        }
                      />
                    </Box>
                  </Box>
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ปริมาณที่เผาผลาญ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="kcal"
                    required
                    variant="outlined"
                    size="small"
                    type="number"
                    InputProps={{
                      value: kcal?.toLocaleString(),
                    }}
                    onChange={(e) =>
                      Number(e.target.value) >= 0 &&
                      setKcal(Number(e.target.value))
                    }
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={currentServing?.activated ?? false}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={isPendingUpdateServing}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        />
        {/* Edit Food  Dialog */}
        <Dialog open={openEditFoodDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              defaultValues={{
                name: currentFood?.nameThai,
                unit: currentFood?.measurementUnit,
                description: currentFood?.description,
              }}
              onSuccess={(data) => handleEditFood(data)}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่ออาหาร <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หน่วยบริโภค <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="unit"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Box
                  sx={{
                    border: "2px dashed #CBD0DC",
                    width: "180px",
                    height: "180px",
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundImage: imagePreviewTemp
                      ? ``
                      : `url(${defaultFoodImg})`,
                    backgroundSize: "cover",
                  }}
                >
                  {imagePreviewTemp ? (
                    <Box
                      sx={{
                        position: "relative",
                        height: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={imagePreviewTemp}
                        alt="Preview"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          cursor: "pointer",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <CancelOutlinedIcon sx={{ color: "#979797" }} />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography fontSize={16} fontWeight={500}>
                        เลือกไฟล์รูป
                      </Typography>
                      <Typography color={"#54575C"} fontWeight={500}>
                        JPEG, PNG, up to 50MB{" "}
                      </Typography>
                      <Typography color={"#54575C"} fontWeight={500}>
                        (ขนาดที่แนะนำ 180*180){" "}
                      </Typography>
                      {!imagePreviewTemp && (
                        <div style={{ marginTop: "8px" }}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={handleUploadClick}
                          />
                          <label htmlFor="contained-button-file">
                            <Stack direction={"row"} spacing={2}>
                              <Button
                                variant="contained"
                                component="span"
                                sx={{
                                  bgcolor: "white",
                                  color: "#54575C",
                                  "&:hover": {
                                    bgcolor: "white",
                                  },
                                }}
                              >
                                เลือกไฟล์
                              </Button>
                            </Stack>
                          </label>
                        </div>
                      )}
                    </Box>
                  )}
                </Box>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={isPendingUpdateFood}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Session Expired Dialog */}
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            navigate("/");
            setIsTokenExpired(false);
          }}
        />
        {/* Failure Dialog */}
        <ErrorDialog
          openDialog={isShowError}
          handleCloseDialog={() => {
            setIsShowError(false);
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }}
          title="เกิดข้อผิดพลาด"
          content={messageError}
        />
      </ThemeProvider>
    </Layout>
  );
}
