import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

export const TextGreyBox = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: "#F5F5F5",
      width: "60%",
      height: "48px",
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      px: 2,
      fontSize: 14,
    }}
  >
    {children}
  </Box>
);
export const TextWhiteBox = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: "white",
      width: "60%",
      // height: "40px",
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      px: 2,
      fontSize: 14,
    }}
  >
    {children}
  </Box>
);
export const RadioGreyBox = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: "#F5F5F5",
      // width: "20%",
      height: "48px",
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      px: 1,
      fontSize: 14,
    }}
  >
    {children}
  </Box>
);
export const YesNoBox = () => (
  <Box
    sx={{
      // height: "40px",
      width: "100px",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 3,
      fontSize: 14,
    }}
  >
    <Typography fontSize={14}>Yes</Typography>
    <Typography fontSize={14}>No</Typography>
  </Box>
);
