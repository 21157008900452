import { AllCollectData, FoodItem } from "../type";

type Props = {
  data: {
    morning: FoodItem[];
    lunch: FoodItem[];
    dinner: FoodItem[];
    other: FoodItem[];
  };
};
function customRound(num: number): number {
  const integerPart = Math.floor(num);
  const decimalPart = num - integerPart;

  if (decimalPart > 0.5) {
    return Math.ceil(num);
  } else if (decimalPart < 0.5) {
    return Math.floor(num);
  } else {
    const lastDigit = integerPart % 10;
    if (lastDigit % 2 === 0) {
      return integerPart;
    } else {
      return integerPart + 1;
    }
  }
}
export const calculateNutritionPercentages = ({ data }: Props) => {
  const calculateNutrientPercentages = (food: FoodItem) => {
    const totalNutrients = Number(
      (
        Number(food.totalProtein) +
        Number(food.totalCarbohydrate) +
        Number(food.totalSodium) +
        Number(food.totalFat)
      ).toFixed(1)
    );

    return {
      protein: Number(
        ((Number(food.totalProtein) / totalNutrients) * 100).toFixed(1)
      ),
      carbohydrate: Number(
        ((Number(food.totalCarbohydrate) / totalNutrients) * 100).toFixed(1)
      ),
      sodium: Number(
        ((Number(food.totalSodium) / totalNutrients) * 100).toFixed(1)
      ),
      fat: Number(((Number(food.totalFat) / totalNutrients) * 100).toFixed(1)),
    };
  };

  const allMeals = [
    ...(data.morning || []),
    ...(data.lunch || []),
    ...(data.dinner || []),
    ...(data.other || []),
  ];
  const totals = allMeals.reduce(
    (acc, food) => {
      const percentages = calculateNutrientPercentages(food);
      acc.protein += percentages.protein;
      acc.carbohydrate += percentages.carbohydrate;
      acc.sodium += percentages.sodium;
      acc.fat += percentages.fat;
      return acc;
    },
    { protein: 0, carbohydrate: 0, sodium: 0, fat: 0 }
  );
  const roundedTotals = {
    protein: customRound(totals.protein),
    carbohydrate: customRound(totals.carbohydrate),
    sodium: customRound(totals.sodium),
    fat: customRound(totals.fat),
  };

  const totalNutrientsSum =
    roundedTotals.protein +
    roundedTotals.carbohydrate +
    roundedTotals.sodium +
    roundedTotals.fat;
  const result = [
    {
      name: "Protein",
      y: customRound((roundedTotals.protein / totalNutrientsSum) * 100),
    },
    {
      name: "Carbohydrate",
      y: customRound((roundedTotals.carbohydrate / totalNutrientsSum) * 100),
    },
    {
      name: "Sodium",
      y: customRound((roundedTotals.sodium / totalNutrientsSum) * 100),
    },
    {
      name: "Fat",
      y: customRound((roundedTotals.fat / totalNutrientsSum) * 100),
    },
  ];
  return result;
  //   const mealPercentages = Object.entries(data).map(([mealType, foods]) => {
  //     const totals = foods?.reduce(
  //       (acc, food) => {
  //         const percentages = calculateNutrientPercentages(food);
  //         acc.protein += percentages.protein;
  //         acc.carbohydrate += percentages.carbohydrate;
  //         acc.sodium += percentages.sodium;
  //         acc.fat += percentages.fat;
  //         console.log(food.nameThai, acc.protein);
  //         return acc;
  //       },
  //       { protein: 0, carbohydrate: 0, sodium: 0, fat: 0 }
  //     );
  //     return {
  //       mealType,
  //       totalPercentages: totals,
  //     };
  //   });

  //   const grandTotals = mealPercentages.reduce(
  //     (acc, meal) => {
  //       acc.protein += meal.totalPercentages.protein;
  //       acc.carbohydrate += meal.totalPercentages.carbohydrate;
  //       acc.sodium += meal.totalPercentages.sodium;
  //       acc.fat += meal.totalPercentages.fat;
  //       return acc;
  //     },
  //     { protein: 0, carbohydrate: 0, sodium: 0, fat: 0 }
  //   );
  //   const roundedGrandTotals = {
  //     protein: customRound(grandTotals.protein),
  //     carbohydrate: customRound(grandTotals.carbohydrate),
  //     sodium: customRound(grandTotals.sodium),
  //     fat: customRound(grandTotals.fat),
  //   };

  //   const totalNutrientsSum =
  //     roundedGrandTotals.protein +
  //     roundedGrandTotals.carbohydrate +
  //     roundedGrandTotals.sodium +
  //     roundedGrandTotals.fat;

  //   const nutrientPercentages = {
  //     protein: customRound((grandTotals.protein / totalNutrientsSum) * 100),
  //     carbohydrate: customRound(
  //       (grandTotals.carbohydrate / totalNutrientsSum) * 100
  //     ),
  //     sodium: customRound((grandTotals.sodium / totalNutrientsSum) * 100),
  //     fat: customRound((grandTotals.fat / totalNutrientsSum) * 100),
  //   };

  //   const result = [
  //     {
  //       name: "Protein",
  //       y: customRound((grandTotals.protein / totalNutrientsSum) * 100),
  //     },
  //     {
  //       name: "Carbohydrate",
  //       y: customRound((grandTotals.carbohydrate / totalNutrientsSum) * 100),
  //     },
  //     {
  //       name: "Sodium",
  //       y: customRound((grandTotals.sodium / totalNutrientsSum) * 100),
  //     },
  //     { name: "Fat", y: customRound((grandTotals.fat / totalNutrientsSum) * 100) },
  //   ];
  //   return "result";
};

export const calculateAverageNutritionPercentages = (
  data: AllCollectData[]
) => {
  const totalNutrition = {
    protein: 0,
    carbohydrate: 0,
    sodium: 0,
    fat: 0,
  };

  data?.forEach((item) => {
    const dailyPercentages = calculateNutritionPercentages({
      data: item.foodIntake,
    });

    dailyPercentages.forEach((nutrient) => {
      if (nutrient.name === "Protein") {
        totalNutrition.protein += customRound(nutrient.y);
      } else if (nutrient.name === "Carbohydrate") {
        totalNutrition.carbohydrate += customRound(nutrient.y);
      } else if (nutrient.name === "Sodium") {
        totalNutrition.sodium += customRound(nutrient.y);
      } else if (nutrient.name === "Fat") {
        totalNutrition.fat += customRound(nutrient.y);
      }
    });
  });
  const numberOfDays = data?.length;
  const averageNutrition = {
    protein: totalNutrition.protein / numberOfDays,
    carbohydrate: totalNutrition.carbohydrate / numberOfDays,
    sodium: totalNutrition.sodium / numberOfDays,
    fat: totalNutrition.fat / numberOfDays,
  };

  return [
    {
      name: "Protein",
      y: customRound(Number(averageNutrition.protein.toFixed(1))),
    },
    {
      name: "Carbohydrate",
      y: customRound(Number(averageNutrition.carbohydrate.toFixed(1))),
    },
    {
      name: "Sodium",
      y: customRound(Number(averageNutrition.sodium.toFixed(1))),
    },
    { name: "Fat", y: customRound(Number(averageNutrition.fat.toFixed(1))) },
  ];
};
