import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { InvestigationEndoscopyDialog } from "./InvestigationEndoscopyDialog";
import { InvestigationRow } from "../../../components/InvestigateRow";
import { title10Tab } from "../../../style";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { InvestigationResponse } from "./Investigation";
import { store } from "../../../redux/store";

export type InvestigationEndoscopy = {
  hiatalHernia: string;
  refluxEsophagitis: string;
  gastritis: string;
  gastricDuodenalUlcer: string;
  hPyroliInfection: string;
  other: string;
  otherDetail: string[];
};
type Props = {
  handleSaveEndoscopyValues: (value: InvestigationEndoscopy) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
};
export const InvestigationEndoscopy = ({
  handleSaveEndoscopyValues,
  latestEntryId,
  currentEntryId,
}: Props) => {
  const [values, setValues] = useState<InvestigationEndoscopy>({
    hiatalHernia: "",
    refluxEsophagitis: "",
    gastritis: "",
    gastricDuodenalUlcer: "",
    hPyroliInfection: "",
    other: "",
    otherDetail: [""],
  });
  const [openEndoscopyDialog, setOpenEndoscopyDialog] = useState(false);
  const [endoscopIsDirty, setEndoscopIsDirty] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  const handleClickEndoscopy = () => {
    setOpenEndoscopyDialog(true);
  };
  const handleClickEndoscopyClose = (data: InvestigationEndoscopy) => {
    setValues(data);
    setOpenEndoscopyDialog(false);
  };
  const handleClickEndoscopySave = (data: InvestigationEndoscopy) => {
    if (patientDataPermissionEditAble === false) {
      setOpenEndoscopyDialog(false);
      setEndoscopIsDirty(false);
      return;
    }
    setValues(data);
    setOpenEndoscopyDialog(false);
    handleSaveEndoscopyValues(data);
    setEndoscopIsDirty(true);
  };
  const endoscopyList = [
    {
      title: "Hiatal Hernia",
      value: values.hiatalHernia,
    },
    {
      title: "Reflux Esophagitis",
      value: values.refluxEsophagitis,
    },
    {
      title: "Gastritis",
      value: values.gastritis,
    },
    {
      title: "Gastric Duodenal Ulcer",
      value: values.gastricDuodenalUlcer,
    },
    {
      title: "H.Pyroli Infection",
      value: values.hPyroliInfection,
    },
    {
      title: "Other",
      value: values.other,
    },
  ];
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(currentEntryId);
        setValues({
          hiatalHernia: investigationData.hiatalHernia ? "yes" : "no",
          refluxEsophagitis: investigationData.refluxEsophagitis ? "yes" : "no",
          gastritis: investigationData.gastritis ? "yes" : "no",
          gastricDuodenalUlcer: investigationData.gastric ? "yes" : "no",
          hPyroliInfection: investigationData.hpyroli ? "yes" : "no",
          other: investigationData.other ? "yes" : "no",
          otherDetail: investigationData.otherText ?? [""],
        });
      } else if (latestEntryId) {
        const investigationData: InvestigationResponse =
          await visitDataService.getInvestigationFromEntryId(latestEntryId);
        setValues({
          hiatalHernia: investigationData.hiatalHernia ? "yes" : "no",
          refluxEsophagitis: investigationData.refluxEsophagitis ? "yes" : "no",
          gastritis: investigationData.gastritis ? "yes" : "no",
          gastricDuodenalUlcer: investigationData.gastric ? "yes" : "no",
          hPyroliInfection: investigationData.hpyroli ? "yes" : "no",
          other: investigationData.other ? "yes" : "no",
          otherDetail: investigationData.otherText ?? [""],
        });
      }
    };
    fetchData();
  });
  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height={"100%"} sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>Endoscopy</Typography>
          <Typography
            style={{
              color: "#646D78",
              cursor: "pointer",
            }}
            onClick={handleClickEndoscopy}
          >
            แก้ไข
          </Typography>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          {endoscopyList.map((endoscopy, index) => (
            <InvestigationRow
              key={index}
              title={endoscopy?.title}
              value={
                currentEntryId || endoscopIsDirty ? endoscopy?.value : undefined
              }
            />
          ))}
        </Box>
      </Box>
      <InvestigationEndoscopyDialog
        open={openEndoscopyDialog}
        onClose={handleClickEndoscopyClose}
        onSave={handleClickEndoscopySave}
        {...values}
      />
    </Box>
  );
};
