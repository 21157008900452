import { Box, Paper, Stack, Typography } from "@mui/material";
import { body14500, body14700, title16600 } from "../../style";
import { PatientUser } from "../../type";
import { calculateBmi } from "../../utils/calculateBmi";

type Props = {
  patient: PatientUser;
  weight: number;
};

export const BmiCard = ({ patient, weight }: Props) => {
  return (
    <Paper elevation={4} sx={{ borderRadius: 2, height: "100%" }}>
      <Box sx={{ p: 2 }}>
        <Typography style={title16600}>BMI</Typography>
        <Typography style={body14500} sx={{ color: "#B5B5C3" }}>
          Lasted Body Mass Index
        </Typography>
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ pb: 1, borderBottom: "1px dashed #E1E3EA" }}
          >
            <Typography style={body14500} sx={{ color: "#7E8299" }}>
              BMI
            </Typography>
            <Box>
              <Typography style={body14700} sx={{ color: "#181C32" }}>
                {calculateBmi(weight, patient.height)}
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ pb: 1, borderBottom: "1px dashed #E1E3EA" }}
          >
            <Typography style={body14500} sx={{ color: "#7E8299" }}>
              ส่วนสูง (Height)
            </Typography>
            <Box>
              <Typography
                style={body14700}
                sx={{ color: "#181C32" }}
              >{`${patient.height} cm`}</Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ pb: 1, borderBottom: "1px dashed #E1E3EA" }}
          >
            <Typography style={body14500} sx={{ color: "#7E8299" }}>
              น้ำหนัก (Weight)
            </Typography>
            <Box>
              <Typography
                style={body14700}
                sx={{ color: "#181C32" }}
              >{`${weight} kg`}</Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};
