import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { BodyAndCompositionDialog } from "./BodyAndCompositionDialog";
import { BodyLabRow } from "../../../components/BodyLabRow";
import { useEffectOnce } from "react-use";
import { BodyLabResponse } from "./BodyAndCompositionAndLab";
import { visitDataService } from "../../../service/visitDataService";
import { calculateBmi } from "../../../utils/calculateBmi";
import { calculateIdealWeight } from "../../../utils/calculateIdealWeight";
import { calculateEWL } from "../../../utils/calculateEWL";
import { calculateTWL } from "../../../utils/calculateTWL";
import { calculateEBMIL } from "../../../utils/calculateEBMIL";
import { title10Tab } from "../../../style";
import { store } from "../../../redux/store";
import { masterDataService } from "../../../service/masterDataService";
import { LabItem } from "../../../Pages/MaterData/LabMaster";

export type BodyAndCompositionValues = {
  sbp: string;
  dbp: string;
  weight: number;
  smm: string;
  bfm: string;
  pbf: string;
  previousWeight?: number | null;
  height?: number | null;
};

type Props = {
  handleSaveBodyAndCompositionValues: (value: BodyAndCompositionValues) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
  currentWeight?: number | null;
  previousWeight?: number | null;
  height?: number | null;
};

export const BodyAndComposition = ({
  handleSaveBodyAndCompositionValues,
  latestEntryId,
  currentEntryId,
  currentWeight,
  previousWeight,
  height,
}: Props) => {
  const [values, setValues] = useState<BodyAndCompositionValues>({
    sbp: "",
    dbp: "",
    weight: currentWeight ? currentWeight : 0,
    smm: "",
    bfm: "",
    pbf: "",
    previousWeight: previousWeight ? previousWeight : 0,
    height: height ? height : 0,
  });
  const [openBodyAndCompositionDialog, setOpenBodyAndCompositionDialog] =
    useState(false);
  const [bodyAndCompositionIsDirty, setBodyAndCompositionIsDirty] =
    useState(false);
  const handleClickBodyAndComposition = () => {
    setOpenBodyAndCompositionDialog(true);
  };

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  const handleClickCancle = (newValues: BodyAndCompositionValues) => {
    setValues(newValues);
    setOpenBodyAndCompositionDialog(false);
  };
  const handleSave = (newValues: BodyAndCompositionValues) => {
    if (patientDataPermissionEditAble === true) {
      setValues(newValues);
      handleSaveBodyAndCompositionValues(newValues);
      setOpenBodyAndCompositionDialog(false);
      setBodyAndCompositionIsDirty(true);
    } else {
      setOpenBodyAndCompositionDialog(false);
    }
  };

  const [labList, setLabList] = useState<LabItem[]>([]);
  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const bodyLabData: BodyLabResponse =
          await visitDataService.getBodyLabFromEntryId(currentEntryId);
        setValues({
          sbp: bodyLabData.sbp,
          dbp: bodyLabData.dbp,
          weight: currentWeight ? currentWeight : 0,
          smm: bodyLabData.smm,
          bfm: bodyLabData.bfm,
          pbf: bodyLabData.pbf,
          previousWeight: previousWeight ? previousWeight : 0,
          height: height ? height : 0,
        });
      }
      const masterData = await masterDataService.getLabMasterData();
      setLabList(masterData);
      // else if (latestEntryId) {
      //   const bodyLabData: BodyLabResponse =
      //     await visitDataService.getBodyLabFromEntryId(latestEntryId);
      //   setValues({
      //     sbp: bodyLabData.sbp,
      //     dbp: bodyLabData.sbp,
      //     weight: currentWeight ? Math.round(currentWeight * 100) / 100 : 0,
      //     smm: bodyLabData.smm,
      //     bfm: bodyLabData.bfm,
      //     pbf: bodyLabData.pbf,
      //     previousWeight: previousWeight,
      //     height: height,
      //   });
      // }
    };
    fetchData();
  });

  const getTitleAndUnit = (id: number) => {
    const data = labList.find((item) => item.id === id);
    if (data) {
      return {
        title: data.name,
        unit: data.measurementUnit,
        maximum: data.maximum,
        minimum: data.minimum,
        maximumWarning: data.maximumWarning,
        minimumWarning: data.minimumWarning,
      };
    }
    return { title: "Unknown", unit: "" };
  };

  const sbpData = getTitleAndUnit(1); // Assuming sbp id is 1
  const dbpData = getTitleAndUnit(2); // Assuming dbp id is 2
  const weightData = getTitleAndUnit(3); // Assuming weight id is 3
  const smmData = getTitleAndUnit(4); // Assuming smm id is 4
  const bfmData = getTitleAndUnit(5); // Assuming bfm id is 5
  const pbfData = getTitleAndUnit(6); // Assuming pbf id is 6

  const bodyComposition = [
    {
      title: sbpData.title !== "Unknown" ? sbpData.title : "SBP",
      value: values.sbp,
      unit: sbpData.unit ? sbpData.unit : "mmHg",
      maximum: sbpData.maximum,
      minimum: sbpData.minimum,
      maximumWarning: sbpData.maximumWarning,
      minimumWarning: sbpData.minimumWarning,
    },
    {
      title: dbpData.title !== "Unknown" ? dbpData.title : "DBP",
      value: values.dbp,
      unit: dbpData.unit ? dbpData.unit : "mmHg",
      maximum: dbpData.maximum,
      minimum: dbpData.minimum,
      maximumWarning: dbpData.maximumWarning,
      minimumWarning: dbpData.minimumWarning,
    },
    {
      title: weightData.title !== "Unknown" ? weightData.title : "Weight",
      value: currentWeight ? currentWeight : "",
      unit: weightData.unit ? weightData.unit : "kg",
      maximum: weightData.maximum,
      minimum: weightData.minimum,
      maximumWarning: weightData.maximumWarning,
      minimumWarning: weightData.minimumWarning,
    },
    {
      title: "BMI",
      value:
        currentWeight && height
          ? calculateBmi(currentWeight, height).toString()
          : "(Auto calculation)",
      unit: null,
    },
    {
      title: "Ideal weight",
      value: height
        ? calculateIdealWeight(height).toString()
        : "(Auto calculation)",
      unit: null,
    },
    {
      title: "Percent of excess weight loss (%EWL)",
      value:
        currentWeight && previousWeight && height
          ? calculateEWL(
              currentWeight,
              previousWeight,
              calculateIdealWeight(height)
            ).toString()
          : "-",
      unit: null,
    },
    {
      title: "Percent of total weight loss (%TWL)",
      value:
        currentWeight && previousWeight
          ? calculateTWL(currentWeight, previousWeight).toString()
          : "-",
      unit: null,
    },
    {
      title: "Percent excess BMI loss (%EBMIL)",
      value:
        currentWeight && previousWeight && height
          ? calculateEBMIL(currentWeight, previousWeight, height).toString()
          : "-",
      unit: null,
    },
    {
      title: smmData.title !== "Unknown" ? smmData.title : "SMM",
      value: values.smm,
      unit: smmData.unit ? smmData.unit : "kg",
      maximum: smmData.maximum,
      minimum: smmData.minimum,
      maximumWarning: smmData.maximumWarning,
      minimumWarning: smmData.minimumWarning,
    },
    {
      title: bfmData.title !== "Unknown" ? bfmData.title : "BFM",
      value: values.bfm,
      unit: bfmData.unit ? bfmData.unit : "kg",
      maximum: bfmData.maximum,
      minimum: bfmData.minimum,
      maximumWarning: bfmData.maximumWarning,
      minimumWarning: bfmData.minimumWarning,
    },
    {
      title: pbfData.title !== "Unknown" ? pbfData.title : "PBF",
      value: values.pbf,
      unit: pbfData.unit ? pbfData.unit : "%",
      maximum: pbfData.maximum,
      minimum: pbfData.minimum,
      maximumWarning: pbfData.maximumWarning,
      minimumWarning: pbfData.minimumWarning,
    },
  ];

  useEffect(() => {
    setValues({
      ...values,
      ["weight"]: currentWeight ? currentWeight : 0,
      ["previousWeight"]: previousWeight ? previousWeight : 0,
      ["height"]: height ? height : 0,
    });
  }, [currentWeight, previousWeight, height]);
  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height={"100%"} sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>
            Body Composition (Current Visit)
          </Typography>
          <Typography
            style={{
              color: "#646D78",
              cursor: "pointer",
            }}
            onClick={handleClickBodyAndComposition}
          >
            แก้ไข
          </Typography>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          {bodyComposition.map((bodyComposition, index) => (
            <BodyLabRow
              key={index}
              title={bodyComposition?.title ?? ""}
              value={
                currentEntryId || bodyAndCompositionIsDirty
                  ? bodyComposition?.value ?? undefined
                  : undefined
              }
              detail={bodyComposition?.unit ?? undefined}
              maximum={bodyComposition?.maximum ?? undefined}
              minimum={bodyComposition?.minimum ?? undefined}
              maximumWarning={bodyComposition?.maximumWarning ?? undefined}
              minimumWarning={bodyComposition?.minimumWarning ?? undefined}
            />
          ))}
        </Box>
      </Box>
      <BodyAndCompositionDialog
        open={openBodyAndCompositionDialog}
        onClose={handleClickCancle}
        onSave={handleSave}
        {...values}
      />
    </Box>
  );
};
