import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { RadioGreyBox, TextGreyBox } from "../../../components/TextBox";
import { ContraceptionState } from "./Gynecologic";
import { OtherAdd, OtherArrow, OtherRemove } from "../../../asset/iconSvg";
import { Info } from "../../../asset/iconSvg";
import { tootlipPlacement } from "../../../style";
import { masterDataService } from "../../../service/masterDataService";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { ContraceptionItem } from "../../../Pages/MaterData/ContraceptionMaster";
import { ErrorDialog } from "../../../components/dialog/ErrorDialog";
import { useNavigate } from "react-router";

type Props = {
  ContraceptionValues: ContraceptionState;
  setContraceptionValues: React.Dispatch<
    React.SetStateAction<ContraceptionState>
  >;
  otherDetails: string[];
  handleContraceptionChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleOtherAdd: () => void;
  handleOtherRemove: (index: number) => void;
  handleOtherChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  validationError: boolean;
};
export const GynecologicContraception = ({
  ContraceptionValues,
  setContraceptionValues,
  otherDetails,
  handleContraceptionChange,
  handleOtherAdd,
  handleOtherRemove,
  handleOtherChange,
  validationError,
}: Props) => {
  const navigate = useNavigate();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isFetchData, setIsFetchData] = useState(false);
  const [contraceptionList, setContraceptionList] = useState<
    ContraceptionItem[]
  >([]);
  const [messageError, setMessageError] = useState(
    "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
  );

  const queryFunction = async () => {
    const response = await masterDataService.getContraceptionMasterData();
    return response;
  };
  const {
    data: contraceptionData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["contraceptionMasterData"],
    queryFn: queryFunction,
    enabled: false,
  });

  useEffect(() => {
    if (error && error instanceof AxiosError) {
      if (error.response?.status === 401) {
        setIsTokenExpired(true);
      } else {
        setIsShowError(true);
      }
    }
  }, [error]);

  useEffect(() => {
    refetch();
  }, [isFetchData]);
  useEffect(() => {
    if (contraceptionData) {
      const activeContraceptionItem = contraceptionData.filter(
        (item: ContraceptionItem) =>
          item.isActivated === true && item.name !== "อื่นๆ"
      );
      setContraceptionList(activeContraceptionItem);
    } else {
      setContraceptionList([]);
    }
  }, [contraceptionData]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.name);
    setContraceptionValues((prevValues) => ({
      ...prevValues,
      selectedContraceptions: event.target.checked
        ? [...(prevValues.selectedContraceptions || []), id]
        : (prevValues.selectedContraceptions || []).filter(
            (contraceptionId) => contraceptionId !== id
          ),
    }));
  };

  return (
    <Box sx={{ pt: 3 }}>
      <Divider sx={{ width: "100%", mb: 3 }} />
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography color={"#646D78"}>
            frequency of sexual intercourse{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value={"1"}
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "1"
                  }
                />
              }
              label="<  1 ครั้งต่อเดือน"
            />
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value="2"
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "2"
                  }
                />
              }
              label="1 ครั้งต่อเดือน"
            />
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value="3"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "3"
                  }
                />
              }
              label="1 ครั้งต่อสัปดาห์"
            />
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value="4"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "4"
                  }
                />
              }
              label=">= 2 ครั้งต่อสัปดาห์"
            />
          </Box>
        </Stack>
        <Stack spacing={1} width={"90%"}>
          <Typography color={"#646D78"}>
            Contraception <span style={{ color: "red" }}>*</span>{" "}
            <Tooltip
              title="Total iron-binding capacity"
              placement={tootlipPlacement}
              PopperProps={{
                sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
              }}
            >
              <span>
                <Info width={15} height={15} color="#A0ABBA" />
              </span>
            </Tooltip>
          </Typography>
          <Grid container rowGap={1}>
            {contraceptionList.map((contraception, index) => (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Stack spacing={1}>
                  <Stack direction={"row"} spacing={1}>
                    <TextGreyBox>{contraception.name}</TextGreyBox>
                    <RadioGreyBox>
                      <Checkbox
                        name={contraception.id.toString()}
                        checked={
                          ContraceptionValues.selectedContraceptions?.includes(
                            contraception.id
                          ) || false
                        }
                        onChange={handleCheckboxChange}
                        value="yes"
                        style={{ color: "#098484" }}
                      />
                    </RadioGreyBox>
                  </Stack>
                </Stack>
              </Grid>
            ))}
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Stack direction={"row"} spacing={1}>
                <TextGreyBox>Other (อื่นๆ) โปรดระบุ</TextGreyBox>
                <RadioGreyBox>
                  <Checkbox
                    name="other"
                    checked={ContraceptionValues.other}
                    onChange={handleContraceptionChange}
                    value="yes"
                    style={{ color: "#098484" }}
                  />
                </RadioGreyBox>
              </Stack>

              {ContraceptionValues.other && (
                <Stack spacing={1}>
                  {otherDetails?.map((detail, index) => {
                    return (
                      <Stack
                        key={index}
                        spacing={1}
                        direction={"row"}
                        // alignItems={"center"}
                      >
                        <Box sx={{ pt: 1 }}>
                          <OtherArrow />
                        </Box>

                        <TextField
                          required
                          sx={{ width: "61%" }}
                          value={detail}
                          onChange={(e) => handleOtherChange(index, e)}
                          color="secondary"
                          error={validationError && !detail.trim()}
                          helperText={
                            validationError && !detail.trim()
                              ? "This field is required"
                              : ""
                          }
                        />
                        <Box
                          sx={{ pt: 0.5 }}
                          onClick={() => handleOtherRemove(index)}
                        >
                          <OtherRemove />
                        </Box>
                        <Box sx={{ pt: 0.5 }} onClick={handleOtherAdd}>
                          <OtherAdd />
                        </Box>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <ErrorDialog
        openDialog={isShowError}
        handleCloseDialog={() => {
          setIsShowError(false);
          setMessageError(
            "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
          );
        }}
        title="เกิดข้อผิดพลาด"
        content={messageError}
      />
      {/* Session Expired Dialog */}
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Box>
  );
};
