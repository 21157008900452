import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { ChemistryDialog } from "./ChemistryDialog";
import { BodyLabRow } from "../../../components/BodyLabRow";
import { useEffectOnce } from "react-use";
import { BodyLabResponse } from "./BodyAndCompositionAndLab";
import { visitDataService } from "../../../service/visitDataService";
import { title10Tab } from "../../../style";
import { store } from "../../../redux/store";
import { masterDataService } from "../../../service/masterDataService";
import { LabItem } from "../../../Pages/MaterData/LabMaster";

export type ChemistryValues = {
  FBS: string;
  HbA1C: string;
  cholesterol: string;
  HDL: string;
  triglyceride: string;
  LDLC: string;
  creatinine: string;
  albumin: string;
  SGOT: string;
  SGPT: string;
  ALP: string;
};

type Props = {
  handleSaveChemistryValues: (value: ChemistryValues) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
};
export const Chemistry = ({
  handleSaveChemistryValues,
  latestEntryId,
  currentEntryId,
}: Props) => {
  const [values, setValues] = useState<ChemistryValues>({
    FBS: "",
    HbA1C: "",
    cholesterol: "",
    HDL: "",
    triglyceride: "",
    LDLC: "",
    creatinine: "",
    albumin: "",
    SGOT: "",
    SGPT: "",
    ALP: "",
  });
  const [openChemistryDialog, setOpenChemistryDialog] = useState(false);
  const [chemistryIsDirty, setChemistryIsDirty] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [labList, setLabList] = useState<LabItem[]>([]);
  const [masterData, setMasterData] = useState([]);

  const handleClickChemistry = () => {
    setOpenChemistryDialog(true);
  };
  const handleClickCancle = (newValues: ChemistryValues) => {
    setValues(newValues);
    setOpenChemistryDialog(false);
  };
  const handleClickSave = (newValues: ChemistryValues) => {
    if (patientDataPermissionEditAble === true) {
      setValues(newValues);
      handleSaveChemistryValues(newValues);
      setOpenChemistryDialog(false);
      setChemistryIsDirty(true);
    } else {
      setOpenChemistryDialog(false);
    }
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const bodyLabData: BodyLabResponse =
          await visitDataService.getBodyLabFromEntryId(currentEntryId);
        setValues({
          FBS: bodyLabData.fbs,
          HbA1C: bodyLabData.hba1c,
          cholesterol: bodyLabData.chol,
          HDL: bodyLabData.hdl,
          triglyceride: bodyLabData.tg,
          LDLC: bodyLabData.ldlc,
          creatinine: bodyLabData.cr,
          albumin: bodyLabData.alb,
          SGOT: bodyLabData.sgot,
          SGPT: bodyLabData.sgpt,
          ALP: bodyLabData.alp,
        });
      }
      const masterData = await masterDataService.getLabMasterData();
      setMasterData(masterData);
      setLabList(masterData);
      // else if (latestEntryId) {
      //   const bodyLabData: BodyLabResponse =
      //     await visitDataService.getBodyLabFromEntryId(latestEntryId);
      //   setValues({
      //     FBS: bodyLabData.fbs,
      //     HbA1C: bodyLabData.hba1c,
      //     cholesterol: bodyLabData.chol,
      //     HDL: bodyLabData.hdl,
      //     triglyceride: bodyLabData.tg,
      //     LDLC: bodyLabData.ldlc,
      //     creatinine: bodyLabData.cr,
      //     albumin: bodyLabData.alb,
      //     SGOT: bodyLabData.sgot,
      //     SGPT: bodyLabData.sgpt,
      //     ALP: bodyLabData.alp,
      //   });
      // }
    };
    fetchData();
  });

  const getTitleAndUnit = (id: number) => {
    const data = labList.find((item) => item.id === id);
    if (data) {
      return {
        title: data.name,
        unit: data.measurementUnit,
        maximum: data.maximum,
        minimum: data.minimum,
        maximumWarning: data.maximumWarning,
        minimumWarning: data.minimumWarning,
      };
    }
    return { title: "Unknown", unit: "" };
  };

  const fbsData = getTitleAndUnit(7);
  const hba1cData = getTitleAndUnit(8);
  const cholesterolData = getTitleAndUnit(9);
  const hdlData = getTitleAndUnit(10);
  const triglycerideData = getTitleAndUnit(11);
  const ldlcData = getTitleAndUnit(12);
  const creatinineData = getTitleAndUnit(13);
  const albuminData = getTitleAndUnit(14);
  const sgotData = getTitleAndUnit(15);
  const sgptData = getTitleAndUnit(16);
  const alpData = getTitleAndUnit(17);

  const chemistryList = [
    {
      title: fbsData.title !== "Unknown" ? fbsData.title : "FBS",
      value: values.FBS,
      unit: fbsData.unit ? fbsData.unit : "mg/dL",
      maximum: fbsData.maximum,
      minimum: fbsData.minimum,
      maximumWarning: fbsData.maximumWarning,
      minimumWarning: fbsData.minimumWarning,
    },
    {
      title: hba1cData.title !== "Unknown" ? hba1cData.title : "HbA1C",
      value: values.HbA1C,
      unit: hba1cData.unit ? hba1cData.unit : "%",
      maximum: hba1cData.maximum,
      minimum: hba1cData.minimum,
      maximumWarning: hba1cData.maximumWarning,
      minimumWarning: hba1cData.minimumWarning,
    },
    {
      title:
        cholesterolData.title !== "Unknown"
          ? cholesterolData.title
          : "Cholesterol",
      value: values.cholesterol,
      unit: cholesterolData.unit ? cholesterolData.unit : "mg/dL",
      maximum: cholesterolData.maximum,
      minimum: cholesterolData.minimum,
      maximumWarning: cholesterolData.maximumWarning,
      minimumWarning: cholesterolData.minimumWarning,
    },
    {
      title: hdlData.title !== "Unknown" ? hdlData.title : "HDL",
      value: values.HDL,
      unit: hdlData.unit ? hdlData.unit : "mg/dL",
      maximum: hdlData.maximum,
      minimum: hdlData.minimum,
      maximumWarning: hdlData.maximumWarning,
      minimumWarning: hdlData.minimumWarning,
    },
    {
      title:
        triglycerideData.title !== "Unknown"
          ? triglycerideData.title
          : "Triglyceride",
      value: values.triglyceride,
      unit: triglycerideData.unit ? triglycerideData.unit : "mg/dL",
      maximum: triglycerideData.maximum,
      minimum: triglycerideData.minimum,
      maximumWarning: triglycerideData.maximumWarning,
      minimumWarning: triglycerideData.minimumWarning,
    },
    {
      title: ldlcData.title !== "Unknown" ? ldlcData.title : "LDL-C",
      value: values.LDLC,
      unit: ldlcData.unit ? ldlcData.unit : "mg/dL",
      maximum: ldlcData.maximum,
      minimum: ldlcData.minimum,
      maximumWarning: ldlcData.maximumWarning,
      minimumWarning: ldlcData.minimumWarning,
    },
    {
      title:
        creatinineData.title !== "Unknown"
          ? creatinineData.title
          : "Creatinine",
      value: values.creatinine,
      unit: creatinineData.unit ? creatinineData.unit : "mg/dL",
      maximum: creatinineData.maximum,
      minimum: creatinineData.minimum,
      maximumWarning: creatinineData.maximumWarning,
      minimumWarning: creatinineData.minimumWarning,
    },
    {
      title: albuminData.title !== "Unknown" ? albuminData.title : "Albumin",
      value: values.albumin,
      unit: albuminData.unit ? albuminData.unit : "g/dL",
      maximum: albuminData.maximum,
      minimum: albuminData.minimum,
      maximumWarning: albuminData.maximumWarning,
      minimumWarning: albuminData.minimumWarning,
    },
    {
      title: sgotData.title !== "Unknown" ? sgotData.title : "SGOT",
      value: values.SGOT,
      unit: sgotData.unit ? sgotData.unit : "u/L",
      maximum: sgotData.maximum,
      minimum: sgotData.minimum,
      maximumWarning: sgotData.maximumWarning,
      minimumWarning: sgotData.minimumWarning,
    },
    {
      title: sgptData.title !== "Unknown" ? sgptData.title : "SGPT",
      value: values.SGPT,
      unit: sgptData.unit ? sgptData.unit : "u/L",
      maximum: sgptData.maximum,
      minimum: sgptData.minimum,
      maximumWarning: sgptData.maximumWarning,
      minimumWarning: sgptData.minimumWarning,
    },
    ,
    {
      title: alpData.title !== "Unknown" ? alpData.title : "ALP",
      value: values.ALP,
      unit: alpData.unit ? alpData.unit : "u/L",
      maximum: alpData.maximum,
      minimum: alpData.minimum,
      maximumWarning: alpData.maximumWarning,
      minimumWarning: alpData.minimumWarning,
    },
  ];

  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height="100%" sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>Chemistry</Typography>
          <Typography
            sx={{
              color: "#646D78",
              cursor: "pointer",
            }}
            onClick={handleClickChemistry}
          >
            แก้ไข
          </Typography>
        </Box>
        <Box sx={{ px: 2, py: 1 }}>
          {chemistryList.map((chemistry, index) => (
            <BodyLabRow
              key={index}
              title={chemistry?.title ?? ""}
              value={
                currentEntryId || chemistryIsDirty
                  ? chemistry?.value ?? undefined
                  : "-"
              }
              detail={chemistry?.unit ?? undefined}
              maximum={chemistry?.maximum ?? undefined}
              minimum={chemistry?.minimum ?? undefined}
              maximumWarning={chemistry?.maximumWarning ?? undefined}
              minimumWarning={chemistry?.minimumWarning ?? undefined}
            />
          ))}
        </Box>
      </Box>
      <ChemistryDialog
        open={openChemistryDialog}
        onClose={handleClickCancle}
        onSave={handleClickSave}
        {...values}
      />
    </Box>
  );
};
