import dayjs, { Dayjs } from "dayjs";

const currentYear = dayjs().year();
const isLeapYear = (year: number) =>
  (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
const daysInYear = isLeapYear(currentYear) ? 366 : 365;

export const generateXLabels = (lengthType: string, startDate: Dayjs) => {
  //eslint-disable-next-line
  const xLabels: any[] = [];
  for (
    let i = 0;
    i <
    (lengthType === "Weekly"
      ? 7
      : lengthType === "Monthly"
      ? dayjs().daysInMonth()
      : daysInYear);
    i++
  ) {
    xLabels.push(startDate.add(i, "day").format("YYYY-MM-DD"));
  }
  return xLabels;
};
