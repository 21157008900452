export const getEarliestDate = (data1: any, data2: any) => {
  const allDates = [
    ...data1.map((item: any) => item.date),
    ...data2.map((item: any) => item.date),
  ];
  const allDateObjects = allDates.map((date) => new Date(date));
  const earliestDate = new Date(
    Math.min(...allDateObjects.map((date) => date.getTime()))
  );
  //   const earliestDateString = earliestDate.toISOString().split("T")[0];
  return earliestDate;
};

export const getLatestDate = (data1: any, data2: any) => {
  const allDates = [
    ...data1.map((item: any) => item.date),
    ...data2.map((item: any) => item.date),
  ];
  const allDateObjects = allDates.map((date) => new Date(date));
  const latestDate = new Date(
    Math.max(...allDateObjects.map((date) => date.getTime()))
  );
  //   const earliestDateString = latestDate.toISOString().split("T")[0];
  return latestDate;
};

export const getMinWeight = (data1: any, data2: any) => {
  const allWeight = [
    ...data1.map((item: any) => item.weight),
    ...data2.map((item: any) => item.weight),
  ];
  const minWeight = Math.min(...allWeight);
  return minWeight;
};

export const getMaxWeight = (data1: any, data2: any) => {
  const allWeight = [
    ...data1.map((item: any) => item.weight),
    ...data2.map((item: any) => item.weight),
  ];
  const maxWeight = Math.max(...allWeight);
  return maxWeight;
};

export const getWeightEarliestDate = (data1: any, data2: any) => {
  const allData = [...data1, ...data2];
  const earliestDate = allData.reduce((earliest, item) => {
    const currentDate = new Date(item.date);
    return currentDate < earliest ? currentDate : earliest;
  }, new Date());
  const weightAtEarliestDate = allData.find(
    (item) => new Date(item.date).toISOString() === earliestDate.toISOString()
  );
  return weightAtEarliestDate?.weight;
};
