import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { EndOfStudyResponse } from "./EndOfStudy";
import { userManagementService } from "../../../service/userManagementService";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};

type EndOfStudy = {
  editorId: string | number;
  endOfStudy: boolean;
  endOfStudyType: number;
  endOfStudyDate: string;
  daySinceLastVisit: number;
};

const endOfStudyTypeLabels = [
  { value: 2, label: "Dead" },
  { value: 1, label: "Loss to f/u" },
];

const EndOfStudyLabels: { [key in keyof EndOfStudy]: string } = {
  editorId: "Editor",
  endOfStudy: "End of study",
  endOfStudyType: "End of study type",
  endOfStudyDate: "End of study date",
  daySinceLastVisit: "Amount of time since the latest visit",
};

export const EndOfStudyVisitLogDialog = ({
  open,
  handleCloseDialog,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [endOfStudyHistory, setEndOfStudyHistory] = useState<
    EndOfStudyResponse[]
  >([]);
  const endOfStudykey = Object.keys(EndOfStudyLabels) as (keyof EndOfStudy)[];

  useEffect(() => {
    const fetchData = async () => {
      const visitId = store.getState().patientUser.selectedVisitId;
      if (visitId) {
        try {
          const endOfStudyHistory: EndOfStudyResponse[] =
            await visitDataService.getEndOfStudyVisitLog(visitId);
          if (endOfStudyHistory) {
            const updateEndOfStudyLog = await Promise.all(
              endOfStudyHistory.map(async (entry) => {
                try {
                  if (entry.editorId) {
                    const userDetails =
                      await userManagementService.getUserDetail(
                        entry.editorId.toString()
                      );
                    entry.editorId = `${userDetails.firstName} ${userDetails.lastName}`;
                  } else {
                    entry.editorId = "-";
                  }
                } catch (error) {
                  console.error("Failed to fetch user details:", error);
                  entry.editorId = "-";
                }
                return entry;
              })
            );
            setEndOfStudyHistory(updateEndOfStudyLog);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>History Log</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Edit Date
                </TableCell>

                {endOfStudyHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.createDateTime?.toString()).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {endOfStudykey.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "1px solid #E5EBEB",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      bgcolor: "white",
                    }}
                  >
                    {EndOfStudyLabels[key]}
                  </TableCell>

                  {endOfStudyHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          alignContent: "baseline",
                        }}
                      >
                        {key === "endOfStudyDate" ? (
                          entry[key as keyof EndOfStudyResponse] ? (
                            <div>
                              {dayjs(entry.endOfStudyDate).format("DD/MM/YYYY")}
                            </div>
                          ) : (
                            "-"
                          )
                        ) : key === "editorId" ? (
                          entry[key as keyof EndOfStudyResponse] ? (
                            <div>{entry.editorId}</div>
                          ) : (
                            "-"
                          )
                        ) : key === "endOfStudyType" ? (
                          entry[key as keyof EndOfStudyResponse] ? (
                            <div>
                              {
                                endOfStudyTypeLabels.find(
                                  (type) => type.value === entry.endOfStudyType
                                )?.label
                              }
                            </div>
                          ) : (
                            "-"
                          )
                        ) : key === "daySinceLastVisit" ? (
                          entry[key as keyof EndOfStudyResponse] ? (
                            <div>{entry.daySinceLastVisit}</div>
                          ) : (
                            "-"
                          )
                        ) : entry[key as keyof EndOfStudyResponse] ? (
                          "Yes"
                        ) : (
                          "No"
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={endOfStudyHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};
