import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { MedicationResponse } from "./MedAndNutritional";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};
interface  MedAndNutritional{
  medicationUse: boolean,
  dmMed: boolean,
  glp1Ra: boolean,
  insulin: boolean,
  dlpMed: boolean,
  htMed: boolean,
  dmOther: boolean,
  supplementUse: boolean,
  calcium: boolean,
  vitaminD: boolean,
  iron: boolean,
  vitaminB12: boolean
}
const medAndNutritionalLabels: { [key in keyof MedAndNutritional]: string } = {
  medicationUse: "Medication Use",
  dmMed: "DM med",
  glp1Ra: "GLP1 RA",
  insulin: "Insulin",
  dmOther: "DM med Data Other",
  dlpMed:"DLP med",
  htMed: "HT med",
  supplementUse: "Nutritional Supplement",
  calcium: "calcium Supplement",
  vitaminD: "Vitamin D Supplement",
  iron: "Iron Supplement",
  vitaminB12: "Vitamin B12 (1000 mcg/ml)"
};
export const MedAndNutritionalHistoryDialog = ({ open, handleCloseDialog }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [medicationHistory, setMedicationHistory] = useState<MedicationResponse[]>(
    []
  );
  const medAndNutritionalKeys = Object.keys(medAndNutritionalLabels) as (keyof MedAndNutritional)[];

  useEffect(() => {
    const fetchData = async () => {
      const patientId = store.getState().patientUser.patientUser?.id;
      if (patientId) {
        try {
          const medicationHistory: MedicationResponse[] =
            await visitDataService.getMedicationHistory(patientId);
          if (medicationHistory) {
            setMedicationHistory(medicationHistory);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>Visit History</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Visit Date
                </TableCell>

                {medicationHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.visitDate).format("DD/MM/BBBB")}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {medAndNutritionalKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "1px solid #E5EBEB",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      bgcolor: "white",
                    }}
                  >
                    {medAndNutritionalLabels[key]}
                  </TableCell>

                  {medicationHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          alignContent: "baseline",
                        }}
                      >
                        <Box
                          display={"grid"}
                          gridTemplateColumns={"1fr 1fr"}
                          gap={2}
                        >
                          <Typography
                            sx={{
                              textAlign: "right",
                              fontSize: 14,
                              color: "#646D78",
                            }}
                          >
                            {medAndNutritionalLabels[key]}
                          </Typography>
                          <Typography sx={{ textAlign: "left", fontSize: 14 }}>
                            {entry[key] ? "Yes" : "No"}
                          </Typography>
                        </Box>
                        {key === "dmOther" && entry[key] && (
                          <Box
                            display={"grid"}
                            gridTemplateColumns={"1fr 1fr"}
                            gap={2}
                          >
                            <Typography
                              sx={{
                                textAlign: "right",
                                fontSize: 14,
                                color: "#646D78",
                              }}
                            >
                              ระบุข้อมูลเพิ่มเติม
                            </Typography>
                            <Typography
                              sx={{ textAlign: "left", fontSize: 14 }}
                            >
                              {key === "dmOther" && entry[key] ? (
                                <Typography>{entry.dmOtherNote.join(', ')}</Typography>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                        )}                                 
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={medicationHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};
