import { ThemeProvider } from "@emotion/react";
import {
  Box,
  CircularProgress,
  createMuiTheme,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import ExcelExport from "../../components/ExcelExport";
import { Layout } from "../../Layout";
import { ReportFiltering } from "../../shared/ReportFiltering";
import { reportValues } from "../../sharedValue";
import { title16500 } from "../../style";
import { theme2 } from "../../theme";
import { useEffectOnce } from "react-use";
import { set } from "date-fns";
import { report } from "process";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { useNavigate } from "react-router";
import { masterDataService } from "../../service/masterDataService";
import { get } from "http";
import path from "path";
import { reportService } from "../../service/reportService";
import { se, tr } from "date-fns/locale";
import dayjs from "dayjs";

const theme = createMuiTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          "&.Mui-selected": {
            backgroundColor: "#DF5C8E",
            color: "#ffffff",
          },
        },
      },
    },
  },
});

export type FilterItem = {
  name: string;
  minDate: string;
  maxDate: string;
  sex: string;
  // age: string[];
  minAge: number;
  maxAge: number;
  minWeightFirstVisitValue: number;
  maxWeightFirstVisitValue: number;
  minWeightCurrentValue: number;
  maxWeightCurrentValue: number;
  minBmiFirstVisit: number;
  maxBmiFirstVisit: number;
  minBmiCurrent: number;
  maxBmiCurrent: number;
  minHeight: number;
  maxHeight: number;
  comorbidities: string[];
  surgeryType: string;
  // surgeryNo: number;
  surgeryCount: string;
  pwl: string;
  ewl: string;
  twl: string;
  // minPwl: number;
  // maxPwl: number;
  ultrasound: string;
  endoscopy: string;
  fibroScan: string;
  unComorbidities: string[];
  complication: string[];
  followUp: string;
  pathology: string[];
};

type FilterRequest = {
  name: string | null;
  dateRange: {
    startDate: any;
    endDate: any;
  } | null;
  sex: number | null;
  ageRange: {
    min: number | null;
    max: number | null;
  } | null;
  firstWeightRange: {
    min: number | null;
    max: number | null;
  } | null;
  currentWeightRange: {
    min: number | null;
    max: number | null;
  } | null;
  firstBmiRange: {
    min: number | null;
    max: number | null;
  } | null;
  currentBmiRange: {
    min: number | null;
    max: number | null;
  } | null;
  complicationId: number[] | null;
  operationType: string | null;
  numberOfOperation: number | null;
  preOpsWeightLoss: {
    min: number | null;
    max: number | null;
  } | null;
  excessWeightLoss: {
    min: number | null;
    max: number | null;
  } | null;
  totalWeightLoss: {
    min: number | null;
    max: number | null;
  } | null;
  ultrasound: string | null;
  endoscopy: string | null;
  fibroScan: boolean | null;
  recoveredFromComplicationId: number[] | null;
  earlyComplication: string[] | null;
  followUp: number | null;
  pathology: string[] | null;
};
export const pathologyOptions = [
  "Stomach",
  "Liver Preop",
  "Liver Postop",
  "Gastroscopy",
];
export const earlyComplicationOptions = [
  "Leakage",
  "Bleeding",
  "Surgical Site Infection",
  "Pulmonary Complication",
  "Obstruction",
  "VTE",
  "Cardiovascular events (MI, Stroke)",
];

export const Report = () => {
  const filterRef = useRef<HTMLDivElement>(null);
  const [reportType, setReportType] = useState("");
  const [complicationOptions, setComplicationOptions] = useState<any[]>([]);
  const [filter, setFilter] = useState<FilterItem>();
  const [filterRequest, setFilterRequest] = useState<FilterRequest>();
  const [isHideFilter, setIsHideFilter] = useState(false);
  const [boxHeight, setBoxHeight] = useState("calc(100vh - 120px)");
  const [originalReportData, setOriginalReportData] = useState<any[]>([]);
  const [currentReportList, setCurrentReportList] = useState<any[]>([]);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const totalPages = Math.ceil(originalReportData.length / 10);
  const [dynamicColumns, setDynamicColumns] = useState<
    { name: string; align: string; key: string }[]
  >([]);
  const currentDateTime = dayjs().format('DDMMYYYYHHmm');

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (filterRef.current) {
        const stackHeight = filterRef.current.clientHeight;
        const calculatedHeight =
          stackHeight > 56
            ? `calc(100vh - ${stackHeight}px)`
            : "calc(100vh - 120px)";
        setBoxHeight(calculatedHeight);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filter, isHideFilter]);

  const currentReport = useMemo(() => {
    const report = reportValues.find((item) => item.reportType === reportType);
    if ((reportType === "RAW02" || reportType === "RAW04") && report) {
      return {
        ...report,
        columns: [...report.columns, ...dynamicColumns],
      };
    }
    return report;
  }, [reportType, dynamicColumns]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        //get complication master data
        const data = await masterDataService.getComplicationMasterData();
        setComplicationOptions(data);
      } catch (error: any) {
        setIsLoading(false);
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData().then(() => setIsLoading(false));
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        //get report data
        // const a = reportValues.find((item) => item.reportType === reportType);
        // setOriginalReportData(a?.data || []);
        if (reportType === "RAW01") {
          const response = await reportService.getDemographicReport(
            filterRequest
          );
          if (response) {
            const processedResponse = processDemographicReport(response);
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW02") {
          const response = await reportService.getComplicationReport(
            filterRequest
          );
          const processedResponse = processComplicationReport(response);
          // console.log("processedResponse", processedResponse);
          if (response) {
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW03") {
          const response = await reportService.getLaboratoryReport(
            filterRequest
          );
          if (response) {
            const processedResponse = processLaboratoryReport(response);
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW04") {
          const response = await reportService.getGynecologicReport(
            filterRequest
          );
          const processedResponse = processGynecologicReport(response);
          if (response) {
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW05") {
          const response = await reportService.getInvestigationReport(
            filterRequest
          );
          if (response) {
            setOriginalReportData(response);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW06") {
          const response = await reportService.getPathologyReport(
            filterRequest
          );
          if (response) {
            setOriginalReportData(response);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW07") {
          const response = await reportService.getMedicationReport(
            filterRequest
          );
          const processedResponse = processMedicationReport(response);
          if (response) {
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW08") {
          const response = await reportService.getBariatricSurgeryReport(
            filterRequest
          );
          const processedResponse = processBariatricReport(response);
          if (response) {
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW09") {
          const response = await reportService.getLateComplicationReport(
            filterRequest
          );
          const processedResponse = processLateComplicationReport(response);
          if (response) {
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else if (reportType === "RAW10") {
          const response = await reportService.getEndOfStudyReport(
            filterRequest
          );
          const processedResponse = processEndOfstudyReport(response);
          if (response) {
            setOriginalReportData(processedResponse);
          } else {
            setOriginalReportData([]);
          }
        } else {
          setOriginalReportData([]);
        }
      } catch (error: any) {
        setIsLoading(false);
        setOriginalReportData([]);
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, [reportType, currentPage, filterRequest]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setCurrentReportList(
          originalReportData?.slice(
            (currentPage - 1) * usersPerPage,
            currentPage * usersPerPage
          )
        );
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, [currentPage, originalReportData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [reportType]);

  const complicationMapping: { [key: string]: string } = {
    "CPAP use": "cpap",
    "ภาวะไขมันสะสมในตับ": "nafld",
    "โรคหลอดเลือดหัวใจ": "cad",
    "โรคหลอดเลือดสมอง": "cva",
    "โรคนิ่วในถุงน้ำดี": "gallstones",
    "โรคข้อเข่าเสื่อม": "kneeOsteoarthritis",
    "โรคกระดูกสันหลัง": "spondylosis",
    "โรคกรดไหลย้อน": "gerd",
    "ประวัติโรคมะเร็ง": "caco",
    "ภาวะทางเดินหายใจอุดกั้นขณะนอนหลับ": "osa",
    "โรคความดันโลหิตสูง": "ht",
    "ขาดสารอาหาร": "malnutrition",
    "โรคทางจิตเวช": "psych",
    "โรคไขมันในเลือดสูง": "dlp",
    "โรคเบาหวาน": "dm",
    "โรคถุงน้ำผิดปกติที่รังไข่": "pcos",
  };

  const contraceptionMapping: { [key: string]: string } = {
    "ถุงยางอนามัยชาย":"maleCondom",
    "ยาเม็ดคุมกำเนิดฮอร์โมนรวม":"combinedOralContraceptivePill",
    "ยาเม็ดคุมกำเนิดฮอร์โมนเดี่ยวหรือยาเม็ด คุมกำเนิดสำหรับให้นมบุตร":"combinedOralContraceptivePillTargeted",
    "ยาฉีดคุมกำเนิด":"dmpa",
    "ยาฝังคุมกำเนิด":"contraceptiveImplant",
    "ห่วงอนามัยทองแดง":"copperIud",
    "ห่วงอนามัยฮอร์โมน":"levonorgestrelIud",
    "หลั่งนอก":"withdrawal",
    "นับวันปลอดภัย":"fertilityAwareness",
  };
  const frequencyOfSexualIntercourseLabel : { [key: number]: string } = {
    1: "< 1 ครั้งต่อเดือน",
    2: "1 ครั้งต่อเดือน",
    3: "1 ครั้งต่อสัปดาห์",
    4: ">= 2 ครั้งต่อสัปดาห์",
  };
  const processComplicationReport = (response: any) => {
    const dynamicCols: { name: string; align: string; key: string }[] = [];

    const processedData = response.map((item: any) => {
      const processedItem: any = {
        hn: item.hn,
        name: item.name,
        visitDate: item.visitDate,
      };

      item.complicationList.forEach((complication: any) => {
        const key = complicationMapping[complication.name];
        if (key) {
          processedItem[key] = complication.value;
        } else {
          // Handle dynamic complications
          const dynamicKey = complication.name;
          processedItem[dynamicKey] = complication.value;

          // Add to dynamic columns if not already present
          if (!dynamicCols.some((col) => col.key === dynamicKey)) {
            dynamicCols.push({
              name: complication.name,
              align: "center",
              key: dynamicKey,
            });
          }
        }
      });
      return processedItem;
    });
    setDynamicColumns(dynamicCols);
    return processedData;
  };

  const processDemographicReport = (response: any) => {
    return response.map((item: any) => {
      const processedItem: any = { ...item };
  
      if (item.bmi !== undefined && item.bmi !== null) {
        processedItem.bmi = parseFloat(item.bmi).toFixed(2);
      }
  
      return processedItem;
    });
  };

  const processLaboratoryReport = (response: any) => {
    return response.map((item: any) => {
      const processedItem: any = { ...item };
  
      if (item.excessWeightLoss !== undefined && item.excessWeightLoss !== null) {
        processedItem.excessWeightLoss = `${item.excessWeightLoss} %`;
      }
      
      if (item.excessBmiLoss !== undefined && item.excessBmiLoss !== null) {
        processedItem.excessBmiLoss = `${item.excessBmiLoss} %`;
      }
      
      if (item.totalWeightLoss !== undefined && item.totalWeightLoss !== null) {
        processedItem.totalWeightLoss = `${item.totalWeightLoss} %`;
      }
  
      return processedItem;
    });
  };

  const processGynecologicReport = (response: any) => {
    const dynamicCols: { name: string; align: string; key: string }[] = [];

    const processedData = response.map((item: any) => {
      const processedItem: any = {
        hn: item.hn,
        name: item.name,
        visitDate: item.visitDate,
        sexuallyActive: item.sexuallyActive,
        frequencyOfSexualIntercourse: frequencyOfSexualIntercourseLabel[item.sexFrequency]
      };

      item.contraception.forEach((contraception: any) => {
        const key = contraceptionMapping[contraception.name];
        if (key) {
          processedItem[key] = contraception.value;
        } else {
          // Handle dynamic complications
          const dynamicKey = contraception.name;
          processedItem[dynamicKey] = contraception.value;

          // Add to dynamic columns if not already present
          if (!dynamicCols.some((col) => col.key === dynamicKey)) {
            dynamicCols.push({
              name: contraception.name,
              align: "center",
              key: dynamicKey,
            });
          }
        }
      });
      return processedItem;
    });
    setDynamicColumns(dynamicCols);
    // console.log("dynamicCols", dynamicCols);
    return processedData;
  };

  const processMedicationReport = (response: any) => {
    return response.map((item: any) => {
      const processedItem: any = { ...item };
  
      if (!!item.dmOtherNote && item.dmOtherNote !== null) {
        processedItem.dmOtherNote = item.dmOtherNote.includes('|')
          ? item.dmOtherNote.replace(/\|/g, ',')
          : item.dmOtherNote;
      }
  
      return processedItem;
    });
  };

  const processBariatricReport = (response: any) => {
    const subsequentOperationList = [
      {
        label: "Laparoscopic cholecystectomy",
        value: "laparoscopicCholecystectomy",
      },
      {
        label: "Laparoscopic hiatal hernia repair",
        value: "laparoscopicHiatalHerniaRepair",
      },
      {
        label: "Other",
        value: "other",
      },
    ];
  
    const processedData = response.map((item: any) => {
      const processedItem: any = {
        hn: item.hn,
        name: item.name,
        visitDate: item.visitDate,
        surgeryDate: item.surgeryDate,
        operationTime: item.operationTime,
        acuteWeightLoss: item.acuteWeightLoss,
        acuteWeightLossType: item.acuteWeightLossType,
        acuteWeightLossDay: item.acuteWeightLossDay,
        preAwlWeight: item.preAwlWeight,
        postAwlWeight: item.postAwlWeight,
        weightChange:
          !!item.preAwlWeight && !!item.postAwlWeight
            ? (
                ((item.preAwlWeight - item.postAwlWeight) / item.postAwlWeight) *
                100
              ).toFixed(2) + "%"
            : null,
        surgicalTechnique: item.surgicalTechnique,
        operationType: item.operationType,
        bougieSize: item.bougieSize,
        bpLimb: item.bpLimb,
        rouxLimb: item.rouxLimb,
        ebl: item.ebl,
        subsequentOperationOtherText: Array.isArray(item.subsequentOperationOtherText)
        ? item.subsequentOperationOtherText.join(", ")
        : item.subsequentOperationOtherText,        
        earlyComplication: item.earlyComplication,
        leakage: item.earlyComplicationType["leakage"],
        surgicalSiteInfection: item.earlyComplicationType["surgicalSiteInfection"],
        obstruction: item.earlyComplicationType["obstruction"],
        cardiovascularEvents: item.earlyComplicationType["cardiovascularEvents"],
        bleeding: item.earlyComplicationType["bleeding"],
        pulmonaryComplication: item.earlyComplicationType["pulmonaryComplication"],
        vte: item.earlyComplicationType["vte"],
        other: item.earlyComplicationType["other"],
        treatment: Array.isArray(item.treatment)
        ? item.treatment.join(", ")
        : item.treatment,
        earlyComplicationTypeOtherText: item.earlyComplicationTypeOtherText,
        reOperation: item.reOperation,
      };
  
      // Map subsequentOperation with label and join with comma
      const subsequentOperations = [];
  
      if (item.laparoscopicCholecystectomy) {
        subsequentOperations.push(
          subsequentOperationList.find(
            (op) => op.value === "laparoscopicCholecystectomy"
          )?.label || "Laparoscopic cholecystectomy"
        );
      }
  
      if (item.laparoscopicHiatalHerniaRepair) {
        subsequentOperations.push(
          subsequentOperationList.find(
            (op) => op.value === "laparoscopicHiatalHerniaRepair"
          )?.label || "Laparoscopic hiatal hernia repair"
        );
      }
  
      if (item.subsequentOperationOther) {
        subsequentOperations.push(
          subsequentOperationList.find((op) => op.value === "other")?.label ||
            "Other"
        );
      }
  
      if (item.subsequentOperation) {
        subsequentOperations.push(
          ...item.subsequentOperation.map((operation: string) => {
            const operationLabel = subsequentOperationList.find(
              (op) => op.value === operation
            )?.label;
            return operationLabel || operation;
          })
        );
      }
  
      processedItem.subsequentOperation = subsequentOperations.join(", ");
  
      // Map anastomosis values
      const anastomosisMapping: { [key: string]: string } = {
        "DJ anastomosis": "djAnastomosis",
        "GJ anastomosis": "gjAnastomosis",
        "JJ anastomosis": "jjAnastomosis",
        "DJ anastomosis Technique": "djAnastomosisTechnique",
        "GJ anastomosis Technique": "gjAnastomosisTechnique",
        "JJ anastomosis Technique": "jjAnastomosisTechnique",
        "Technique (ระบุข้อมูล)": "techniqueNote",
      };
      if(!!item.operationLocation) {
     
    item.operationLocation.forEach((anastomosis: any) => {
      const locationKey = anastomosisMapping[anastomosis.location];
      const techniqueKey = anastomosisMapping[`${anastomosis.location} Technique`];
      if (locationKey) {
        processedItem[locationKey] = true;
      }
      if (techniqueKey) {
        processedItem[techniqueKey] = anastomosis.technique;
      }
      if (anastomosis.techniqueDetail) {
        processedItem.techniqueDetail = anastomosis.techniqueDetail === "-" ? "" : anastomosis.techniqueDetail;      }
    });
    }
      return processedItem;
    });
  
    return processedData;
  };
  
  
  const processLateComplicationReport = (response: any) => {
    const gerdTypeLabels = [
      { value: 1, label: "De novo" },
      { value: 2, label: "Improve" },
      { value: 3, label: "Worsening" },
      { value: 0, label: "No" },
    ];
    const herniaTypeLabels = [
      { value: 1, label: "Internal" },
      { value: 2, label: "Abdominal wall" },
      { value: 0, label: "No" },
    ];

    return response.map((item: any) => {
      const processedItem: any = { ...item };
  
      if (item.gerdType !== undefined && item.gerdType !== null) {
        const label = gerdTypeLabels.find(
          (label) => label.value === item.gerdType
        )?.label;
        processedItem.gerdType = label || item.gerdType;
      } 
      if(item.herniaType !== undefined && item.herniaType !== null) {
        const label = herniaTypeLabels.find(
          (label) => label.value === item.herniaType
        )?.label;
        processedItem.herniaType = label || item.herniaType;
      }  
      return processedItem;
    });
  };

  const processEndOfstudyReport = (response: any) => {
    const endOfStudyTypeLabels = [
      { value: 2, label: "Dead" },
      { value: 1, label: "Loss to f/u" },
    ];
  
    return response.map((item: any) => {
      const processedItem: any = { ...item };
  
      if (item.endOfStudyType !== undefined && item.endOfStudyType !== null) {
        const label = endOfStudyTypeLabels.find(
          (label) => label.value === item.endOfStudyType
        )?.label;
        processedItem.endOfStudyType = label || item.endOfStudyType;
      }
  
      return processedItem;
    });
  };

  const handleApplyFilter = (newFilter: FilterItem) => {
    setFilter(newFilter);
    setCurrentPage(1);
    setFilterRequest({
      name: newFilter.name ?? null,
      dateRange:
        newFilter.minDate === "" && newFilter.maxDate === ""
          ? null
          : {
              startDate: newFilter.minDate === "" ? null : newFilter.minDate,
              endDate: newFilter.maxDate === "" ? null : newFilter.maxDate,
            },
      sex: getSexValue(newFilter) ?? null,
      ageRange:
        newFilter.minAge === undefined && newFilter.maxAge === undefined
          ? null
          : {
              min: newFilter.minAge === undefined ? null : newFilter.minAge,
              max: newFilter.maxAge === undefined ? null : newFilter.maxAge,
            },
      firstWeightRange:
        newFilter.minWeightFirstVisitValue === undefined &&
        newFilter.maxWeightFirstVisitValue === undefined
          ? null
          : {
              min:
                newFilter.minWeightFirstVisitValue === undefined
                  ? null
                  : newFilter.minWeightFirstVisitValue,
              max:
                newFilter.maxWeightFirstVisitValue === undefined
                  ? null
                  : newFilter.maxWeightFirstVisitValue,
            },
      currentWeightRange:
        newFilter.minWeightCurrentValue === undefined &&
        newFilter.maxWeightCurrentValue === undefined
          ? null
          : {
              min:
                newFilter.minWeightCurrentValue === undefined
                  ? null
                  : newFilter.minWeightCurrentValue,
              max:
                newFilter.maxWeightCurrentValue === undefined
                  ? null
                  : newFilter.maxWeightCurrentValue,
            },
      firstBmiRange:
        newFilter.minBmiFirstVisit === undefined &&
        newFilter.maxBmiFirstVisit === undefined
          ? null
          : {
              min:
                newFilter.minBmiFirstVisit === undefined
                  ? null
                  : newFilter.minBmiFirstVisit,
              max:
                newFilter.maxBmiFirstVisit === undefined
                  ? null
                  : newFilter.maxBmiFirstVisit,
            },
      currentBmiRange:
        newFilter.minBmiCurrent === undefined &&
        newFilter.maxBmiCurrent === undefined
          ? null
          : {
              min:
                newFilter.minBmiCurrent === undefined
                  ? null
                  : newFilter.minBmiCurrent,
              max:
                newFilter.maxBmiCurrent === undefined
                  ? null
                  : newFilter.maxBmiCurrent,
            },
      complicationId: getComplicationId(newFilter.comorbidities) ?? null,
      operationType:
        newFilter.surgeryType === "ทั้งหมด" ? null : newFilter.surgeryType,
      numberOfOperation:
        getNumberOfSurgeryCount(newFilter.surgeryCount) ?? null,
      preOpsWeightLoss: getMinMaxPwlValue(newFilter.pwl),
      excessWeightLoss: getMinMaxEwlValue(newFilter.ewl),
      totalWeightLoss: getMinMaxTwlValue(newFilter.twl),
      ultrasound:
        newFilter.ultrasound === "ทั้งหมด" ? null : getUltrasoundValue(newFilter.ultrasound),
      endoscopy: newFilter.endoscopy === "ทั้งหมด" ? null : getEndoscopyValue(newFilter.endoscopy),
      fibroScan:
        newFilter.fibroScan === "ทั้งหมด"
          ? null
          : newFilter.fibroScan === "Yes"
          ? true
          : false,
      recoveredFromComplicationId: getComplicationId(newFilter.unComorbidities),
      earlyComplication: newFilter.complication.includes("ทั้งหมด")
        ? null
        : getEarlyComplicationValue(newFilter.complication),
      followUp: getFollowUpValue(newFilter.followUp),
      pathology: getPathologyValue(newFilter.pathology) ?? null,
    });
    console.log("Applied Filter:", newFilter);
    console.log("Filter Request:", filterRequest);
  };

  const getSexValue = (filter: FilterItem) => {
    if (filter.sex === "ทั้งหมด") {
      return null;
    } else if (filter.sex === "ชาย (Male)") {
      return 1;
    } else {
      return 2;
    }
  };

  const getComplicationId = (complication: string[]) => {
    if (complication.includes("ทั้งหมด")) {
      return null;
    }
    return complicationOptions
      .filter((item) => complication.includes(item.name))
      .map((item) => item.id);
  };

  const getNumberOfSurgeryCount = (surgeryCount: string) => {
    if (surgeryCount === "ทั้งหมด") {
      return null;
    } else if (surgeryCount === "1 ครั้ง") {
      return 1;
    } else if (surgeryCount === "2 ครั้ง") {
      return 2;
    } else if (surgeryCount === "มากกว่า 2 ครั้งขึ้นไป") {
      return 3;
    }
  };
  const getFollowUpValue = (followUp: string) => {
    if (followUp === "ทั้งหมด") {
      return null;
    }

    switch (followUp) {
      case "0 - 1 months":
        return 1;
      case "1 - 3 months":
        return 3;
      case "3 - 6 months":
        return 6;
      case "6 - 12 months":
        return 12;
      case "2 years":
        return 24;
      case "3 years":
        return 36;
      case "4 years":
        return 48;
      case "5 years":
        return 60;
      case "6 years":
        return 72;
      case "7 years":
        return 84;
      case "8 year":
        return 96;
      case "9 years":
        return 108;
      case "10 years":
        return 120;
      case "10+ years":
        return 120; // Assuming 10+ years is treated as 120 months
      default:
        return null;
    }
  };
  const getMinMaxPwlValue = (range: string) => {
    if (range === "ทั้งหมด") {
      return null;
    }

    const rangeMap: {
      [key: string]: { min: number | null; max: number | null };
    } = {
      "<5": { min: null, max: 5 },
      "5-10": { min: 5, max: 10 },
      "10-15": { min: 10, max: 15 },
      ">15": { min: 15, max: null },
    };
    return rangeMap[range] || null;
  };
  const getMinMaxEwlValue = (range: string) => {
    if (range === "ทั้งหมด") {
      return null;
    }

    const rangeMap: {
      [key: string]: { min: number | null; max: number | null };
    } = {
      "<10": { min: null, max: 10 },
      "10-30": { min: 10, max: 30 },
      "30-50": { min: 30, max: 50 },
      "50-75": { min: 50, max: 75 },
      "75-100": { min: 75, max: 100 },
      ">100": { min: 100, max: null },
    };

    return rangeMap[range] || null;
  };
  const getMinMaxTwlValue = (range: string) => {
    if (range === "ทั้งหมด") {
      return null;
    }

    const rangeMap: {
      [key: string]: { min: number | null; max: number | null };
    } = {
      "<5": { min: null, max: 5 },
      "5-10": { min: 5, max: 10 },
      "10-20": { min: 10, max: 20 },
      "20-30": { min: 20, max: 30 },
      ">30": { min: 30, max: null },
    };

    return rangeMap[range] || null;
  };

  const ultrasoundMapping: { [key: string]: string } = {
    "Fatty Liver": "fattyLiver",
    "Gallstone": "gallStone",
    "Cirrhosis": "cirrhosis",
    "Ascites": "ascites",
  };
  const getUltrasoundValue = (ultrasound: string) => {
    if (ultrasound === "ทั้งหมด") {
      return null;
    }
    const key = ultrasoundMapping[ultrasound];
    if (key) {
      return key;
    }
    return null;
  };
  
  const endoscopyMapping: { [key: string]: string } = {
    "Hiatal Hernia": "hiatalHernia",
    "Reflux Esophagitis": "refluxEsophagitis",
    "Gastritis": "gastritis",
    "Gastric/Duodenal Ulcer": "gastricDuodenalUlcer",
    "H.Pyroli Infection": "h.PyroliInfection",
  };
  const getEndoscopyValue = (endoscopy: string) => {
    if (endoscopy === "ทั้งหมด") {
      return null;
    }
    const key = endoscopyMapping[endoscopy];
    if (key) {
      return key;
    }
    return null;
  };

  const earlyComplicationMapping: { [key: string]: string } = {
    "Leakage": "leakage",
    "Bleeding": "bleeding",
    "Surgical Site Infection": "surgicalSiteInfection",
    "Pulmonary Complication": "pulmonaryComplication",
    "Obstruction": "obstruction",
    "VTE": "vte",
    "Cardiovascular events (MI, Stroke)": "cardiovascularEvents",
  };
  const getEarlyComplicationValue = (complication: string[]) => {
    if (complication.includes("ทั้งหมด")) {
      return null;
    }
  
    const mappedComplications: string[] = complication.map((item) => {
      const key = earlyComplicationMapping[item];
      return key ? key : item;
    });
  
    return mappedComplications;
  };

  const pathologyMapping: { [key: string]: string } = {
    "Stomach": "stomach",
    "Liver Preop": "liverPreop",
    "Liver Postop": "iverPostop",
    "Gastroscopy": "gastroscopy",
  };
  const getPathologyValue = (pathology: string[]) => {
    if (pathology.includes("ทั้งหมด")) {
      return null;
    } 
    const mappedPathologys: string[] = pathology.map((item) => {
      const key = pathologyMapping[item];
      return key ? key : item;
    });
  
    return mappedPathologys;
  };

  return (
    <Layout activeTab="report">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1, boxSizing: "border-box" }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              รายงาน
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <ReportFiltering
              filter={filter}
              reportType={reportType}
              isHideFilter={isHideFilter}
              onApplyFilter={handleApplyFilter}
              setReportType={setReportType}
              setFilter={setFilter}
              setIsHideFilter={setIsHideFilter}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              mt: 2,
              overflow: "auto",
              scrollbarWidth: "thin",
              p: "20px",
              boxSizing: "border-box",
              height: boxHeight,
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={18} fontWeight={600}>
                รายการข้อมูลที่ตรงตามเงื่อนไขที่ค้นหา
              </Typography>
              {reportType && (
                <ExcelExport
                  columns={currentReport?.columns as any[]}
                  data={originalReportData as any[]}
                  fileName={`${currentReport?.reportType} ${currentReport?.reportTitle} ${currentDateTime}`}
                  reportTitle={currentReport?.reportTitle as string}
                  reportDescription={currentReport?.reportDescription as string}
                />
              )}
            </Stack>
            <Box mt={"20px"}>
              {reportType && (
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minWidth: currentReport?.columns.length
                        ? currentReport?.columns.length * 180
                        : 1500,
                      boxSizing: "border-box",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {currentReport?.columns.map((item, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              textAlign: item.align,
                            }}
                          >
                            {item.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentReportList?.map((item: any, index) => (
                        <TableRow key={index} style={{ padding: 0 }}>
                          {currentReport?.columns.map((column, colIndex) => {
                            const value = item[column.key];
                            return (
                              <TableCell
                                key={colIndex}
                                sx={{
                                  textAlign: column.align,
                                }}
                              >
                                {column.key === "order"
                                  ? (currentPage - 1) * usersPerPage + index + 1
                                  : value === true
                                  ? "Yes"
                                  : value === false
                                  ? "No"
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {originalReportData.length === 0 && (
                <Box
                  sx={{
                    display: isLoading && reportType ? "flex" : "none",
                    justifyContent: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 8,
                    border: "1px solid #ddd", // Add this line
                    borderRadius: "4px",
                  }}
                >
                  <CircularProgress />
                  <Typography>กำลังโหลดข้อมูล...</Typography>
                </Box>
              )}
              {/* <Box
                sx={{
                  display:
                    originalReportData.length === 0 && reportType && !isLoading
                      ? "flex"
                      : "none",
                  justifyContent: "center",
                  fontSize: 20,
                  color: "#9AA1AE",
                  py: 8,
                  border: "1px solid #ddd", // Add this line
                  borderRadius: "4px",
                }}
              >
                <Typography>--ไม่พบข้อมูล--</Typography>
              </Box> */}
            </Box>
            {!reportType ||
              (originalReportData.length === 0 && !isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                    mt: 5,
                  }}
                >
                  --ไม่พบข้อมูล--
                </Box>
              ))}
            <ThemeProvider theme={theme}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "30px",
                }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </ThemeProvider>
          </Box>
        </Box>
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            navigate("/");
            setIsTokenExpired(false);
          }}
        />
      </ThemeProvider>
    </Layout>
  );
};
