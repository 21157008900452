import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Grid,
  Button,
  FormHelperText,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import MenuAppBar from "../../shared/MenuAppBar";
import VerticalTab from "../../shared/VerticalTab";
import DropZone from "../../shared/DropZone";
import { useEffect, useState } from "react";
import "./AddUser.css";
import withStyles from "@material-ui/core/styles/withStyles";
import { format } from "date-fns";
import { useDropzone } from "react-dropzone";
import { userManagementService } from "../../service/userManagementService";
import { useEffectOnce } from "react-use";
import { Layout } from "../../Layout";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import DownloadFile from "../../shared/DownloadFile";
import { UserDetail } from "../ApproveUser/ApproveUserDetail";
import DropZoneTest from "../../shared/DropZoneTest";
import EditFile from "../../shared/EditFile";
import { UpdateUserDetail, UserPermission } from "../../type";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { updateUserDetailProfile } from "../../redux/features/loginUserSlice";

const EditUser = () => {
  const navigate = useNavigate();
  const nameTitleList = [
    {
      value: "นาย",
      label: "นาย",
    },
    {
      value: "นาง",
      label: "นาง",
    },
    {
      value: "นางสาว",
      label: "นางสาว",
    },
  ];
  const [userDetail, setUserDetail] = useState<UserDetail>({
    title: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    affiliatedHospital: "",
    medicalLicenseNumber: "",
    jobPosition: "",
    siteRoleId: "",
    username: "",
    registrationDate: "",
    approvalDate: "",
    approveByUserId: "",
    email: "",
    userRestricted: false,
  });
  const [updateUserDetail, setUpdateUserDetail] = useState<UpdateUserDetail>({
    email: "",
    title: "",
    firstName: "",
    lastName: "",
    nationalId: "",
    phoneNumber: "",
    phoneNumberExtension: "",
    mobilePhoneNumber: "",
    affiliatedHospitalId: 0,
    medicalLicenseNumber: "",
    jobPosition: "",
    permissionId: 0,
    userRestricted: false,
  });
  const [userPermission, setUserPermission] = useState("");
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [affiliatedHospitalList, setAffiliateHospitalHospitalList] = useState<
    any[]
  >([]);
  const [selectedHospital, setSelectedHospital] = useState("");
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isClickSave, setIsClickSave] = useState(false);
  const [isSubmittedApproval, setIsSubmittedApproval] = useState(false);
  const dispatch = useDispatch();

  let userId: any = null;

  const location = useLocation();
  if (location?.state?.user) {
    userId = location.state.user;
  }

  const GreenSwitch = withStyles({
    switchBase: {
      color: "#26B465",
      "&$checked": {
        color: "#26B465",
      },
      "&$checked + $track": {
        backgroundColor: "#26B465",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const affiliatedHospitalListResponse =
          await userManagementService.getAffiliatedHospital();
        setAffiliateHospitalHospitalList(affiliatedHospitalListResponse);
        const userPermissionList =
          await userManagementService.getUserPermission();
        const activeUserPermissionList = userPermissionList.filter(
          (permission: UserPermission) => permission.permissionActive !== false
        );
        setUserPermissionList(activeUserPermissionList);
        if (userId) {
          const userDetails = await userManagementService.getUserDetail(userId);
          const hospitalExists = affiliatedHospitalListResponse.some(
            (hospital: { nameThai: any }) =>
              hospital.nameThai === userDetail.affiliatedHospital
          );
          setSelectedHospital(
            hospitalExists ? userDetail.affiliatedHospital : "aaa"
          ); // Ensure this value is in the list
          setUserDetail(userDetails);
          setUpdateUserDetail({
            email: userDetails.email,
            title: userDetails.title,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            nationalId: userDetails.nationalId,
            phoneNumber: userDetails.phoneNumber,
            phoneNumberExtension: userDetails.phoneNumberExtension,
            mobilePhoneNumber: userDetails.mobilePhoneNumber,
            affiliatedHospitalId: affiliatedHospitalListResponse.find(
              (hospital: { nameThai: any }) =>
                hospital.nameThai === userDetails.affiliatedHospital
            ).id,
            medicalLicenseNumber: userDetails.medicalLicenseNumber,
            jobPosition: userDetails.jobPosition,
            permissionId: userDetails.siteRoleId,
            userRestricted: userDetails.userRestricted,
          });
          setUserPermission(userDetail.siteRoleId);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });

  const handleAffiliatedHospitalDropdownChange = (event: {
    target: { value: string };
  }) => {
    setUpdateUserDetail({
      ...updateUserDetail,
      affiliatedHospitalId: parseInt(event.target.value),
    });
  };

  const handleUserPermissionDropdownChange = (event: {
    target: { value: string };
  }) => {
    setUpdateUserDetail({
      ...updateUserDetail,
      permissionId: parseInt(event.target.value),
    });
    userPermissionList.map((userPermission) => {
      if (userPermission.id === parseInt(event.target.value)) {
        setUserPermission(event.target.value);
      }
    });
  };

  const validateNationalId = (nationalId: string) => {
    if (nationalId.length !== 13 || !/^\d+$/.test(nationalId)) {
      return false;
    } else {
      return true;
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length < 10 || !/^\d+$/.test(phoneNumber)) {
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = (email: string) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitData = async () => {
    try {
      setIsClickSave(true);
      if (
        !updateUserDetail.email ||
        !updateUserDetail.title ||
        !updateUserDetail.firstName ||
        !updateUserDetail.lastName ||
        !updateUserDetail.nationalId ||
        !updateUserDetail.mobilePhoneNumber ||
        !updateUserDetail.affiliatedHospitalId ||
        !updateUserDetail.medicalLicenseNumber ||
        !updateUserDetail.permissionId ||
        !validatePhoneNumber(updateUserDetail.mobilePhoneNumber) ||
        !validateNationalId(updateUserDetail.nationalId) ||
        !validateEmail(updateUserDetail.email)
      ) {
        enqueueSnackbar("กรุณากรอกข้อมูลให้ครบถ้วน", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        });
        return;
      }
      await submitDataAsync();
    } catch (error) {}
  };

  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await userManagementService.updateUserDetail(
        userId,
        updateUserDetail
      );
      return response;
    },
    onSuccess(data) {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      dispatch(updateUserDetailProfile(data));
      localStorage.setItem("userDetail", JSON.stringify(data));
      setIsSubmittedApproval(true);
      // setIsShowCancelApprovalDialog(false);
    },
    onError(error) {
      // setOpenIncompleteDataDialog(true);
      console.log(error);
    },
  });

  return (
    <Layout activeTab="userPermission">
      <div className="add-user-tab-content">
        <div className="add-user-filtering-container">
          <div>
            <Typography style={{ fontSize: "16px", padding: "16px" }}>
              จัดการผู้ใช้งาน / รายการผู้ใช้งาน / เพิ่มผู้ใช้งาน
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                p={2}
                bgcolor={"white"}
                style={{
                  width: "100%",
                  paddingTop: "0px",
                  height: "calc(100vh - 100px)",
                  overflow: "auto",
                  scrollbarWidth: "thin",
                }}
                sx={{ height: "inherit" }}
              >
                <p
                  style={{ borderBottom: "2px solid #D8D8D8", padding: "5px" }}
                >
                  <span
                    style={{
                      borderBottom: "3px solid #DF5C8E",
                      fontWeight: "bold",
                      padding: "5px",
                    }}
                  >
                    รายการผู้ใช้งาน
                  </span>
                </p>
                <p style={{ color: "#646D78" }}>
                  กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "70px",
                  }}
                >
                  <Box p={1} sx={{ paddingRight: "30px" }}>
                    <Typography style={{ color: "#646D78" }}>
                      Email Address <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      label="-- โปรดระบุข้อมูล --"
                      variant="outlined"
                      value={updateUserDetail.email}
                      onChange={(e) => {
                        setUpdateUserDetail({
                          ...updateUserDetail,
                          email: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={!updateUserDetail.email && isClickSave}
                    />
                    {(!updateUserDetail.email ||
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        updateUserDetail.email
                      )) && (
                      <FormHelperText error style={{ color: "grey" }}>
                        กรุณากรอกอีเมลให้ถูกต้อง
                      </FormHelperText>
                    )}
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      เปิดการใช้งาน <span style={{ color: "red" }}>*</span>
                    </p>
                    <Stack spacing={1} direction={"row"} alignItems={"center"}>
                      <Switch
                        checked={!updateUserDetail.userRestricted}
                        color="success"
                        onChange={(e) => {
                          setUpdateUserDetail({
                            ...updateUserDetail,
                            userRestricted: !e.target.checked,
                          });
                        }}
                      />
                      {/* <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography> */}
                    </Stack>
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "70px",
                  }}
                >
                  <Box p={1} sx={{ paddingRight: "30px" }}>
                    <p style={{ color: "#646D78" }}>
                      คำนำหน้า <span style={{ color: "red" }}>*</span>
                    </p>
                    <FormControl variant="outlined" style={{ width: "296px" }}>
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        id="prefix"
                        name="prefix"
                        value={updateUserDetail.title}
                        onChange={(e) => {
                          setUpdateUserDetail({
                            ...updateUserDetail,
                            title: e.target.value,
                          });
                        }}
                        size="small"
                        error={!userDetail.title && isClickSave}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {nameTitleList.map((nameTitle) => (
                          <MenuItem
                            value={nameTitle.label}
                            sx={{ fontSize: 14 }}
                          >
                            {nameTitle.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      ชื่อ <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      label={
                        updateUserDetail.firstName ? "" : "-- โปรดระบุข้อมูล --"
                      }
                      variant="outlined"
                      value={updateUserDetail.firstName}
                      onChange={(e) => {
                        setUpdateUserDetail({
                          ...updateUserDetail,
                          firstName: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={!updateUserDetail.firstName && isClickSave}
                    />
                  </Box>

                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      นามสกุล <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      label={
                        updateUserDetail.lastName ? "" : "-- โปรดระบุข้อมูล --"
                      }
                      variant="outlined"
                      value={updateUserDetail.lastName}
                      onChange={(e) => {
                        setUpdateUserDetail({
                          ...updateUserDetail,
                          lastName: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={!updateUserDetail.lastName && isClickSave}
                    />
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid
                    container
                    rowGap={3}
                    alignItems="stretch"
                    sx={{ my: 2 }}
                  >
                    <Box
                      p={1}
                      sx={{
                        paddingRight: "85px",
                      }}
                    >
                      <p style={{ color: "#646D78" }}>
                        เลขที่บัตรประชาชน{" "}
                        <span style={{ color: "red" }}>*</span>
                      </p>
                      <TextField
                        variant="outlined"
                        label={
                          updateUserDetail.nationalId
                            ? ""
                            : "-- โปรดระบุข้อมูล --"
                        }
                        value={updateUserDetail.nationalId}
                        onChange={(e) => {
                          setUpdateUserDetail({
                            ...updateUserDetail,
                            nationalId: e.target.value,
                          });
                        }}
                        size="small"
                        style={{ width: "296px" }}
                        error={!updateUserDetail.nationalId && isClickSave}
                      />
                      {(updateUserDetail.nationalId.length !== 13 ||
                        !/^\d+$/.test(updateUserDetail.nationalId)) && (
                        <FormHelperText error style={{ color: "grey" }}>
                          กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก
                          และเป็นตัวเลขเท่านั้น
                        </FormHelperText>
                      )}
                    </Box>
                    <Box p={1} sx={{ paddingRight: "10px" }}>
                      <p style={{ color: "#646D78" }}>เบอร์โทรศัพท์</p>
                      <TextField
                        variant="outlined"
                        value={updateUserDetail.phoneNumber}
                        onChange={(e) => {
                          setUpdateUserDetail({
                            ...updateUserDetail,
                            phoneNumber: e.target.value,
                          });
                        }}
                        size="small"
                        style={{ width: "168px" }}
                      />
                    </Box>
                    <Box p={1} style={{ paddingRight: "80px" }}>
                      <p style={{ color: "#646D78" }}>Ext</p>
                      <TextField
                        variant="outlined"
                        value={updateUserDetail.phoneNumberExtension}
                        onChange={(e) => {
                          setUpdateUserDetail({
                            ...updateUserDetail,
                            phoneNumberExtension: e.target.value,
                          });
                        }}
                        size="small"
                        style={{ width: "114px" }}
                      />
                    </Box>
                    <Box p={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ color: "#646D78" }}>
                          เบอร์มือถือ (รหัสผ่านตั้งต้นสำหรับเข้าสู่ระบบครั้งแรก){" "}
                          <span style={{ color: "red" }}>*</span>
                        </p>
                        <TextField
                          variant="outlined"
                          label={
                            updateUserDetail.mobilePhoneNumber
                              ? ""
                              : "-- โปรดระบุข้อมูล --"
                          }
                          value={updateUserDetail.mobilePhoneNumber}
                          onChange={(e) => {
                            setUpdateUserDetail({
                              ...updateUserDetail,
                              mobilePhoneNumber: e.target.value,
                            });
                          }}
                          size="small"
                          style={{ width: "296px" }}
                          error={
                            !updateUserDetail.mobilePhoneNumber && isClickSave
                          }
                        />
                        {(updateUserDetail.mobilePhoneNumber.length < 10 ||
                          !/^\d+$/.test(
                            updateUserDetail.mobilePhoneNumber
                          )) && (
                          <FormHelperText error style={{ color: "grey" }}>
                            กรุณากรอกเบอร์มือถือให้ครบ 10 หลัก
                            และเป็นตัวเลขเท่านั้น
                          </FormHelperText>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </div>

                <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "70px",
                  }}
                >
                  <Box p={1} sx={{ paddingRight: "30px" }}>
                    <p style={{ color: "#646D78" }}>
                      ชื่อโรงพยาบาล <span style={{ color: "red" }}>*</span>
                    </p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        value={updateUserDetail.affiliatedHospitalId?.toString()}
                        onChange={handleAffiliatedHospitalDropdownChange}
                        size="small"
                        style={{ width: "296px" }}
                        error={
                          !updateUserDetail.affiliatedHospitalId && isClickSave
                        }
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {affiliatedHospitalList.map((hospital) => (
                          <MenuItem value={hospital.id} sx={{ fontSize: 14 }}>
                            {hospital.nameThai}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      เลขที่ใบอนุญาตใบประกอบวิชาชีพ{" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      variant="outlined"
                      label={
                        updateUserDetail.medicalLicenseNumber
                          ? ""
                          : "-- โปรดระบุข้อมูล --"
                      }
                      value={updateUserDetail.medicalLicenseNumber}
                      onChange={(e) => {
                        setUpdateUserDetail({
                          ...updateUserDetail,
                          medicalLicenseNumber: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                      error={
                        !updateUserDetail.medicalLicenseNumber && isClickSave
                      }
                    />
                  </Box>
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>ตำแหน่ง</p>
                    <TextField
                      variant="outlined"
                      label={
                        updateUserDetail.jobPosition
                          ? ""
                          : "-- โปรดระบุข้อมูล --"
                      }
                      value={updateUserDetail.jobPosition}
                      onChange={(e) => {
                        setUpdateUserDetail({
                          ...updateUserDetail,
                          jobPosition: e.target.value,
                        });
                      }}
                      size="small"
                      style={{ width: "296px" }}
                    />
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "50px",
                  }}
                >
                  <Box p={1}>
                    <p style={{ color: "#646D78" }}>
                      สิทธิ์การใช้งาน{" "}
                      <span style={{ color: "red" }}>
                        * (เลือกได้เพียง 1 ประเภทเท่านั้น)
                      </span>
                    </p>
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
                      <Select
                        label="Regular Dropdown"
                        value={updateUserDetail.permissionId?.toString()}
                        onChange={handleUserPermissionDropdownChange}
                        size="small"
                        style={{ width: "296px" }}
                        error={!updateUserDetail.permissionId && isClickSave}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "250px",
                              scrollbarWidth: "thin",
                            },
                          },
                        }}
                      >
                        {userPermissionList.map((userPermission) => (
                          <MenuItem
                            value={userPermission.id}
                            sx={{ fontSize: 14 }}
                          >
                            {userPermission.nameThai}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <p style={{ borderBottom: "1px solid #D8D8D8" }}></p>
                <div>
                  <Typography style={{ color: "#212121", fontWeight: "bold" }}>
                    เอกสารแนบ
                  </Typography>
                  <DownloadFile userId={userId} />
                </div>
              </Box>
            </div>
            <br />
            <Box p={2} bgcolor={"white"} style={{ height: "60px" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#7A8688",
                    width: "90px",
                    height: "35px",
                    marginRight: "10px",
                  }}
                  onClick={() => navigate("/UserTab")}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#DF5C8E",
                    width: "90px",
                    height: "35px",
                  }}
                  onClick={() => handleSubmitData()}
                >
                  บันทึก
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Layout>
  );
};
export default EditUser;
