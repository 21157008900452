import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const userManagementService = {
  login: async function (email: string, password: string) {
    try {
      const response = await axios.post(`${BASE_URL}/v1/auth/login`, {
        clientType: "web",
        email: email,
        password: password,
        validationType: "PASSWORD",
      });
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("userId", response.data.userId);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  loginWithSSO: async function (googleToken: string) {
    try {
      const response = await axios.post(`${BASE_URL}/v1/auth/login`, {
        clientType: "sso",
        validationType: "SSO",
        googleToken: googleToken,
      });
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("userId", response.data.userId);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  register: async function (data: any) {
    try {
      const response = await axios.post(`${BASE_URL}/v1/auth/register`, data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  addPatient: async function (data: any) {
    try {
      const response = await axios.post(
        `${BASE_URL}/v1/auth/register/mobile`,
        data
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createWebUser: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/users/webUser/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  deleteWebUser: async function (userId: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(
        `${BASE_URL}/v1/users/webUser/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getUserList: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/v1/users/webUser/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getApprovalWaitingList: async function (accountStatus: string) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/v1/users/webUser/approving/list?accountStatus=${accountStatus}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getUserDetail: async (userId: string) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/v1/users/${userId}/userDetail`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getAffiliatedHospital: async () => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/v1/datalist/affiliatedHospital`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getUserPermission: async () => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/v1/permission/fetch`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  approveUser: async (data: any) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/users/approve/single`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  approveBatchUser: async (data: any) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/users/approve/batch`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updatePatientProfile: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/v1/users/mobile/userDetail`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getDocumentList: async (userId: string) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/v1/document/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getDocumentById: async (documentId: string) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/v1/document/${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getAllCollectData: async (id: number) => {
    try {
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const response = await axios.post(
        `${BASE_URL}/v1/dataCollection/daily/mobile/list/all`,
        {
          targetUserId: id,
        },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getCurrentHealthInfo: async (id: number, date: string) => {
    try {
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const response = await axios.post(
        `${BASE_URL}/v1/dataCollection/daily/mobile/search`,
        {
          targetUserId: id,
          targetDate: date,
        },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateEntryValidity: async (id: number, isValid: boolean) => {
    try {
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const response = await axios.post(
        `${BASE_URL}/v1/dataCollection/daily/mobile/validate`,
        {
          entryId: id,
          isValid: isValid,
        },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getLatestConsent: async () => {
    try {
      const response = await axios.post(`${BASE_URL}/v1/consent/latest`, {
        userType: "WEB",
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getLatestConsentMobile: async () => {
    try {
      const response = await axios.post(`${BASE_URL}/v1/consent/latest`, {
        userType: "MOBILE",
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateLatestConsent: async (ConsentData: any, userId: string) => {
    try {
      const response = await axios.put(`${BASE_URL}/v1/consent/submit`, {
        userId: userId,
        tcVersion: ConsentData.termAndCondition.version,
        pdpaVersion: ConsentData.personalDataProtectionAct.version,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createUserPermission: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/permission/create`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateUserPermission: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/v1/permission/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  resetPassword: async function (email: string, password: string) {
    try {
      const response = await axios.post(`${BASE_URL}/v1/auth/changePassword`, {
        email: email,
        newPassword: password,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  resetPasswordWithNoEmail: async function (
    tokenReq: string,
    password: string
  ) {
    try {
      const response = await axios.post(`${BASE_URL}/v1/auth/changePassword`, {
        token: tokenReq,
        newPassword: password,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  postChangePasswordEmail: async function (email: string) {
    try {
      const response = await axios.post(
        `${BASE_URL}/v1/auth/changePasswordEmail`,
        {
          email: email,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  deleteUserPermission: async (webUserRoleId: string) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(
        `${BASE_URL}/v1/permission/delete/${webUserRoleId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateUserDetail: async (userId: string, data: any) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/v1/users/${userId}/userDetail`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateUserDetailProfile: async (userId: string, data: any) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${BASE_URL}/v1/users/${userId}/userDetail`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getPatientUserDetail: async (userId: string) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/v1/users/mobile/${userId}/userDetail`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getHistoryLog: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/v1/log/search`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getExportExcelHistoryLog: async function (data: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/v1/log/export`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getAvailableLogFilter: async function () {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/v1/log/availableFilter`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getUserImage: async function (userId: number) {
    const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    };
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/users/mobile/${userId}/avatar/fetch`,
        {},
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const imageBuffer = response.data;
      const base64Image = arrayBufferToBase64(imageBuffer);
      const imageUrl = `data:image/jpeg;base64,${base64Image}`;
      return imageUrl;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  checkUserImage: async function (userId: number): Promise<boolean> {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/users/mobile/${userId}/avatar/check`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
