import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, MenuItem, Select, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ChangeEvent, useState } from "react";
import {
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useEffectOnce } from "react-use";
import { CustomMenuItem } from "../../../components/CustomMenuItem";
import {
  KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowUpIcon,
} from "../../../icon";
import { chronicDiseaseList, healthBenefitList } from "../../../sharedValue";
import { body14400, profileTitle, spacingForm } from "../../../style";
import { theme2 } from "../../../theme";
import { MasterData } from "../../../type";
import { calculateAge } from "../../../utils/calculateAge";
import { getDomicile } from "../../../utils/get-data/getDomicile";
import { getHospital } from "../../../utils/get-data/getHospital";
import { getOccupation } from "../../../utils/get-data/getOccupation";
import { getPrefix } from "../../../utils/get-data/getPrefix";
import { FormData } from "../PatientFilterTab";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type Props = {
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmit: () => void;
  formData: FormData;
  isAddHealthInfo: boolean;
  handleChange: (name: string, value: string | string[]) => void;
  setFormData: (value: React.SetStateAction<FormData>) => void;
  setISAddHealthInfo: (value: React.SetStateAction<boolean>) => void;
  isPending: boolean;
};

export default function AddPatientDialog({
  openDialog,
  handleCloseDialog,
  handleSubmit,
  formData,
  isAddHealthInfo,
  handleChange,
  setFormData,
  setISAddHealthInfo,
  isPending,
}: Props) {
  const [occupationList, setOccupationList] = useState<MasterData[]>();
  const [domicileList, setDomicileList] = useState<MasterData[]>();
  const [hospitalList, setHospitalList] = useState<MasterData[]>();
  const [prefixList, setPrefixList] = useState<MasterData[]>();

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const occupationList = await getOccupation();
        setOccupationList(occupationList);
        const domicileList = await getDomicile();
        setDomicileList(domicileList);
        const prefixList = await getPrefix();
        setPrefixList(prefixList);
        const hospitalList = await getHospital();
        setHospitalList(hospitalList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });
  const handleHnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[A-Za-zก-ฮ\d]+$/;
    if (regex.test(value) || value === "") {
      setFormData((prev) => ({ ...prev, hn: value }));
    } else {
      setFormData((prev) => ({ ...prev, hn: value }));
    }
  };
  return (
    <ThemeProvider theme={theme2}>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="profile-dialog-title"
        open={openDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{ m: 0, py: 1, px: 2, bgcolor: "#F7F8FC" }}
          id="profile-dialog-title"
        >
          <Typography fontSize={18} fontWeight={500}>
            เพิ่มข้อมูลผู้ป่วย
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ scrollbarWidth: "thin" }}>
          <FormContainer onSuccess={handleSubmit}>
            <Stack spacing={3} sx={{ pb: 6 }}>
              <Typography
                component="label"
                color="primary.main"
                fontWeight={700}
              >
                ส่วนที่ 1 : ข้อมูลส่วนตัว
              </Typography>
              <Typography component="label" color={"#646D78"}>
                กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    HN <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"hn"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleHnChange}
                    InputProps={{
                      value: formData.hn?.replace(/^ID/, ""),
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    value={formData.hn?.replace(/^ID/, "") || ""}
                  />
                  {/* <TextField
                    inputRef={hnRef}
                    required
                    name={"hn"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={handleHnChange}
                    InputProps={{
                      value: formData.hn?.replace(/^ID/, ""),
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    value={formData.hn?.replace(/^ID/, "") || ""}
                    error={isHnRequired && !formData.hn}
                    helperText={
                      isHnRequired && !formData.hn
                        ? "This field is required"
                        : ""
                    }
                  /> */}
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    คำนำหน้า <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <SelectElement
                    required
                    name="prefix"
                    size="small"
                    sx={{ width: 1 }}
                    onChange={(e) => handleChange("prefix", e)}
                    SelectProps={{
                      value: formData.prefix,
                      displayEmpty: true,
                      sx: {
                        fontSize: 14,
                        color:
                          formData.prefix === ""
                            ? "rgba(0,0,0,0.4)"
                            : "inherit",
                      },
                      MenuProps: {
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "355px !important",
                          },
                        },
                      },
                    }}
                    options={[
                      {
                        id: "",
                        label: "โปรดระบุ",
                        disabled: true,
                      },
                      ...(prefixList
                        ? prefixList.map((item) => ({
                            id: item.nameThai,
                            label: item.nameThai,
                          }))
                        : []),
                    ]}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    ชื่อ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"firstName"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={(e) => handleChange("firstName", e.target.value)}
                    InputProps={{
                      value: formData.firstName,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    นามสกุล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"lastName"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                    InputProps={{
                      value: formData.lastName,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    เพศ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectElement
                    required
                    name="sex"
                    size="small"
                    sx={{ width: 1 }}
                    onChange={(e) => handleChange("sex", e)}
                    SelectProps={{
                      value: formData.sex,
                      displayEmpty: true,
                      sx: {
                        fontSize: 14,
                        color:
                          formData.sex === "" ? "rgba(0,0,0,0.4)" : "inherit",
                      },
                      MenuProps: {
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "355px !important",
                          },
                        },
                      },
                    }}
                    options={[
                      {
                        id: "",
                        label: "โปรดระบุ",
                        disabled: true,
                      },
                      {
                        id: "male",
                        label: "ผู้ชาย",
                      },
                      {
                        id: "female",
                        label: "ผู้หญิง",
                      },
                    ]}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    วันเดือนปีเกิด <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <DatePicker
                    maxDate={dayjs()}
                    value={dayjs(formData.birthDate)}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        fontSize: 14,
                      },
                    }}
                    onChange={(e) =>
                      handleChange("birthDate", e?.toISOString() as string)
                    }
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  อายุ
                </Typography>
                <Typography
                  component="label"
                  style={body14400}
                  color={"#098484"}
                >
                  {formData.birthDate ? calculateAge(formData.birthDate) : `-`}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    น้ำหนักปัจจุบัน (กก.){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name={"weight"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={(e) => handleChange("weight", e.target.value)}
                    InputProps={{
                      value: formData.weight?.toLocaleString(),
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    rules={{
                      required: "This field is required",
                      min: {
                        value: 1,
                        message: "This field is required and value > 0",
                      },
                    }}
                  />

                  {/* <TextField
                    inputRef={weightRef}
                    id="weight"
                    variant="outlined"
                    size="small"
                    placeholder="โปรดระบุ"
                    sx={{ width: 1 }}
                    inputProps={{
                      min: 1,
                    }}
                    InputProps={{
                      value: formData.weight?.toLocaleString(),
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={(e) => handleChange(`weight`, e.target.value)}
                    error={isWeightRequired && Number(formData.weight) < 1}
                    helperText={
                      isWeightRequired && Number(formData.weight) < 1
                        ? "This field is required"
                        : ""
                    }
                  /> */}
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    ส่วนสูง (ซม.) <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required
                    name="height"
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    onChange={(e) => handleChange("height", e.target.value)}
                    InputProps={{
                      value: formData.height?.toLocaleString(),
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    rules={{
                      required: "This field is required",
                      min: {
                        value: 1,
                        message: "This field is required and value > 0",
                      },
                    }}
                  />
                  {/* <TextField
                    inputRef={heightRef}
                    id="height"
                    variant="outlined"
                    size="small"
                    placeholder="โปรดระบุ"
                    sx={{ width: 1 }}
                    inputProps={{
                      min: 1,
                    }}
                    InputProps={{
                      value: formData.height?.toLocaleString(),
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={(e) => handleChange(`height`, e.target.value)}
                    error={isHeightRequired && Number(formData.height) < 1}
                    helperText={
                      isHeightRequired && Number(formData.height) < 1
                        ? "This field is required"
                        : ""
                    }
                  /> */}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    โรงพยาบาล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectElement
                    name="affiliatedHospital"
                    required
                    value={formData.affiliatedHospital}
                    size="small"
                    sx={{ width: 1 }}
                    onChange={(e) => handleChange("affiliatedHospital", e)}
                    SelectProps={{
                      value: formData.affiliatedHospital ?? "",
                      displayEmpty: true,
                      sx: {
                        fontSize: 14,
                        color:
                          formData.affiliatedHospital === ""
                            ? "rgba(0,0,0,0.4)"
                            : " #212121",
                      },
                      MenuProps: {
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "355px !important",
                          },
                        },
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                      },
                    }}
                    options={[
                      {
                        id: "",
                        label: "โปรดระบุ",
                        disabled: true,
                      },
                      ...(hospitalList
                        ? hospitalList?.map((item) => ({
                            id: item.nameThai,
                            label: item.nameThai,
                          }))
                        : []),
                    ]}
                  />
                  {/* <Select
                    inputRef={hospitalRef}
                    name="affiliatedHospital"
                    required
                    value={formData.affiliatedHospital ?? ""}
                    size="small"
                    sx={{
                      width: 1,
                      fontSize: 14,
                      color:
                        formData.affiliatedHospital === ""
                          ? "rgba(0,0,0,0.4)"
                          : "#212121",
                    }}
                    onChange={(e) =>
                      handleChange("affiliatedHospital", e.target.value)
                    }
                    displayEmpty
                    MenuProps={{
                      sx: {
                        zoom: 100 * (100 / 85) + "%",
                        top: "0px",
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: "250px",
                          scrollbarWidth: "thin",
                          minWidth: "355px !important",
                        },
                      },
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    }}
                    error={isHospitalRequired && !formData.affiliatedHospital}
                  >
                    <MenuItem disabled value={""}>
                      โปรดระบุ
                    </MenuItem>
                    {hospitalList &&
                      hospitalList.length > 0 &&
                      hospitalList.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.nameThai}>
                            {item.nameThai}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {isHospitalRequired && !formData.affiliatedHospital ? (
                    <FormHelperText error sx={{ pl: "14px" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    ""
                  )} */}
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    สิทธิ์การรักษา <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <SelectElement
                    name="healthBenefit"
                    required
                    value={formData.healthBenefit}
                    size="small"
                    sx={{ width: 1 }}
                    onChange={(e) => handleChange("healthBenefit", e)}
                    SelectProps={{
                      value: formData.healthBenefit ?? "",
                      displayEmpty: true,
                      sx: {
                        fontSize: 14,
                        color:
                          formData.healthBenefit === ""
                            ? "rgba(0,0,0,0.4)"
                            : " #212121",
                      },
                      MenuProps: {
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "355px !important",
                          },
                        },
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                      },
                    }}
                    options={[
                      {
                        id: "",
                        label: "โปรดระบุ",
                        disabled: true,
                      },
                      ...(healthBenefitList
                        ? healthBenefitList?.map((item) => ({
                            id: item,
                            label: item,
                          }))
                        : []),
                    ]}
                  />
                </Stack>
              </Stack>
              {formData.healthBenefit === "อื่นๆ" && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    สิทธิ์การรักษา (อื่นๆ){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={formData.healthBenefit === "อื่นๆ"}
                    name={"healthBenefitOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: formData.healthBenefitOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={(e) =>
                      handleChange("healthBenefitOther", e.target.value)
                    }
                  />
                </Stack>
              )}
              <Stack
                direction="row"
                useFlexGap
                display={"grid"}
                gridTemplateColumns={"1fr 1fr"}
                gap={2}
              >
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    ภูมิลำเนา
                  </Typography>
                  <SelectElement
                    name="domicile"
                    value={formData.domicile}
                    size="small"
                    sx={{ width: 1 }}
                    onChange={(e) => handleChange("domicile", e)}
                    SelectProps={{
                      value: formData.domicile ?? "",
                      displayEmpty: true,
                      sx: {
                        fontSize: 14,
                        color:
                          formData.domicile === ""
                            ? "rgba(0,0,0,0.4)"
                            : "#212121",
                      },
                      MenuProps: {
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "355px !important",
                          },
                        },
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                      },
                    }}
                    options={[
                      {
                        id: "",
                        label: "โปรดระบุ",
                        disabled: true,
                      },
                      ...(domicileList
                        ? domicileList?.map((item) => ({
                            id: item.nameThai,
                            label: item.nameThai,
                          }))
                        : []),
                    ]}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    อาชีพ
                  </Typography>
                  <SelectElement
                    name="occupation"
                    value={formData.occupation}
                    size="small"
                    sx={{ width: 1 }}
                    onChange={(e) => handleChange("occupation", e)}
                    SelectProps={{
                      value: formData.occupation ?? "",
                      displayEmpty: true,
                      sx: {
                        fontSize: 14,
                        color:
                          formData.occupation === ""
                            ? "rgba(0,0,0,0.4)"
                            : " #212121",
                      },
                      MenuProps: {
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "355px !important",
                          },
                        },
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                      },
                    }}
                    options={[
                      {
                        id: "",
                        label: "โปรดระบุ",
                        disabled: true,
                      },
                      ...(occupationList
                        ? occupationList?.map((item) => ({
                            id: item.nameThai,
                            label: item.nameThai,
                          }))
                        : []),
                    ]}
                  />
                </Stack>
              </Stack>
              {formData.occupation === "อื่นๆ (โปรดระบุ)" && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    อาชีพ (อื่นๆ) <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={formData.occupation === "อื่นๆ (โปรดระบุ)"}
                    name={"occupationOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: formData.occupationOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={(e) =>
                      handleChange("occupationOther", e.target.value)
                    }
                  />
                </Stack>
              )}
              <Box
                sx={{
                  bgcolor: !isAddHealthInfo
                    ? "rgba(9,132,132,0.13)"
                    : "rgba(253,233,233,1)",
                  p: 1,
                  px: 2,
                  color: !isAddHealthInfo ? "primary.main" : "#F34141",
                  borderRadius: 2,
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setISAddHealthInfo(!isAddHealthInfo)}
              >
                {!isAddHealthInfo ? (
                  <>
                    <KeyboardDoubleArrowDownIcon /> เพิ่มข้อมูลสุขภาพ
                  </>
                ) : (
                  <>
                    {" "}
                    <KeyboardDoubleArrowUpIcon /> ลบข้อมูลสุขภาพ
                  </>
                )}
              </Box>
              {isAddHealthInfo && (
                <Stack spacing={3} sx={{ pb: 6 }}>
                  <Typography
                    component="label"
                    color="primary.main"
                    fontWeight={700}
                  >
                    ส่วนที่ 2 : ข้อมูลสุขภาพ
                  </Typography>
                  <Stack>
                    <Typography component="label" style={profileTitle}>
                      เคยผ่าตัดกระเพาะอาหาร
                    </Typography>
                    <RadioButtonGroup
                      row
                      name="bariatricSurgeryHistory"
                      onChange={(e) =>
                        handleChange(
                          `bariatricSurgeryHistory`,
                          e?.toString() as string
                        )
                      }
                      options={[
                        {
                          id: "ใช่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ใช่
                            </span>
                          ),
                        },
                        {
                          id: "ไม่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ไม่
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Stack>
                  <Stack>
                    <Typography component="label" style={profileTitle}>
                      การดื่มเหล้า
                    </Typography>
                    <RadioButtonGroup
                      row
                      name="drinkingHistory"
                      onChange={(e) =>
                        handleChange(`drinkingHistory`, e?.toString() as string)
                      }
                      options={[
                        {
                          id: "ใช่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ใช่
                            </span>
                          ),
                        },
                        {
                          id: "ไม่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ไม่
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Stack>
                  <Stack>
                    <Typography component="label" style={profileTitle}>
                      การสูบบุหรี่
                    </Typography>
                    <RadioButtonGroup
                      row
                      name="smokingHistory"
                      onChange={(e) =>
                        handleChange(`smokingHistory`, e?.toString() as string)
                      }
                      options={[
                        {
                          id: "ใช่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ใช่
                            </span>
                          ),
                        },
                        {
                          id: "ไม่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ไม่
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Stack>
                  <Stack spacing={spacingForm}>
                    <Typography component="label" style={profileTitle}>
                      น้ำหนักมากที่สุดของคุณ (กก.)
                    </Typography>
                    <TextField
                      id="highestWeight"
                      variant="outlined"
                      size="small"
                      placeholder="โปรดระบุ"
                      sx={{ width: 1 }}
                      inputProps={{
                        min: 1,
                      }}
                      InputProps={{
                        value: formData.highestWeight?.toLocaleString(),
                        sx: {
                          fontSize: 14,
                          color: "#212121",
                        },
                      }}
                      onChange={(e) =>
                        handleChange(`highestWeight`, e.target.value)
                      }
                    />
                  </Stack>
                  <Stack spacing={spacingForm}>
                    <Typography component="label" style={profileTitle}>
                      วิธีลดความอ้วนที่เคยใช้
                    </Typography>
                    <Select
                      id="weightLossMethod"
                      multiple
                      value={formData.weightLossMethod ?? []}
                      size="small"
                      sx={{
                        width: 1,
                        fontSize: 14,
                        color:
                          formData.weightLossMethod.length === 0
                            ? "rgba(0,0,0,0.4)"
                            : "inherit",
                      }}
                      onChange={(e) =>
                        handleChange(`weightLossMethod`, e.target.value)
                      }
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <>โปรดระบุ</>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected?.map((value) => (
                                <Chip key={value} label={`${value}`} />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={{
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "465px !important",
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value={""}>
                        โปรดระบุ
                      </MenuItem>
                      <CustomMenuItem
                        value="ออกกำลังกาย"
                        label="ออกกำลังกาย"
                        isSelected={
                          formData.weightLossMethod?.includes("ออกกำลังกาย") ??
                          false
                        }
                        onClick={() => {}}
                      />
                      <CustomMenuItem
                        value="ยาลดน้ำหนัก"
                        label="ยาลดน้ำหนัก"
                        isSelected={
                          formData.weightLossMethod?.includes("ยาลดน้ำหนัก") ??
                          false
                        }
                        onClick={() => {}}
                      />
                      <CustomMenuItem
                        value="คุมอาหาร"
                        label="คุมอาหาร"
                        isSelected={
                          formData.weightLossMethod?.includes("คุมอาหาร") ??
                          false
                        }
                        onClick={() => {}}
                      />
                      <CustomMenuItem
                        value="อื่นๆ"
                        label="อื่นๆ"
                        isSelected={
                          formData.weightLossMethod?.includes("อื่นๆ") ?? false
                        }
                        onClick={() => {}}
                      />
                    </Select>
                  </Stack>
                  {formData.weightLossMethod?.includes("อื่นๆ") && (
                    <Stack spacing={spacingForm}>
                      <Typography component="label" style={profileTitle}>
                        วิธีลดความอ้วนที่เคยใช้ (อื่นๆ){" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        required={formData.weightLossMethod.includes("อื่นๆ")}
                        name={"weightLossMethodOther"}
                        size="small"
                        sx={{ width: 1 }}
                        placeholder="โปรดระบุ"
                        InputProps={{
                          value: formData.weightLossMethodOther,
                          sx: {
                            fontSize: 14,
                            color: "#212121",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(`weightLossMethodOther`, e.target.value)
                        }
                      />
                    </Stack>
                  )}
                  <Stack spacing={spacingForm}>
                    <Typography component="label" style={profileTitle}>
                      โรคประจำตัว
                    </Typography>
                    <Select
                      id="chronicDisease"
                      multiple
                      value={formData.chronicDisease ?? []}
                      size="small"
                      sx={{
                        width: 1,
                        fontSize: 14,
                        color:
                          formData.chronicDisease.length === 0
                            ? "rgba(0,0,0,0.4)"
                            : "inherit",
                      }}
                      onChange={(e) =>
                        handleChange(`chronicDisease`, e.target.value)
                      }
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <>โปรดระบุ</>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected?.map((value) => (
                                <Chip key={value} label={`${value}`} />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={{
                        sx: {
                          zoom: 100 * (100 / 85) + "%",
                          top: "0px",
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: "250px",
                            scrollbarWidth: "thin",
                            minWidth: "465px !important",
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value={""}>
                        โปรดระบุ
                      </MenuItem>
                      {chronicDiseaseList.map((item, idx) => {
                        return (
                          <CustomMenuItem
                            key={idx}
                            value={item}
                            label={item}
                            isSelected={
                              formData.chronicDisease?.includes(item) ?? false
                            }
                            onClick={() => {}}
                          />
                        );
                      })}
                    </Select>
                  </Stack>
                  {formData.chronicDisease?.includes("โรคทางจิตเวช") && (
                    <Stack spacing={spacingForm}>
                      <Typography component="label" style={profileTitle}>
                        โรคทางจิตเวช (ระบุเพิ่มเติม){" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        required={formData.chronicDisease.includes(
                          "โรคทางจิตเวช"
                        )}
                        name={"psychoticDisorderOther"}
                        size="small"
                        sx={{ width: 1 }}
                        placeholder="โปรดระบุ"
                        InputProps={{
                          value: formData.psychoticDisorderOther,
                          sx: {
                            fontSize: 14,
                            color: "#212121",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(`psychoticDisorderOther`, e.target.value)
                        }
                      />
                    </Stack>
                  )}
                  {formData.chronicDisease?.includes("โรคมะเร็ง") && (
                    <Stack spacing={spacingForm}>
                      <Typography component="label" style={profileTitle}>
                        โรคมะเร็ง (ระบุเพิ่มเติม){" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        required={formData.chronicDisease.includes("โรคมะเร็ง")}
                        name={"cancerOther"}
                        size="small"
                        sx={{ width: 1 }}
                        placeholder="โปรดระบุ"
                        InputProps={{
                          value: formData.cancerOther,
                          sx: {
                            fontSize: 14,
                            color: "#212121",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(`cancerOther`, e.target.value)
                        }
                      />
                    </Stack>
                  )}
                  {formData.chronicDisease?.includes("โรคอื่นๆ") && (
                    <Stack spacing={spacingForm}>
                      <Typography component="label" style={profileTitle}>
                        โรคอื่นๆ (ระบุเพิ่มเติม){" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        required={formData.chronicDisease.includes("โรคอื่นๆ")}
                        name={"chronicDiseaseOther"}
                        size="small"
                        sx={{ width: 1 }}
                        placeholder="โปรดระบุ"
                        InputProps={{
                          value: formData.chronicDiseaseOther,
                          sx: {
                            fontSize: 14,
                            color: "#212121",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(`chronicDiseaseOther`, e.target.value)
                        }
                      />
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                bgcolor: "white",
                height: "45px",
                display: "flex",
                justifyContent: "flex-end",
                width: "95%",
                pt: 1,
                borderTop: "1px solid #E3E7F0",
              }}
            >
              <Stack direction={"row"} spacing={1} height={35}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleCloseDialog}
                  sx={{
                    width: 100,
                    bgcolor: "#7A8688",
                    "&:hover": {
                      bgcolor: "#7A8688",
                    },
                  }}
                >
                  ยกเลิก
                </Button>
                {/* <Button
                  autoFocus
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{
                    width: 150,
                    bgcolor: "#098484",
                    "&:hover": {
                      bgcolor: "#098484",
                    },
                  }}
                >
                  สร้างทะเบียนผู้ป่วย
                </Button> */}
                <LoadingButton
                  loading={isPending}
                  size="small"
                  type="submit"
                  variant="contained"
                  sx={{
                    width: 150,
                    bgcolor: "#098484",
                    "&:hover": {
                      bgcolor: "#098484",
                    },
                  }}
                >
                  สร้างทะเบียนผู้ป่วย
                </LoadingButton>
              </Stack>
            </Box>
          </FormContainer>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  );
}
