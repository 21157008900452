import { Box } from "@mui/material";
import { SubmitBottomBar } from "./SubmitBottomBar";
import React from "react";

type Props = {
  children: React.ReactNode;
  handleCancelClick: () => void;
  handleSubmitData: () => void;
};
export const LayoutTab = ({
  children,
  handleCancelClick,
  handleSubmitData,
}: Props) => {
  return (
    <Box sx={{ bgcolor: "#DEE2E6" }}>
      <Box
        sx={{
          height: "calc(100vh - 120px)",
          width: "calc(100% - 0px)",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          bgcolor: "white",
          scrollbarWidth: "thin",
        }}
      >
        {children}
      </Box>
      <Box sx={{ mt: 1, width: 1 }}>
        <SubmitBottomBar
          handleCancelClick={handleCancelClick}
          handleSubmitData={handleSubmitData}
        />
      </Box>
    </Box>
  );
};
