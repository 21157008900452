import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  DialogActions,
  Button,
  RadioGroup,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  RadioGreyBox,
  TextGreyBox,
  TextWhiteBox,
  YesNoBox,
} from "../../../components/TextBox";
import { InvestigationEndoscopy } from "./InvestigationEndoscopy";
import { OtherAdd, OtherArrow, OtherRemove } from "../../../asset/iconSvg";
import { store } from "../../../redux/store";

interface InvestigationEndoscopyProps extends InvestigationEndoscopy {
  open: boolean;
  onClose: (data: InvestigationEndoscopy) => void;
  onSave: (data: InvestigationEndoscopy) => void;
}

export const InvestigationEndoscopyDialog: React.FC<
  InvestigationEndoscopyProps
> = ({
  open,
  onClose,
  onSave,
  hiatalHernia,
  refluxEsophagitis,
  gastritis,
  gastricDuodenalUlcer,
  hPyroliInfection,
  other,
  otherDetail,
}) => {
  const initialValues = useMemo(
    () => ({
      hiatalHernia,
      refluxEsophagitis,
      gastritis,
      gastricDuodenalUlcer,
      hPyroliInfection,
      other,
      otherDetail,
    }),
    [
      hiatalHernia,
      refluxEsophagitis,
      gastritis,
      gastricDuodenalUlcer,
      hPyroliInfection,
      other,
      otherDetail,
    ]
  );
  const [endoscopyValues, setEndoscopyValues] =
    useState<InvestigationEndoscopy>(initialValues);
  const [endoscopyValueTemp, setEndoscopyValuesTemp] =
    useState<InvestigationEndoscopy>(initialValues);
  const [otherDetails, setOtherDetails] = useState(initialValues.otherDetail);
  const [validationError, setValidationError] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffect(() => {
    setEndoscopyValues(initialValues);
    setEndoscopyValuesTemp(initialValues);
    setOtherDetails(initialValues.otherDetail);
  }, [initialValues]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndoscopyValues({
      ...endoscopyValues,
      [event.target.name]: event.target.value === "yes" ? "yes" : "no",
    });
  };
  const handleClickCancle = () => {
    setEndoscopyValues(endoscopyValueTemp);
    onClose(endoscopyValueTemp);
  };
  useEffect(() => {
    setEndoscopyValues({
      ...endoscopyValues,
      otherDetail: otherDetails,
    });
  }, [otherDetails]);
  const handleClickSave = () => {
    // onSave(endoscopyValues);
    if (patientDataPermissionEditAble === false) {
      setEndoscopyValues(endoscopyValueTemp);
      onClose(endoscopyValueTemp);
      return;
    }
    if (endoscopyValues.other === "yes") {
      if (validateOtherRequiredFields()) {
        setValidationError(false);
        onSave(endoscopyValues);
      } else {
        setValidationError(true);
      }
    } else {
      onSave(endoscopyValues);
      setValidationError(false);
    }
  };
  const handleOtherAdd = () => {
    setOtherDetails((prevDetails) => [...prevDetails, ""]);
  };
  const handleOtherRemove = (index: number) => {
    if (otherDetails.length !== 1) {
      const newDetails = otherDetails.filter((_, i) => i !== index);
      setOtherDetails(newDetails);
    }
  };
  const handleOtherChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDetails = [...otherDetails];
    newDetails[index] = event.target.value;
    setOtherDetails(newDetails);
  };
  const validateOtherRequiredFields = () => {
    for (let detail of otherDetails) {
      if (!detail.trim()) {
        return false;
      }
    }
    return true;
  };
  return (
    <Dialog open={open} onClose={handleClickCancle}>
      <DialogTitle>Endoscopy</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div style={{ width: "550px", display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Stack spacing={1}>
              <Stack spacing={1} direction={"row"}>
                <TextWhiteBox>{``}</TextWhiteBox>
                <YesNoBox />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Hiatal Hernia</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="hiatalHernia"
                    defaultValue="no"
                  >
                    <Radio
                      checked={endoscopyValues.hiatalHernia === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={endoscopyValues.hiatalHernia === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Reflux Esophagitis</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="refluxEsophagitis"
                    defaultValue="no"
                  >
                    <Radio
                      checked={endoscopyValues.refluxEsophagitis === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={endoscopyValues.refluxEsophagitis === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Gastritis</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="gastritis"
                    defaultValue="no"
                  >
                    <Radio
                      checked={endoscopyValues.gastritis === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={endoscopyValues.gastritis === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Gastric/Duodenal Ulcer</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="gastricDuodenalUlcer"
                    defaultValue="no"
                  >
                    <Radio
                      checked={endoscopyValues.gastricDuodenalUlcer === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={endoscopyValues.gastricDuodenalUlcer === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>H. pyroli Infection</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="hPyroliInfection"
                    defaultValue="no"
                  >
                    <Radio
                      checked={endoscopyValues.hPyroliInfection === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={endoscopyValues.hPyroliInfection === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Other</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="other"
                    defaultValue="no"
                  >
                    <Radio
                      checked={endoscopyValues.other === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={endoscopyValues.other === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              {endoscopyValues.other === "yes" && (
                <Stack spacing={1}>
                  {otherDetails.map((detail, index) => {
                    return (
                      <Stack
                        key={index}
                        spacing={1}
                        direction={"row"}
                        // alignItems={"center"}
                      >
                        <Box sx={{ pt: 1 }}>
                          <OtherArrow />
                        </Box>

                        <TextField
                          required
                          sx={{ width: "62%" }}
                          value={detail}
                          onChange={(e) => handleOtherChange(index, e)}
                          error={validationError && !detail.trim()}
                          helperText={
                            validationError && !detail.trim()
                              ? "This field is required"
                              : ""
                          }
                        />
                        <Box
                          sx={{ pt: 0.5 }}
                          onClick={() => handleOtherRemove(index)}
                        >
                          <OtherRemove />
                        </Box>
                        <Box sx={{ pt: 0.5 }} onClick={handleOtherAdd}>
                          <OtherAdd />
                        </Box>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClickCancle}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};
