import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { HistoryIcon, LogIcon } from "../../icon";

type Props = {
  handleHistoryClick: () => void;
  handleLogClick: () => void;
  visitDate: string;
};
export const HistoryButtonGroup = ({
  handleHistoryClick,
  handleLogClick,
  visitDate,
}: Props) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      gap={2}
      alignItems={"center"}
    >
      <Typography>{`Visit Date : ${dayjs(visitDate).format(
        "DD/MM/BBBB"
      )}`}</Typography>
      <Box
        sx={{
          border: "2px solid #C1E0E0",
          color: "secondary.main",
          fontWeight: 700,
          fontSize: 14,
          px: 1,
          py: 1 / 2,
          borderRadius: 2,
          bgcolor: "#F5FAFA",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
        onClick={handleHistoryClick}
      >
        <HistoryIcon fontSize="small" />
        Visit History
      </Box>
      <Box
        sx={{
          border: "2px solid #8A8A8A",
          color: "#141414",
          fontWeight: 700,
          fontSize: 14,
          px: 1,
          py: 1 / 2,
          borderRadius: 2,
          bgcolor: "#F6F6F6",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
        onClick={handleLogClick}
      >
        <LogIcon fontSize="small" />
        History Log
      </Box>
    </Box>
  );
};
