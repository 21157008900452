export const calculateAge = (inputDate: string, onlyYear: boolean = false) => {
  const birthDate = new Date(inputDate);
  const today = new Date();
  let age = { years: 0, months: 0, days: 0 };

  age.years = today.getFullYear() - birthDate.getFullYear();
  age.months = today.getMonth() - birthDate.getMonth();
  age.days = today.getDate() - birthDate.getDate();

  if (age.days < 0) {
    age.months--;
    age.days += 30;
  }

  if (age.months < 0) {
    age.years--;
    age.months += 12;
  }
  if (onlyYear) {
    return age.years;
  }
  return `${age.years} ปี ${age.months} เดือน ${age.days} วัน`;
};
