export const imageToBase64 = async (
  imagePath: string
): Promise<string | ArrayBuffer | null> => {
  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject("Error reading image data");
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image:", error);
    return null;
  }
};
