import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set } from 'date-fns';
import { UserPermission } from '../../type';

export interface UserDetail {
  userDetail: UserDetail;
  title: string;
  firstName: string;
  lastName: string;
  nationalId: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  mobilePhoneNumber: string;
  affiliatedHospital: string;
  medicalLicenseNumber: string;
  jobPosition: string;
  siteRoleId: string;
  username: string;
  registrationDate: string;
  approvalDate: string;
  approveByUserId: string;
  email: string;
  permission: string;
}

interface LoginUserState {
  userDetail: UserDetail | null;
  userPermission: UserPermission | null;
  userId: string;
}

const initialState: LoginUserState = {
  userDetail: null,
  userPermission: null,
  userId: '',
};

const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState,
  reducers: {
    setUserDetail(state, action: PayloadAction<UserDetail>) {
      state.userDetail = action.payload;
    },
    setUserPermission(state, action: PayloadAction<UserPermission>) {
      state.userPermission = action.payload;
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    updateUserDetailProfile(state, action: PayloadAction<Partial<UserDetail>>) {
      if (state.userDetail) {
        state.userDetail = { ...state.userDetail, ...action.payload };
      }
    },
  },
});

export const { setUserDetail, setUserPermission, setUserId, updateUserDetailProfile } = loginUserSlice.actions;
export default loginUserSlice.reducer;