import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { ChangeEvent } from "react";
import { title10Tab } from "../../style";

type Props = {
  value: number;
  handleStatusChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};
export const StatusBottomBar = ({ value, handleStatusChange }: Props) => {
  return (
    <Stack spacing={1} sx={{ mt: 2, pb: 2, width: 1 }}>
      <Divider />
      <Typography style={title10Tab}>Status</Typography>
      <Typography color={"#646D78"}>Complete ?</Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="status-radio"
          name="status"
          value={value}
          onChange={handleStatusChange}
        >
          <FormControlLabel
            value={1}
            control={<Radio color="secondary" />}
            label="Unverified"
          />
          <FormControlLabel
            value={2}
            control={<Radio color="secondary" />}
            label="Complete"
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
