import { Add } from "@material-ui/icons";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Popper,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { PatientCardListComponent } from "../../components/PatientCardListComponent";
import { DoubleArrowLeftIcon } from "../../icon";
import { updatePatientFilter } from "../../redux/features/patientFilter-slice";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { store } from "../../redux/store";
import { userManagementService } from "../../service/userManagementService";
import { body14400, title16500 } from "../../style";
import { theme2 } from "../../theme";
import { MasterData, PatientUser } from "../../type";
import { getHospital } from "../../utils/get-data/getHospital";
import { getPrefix } from "../../utils/get-data/getPrefix";
import AddPatientDialog from "./dialog/AddPatientDialog";
import "./PatientFilterTab.css";

export type FormData = {
  prefix: string;
  firstName: string;
  lastName: string;
  sex: string;
  hn: string;
  birthDate: string;
  weight: string;
  height: string;
  highestWeight: string;
  occupation: string;
  occupationOther: string;
  domicile: string;
  affiliatedHospital: string;
  healthBenefit: string;
  healthBenefitOther: string;
  bariatricSurgeryHistory?: boolean;
  drinkingHistory?: boolean;
  smokingHistory?: boolean;
  weightLossMethod: string[];
  weightLossMethodOther: string;
  chronicDisease: string[];
  psychoticDisorderOther: string;
  cancerOther: string;
  chronicDiseaseOther: string;
};

type Props = {
  patientList: PatientUser[];
  currentPatient?: PatientUser;
  handlePatientClick: (patient: PatientUser) => void;
  handleHideFilterTab: () => void;
  addPatient: (data: any) => void;
  openUpdateProfileFailureDialog: boolean;
  setOpenUpdateProfileFailureDialog: (
    value: React.SetStateAction<boolean>
  ) => void;
  openDuplicateUserDialog: boolean;
  setOpenDuplicateUserDialog: (value: React.SetStateAction<boolean>) => void;
  openAddPatientDialog: boolean;
  setOpenAddPatientDialog: (value: React.SetStateAction<boolean>) => void;
  isPendingAddPaient: boolean;
};

const initialData: FormData = {
  prefix: "",
  firstName: "",
  lastName: "",
  sex: "",
  hn: "",
  birthDate: dayjs().toISOString(),
  weight: "",
  height: "",
  highestWeight: "",
  occupation: "",
  occupationOther: "",
  domicile: "",
  affiliatedHospital: "",
  healthBenefit: "",
  healthBenefitOther: "",
  bariatricSurgeryHistory: undefined,
  drinkingHistory: undefined,
  smokingHistory: undefined,
  weightLossMethod: [],
  weightLossMethodOther: "",
  chronicDisease: [],
  psychoticDisorderOther: "",
  cancerOther: "",
  chronicDiseaseOther: "",
};

export const PatientFilterTab = ({
  patientList,
  currentPatient,
  handlePatientClick,
  handleHideFilterTab,
  addPatient,
  openDuplicateUserDialog,
  openUpdateProfileFailureDialog,
  setOpenDuplicateUserDialog,
  setOpenUpdateProfileFailureDialog,
  openAddPatientDialog,
  setOpenAddPatientDialog,
  isPendingAddPaient,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedHospital = useAppSelector(
    (state) => state.patientFilter.hospital
  );
  const searchHn = useAppSelector((state) => state.patientFilter.hn);
  const searchName = useAppSelector((state) => state.patientFilter.name);
  const selectedStatus = useAppSelector((state) => state.patientFilter.status);
  const isUnverifiredVisit = useAppSelector(
    (state) => state.patientFilter.unverifiredVisits
  );
  const [filteredPatient, setFilteredPatient] =
    useState<PatientUser[]>(patientList);
  const [hospitalList, setHospitalList] = useState<MasterData[]>([]);
  const [prefixList, setPrefixList] = useState<MasterData[]>();
  const [formData, setFormData] = useState<FormData>(initialData);
  const [isAddHealthInfo, setISAddHealthInfo] = useState(false);

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const prefixList = await getPrefix();
        setPrefixList(prefixList);
        const hospitalList = await getHospital();
        setHospitalList(hospitalList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
    filterPatient();
  });
  useEffect(() => {
    if (!isAddHealthInfo) {
      setFormData((prev) => ({
        ...prev,
        bariatricSurgeryHistory: undefined,
        drinkingHistory: undefined,
        smokingHistory: undefined,
        weightLossMethod: [],
        weightLossMethodOther: "",
        chronicDisease: [],
        psychoticDisorderOther: "",
        cancerOther: "",
        chronicDiseaseOther: "",
      }));
    }
  }, [isAddHealthInfo]);
  useEffect(() => {
    setFilteredPatient(patientList);
  }, [patientList]);
  const handleChange = (name: string, value: string | string[]) => {
    const numberWithTwoDecimalsRegex = /^\d+(\.\d{0,2})?$/;

    if (name === "weight" || name === "height" || name === "highestWeight") {
      if (!numberWithTwoDecimalsRegex.test(value as string)) {
        return;
      }
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "sex" && value === "male") {
      setFormData((prev) => ({
        ...prev,
        stillHavingPeriod: undefined,
        periodGapDuration: "",
        isPregnant: undefined,
        pregnancyStatusList: [],
        numberOfPregnancy: 0,
        numberOfMiscarriage: 0,
      }));
    } else if (name === "birthDate") {
      const formattedDate = dayjs(value as string).format("YYYY-MM-DD");
      setFormData((prev) => ({ ...prev, [name]: formattedDate }));
    } else if (name === "occupation" && value !== "อื่นๆ (โปรดระบุ)") {
      setFormData((prev) => ({ ...prev, occupationOther: "" }));
    } else if (name === "healthBenefit" && value !== "อื่นๆ") {
      setFormData((prev) => ({ ...prev, healthBenefitOther: "" }));
    } else if (name === "bariatricSurgeryHistory") {
      setFormData((prev) => ({
        ...prev,
        bariatricSurgeryHistory: value === "ใช่" ? true : false,
      }));
    } else if (name === "drinkingHistory") {
      setFormData((prev) => ({
        ...prev,
        drinkingHistory: value === "ใช่" ? true : false,
      }));
    } else if (name === "smokingHistory") {
      setFormData((prev) => ({
        ...prev,
        smokingHistory: value === "ใช่" ? true : false,
      }));
    } else if (name === "weightLossMethod") {
      setFormData((prev) => ({
        ...prev,
        weightLossMethod: value as string[],
      }));
      if (!value.includes("อื่นๆ")) {
        setFormData((prev) => ({ ...prev, weightLossMethodOther: "" }));
      }
    }
  };
  const handleSelectedStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const statusName = event.target.name;
    const newSelectedStatus = [...selectedStatus];
    const index = newSelectedStatus.indexOf(statusName);
    if (index !== -1) {
      newSelectedStatus.splice(index, 1);
    } else {
      newSelectedStatus.push(statusName);
    }
    dispatch(
      updatePatientFilter({
        status: newSelectedStatus,
      })
    );
  };
  const handleIsUnverifiredChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updatePatientFilter({
        unverifiredVisits: event.target.checked,
      })
    );
  };
  const filterPatient = () => {
    const hospitalFilter = (item: PatientUser) => {
      if (!selectedHospital) return true;
      if (selectedHospital === "-") return item.affiliatedHospital === null;
      return item.affiliatedHospital
        ?.toLowerCase()
        .includes(selectedHospital.toLowerCase());
    };

    const hnFilter = (item: PatientUser) => {
      if (!searchHn) return true;
      if (searchHn === "-") return item.hn === null;
      return item.hn?.toLowerCase().includes(searchHn.toLowerCase());
    };

    const nameFilter = (item: PatientUser) => {
      if (!searchName) return true;
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
      return (
        item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
        fullName.includes(searchName.toLowerCase())
      );
    };

    const statusFilter = (item: PatientUser) => {
      if (selectedStatus.length === 0) return true;
      return selectedStatus.includes(item.userStatus);
    };
    setFilteredPatient(
      patientList.filter(
        (item) =>
          hospitalFilter(item) &&
          hnFilter(item) &&
          nameFilter(item) &&
          statusFilter(item)
      )
    );
  };
  const handleHospitalChange = (value: string) => {
    dispatch(
      updatePatientFilter({
        hospital: value,
      })
    );
  };
  const handleHnChange = (value: string) => {
    dispatch(
      updatePatientFilter({
        hn: value,
      })
    );
  };
  const handleNameChange = (value: string) => {
    dispatch(
      updatePatientFilter({
        name: value,
      })
    );
  };
  const handleClearFilter = () => {
    dispatch(
      updatePatientFilter({
        hospital: "",
        hn: "",
        name: "",
        status: [],
        unverifiredVisits: false,
      })
    );
    setFilteredPatient(patientList);
  };
  const handleCloseDialog = () => {
    setOpenAddPatientDialog(false);
  };
  const handleAddPatientClick = () => {
    setOpenAddPatientDialog(true);
    setFormData(initialData);
    setISAddHealthInfo(false);
  };
  const submitAddPatient = async () => {
    const consent = await userManagementService.getLatestConsentMobile();

    Number(formData.weight) > 0 &&
      Number(formData.height) > 0 &&
      formData.affiliatedHospital &&
      formData.hn !== "" &&
      (await addPatient({
        lineUserId: null,
        tc_version: consent.termAndCondition.version,
        pdpaVersion: consent.personalDataProtectionAct.version,
        personalData: {
          prefix: prefixList?.find((p) => p.nameThai === formData.prefix)?.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          sex: formData.sex === "male" ? 1 : 2,
          birthDate: formData.birthDate,
          weight: formData.weight.toString(),
          height: formData.height.toString(),
          occupation: formData.occupation !== "" ? formData.occupation : null,
          occupationOther:
            formData.occupationOther !== "" ? formData.occupationOther : null,
          domicile: formData.domicile !== "" ? formData.domicile : null,
        },
        healthInfo: {
          hospital:
            formData.affiliatedHospital !== ""
              ? hospitalList?.find(
                  (h) => h.nameThai === formData.affiliatedHospital
                )?.id
              : null,
          hn: formData.hn,
          healthBenefit:
            formData.healthBenefit !== "" ? formData.healthBenefit : null,
          healthBenefitOther:
            formData.healthBenefitOther !== ""
              ? formData.healthBenefitOther
              : null,
          bariatricSurgeryHistory:
            formData.bariatricSurgeryHistory === true
              ? true
              : formData.bariatricSurgeryHistory === false
              ? false
              : null,
          drinkingHistory:
            formData.drinkingHistory === true
              ? true
              : formData.drinkingHistory === false
              ? false
              : null,
          smokingHistory:
            formData.smokingHistory === true
              ? true
              : formData.smokingHistory === false
              ? false
              : null,
          highestWeight:
            formData.highestWeight !== ""
              ? formData.highestWeight.toString()
              : "0",
          weightLossMethod:
            formData.weightLossMethod?.length > 0
              ? formData.weightLossMethod
              : null,
          weightLossMethodOther:
            formData.weightLossMethodOther !== ""
              ? formData.weightLossMethodOther
              : null,
          chronicDisease:
            formData.chronicDisease?.length > 0
              ? formData.chronicDisease
              : null,
          psychoticDisorderOther:
            formData.psychoticDisorderOther !== ""
              ? formData.psychoticDisorderOther
              : null,
          cancerOther:
            formData.cancerOther !== "" ? formData.cancerOther : null,
          chronicDiseaseOther:
            formData.chronicDiseaseOther !== ""
              ? formData.chronicDiseaseOther
              : null,
        },
        sexualHabit: {
          isSexuallyActive: null,
          sexualActivityFrequency: null,
          contraceptionList: null,
          protectionMethodOther: null,
          hasChild: null,
          numberOfChild: null,
          wantChild: null,
          yearTillChildIsExpected: null,
          expectedNumberOfChild: null,
          stillHavingPeriod: null,
          periodGapDuration: null,
          periodDuration: null,
          isPregnant: null,
          numberOfPregnancy: null,
          pregnancyStatusList: null,
          numberOfMiscarriage: null,
        },
      }));
  };
  return (
    <div>
      <Stack
        spacing={1}
        sx={{
          height: "calc(100vh + 48px)",
        }}
      >
        <Typography style={title16500}>ข้อมูลผู้ป่วย</Typography>
        <div className="new-patient-container" style={{ position: "relative" }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: "primary",
            }}
            onClick={handleAddPatientClick}
          >
            <Add /> สร้างทะเบียนผู้ป่วย
          </Button>
        </div>
        <ThemeProvider theme={theme2}>
          <div className="filter-container" style={{ position: "relative" }}>
            <Stack spacing={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  p: 0,
                  cursor: "pointer",
                }}
                onClick={handleHideFilterTab}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    border: "1px solid #646D78",
                    borderRadius: 1 / 2,
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoubleArrowLeftIcon
                    fontSize="small"
                    sx={{ color: "#646D78" }}
                  />
                </Box>
              </Box>
              <Stack spacing={1} style={body14400}>
                <Typography component="label" style={title16500}>
                  ชื่อโรงพยาบาล
                </Typography>
                <Select
                  id="hospital"
                  value={selectedHospital}
                  size="small"
                  sx={{ width: 1 }}
                  style={body14400}
                  onChange={(e) => handleHospitalChange(e.target.value)}
                  displayEmpty
                >
                  <MenuItem
                    disabled
                    value={""}
                    // style={body14400}
                    sx={{ fontSize: 12 }}
                  >
                    โปรดระบุ
                  </MenuItem>
                  {hospitalList.length > 0 &&
                    hospitalList.map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          value={item.nameThai}
                          // style={body14400}
                          sx={{ fontSize: 12 }}
                        >
                          {item.nameThai}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Stack>
              <Stack spacing={1}>
                <Typography component="label" style={title16500}>
                  HN No.
                </Typography>
                <TextField
                  id="hn"
                  value={searchHn}
                  variant="outlined"
                  size="small"
                  placeholder="โปรดระบุ"
                  type="text"
                  sx={{ width: 1 }}
                  style={body14400}
                  onChange={(e) => handleHnChange(e.target.value)}
                  InputProps={{
                    sx: {
                      fontSize: 14,
                    },
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography component="label" style={title16500}>
                  ชื่อ-นามสกุล
                </Typography>
                <Autocomplete
                  freeSolo
                  disableClearable
                  id="name"
                  size="small"
                  value={searchName}
                  options={patientList.map(
                    (item) => `${item.firstName} ${item.lastName}`
                  )}
                  onInputChange={(_event, value) => handleNameChange(value)}
                  sx={{ width: 1 }}
                  ListboxProps={{
                    sx: { scrollbarWidth: "thin", fontSize: 12 },
                  }}
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      style={{
                        zIndex: 1300,
                        minWidth: "270px",
                        zoom: 100 * (100 / 85) + "%",
                      }}
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="โปรดระบุ"
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          fontSize: 14,
                        },
                      }}
                    />
                  )}
                  style={body14400}
                />
              </Stack>
              <Stack spacing={0}>
                <Typography component="label" style={title16500}>
                  สถานะ
                </Typography>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="register"
                      />
                    }
                    label="ลงทะเบียน"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("register")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#098484",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="pre-ops"
                      />
                    }
                    label="Pre-op"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("pre-ops")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#0095FF",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="post-ops"
                      />
                    }
                    label="Post-op"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("post-ops")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#FF366B",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="end of study"
                      />
                    }
                    label="End of study"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("end of study")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#3C4142",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleIsUnverifiredChange}
                        name="Unverifired Visits"
                      />
                    }
                    label="Unverifired Visits"
                    checked={isUnverifiredVisit}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#FBC235",
                      },
                    }}
                  />
                </FormGroup>
              </Stack>
              <Box display={"flex"}>
                <Button
                  variant="contained"
                  sx={{ width: "fit-content" }}
                  onClick={filterPatient}
                >
                  ค้นหา
                </Button>
                <Button
                  variant="text"
                  sx={{ width: "fit-content" }}
                  onClick={handleClearFilter}
                >
                  ล้างค่า
                </Button>
              </Box>
            </Stack>
          </div>
        </ThemeProvider>
        <div
          className="list-container"
          style={{
            overflowY: "auto",
            height: "100%",
            scrollbarWidth: "thin",
          }}
        >
          {filteredPatient.map((patient, idx) => {
            return (
              <PatientCardListComponent
                patient={patient}
                currentPatient={currentPatient}
                handlePatientClick={handlePatientClick}
              />
            );
          })}
        </div>
      </Stack>
      <AddPatientDialog
        openDialog={openAddPatientDialog}
        handleCloseDialog={handleCloseDialog}
        handleSubmit={submitAddPatient}
        formData={formData}
        setFormData={setFormData}
        isAddHealthInfo={isAddHealthInfo}
        setISAddHealthInfo={setISAddHealthInfo}
        handleChange={handleChange}
        isPending={isPendingAddPaient}
        // isWeightRequired={isWeightRequired}
        // weightRef={weightRef}
        // isHeightRequired={isHeightRequired}
        // heightRef={heightRef}
        // isHospitalRequired={isHospitalRequired}
        // hospitalRef={hospitalRef}
        // isHnRequired={isHnRequired}
        // hnRef={hnRef}
      />
      <ErrorDialog
        openDialog={openUpdateProfileFailureDialog}
        handleCloseDialog={() => setOpenUpdateProfileFailureDialog(false)}
        title="เกิดข้อผิดพลาด"
        content="เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
      />
      <ErrorDialog
        openDialog={openDuplicateUserDialog}
        handleCloseDialog={() => setOpenDuplicateUserDialog(false)}
        title="พบข้อมูลในระบบ"
        content="พบข้อมูลลงทะเบียนในระบบแล้ว กรุณาตรวจสอบความถูกต้องของข้อมูล หรือติดต่อเจ้าหน้าที่ เพื่อเข้ารับการแก้ไข"
      />
    </div>
  );
};
