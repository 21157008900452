import {
    Container,
    Box,
    Typography,
    Stack,
    Button,
    useTheme,
    useMediaQuery,
  } from "@mui/material";
  import "./LoginScreen.css";
  import HeaderMenuBar from "../HeaderMenuBar";
  import Footer from "../Footer";
  import { useLocation, useNavigate } from "react-router-dom";
  import { ErrorDialog } from "../components/dialog/ErrorDialog";
  import { useState } from "react";
  import { FormContainer, TextFieldElement } from "react-hook-form-mui";
  import { ArrowBackIcon, CheckCircleIcon } from "../icon";
  import { userManagementService } from "../service/userManagementService";
  import { useMutation } from "@tanstack/react-query";
  import { LoadingButton } from "@mui/lab";
  
  interface PasswordValidation {
    lengthValid: boolean;
    lowercaseValid: boolean;
    uppercaseValid: boolean;
    numberValid: boolean;
    passwordsMatch: boolean;
  }
  
  function validatePassword(
    newPassword: string,
    confirmPassword: string
  ): PasswordValidation {
    return {
      lengthValid: newPassword.length > 8,
      lowercaseValid: /[a-z]/.test(newPassword),
      uppercaseValid: /[A-Z]/.test(newPassword),
      numberValid: /\d/.test(newPassword),
      passwordsMatch: newPassword === confirmPassword,
    };
  }
  const ForgotPasswordScreen = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [isResetFailed, setIsResetFailed] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [email, setEmail] = useState("");
    const [isResetFailedNoUser,setIsResetFailedNoUser] = useState(false);
    // const validation = validatePassword(newPassword, confirmPassword);
    const location = useLocation();
    // const { status, email } = location.state || {};
  
  
    const handleResetPassword = async () => {
      try {
        resetPasswordAsync();
      } catch (error : any) {
        console.log(error);
      }
    };
    const { mutateAsync: resetPasswordAsync, isPending } = useMutation({
      mutationFn: async () => {
        await userManagementService.postChangePasswordEmail(email);
      },
      onSuccess() {
        navigate("complete");
      },
      onError(error : any){
        if(error.response.data.message === "User not found"){
          setIsResetFailedNoUser(true);
          console.log("User not found");
        }else {
          console.log(error);
          setIsResetFailed(true);
        }
        
      }
    });
    return (
      <div
        style={{
          overflow: "auto",
        }}
      >
        <Box className="reset-passsword-container">
          <HeaderMenuBar />
          <Box
            sx={{
              mt: mediumScreen ? "80px" : "110px",
              height: mediumScreen ? "65vh" : "80vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth="sm">
              <Box
                sx={{
                  bgcolor: "white",
                  padding: "30px 80px",
                  borderRadius: 2,
                }}
              >
                <Stack spacing={2} width={1}>
                  <Typography fontSize={24} fontWeight={500} color={"#0F1011"}>
                    ลืมรหัสผ่าน
                  </Typography>
                  <Typography fontSize={14} color={"#212121"}>
                  กรุณาระบุอีเมลที่ลงทะเบียนไว้ เราจะทำการส่งอีเมลสำหรับรีเซ็ต
                  รหัสผ่านไปยังอีเมลที่คุณทำการลงทะเบียนไว้{" "}
                  </Typography>
                </Stack>
                <FormContainer onSuccess={handleResetPassword}>
                  <Stack spacing={4} sx={{ mt: 4, width: 1 }}>
                    <Stack spacing={1}>
                      <Typography fontSize={12} color={"#646D78"}>
                        อีเมล <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        name="email"
                        required
                        variant="outlined"
                        size="small"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Stack>
                  </Stack>
                  <LoadingButton
                    loading={isPending}
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 4,
                      mb: 2,
                      backgroundColor: "#FF6699",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#FF6699",
                      },
                    }}
                    onClick={() => handleResetPassword}
                  >
                    ส่ง
                  </LoadingButton>
                  {/* <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 4,
                      mb: 2,
                      backgroundColor: "#FF6699",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#FF6699",
                      },
                    }}
                    disabled={
                      !validation.lengthValid ||
                      !validation.lowercaseValid ||
                      !validation.uppercaseValid ||
                      !validation.numberValid ||
                      !validation.passwordsMatch
                    }
                  >
                    รีเซ็ตรหัสผ่าน
                  </Button> */}
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                      alignItems: "center",
                    }}
                    onClick={() => navigate("/")}
                  >
                    <ArrowBackIcon color="primary" />
                    <Typography fontSize={14} fontWeight={700} color={"primary"}>
                      กลับไปยังหน้าเข้าสู่ระบบ
                    </Typography>
                  </Box>
                </FormContainer>
              </Box>
            </Container>
          </Box>
        </Box>
        <div className="reset-passsword-container">
          <Box
            sx={{
              mt: "128px",
              height: "50%",
              maxHeight: "640px",
              overflow: "auto",
            }}
          ></Box>
        </div>
        <Footer />
        <ErrorDialog
          title="ไม่พบผู้ใช้งานในระบบ"
          content="กรุณาตรวจสอบความถูกต้องอีกครั้ง"
          openDialog={isResetFailedNoUser}
          handleCloseDialog={() => {
            setIsResetFailedNoUser(false);
          }}
        />
         <ErrorDialog
          title="เกิดข้อผิดพลาด"
          content="ไม่สามารถเปลี่ยนรหัสผ่านได้ กรุณาลองใหม่อีกครั้งในภายหลัง"
          openDialog={isResetFailed}
          handleCloseDialog={() => {
            setIsResetFailed(false);
          }}
        />
      </div>
    );
  };
  
  export default ForgotPasswordScreen;
  
  const ValidationFeedback = ({
    valid,
    text,
  }: {
    valid: boolean;
    text: string;
  }) => (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CheckCircleIcon sx={{ fontSize: 14, color: valid ? "#26B465" : "gray" }} />
      <Typography fontSize={12} color={valid ? "#26B465" : "gray"}>
        {text}
      </Typography>
    </Stack>
  );
  