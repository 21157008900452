import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getContraception = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/v1/datalist/contraception`);
    return response.data;
  } catch (error) {
    console.error("Error fetching list:", error);
    throw error;
  }
};
