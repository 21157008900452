import { Grid, Typography } from "@mui/material";

export const UltrasoundRow = ({
  title,
  label,
  detail,
}: {
  title: string;
  label?: string;
  detail?: string;
}) => (
  <Grid container direction="row" sx={{ pb: 1 }}>
    <Grid item xs={6}>
      <Typography>{title}</Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>{label || "-"}</Typography>
    </Grid>
    {/* <Grid item xs={4}>
          <Typography>{detail || "-"}</Typography>
        </Grid> */}
  </Grid>
);

export const InvestigationRow = ({
  title,
  value,
  detail,
}: {
  title: string;
  value?: boolean | string;
  detail?: string;
}) => (
  <Grid container direction="row">
    <Grid item xs={6}>
      <Typography style={{ paddingLeft: "10px", paddingBottom: "5px" }}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={1}>
      <Typography>
        {value === "yes" ? "Yes" : value === "no" ? "No" : "N/a"}
      </Typography>
    </Grid>
    {/* <Grid item xs={4}>
        <Typography>{detail || "-"}</Typography>
      </Grid> */}
  </Grid>
);
