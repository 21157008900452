import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useEffectOnce } from "react-use";

type Props = {
  permissionEditAble: boolean;
  handleCancelClick: () => void;
  handleRejectClicked: () => void;
  handleSubmitData: () => void;
};

export const SubmitApproveButtomBar = ({
  permissionEditAble,
  handleCancelClick,
  handleRejectClicked,
  handleSubmitData,
}: Props) => {
  return (
    <Box
      bgcolor={"white"}
      sx={{
        // width: "calc(100% - 30px)",
        height: "35px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "sticky",
        bottom: 0,
        borderTop: "2px solid #D9D9D9",
        p: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "#7A8688",
            width: "90px",
            height: "35px",
            marginRight: "10px",
          }}
          onClick={handleCancelClick}
        >
          {permissionEditAble ? "ยกเลิก" : "ย้อนกลับ"}
        </Button>
        {permissionEditAble && (
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#D80027",
                width: "90px",
                height: "35px",
                marginRight: "10px",
              }}
              onClick={handleRejectClicked}
            >
              ไม่อนุมัติ
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#DF5C8E",
                width: "90px",
                height: "35px",
              }}
              onClick={handleSubmitData}
            >
              อนุมัติ
            </Button>
          </div>
        )}
      </div>
    </Box>
  );
};
