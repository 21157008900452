import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
  Button,
  styled,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

interface HistoryDialogProps {
  open: boolean;
  user: any;
  handleClose: () => void;
}

export const HistoryDialog: React.FC<HistoryDialogProps> = ({
  open,
  handleClose,
  user,
}) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: { width: "640px", height: "622px", margin: "auto" },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          style={{ backgroundColor: "#F7F8FC" }}
        >
          รายละเอียด
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography gutterBottom style={{ color: "#646D78" }}>
                วันที่และเวลา
              </Typography>
              <Typography gutterBottom style={{ color: "#646D78" }}>
                Username
              </Typography>
              <Typography gutterBottom style={{ color: "#646D78" }}>
                ชื่อ-นามสกุล
              </Typography>
              <Typography gutterBottom style={{ color: "#646D78" }}>
                หน่วยงาน
              </Typography>
              <Typography gutterBottom style={{ color: "#646D78" }}>
                Menu
              </Typography>
              {/* <Typography gutterBottom style={{ color: "#646D78" }}>
                Sub Menu
              </Typography> */}
              <Typography gutterBottom style={{ color: "#646D78" }}>
                Activity
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>
                {dayjs(user?.dateTime?.toString()).format(
                  "DD/MM/BBBB HH:mm น."
                )}
              </Typography>
              <Typography gutterBottom>{user?.email}</Typography>
              <Typography gutterBottom>{user?.name}</Typography>
              <Typography gutterBottom>{user?.hospitalName}</Typography>
              <Typography gutterBottom>{user?.menu}</Typography>
              <Typography gutterBottom>{user?.activity}</Typography>
            </Grid>
          </Grid>
          <p style={{ color: "#646D78" }}>Detail</p>
          <div style={{ backgroundColor: "#F7F8FC", padding: "20px" }}>
            <Typography>สิทธิ์การใช้งาน : {user?.permissionName}</Typography>
            <br />
            <Typography>
              ผู้อนุมัติแอคเคาท์ : {user?.approveBy ? user?.approveBy : "-"}
            </Typography>
            <br />
            <Typography>
              วันและเวลาที่อนุมัติ :{" "}
              {user?.approveDateTime
                ? dayjs(user?.approveDateTime?.toString()).format(
                    "DD/MM/BBBB HH:mm น."
                  )
                : "-"}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#7A8688",
              float: "right",
              width: "140px",
              height: "40px",
              fontSize: "14px",
              marginBottom: "20px",
            }}
            onClick={handleClose}
          >
            ปิด
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
