import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { userManagementService } from "../../service/userManagementService";
import { title18700 } from "../../style";
import { AllCollectData, PatientUser } from "../../type";
import { calculateBmi } from "../../utils/calculateBmi";

interface Column {
  id: "date" | "weight" | "bmi" | "meal" | "exercise" | "water";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "date", label: "Date", minWidth: 16 },
  { id: "weight", label: "น้ำหนัก (Weight)", minWidth: 16 },
  {
    id: "bmi",
    label: "BMI",
    minWidth: 16,
    align: "right",
  },
  {
    id: "meal",
    label: "อาหาร",
    minWidth: 16,
    align: "right",
  },
  {
    id: "exercise",
    label: "การออกกำลังกาย",
    minWidth: 16,
    align: "right",
  },

  {
    id: "water",
    label: "ปริมาณการดื่มน้ำ",
    minWidth: 16,
    align: "right",
  },
];

type Props = {
  patientUser: PatientUser;
  handleHistoryClick: (selectedDate: string) => void;
};

export const HistoryCard = ({ patientUser, handleHistoryClick }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allCollectData, setAllCollectData] = useState<
    AllCollectData[] | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allListData = await userManagementService.getAllCollectData(
          patientUser.id
        );
        if (allListData) {
          setAllCollectData(allListData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [patientUser.id]);
  const rows = useMemo(() => {
    const sortedData = allCollectData?.sort((a, b) => {
      const dateA = dayjs(a.date);
      const dateB = dayjs(b.date);
      return dateB.diff(dateA);
    });
    const transfomrData = sortedData?.map((item) => ({
      date: dayjs(item.date).format(`DD/MM/BBBB`),
      weight: item.weight,
      bmi: calculateBmi(item.weight, patientUser.height),
      meal: item.totalCalories,
      exercise: item.totalExercise,
      water: item.totalWaterIntake,
    }));
    return transfomrData;
  }, [allCollectData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper
      elevation={4}
      sx={{ p: 2, borderRadius: 2, height: "100%", width: "100%" }}
    >
      <Typography style={title18700} color={"#141414"}>
        ประวัติการกรอกข้อมูล
      </Typography>
      {rows && rows.length > 0 ? (
        <Paper
          sx={{ mt: 2, width: "100%", height: "100%", overflow: "hidden" }}
        >
          <TableContainer sx={{ height: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      color: "white",
                      backgroundColor: "secondary.main",
                      fontSize: 12,
                    },
                  }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.id === "date" ? "left" : "center",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={idx}
                        onClick={() => handleHistoryClick(row.date)}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{
                                color:
                                  column.id === "bmi"
                                    ? "#FF0101"
                                    : column.id === "date"
                                    ? "#2B6AF1"
                                    : "",

                                textDecoration:
                                  column.id === "date" ? "underline" : "",
                                fontSize: 12,
                                textAlign:
                                  column.id === "date" ? "left" : "center",
                              }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value?.toLocaleString()}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: 12,
            color: "#9AA1AE",
            py: 2,
          }}
        >
          ไม่พบข้อมูล
        </Box>
      )}
    </Paper>
  );
};
