import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dropzone from "../../shared/DropZone";

const RegisterDocument = (
  { prevStep, nextStep, registerForm, handleRegisterChange }:
  { prevStep: any, nextStep: any, registerForm: any, handleRegisterChange: any }
) => {
  const navigate = useNavigate();

  return (
    <Box style={{ padding:"60px", height:"250px", width: "100%"}}>
      <Dropzone registerForm={registerForm} handleRegisterChange={handleRegisterChange} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "50px"
        }}
      >
        <Button
          variant="contained"
          style={{
            border: "1px solid #DF5C8E",
            backgroundColor: "transparent",
            color: "#DF5C8E",
            width: "300px",
            height: "35px",
            maxWidth: "350px", // Add your desired max width
            minWidth: "200px", // Add your desired min width
            marginBottom: "10px"
          }}
          onClick={prevStep}
        >
          ก่อนหน้า
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#DF5C8E",
            width: "300px",
            height: "35px",
            maxWidth: "350px", // Add your desired max width
            minWidth: "200px", // Add your desired min width
          }}
          onClick={nextStep} // TODO : call register API and show success modal if success
        >
          ถัดไป
        </Button>
        <Button
          style={{ paddingTop: "20px", color: "#DF5C8E" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/")}
        >
          กลับไปยังหน้าเข้าสู่ระบบ
        </Button>
      </div>
    </Box>
  );
};

export default RegisterDocument;
