import {
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Checkbox,
  Paper,
  Typography,
  Switch,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import "./GrantUserPermission.css";
import { Layout } from "../../Layout";
import { CreateUserPermission } from "../../type";
import { set } from "date-fns";
import { userManagementService } from "../../service/userManagementService";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { WarningDialog } from "../../components/dialog/WarningDialog";

const AddUserPermission = () => {
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState(true);
  const [createUserPermission, setCreateUserPermission] =
    useState<CreateUserPermission>({
      name: "-",
      nameThai: "",
      createBy: localStorage.getItem("userId") ?? "",
      permissionActive: true,
      dashboardPermission: {
        viewAble: false,
      },
      patientDataPermission: {
        viewAble: false,
        editAble: false,
      },
      userManagePermission: {
        viewAble: false,
        editAble: false,
        addAble: false,
        deleteAble: false,
      },
      userApprovePermission: {
        viewAble: false,
        editAble: false,
      },
      userPermissionPermission: {
        viewAble: false,
        editAble: false,
        addAble: false,
        deleteAble: false,
      },
      userLogPermission: {
        viewAble: false,
        exportAble: false,
      },
      masterDataPermission: {
        viewAble: false,
        editAble: false,
        addAble: false,
        deleteAble: false,
      },
    });

  const [isShowChecked, setIsShowChecked] = useState(false);
  const [isAddChecked, setIsAddChecked] = useState(false);
  const [isEditChecked, setIsEditChecked] = useState(false);
  const [isDeleteChecked, setIsDeleteChecked] = useState(false);
  const [isExportChecked, setIsExportChecked] = useState(false);
  const commonCellStyle = { padding: "4px 8px", fontWeight: "normal" };
  const commonTypographyStyle = { color: "gray", paddingLeft: "6px" };

  const handleMainCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsShowChecked(checked);
    if (!checked) {
      setCreateUserPermission({
        ...createUserPermission,
        dashboardPermission: { viewAble: checked },
        patientDataPermission: {
          viewAble: createUserPermission.patientDataPermission.editAble
            ? true
            : !createUserPermission.patientDataPermission.viewAble,
          editAble: createUserPermission.patientDataPermission.editAble,
        },
        userManagePermission: {
          viewAble:
            createUserPermission.userManagePermission.addAble ||
            createUserPermission.userManagePermission.editAble
              ? true
              : !createUserPermission.userManagePermission.viewAble,
          addAble: createUserPermission.userManagePermission.addAble,
          editAble: createUserPermission.userManagePermission.editAble,
          deleteAble: createUserPermission.userManagePermission.deleteAble,
        },
        userApprovePermission: {
          viewAble: createUserPermission.userApprovePermission.editAble
            ? true
            : !createUserPermission.userApprovePermission.viewAble,
          editAble: createUserPermission.userApprovePermission.editAble,
        },
        userPermissionPermission: {
          viewAble:
            createUserPermission.userPermissionPermission.addAble ||
            createUserPermission.userPermissionPermission.editAble ||
            createUserPermission.userPermissionPermission.deleteAble
              ? true
              : !createUserPermission.userPermissionPermission.viewAble,
          editAble: createUserPermission.userPermissionPermission.editAble,
          addAble: createUserPermission.userPermissionPermission.addAble,
          deleteAble: createUserPermission.userPermissionPermission.deleteAble,
        },
        userLogPermission: {
          viewAble: createUserPermission.userLogPermission.exportAble
            ? true
            : !createUserPermission.userLogPermission.viewAble,
          exportAble: createUserPermission.userLogPermission.exportAble,
        },
        masterDataPermission: {
          viewAble:
            createUserPermission.masterDataPermission.addAble ||
            createUserPermission.masterDataPermission.editAble ||
            createUserPermission.masterDataPermission.deleteAble
              ? true
              : !createUserPermission.masterDataPermission.viewAble,
          editAble: createUserPermission.masterDataPermission.editAble,
          addAble: createUserPermission.masterDataPermission.addAble,
          deleteAble: createUserPermission.masterDataPermission.deleteAble,
        },
      });
    } else {
      setCreateUserPermission({
        ...createUserPermission,
        dashboardPermission: { viewAble: checked },
        patientDataPermission: {
          viewAble: checked,
          editAble: createUserPermission.patientDataPermission.editAble,
        },
        userManagePermission: {
          viewAble: checked,
          editAble: createUserPermission.userManagePermission.editAble,
          addAble: createUserPermission.userManagePermission.addAble,
          deleteAble: createUserPermission.userManagePermission.deleteAble,
        },
        userApprovePermission: {
          viewAble: checked,
          editAble: createUserPermission.userApprovePermission.editAble,
        },
        userPermissionPermission: {
          viewAble: checked,
          editAble: createUserPermission.userPermissionPermission.editAble,
          addAble: createUserPermission.userPermissionPermission.addAble,
          deleteAble: createUserPermission.userPermissionPermission.deleteAble,
        },
        userLogPermission: {
          viewAble: checked,
          exportAble: createUserPermission.userLogPermission.exportAble,
        },
        masterDataPermission: {
          viewAble: checked,
          editAble: createUserPermission.masterDataPermission.editAble,
          addAble: createUserPermission.masterDataPermission.addAble,
          deleteAble: createUserPermission.masterDataPermission.deleteAble,
        },
      });
    }
  };

  const updateAddPermissions = (checked: boolean) => {
    const updatePermission = (permission: any) => ({
      viewAble: checked,
      addAble: checked,
      editAble: permission.editAble,
      deleteAble: permission.deleteAble,
    });

    return {
      dashboardPermission: {
        viewAble: createUserPermission.dashboardPermission.viewAble,
      },
      patientDataPermission: {
        viewAble: createUserPermission.patientDataPermission.viewAble,
        editAble: createUserPermission.patientDataPermission.editAble,
      },
      userManagePermission: updatePermission(
        createUserPermission.userManagePermission
      ),
      userApprovePermission: {
        viewAble: createUserPermission.userApprovePermission.viewAble,
        editAble: createUserPermission.userApprovePermission.editAble,
      },
      userPermissionPermission: updatePermission(
        createUserPermission.userPermissionPermission
      ),
      userLogPermission: {
        viewAble: createUserPermission.userLogPermission.viewAble,
        exportAble: createUserPermission.userLogPermission.exportAble,
      },
      masterDataPermission: updatePermission(
        createUserPermission.masterDataPermission
      ),
    };
  };

  const handleMainAddCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsAddChecked(checked);

    if (!checked) {
      setCreateUserPermission({
        ...createUserPermission,
        dashboardPermission: {
          viewAble: createUserPermission.dashboardPermission.viewAble,
        },
        patientDataPermission: {
          viewAble: createUserPermission.patientDataPermission.viewAble,
          editAble: createUserPermission.patientDataPermission.editAble,
        },
        userManagePermission: {
          viewAble:
            createUserPermission.userManagePermission.addAble ||
            createUserPermission.userManagePermission.editAble ||
            createUserPermission.userManagePermission.deleteAble
              ? true
              : !createUserPermission.userManagePermission.viewAble,
          addAble:
            createUserPermission.userManagePermission.editAble ||
            createUserPermission.userManagePermission.deleteAble
              ? true
              : !createUserPermission.userManagePermission.addAble,
          editAble: createUserPermission.userManagePermission.editAble,
          deleteAble: createUserPermission.userManagePermission.deleteAble,
        },
        userApprovePermission: {
          viewAble: createUserPermission.userApprovePermission.viewAble,
          editAble: createUserPermission.userApprovePermission.editAble,
        },
        userPermissionPermission: {
          viewAble:
            createUserPermission.userPermissionPermission.addAble ||
            createUserPermission.userPermissionPermission.editAble ||
            createUserPermission.userPermissionPermission.deleteAble
              ? true
              : !createUserPermission.userPermissionPermission.viewAble,
          addAble:
            createUserPermission.userPermissionPermission.editAble ||
            createUserPermission.userPermissionPermission.deleteAble
              ? true
              : !createUserPermission.userPermissionPermission.addAble,
          editAble: createUserPermission.userPermissionPermission.editAble,
          deleteAble: createUserPermission.userPermissionPermission.deleteAble,
        },
        userLogPermission: {
          viewAble: createUserPermission.userLogPermission.viewAble,
          exportAble: createUserPermission.userLogPermission.exportAble,
        },
        masterDataPermission: {
          viewAble:
            createUserPermission.masterDataPermission.addAble ||
            createUserPermission.masterDataPermission.editAble ||
            createUserPermission.masterDataPermission.deleteAble
              ? true
              : !createUserPermission.masterDataPermission.viewAble,
          addAble:
            createUserPermission.masterDataPermission.editAble ||
            createUserPermission.masterDataPermission.deleteAble
              ? true
              : !createUserPermission.masterDataPermission.addAble,
          editAble: createUserPermission.masterDataPermission.editAble,
          deleteAble: createUserPermission.masterDataPermission.deleteAble,
        },
      });
    } else {
      setCreateUserPermission({
        ...createUserPermission,
        ...updateAddPermissions(checked),
      });
    }
  };

  const updateEditPermissions = (checked: boolean) => {
    const updatePermission = (permission: any) => ({
      viewAble: checked,
      addAble: checked,
      editAble: checked,
      deleteAble: permission.deleteAble,
    });

    return {
      dashboardPermission: {
        viewAble: createUserPermission.dashboardPermission.viewAble,
      },
      patientDataPermission: {
        viewAble: checked,
        editAble: checked,
      },
      userManagePermission: updatePermission(
        createUserPermission.userManagePermission
      ),
      userApprovePermission: {
        viewAble: checked,
        editAble: checked,
      },
      userPermissionPermission: updatePermission(
        createUserPermission.userPermissionPermission
      ),
      userLogPermission: {
        viewAble: createUserPermission.userLogPermission.viewAble,
        exportAble: createUserPermission.userLogPermission.exportAble,
      },
      masterDataPermission: updatePermission(
        createUserPermission.masterDataPermission
      ),
    };
  };

  const handleMainEditCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsEditChecked(checked);

    if (!checked) {
      setCreateUserPermission({
        ...createUserPermission,
        dashboardPermission: {
          viewAble: createUserPermission.dashboardPermission.viewAble,
        },
        patientDataPermission: {
          viewAble: createUserPermission.patientDataPermission.viewAble,
          editAble: checked,
        },
        userManagePermission: {
          viewAble:
            createUserPermission.userManagePermission.addAble ||
            createUserPermission.userManagePermission.editAble ||
            createUserPermission.userManagePermission.deleteAble
              ? true
              : !createUserPermission.userManagePermission.viewAble,
          addAble:
            createUserPermission.userManagePermission.editAble ||
            createUserPermission.userManagePermission.deleteAble
              ? true
              : !createUserPermission.userManagePermission.addAble,
          editAble: createUserPermission.userManagePermission.deleteAble
            ? true
            : !createUserPermission.userManagePermission.editAble,
          deleteAble: createUserPermission.userManagePermission.deleteAble,
        },
        userApprovePermission: {
          viewAble: createUserPermission.userApprovePermission.viewAble,
          editAble: checked,
        },
        userPermissionPermission: {
          viewAble:
            createUserPermission.userPermissionPermission.addAble ||
            createUserPermission.userPermissionPermission.editAble ||
            createUserPermission.userPermissionPermission.deleteAble
              ? true
              : !createUserPermission.userPermissionPermission.viewAble,
          addAble:
            createUserPermission.userPermissionPermission.editAble ||
            createUserPermission.userPermissionPermission.deleteAble
              ? true
              : !createUserPermission.userPermissionPermission.addAble,
          editAble: createUserPermission.userPermissionPermission.deleteAble
            ? true
            : !createUserPermission.userPermissionPermission.editAble,
          deleteAble: createUserPermission.userPermissionPermission.deleteAble,
        },
        userLogPermission: {
          viewAble: createUserPermission.userLogPermission.viewAble,
          exportAble: createUserPermission.userLogPermission.exportAble,
        },
        masterDataPermission: {
          viewAble:
            createUserPermission.masterDataPermission.addAble ||
            createUserPermission.masterDataPermission.editAble ||
            createUserPermission.masterDataPermission.deleteAble
              ? true
              : !createUserPermission.masterDataPermission.viewAble,
          addAble:
            createUserPermission.masterDataPermission.editAble ||
            createUserPermission.masterDataPermission.deleteAble
              ? true
              : !createUserPermission.masterDataPermission.addAble,
          editAble: createUserPermission.masterDataPermission.deleteAble
            ? true
            : !createUserPermission.masterDataPermission.editAble,
          deleteAble: createUserPermission.masterDataPermission.deleteAble,
        },
      });
    } else {
      setCreateUserPermission({
        ...createUserPermission,
        ...updateEditPermissions(checked),
      });
      setIsAddChecked(true);
    }
  };

  const updateDeletePermissions = (checked: boolean) => {
    const updatePermission = () => ({
      viewAble: checked,
      addAble: checked,
      editAble: checked,
      deleteAble: checked,
    });

    return {
      dashboardPermission: {
        viewAble: createUserPermission.dashboardPermission.viewAble,
      },
      patientDataPermission: {
        viewAble: createUserPermission.patientDataPermission.viewAble,
        editAble: createUserPermission.patientDataPermission.editAble,
      },
      userManagePermission: updatePermission(),
      userApprovePermission: {
        viewAble: createUserPermission.userApprovePermission.viewAble,
        editAble: createUserPermission.userApprovePermission.editAble,
      },
      userPermissionPermission: updatePermission(),
      userLogPermission: {
        viewAble: createUserPermission.userLogPermission.viewAble,
        exportAble: createUserPermission.userLogPermission.exportAble,
      },
      masterDataPermission: updatePermission(),
    };
  };

  const handleMainDeleteCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsDeleteChecked(checked);

    if (!checked) {
      setCreateUserPermission({
        ...createUserPermission,
        dashboardPermission: {
          viewAble: createUserPermission.dashboardPermission.viewAble,
        },
        patientDataPermission: {
          viewAble: createUserPermission.patientDataPermission.viewAble,
          editAble: createUserPermission.patientDataPermission.editAble,
        },
        userManagePermission: {
          viewAble:
            createUserPermission.userManagePermission.addAble ||
            createUserPermission.userManagePermission.editAble ||
            createUserPermission.userManagePermission.deleteAble
              ? true
              : !createUserPermission.userManagePermission.viewAble,
          addAble:
            createUserPermission.userManagePermission.editAble ||
            createUserPermission.userManagePermission.deleteAble
              ? true
              : !createUserPermission.userManagePermission.addAble,
          editAble: createUserPermission.userManagePermission.deleteAble
            ? true
            : !createUserPermission.userManagePermission.editAble,
          deleteAble: checked,
        },
        userApprovePermission: {
          viewAble: createUserPermission.userApprovePermission.viewAble,
          editAble: createUserPermission.userApprovePermission.editAble,
        },
        userPermissionPermission: {
          viewAble:
            createUserPermission.userPermissionPermission.addAble ||
            createUserPermission.userPermissionPermission.editAble ||
            createUserPermission.userPermissionPermission.deleteAble
              ? true
              : !createUserPermission.userPermissionPermission.viewAble,
          addAble:
            createUserPermission.userPermissionPermission.editAble ||
            createUserPermission.userPermissionPermission.deleteAble
              ? true
              : !createUserPermission.userPermissionPermission.addAble,
          editAble: createUserPermission.userPermissionPermission.deleteAble
            ? true
            : !createUserPermission.userPermissionPermission.editAble,
          deleteAble: checked,
        },
        userLogPermission: {
          viewAble: createUserPermission.userLogPermission.viewAble,
          exportAble: createUserPermission.userLogPermission.exportAble,
        },
        masterDataPermission: {
          viewAble:
            createUserPermission.masterDataPermission.addAble ||
            createUserPermission.masterDataPermission.editAble ||
            createUserPermission.masterDataPermission.deleteAble
              ? true
              : !createUserPermission.masterDataPermission.viewAble,
          addAble:
            createUserPermission.masterDataPermission.editAble ||
            createUserPermission.masterDataPermission.deleteAble
              ? true
              : !createUserPermission.masterDataPermission.addAble,
          editAble: createUserPermission.masterDataPermission.deleteAble
            ? true
            : !createUserPermission.masterDataPermission.editAble,
          deleteAble: checked,
        },
      });
    } else {
      setCreateUserPermission({
        ...createUserPermission,
        ...updateDeletePermissions(checked),
      });
      setIsAddChecked(true);
    }
  };

  const handleMainExportCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setIsExportChecked(checked);
    if (!checked) {
      setCreateUserPermission({
        ...createUserPermission,
        userLogPermission: {
          viewAble: createUserPermission.userLogPermission.viewAble,
          exportAble: checked,
        },
      });
    } else {
      setCreateUserPermission({
        ...createUserPermission,
        userLogPermission: {
          viewAble: checked,
          exportAble: checked,
        },
      });
    }
  };

  const menues = [
    {
      label: "แสดง",
      checked: isShowChecked,
      onChange: handleMainCheckboxChange,
    },
    {
      label: "เพิ่ม",
      checked: isAddChecked,
      onChange: handleMainAddCheckboxChange,
    },
    {
      label: "แก้ไข",
      checked: isEditChecked,
      onChange: handleMainEditCheckboxChange,
    },
    {
      label: "ลบ",
      checked: isDeleteChecked,
      onChange: handleMainDeleteCheckboxChange,
      style: { paddingLeft: "8px", marginRight: "60px" },
    },
    {
      label: "Export",
      checked: isExportChecked,
      onChange: handleMainExportCheckboxChange,
    },
  ];
  interface Permission {
    viewAble: boolean;
    editAble: boolean;
    addAble: boolean;
    deleteAble: boolean;
    exportAble: boolean;
  }
  type PermissionType = keyof CreateUserPermission;
  const handleViewCheckboxChange =
    (permissionType: PermissionType, permissionKey: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedPermissions = {
        ...createUserPermission,
        [permissionType]: {
          ...(createUserPermission[permissionType] as Permission),
          [permissionKey]: event.target.checked,
        },
      };
      if (
        permissionType === "patientDataPermission" &&
        permissionKey === "editAble"
      ) {
        updatedPermissions.patientDataPermission = {
          ...updatedPermissions.patientDataPermission,
          viewAble: updatedPermissions.patientDataPermission.viewAble
            ? true
            : !updatedPermissions.patientDataPermission.viewAble,
        };
      } else if (
        permissionType === "userManagePermission" &&
        permissionKey === "addAble"
      ) {
        updatedPermissions.userManagePermission = {
          ...updatedPermissions.userManagePermission,
          viewAble: updatedPermissions.userManagePermission.viewAble
            ? true
            : !updatedPermissions.userManagePermission.viewAble,
        };
      } else if (
        permissionType === "userManagePermission" &&
        permissionKey === "editAble"
      ) {
        updatedPermissions.userManagePermission = {
          ...updatedPermissions.userManagePermission,
          viewAble: updatedPermissions.userManagePermission.viewAble
            ? true
            : !updatedPermissions.userManagePermission.viewAble,
          addAble: updatedPermissions.userManagePermission.addAble
            ? true
            : !updatedPermissions.userManagePermission.addAble,
        };
      } else if (
        permissionType === "userManagePermission" &&
        permissionKey === "deleteAble"
      ) {
        updatedPermissions.userManagePermission = {
          ...updatedPermissions.userManagePermission,
          viewAble: updatedPermissions.userManagePermission.viewAble
            ? true
            : !updatedPermissions.userManagePermission.viewAble,
          addAble: updatedPermissions.userManagePermission.addAble
            ? true
            : !updatedPermissions.userManagePermission.addAble,
          editAble: updatedPermissions.userManagePermission.editAble
            ? true
            : !updatedPermissions.userManagePermission.editAble,
        };
      } else if (
        permissionType === "userApprovePermission" &&
        permissionKey === "editAble"
      ) {
        updatedPermissions.userApprovePermission = {
          ...updatedPermissions.userApprovePermission,
          viewAble: updatedPermissions.userApprovePermission.viewAble
            ? true
            : !updatedPermissions.userApprovePermission.viewAble,
        };
      } else if (
        permissionType === "userPermissionPermission" &&
        permissionKey === "addAble"
      ) {
        updatedPermissions.userPermissionPermission = {
          ...updatedPermissions.userPermissionPermission,
          viewAble: updatedPermissions.userPermissionPermission.viewAble
            ? true
            : !updatedPermissions.userPermissionPermission.viewAble,
        };
      } else if (
        permissionType === "userPermissionPermission" &&
        permissionKey === "editAble"
      ) {
        updatedPermissions.userPermissionPermission = {
          ...updatedPermissions.userPermissionPermission,
          viewAble: updatedPermissions.userPermissionPermission.viewAble
            ? true
            : !updatedPermissions.userPermissionPermission.viewAble,
          addAble: updatedPermissions.userPermissionPermission.addAble
            ? true
            : !updatedPermissions.userPermissionPermission.addAble,
        };
      } else if (
        permissionType === "userPermissionPermission" &&
        permissionKey === "deleteAble"
      ) {
        updatedPermissions.userPermissionPermission = {
          ...updatedPermissions.userPermissionPermission,
          viewAble: updatedPermissions.userPermissionPermission.viewAble
            ? true
            : !updatedPermissions.userPermissionPermission.viewAble,
          editAble: updatedPermissions.userPermissionPermission.editAble
            ? true
            : !updatedPermissions.userPermissionPermission.editAble,
          addAble: updatedPermissions.userPermissionPermission.addAble
            ? true
            : !updatedPermissions.userPermissionPermission.addAble,
        };
      } else if (
        permissionType === "userLogPermission" &&
        permissionKey === "exportAble"
      ) {
        updatedPermissions.userLogPermission = {
          ...updatedPermissions.userLogPermission,
          viewAble: updatedPermissions.userLogPermission.viewAble
            ? true
            : !updatedPermissions.userLogPermission.viewAble,
        };
      } else if (
        permissionType === "masterDataPermission" &&
        permissionKey === "addAble"
      ) {
        updatedPermissions.masterDataPermission = {
          ...updatedPermissions.masterDataPermission,
          viewAble: updatedPermissions.masterDataPermission.viewAble
            ? true
            : !updatedPermissions.masterDataPermission.viewAble,
        };
      } else if (
        permissionType === "masterDataPermission" &&
        permissionKey === "editAble"
      ) {
        updatedPermissions.masterDataPermission = {
          ...updatedPermissions.masterDataPermission,
          viewAble: updatedPermissions.masterDataPermission.viewAble
            ? true
            : !updatedPermissions.masterDataPermission.viewAble,
          addAble: updatedPermissions.masterDataPermission.addAble
            ? true
            : !updatedPermissions.masterDataPermission.addAble,
        };
      } else if (
        permissionType === "masterDataPermission" &&
        permissionKey === "deleteAble"
      ) {
        updatedPermissions.masterDataPermission = {
          ...updatedPermissions.masterDataPermission,
          viewAble: updatedPermissions.masterDataPermission.viewAble
            ? true
            : !updatedPermissions.masterDataPermission.viewAble,
          editAble: updatedPermissions.masterDataPermission.editAble
            ? true
            : !updatedPermissions.masterDataPermission.editAble,
          addAble: updatedPermissions.masterDataPermission.addAble
            ? true
            : !updatedPermissions.masterDataPermission.addAble,
        };
      }
      setCreateUserPermission(updatedPermissions);
      setIsShowChecked(areAllPermissionsChecked(updatedPermissions) || false);
      setIsAddChecked(areAllAddPermissionsChecked(updatedPermissions) || false);
      setIsEditChecked(
        areAllEditPermissionsChecked(updatedPermissions) || false
      );
      setIsDeleteChecked(
        areAllDeletePermissionsChecked(updatedPermissions) || false
      );
      setIsExportChecked(
        updatedPermissions.userLogPermission.exportAble || false
      );
    };
  const areAllPermissionsChecked = (permissions: CreateUserPermission) => {
    if (
      permissions.dashboardPermission.viewAble &&
      permissions.patientDataPermission.viewAble &&
      permissions.userManagePermission.viewAble &&
      permissions.userApprovePermission.viewAble &&
      permissions.userPermissionPermission.viewAble &&
      permissions.userLogPermission.viewAble &&
      permissions.masterDataPermission.viewAble
    ) {
      return true;
    }
  };
  const areAllAddPermissionsChecked = (permissions: CreateUserPermission) => {
    if (
      permissions.userManagePermission.addAble &&
      permissions.userPermissionPermission.addAble &&
      permissions.masterDataPermission.addAble
    ) {
      return true;
    }
  };
  const areAllEditPermissionsChecked = (permissions: CreateUserPermission) => {
    if (
      permissions.patientDataPermission.editAble &&
      permissions.userManagePermission.editAble &&
      permissions.userApprovePermission.editAble &&
      permissions.userPermissionPermission.editAble &&
      permissions.masterDataPermission.editAble
    ) {
      return true;
    }
  };
  const areAllDeletePermissionsChecked = (
    permissions: CreateUserPermission
  ) => {
    if (
      permissions.userManagePermission.deleteAble &&
      permissions.userPermissionPermission.deleteAble &&
      permissions.masterDataPermission.deleteAble
    ) {
      return true;
    }
  };

  const handleSubmitData = async () => {
    if (createUserPermission.nameThai !== "") {
      await submitDataAsync();
    } else {
      setOpenIncompleteDataDialog(true);
    }
  };

  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await userManagementService.createUserPermission(
        createUserPermission
      );

      return response as CreateUserPermission;
    },
    onSuccess(data) {
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setIsSaved(true);
    },
    onError(error) {
      setOpenIncompleteDataDialog(true);
      console.log(error);
    },
  });

  const handleCloseErrorDialog = () => {
    setOpenIncompleteDataDialog(false);
  };

  return (
    <Layout activeTab="userPermission">
      <div className="user-filtering-container">
        <div>
          <p style={{ paddingTop: "0px" }}>
            จัดการสิทธิ์การใช้งาน / รายการสิทธิ์การใช้งาน / เพิ่มสิทธิ์การใช้งาน
          </p>
          <Box
            sx={{
              p: 3,
              overflowY: "auto",
              scrollbarWidth: "thin",
              backgroundColor: "white",
              height: "calc(100vh - 70px)",
              mt: 2,
            }}
          >
            <p style={{ borderBottom: "1px solid grey" }}>
              <span style={{ borderBottom: "5px solid #DF5C8E" }}>
                เพิ่มสิทธิ์การใช้งาน
              </span>
            </p>
            <div className="grant-user-two-column-container">
              <div className="grant-user-column-filter-field">
                <div className="search-container">
                  <div className="search-text">
                    <p style={{ fontWeight: "bold" }}>
                      ชื่อสิทธิ์การใช้งาน<span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  value={createUserPermission?.nameThai}
                  onChange={(e) => {
                    setCreateUserPermission({
                      ...createUserPermission,
                      nameThai: e.target.value,
                    });
                  }}
                  size="small"
                />
              </div>
              <Box p={1}>
                <p style={{ fontWeight: "bold" }}>
                  เปิดการใช้งาน <span style={{ color: "red" }}>*</span>
                </p>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    checked={createUserPermission.permissionActive}
                    color="success"
                    onChange={(e) => {
                      setCreateUserPermission({
                        ...createUserPermission,
                        permissionActive: e.target.checked,
                      });
                    }}
                  />
                  {/* <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography> */}
                </Stack>
              </Box>
            </div>
            <br />
            <p style={{ marginBottom: "20px", fontWeight: "bold" }}>
              สิทธิ์การใช้งานเมนู
            </p>
            <TableContainer component={Paper}>
              <Table className="pink-table-line">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        paddingLeft: "50px",
                        width: "60%",
                        borderBottom: "none",
                        fontWeight: "normal",
                      }}
                    >
                      <Typography style={{ color: "gray", fontWeight: "bold" }}>
                        เมนู
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={6}
                      style={{
                        width: "40%",
                        borderBottom: "none",
                        textAlign: "center",
                        fontWeight: "normal",
                      }}
                    >
                      <Typography style={{ color: "gray", fontWeight: "bold" }}>
                        ชื่อสิทธิ์การใช้งาน
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    {menues.map((menu, index) => (
                      <TableCell key={index} style={commonCellStyle}>
                        <Checkbox
                          checked={menu.checked}
                          onChange={menu.onChange}
                        />
                        <Typography
                          style={{
                            ...commonTypographyStyle,
                            ...menu.style,
                          }}
                        >
                          {menu.label}
                        </Typography>
                      </TableCell>
                    ))}
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "4px 8px",
                        paddingLeft: "50px",
                        paddingBlock: "15px",
                      }}
                    >
                      <Typography>DashBoard</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.dashboardPermission.viewAble
                        }
                        onChange={handleViewCheckboxChange(
                          "dashboardPermission",
                          "viewAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 5px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "4px 8px",
                        paddingLeft: "50px",
                        paddingBlock: "15px",
                      }}
                    >
                      <Typography>ข้อมูลผู้ป่วย</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.patientDataPermission.viewAble
                        }
                        onChange={handleViewCheckboxChange(
                          "patientDataPermission",
                          "viewAble"
                        )}
                        disabled={
                          createUserPermission.patientDataPermission.editAble
                        }
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.patientDataPermission.editAble
                        }
                        onChange={handleViewCheckboxChange(
                          "patientDataPermission",
                          "editAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 5px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "4px 8px",
                        paddingLeft: "50px",
                        paddingBlock: "15px",
                      }}
                    >
                      <Typography>ผู้ใช้งานและสิทธิ์</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: "4px 8px", paddingLeft: "80px" }}
                    >
                      <Typography>จัดการผู้ใช้งาน</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userManagePermission.viewAble
                        }
                        disabled={
                          createUserPermission.userManagePermission.addAble ||
                          createUserPermission.userManagePermission.editAble ||
                          createUserPermission.userManagePermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userManagePermission",
                          "viewAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userManagePermission.addAble
                        }
                        disabled={
                          createUserPermission.userManagePermission.editAble ||
                          createUserPermission.userManagePermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userManagePermission",
                          "addAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userManagePermission.editAble
                        }
                        disabled={
                          createUserPermission.userManagePermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userManagePermission",
                          "editAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userManagePermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userManagePermission",
                          "deleteAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 5px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: "4px 8px", paddingLeft: "80px" }}
                    >
                      <Typography>อนุมัติผู้ใช้งาน</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userApprovePermission.viewAble
                        }
                        disabled={
                          createUserPermission.userApprovePermission.editAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userApprovePermission",
                          "viewAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userApprovePermission.editAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userApprovePermission",
                          "editAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 5px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: "4px 8px", paddingLeft: "80px" }}
                    >
                      <Typography>จัดการสิทธิ์ผู้ใช้งาน</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userPermissionPermission.viewAble
                        }
                        disabled={
                          createUserPermission.userPermissionPermission
                            .editAble ||
                          createUserPermission.userPermissionPermission
                            .addAble ||
                          createUserPermission.userPermissionPermission
                            .deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userPermissionPermission",
                          "viewAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userPermissionPermission.addAble
                        }
                        disabled={
                          createUserPermission.userPermissionPermission
                            .editAble ||
                          createUserPermission.userPermissionPermission
                            .deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userPermissionPermission",
                          "addAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userPermissionPermission.editAble
                        }
                        disabled={
                          createUserPermission.userPermissionPermission
                            .deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userPermissionPermission",
                          "editAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userPermissionPermission
                            .deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userPermissionPermission",
                          "deleteAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 5px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: "4px 8px", paddingLeft: "80px" }}
                    >
                      <Typography>ประวัติการใช้งานระบบ</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userLogPermission.viewAble
                        }
                        disabled={
                          createUserPermission.userLogPermission.exportAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userLogPermission",
                          "viewAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.userLogPermission.exportAble
                        }
                        onChange={handleViewCheckboxChange(
                          "userLogPermission",
                          "exportAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        padding: "4px 8px",
                        paddingLeft: "50px",
                        paddingBlock: "15px",
                      }}
                    >
                      <Typography>ตั้งค่า</Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ padding: "4px 8px", paddingLeft: "80px" }}
                    >
                      <Typography>ข้อมูลหลัก (Master Data) </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.masterDataPermission.viewAble
                        }
                        disabled={
                          createUserPermission.masterDataPermission.addAble ||
                          createUserPermission.masterDataPermission.editAble ||
                          createUserPermission.masterDataPermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "masterDataPermission",
                          "viewAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.masterDataPermission.addAble
                        }
                        disabled={
                          createUserPermission.masterDataPermission.editAble ||
                          createUserPermission.masterDataPermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "masterDataPermission",
                          "addAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.masterDataPermission.editAble
                        }
                        disabled={
                          createUserPermission.masterDataPermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "masterDataPermission",
                          "editAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 8px" }}>
                      <Checkbox
                        checked={
                          createUserPermission.masterDataPermission.deleteAble
                        }
                        onChange={handleViewCheckboxChange(
                          "masterDataPermission",
                          "deleteAble"
                        )}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "4px 5px" }}></TableCell>
                    <TableCell style={{ padding: "4px 8px" }}></TableCell>
                  </TableRow>
                  {/* Add more TableRow components for more rows */}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "10px",
              }}
            >
              {isSaved === false ? (
                <div>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#7A8688",
                      width: "90px",
                      height: "35px",
                      marginRight: "10px",
                    }}
                    onClick={() => navigate("/userPermission")}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#DF5C8E",
                      width: "90px",
                      height: "35px",
                    }}
                    onClick={() => {
                      handleSubmitData();
                    }}
                  >
                    บันทึก
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#7A8688",
                      width: "90px",
                      height: "35px",
                      marginRight: "10px",
                    }}
                    onClick={() => navigate("/userPermission")}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
              )}
            </div>
          </Box>
        </div>
      </div>
      <WarningDialog
        openDialog={openIncompleteDataDialog}
        title="ไม่สำเร็จ"
        content="กรุณาตรวจสอบความถูกต้องของข้อมูล"
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
    </Layout>
  );
};
export default AddUserPermission;
