import { useMemo } from "react";
import { PatientUser } from "../type";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { PersonIcon } from "../icon";
import { calculateAge } from "../utils/calculateAge";

export const PatientCardListComponent = ({
  patient,
  currentPatient,
  handlePatientClick,
}: {
  patient: PatientUser;
  currentPatient?: PatientUser;
  handlePatientClick: (patient: PatientUser) => void;
}) => {
  const userStatusStyle = useMemo(() => {
    const statusMap: Record<string, { bg: string; color: string }> = {
      register: { bg: "#E3F6EB", color: "#098484" },
      "pre-ops": { bg: "#DEE8F5", color: "#0095FF" },
      "post-ops": { bg: "#F8E1EA", color: "#FF366B" },
      "end of study": { bg: "#E5E5E5", color: "#3C4142" },
    };
    return (
      statusMap[patient.userStatus] || { bg: "transparent", color: "black" }
    );
  }, [patient.userStatus]);

  return (
    <>
      <div
        className="patient-card"
        style={{
          border: patient.id === currentPatient?.id ? "3px solid #098484" : "",
          borderRadius: 8,
          marginBottom: 8,
          cursor: "pointer",
          position: "relative",
          padding: 10,
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => handlePatientClick(patient)}
      >
        {/* User Avatar */}
        <Avatar
          sx={{ width: 48, height: 48 }}
          src={patient.profileImageUrl || undefined}
        >
          {!patient.profileImageUrl && <PersonIcon fontSize="medium" />}
        </Avatar>

        {/* Patient Details */}
        <Box flex={1} ml={2}>
          <Typography
            fontSize={14}
            fontWeight={700}
            color="#212121"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 150,
            }}
          >
            {`${patient.firstName} ${patient.lastName}`}
          </Typography>
          <Typography fontSize={12} color="#646D78">
            {`${patient.sex} | ${calculateAge(
              patient.birthDate,
              true
            )} ปี | BMI ${(
              (patient.weight * 10000) /
              (patient.height * patient.height)
            ).toFixed(2)}`}
          </Typography>
        </Box>

        {/* Status & Event Tag */}
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={0.5}
          position="absolute"
          right={8}
        >
          {patient.userType === "EVENT" && (
            <img width={25} src="./logo125tag.png" alt="Event Logo" />
          )}
          <div
            style={{
              backgroundColor: userStatusStyle.bg,
              padding: "4px 8px",
              minWidth: 70,
              color: userStatusStyle.color,
              fontWeight: 600,
              textAlign: "center",
              fontSize: 12,
              borderRadius: 8,
            }}
          >
            {patient.userType === "EVENT"
              ? `ID ${patient.hn ? patient.hn.replace(/^ID/, "") : "-"}`
              : `HN ${patient.hn ?? "-"}`}
          </div>
        </Stack>
      </div>
    </>

    // <div
    //   key={patient.id}
    //   className="patient-card"
    //   style={{
    //     border: patient.id === currentPatient?.id ? "3px solid #098484" : "",
    //     borderRadius: 8,
    //     marginBottom: 8,
    //     cursor: "pointer",
    //     position: "relative",
    //     padding: 10,
    //     display: "flex",
    //     alignItems: "center",
    //   }}
    //   onClick={() => handlePatientClick(patient)}
    // >
    //   {/* User Avatar */}
    //   <Avatar sx={{ width: 48, height: 48 }} src={imageUrl || undefined}>
    //     {!imageUrl && <PersonIcon fontSize="medium" />}
    //   </Avatar>

    //   {/* Patient Details */}
    //   <Box flex={1} ml={2}>
    //     <Typography
    //       fontSize={14}
    //       fontWeight={700}
    //       color="#212121"
    //       sx={{
    //         whiteSpace: "nowrap",
    //         overflow: "hidden",
    //         textOverflow: "ellipsis",
    //         maxWidth: 150,
    //       }}
    //     >
    //       {`${patient.firstName} ${patient.lastName}`}
    //     </Typography>
    //     <Typography fontSize={12} color="#646D78">
    //       {`${patient.sex} | ${calculateAge(
    //         patient.birthDate,
    //         true
    //       )} ปี | BMI ${(
    //         (patient.weight * 10000) /
    //         (patient.height * patient.height)
    //       ).toFixed(2)}`}
    //     </Typography>
    //   </Box>

    //   {/* Status & Event Tag */}
    //   <Stack
    //     flexDirection="row"
    //     alignItems="center"
    //     gap={0.5}
    //     position="absolute"
    //     right={8}
    //   >
    //     {patient.userType === "EVENT" && (
    //       <img width={25} src="./logo125tag.png" alt="Event Logo" />
    //     )}
    //     <div
    //       style={{
    //         backgroundColor:
    //           patient.userStatus === "register"
    //             ? "#E3F6EB"
    //             : patient.userStatus === "pre-ops"
    //             ? "#DEE8F5"
    //             : patient.userStatus === "post-ops"
    //             ? "#F8E1EA"
    //             : patient.userStatus === "end of study"
    //             ? "#E5E5E5"
    //             : "",
    //         padding: "4px 8px",
    //         minWidth: 70,
    //         color:
    //           patient.userStatus === "register"
    //             ? "#098484"
    //             : patient.userStatus === "pre-ops"
    //             ? "#0095FF"
    //             : patient.userStatus === "post-ops"
    //             ? "#FF366B"
    //             : patient.userStatus === "end of study"
    //             ? "#3C4142"
    //             : "",
    //         fontWeight: 600,
    //         textAlign: "center",
    //         fontSize: 12,
    //         borderRadius: 8,
    //       }}
    //     >
    //       {patient.userType === "EVENT"
    //         ? `ID ${patient.hn ? patient.hn.replace(/^ID/, "") : "-"}`
    //         : `HN ${patient.hn ?? "-"}`}
    //     </div>
    //   </Stack>
    // </div>
  );
};
