import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Pagination,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { useNavigate } from "react-router";
import { DeleteDialog } from "../../components/dialog/DeleteDialog";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { AddIcon, DeleteIcon, EditIcon } from "../../icon";
import { Layout } from "../../Layout";
import { masterDataService } from "../../service/masterDataService";
import { MasterDataFiltering } from "../../shared/MasterDataFiltering";
import { labelForm, spacingForm, title16500 } from "../../style";
import { theme2 } from "../../theme";
import { LoadingButton } from "@mui/lab";
import { store } from "../../redux/store";

export type ContraceptionItem = {
  id: number;
  name: string;
  description: string;
  isActivated: boolean;
};

const tableCellValues = [
  { name: "ลำดับ", width: "10%" },
  { name: "ชื่อฟิลด์ข้อมูล", width: "20%" },
  { name: "Description", width: "50%" },
  { name: "สถานะ", width: "10%" },
  { name: "เครื่องมือ", width: "10%" },
];

type CreateUpdateContraceptionMasterPayload = {
  id?: number;
  name: string;
  description: string;
  isActivated: boolean;
};

export const ContraceptionMaster = () => {
  const navigate = useNavigate();
  const filterRef = useRef<HTMLDivElement>(null);
  const [contraceptionList, setContraceptionList] = useState<
    ContraceptionItem[]
  >([]);
  const [filteredList, setFilteredList] = useState<ContraceptionItem[]>([]);
  const [currentContraceptionItem, setContraceptionItem] =
    useState<ContraceptionItem>();
  const [searchText, setSearchText] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(0);
  const [activeStatus, setActiveStatus] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isFetchData, setIsFetchData] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [messageError, setMessageError] = useState(
    "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
  );

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const masterDataPermissionAddAble =
    userId === "1" ? true : userPermission?.masterDataPermission.addAble;
  const masterDataPermissionEditAble =
    userId === "1" ? true : userPermission?.masterDataPermission.editAble;
  const masterDataPermissionDeleteAble =
    userId === "1" ? true : userPermission?.masterDataPermission.deleteAble;

  const queryFunction = async () => {
    const response = await masterDataService.getContraceptionMasterData();
    return response;
  };
  const {
    data: contraceptionData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["contraceptionMasterData"],
    queryFn: queryFunction,
    enabled: false,
  });
  useEffect(() => {
    if (error && error instanceof AxiosError) {
      if (error.response?.status === 401) {
        setIsTokenExpired(true);
      } else {
        setIsShowError(true);
      }
    }
  }, [error]);
  const {
    mutateAsync: createContraceptionMasterAsync,
    isPending: isPendingCreateContraceptionMaster,
  } = useMutation({
    mutationFn: async ({
      name,
      description,
      isActivated,
    }: CreateUpdateContraceptionMasterPayload) => {
      const response = await masterDataService.createContraceptionMasterData({
        name,
        description,
        isActivated,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const {
    mutateAsync: updateContraceptiondMasterAsync,
    isPending: isPendingUpdateContraceptionMaster,
  } = useMutation({
    mutationFn: async ({
      id,
      name,
      description,
      isActivated,
    }: CreateUpdateContraceptionMasterPayload) => {
      const response = await masterDataService.updateContraceptionMasterData({
        id: id,
        name: name,
        description: description,
        isActivated: isActivated,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const { mutateAsync: deleteContraceptionMasterAsync } = useMutation({
    mutationFn: async (id: string) => {
      const response = await masterDataService.deleteContraceptionMasterData(
        id
      );
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          if (
            error.response?.data.message ===
            "Cannot perform operation, data is in use"
          ) {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล เนื่องจากมีการใช้ข้อมูลทำให้ไม่สามารถลบได้"
            );
          } else {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }
          setIsShowError(true);
        }
      }
    },
  });
  useEffect(() => {
    refetch();
  }, [isFetchData]);
  useEffect(() => {
    if (contraceptionData) {
      setContraceptionList(contraceptionData);
      setFilteredList(contraceptionData);
    } else {
      setContraceptionList([]);
      setFilteredList([]);
    }
  }, [contraceptionData]);
  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleTextInputChange = (newValue: string) => {
    setSearchText(newValue);
  };
  const handleSearchStatusChange = (newValue: string) => {
    setSearchStatus(newValue);
  };
  const handleSearchClick = () => {
    const result = contraceptionList.filter((item) => {
      const matchesText = item.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const currentStatus = item.isActivated ? "ใช้งานได้" : "ปิดการใช้งาน";
      const matchesStatus = searchStatus
        ? currentStatus === searchStatus
        : true;
      return matchesText && matchesStatus;
    });
    setPage(1);
    setFilteredList(result);
  };
  const handleClearSearchClick = () => {
    setSearchText("");
    setSearchStatus("");
    setFilteredList(contraceptionList);
  };
  const handleActiveStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatus(event.target.checked);
  };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };
  const handleAddNewItem = async (data: FieldValues) => {
    // const newItem = {
    //   name: data.name,
    //   description: data.description,
    //   status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
    // };
    // setFilteredList([...contraceptionList, newItem]);
    // contraceptionList.push(newItem);
    await createContraceptionMasterAsync({
      name: data.name,
      description: data.description,
      isActivated: activeStatus,
    });
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleDeleteClick = (index: number) => {
    setDeleteItemIndex(index);
    setOpenDeleteDialog(true);
  };
  const handleEditClick = (data: ContraceptionItem) => {
    setOpenEditDialog(true);
    setContraceptionItem(data);
    setActiveStatus(data?.isActivated ? true : false);
  };
  const handleEditItem = async (data: ContraceptionItem) => {
    // setContraceptionList((prevList) =>
    //   prevList.map((item) =>
    //     item.name === currentContraceptionItem?.name
    //       ? { ...data, isActivated: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
    //       : item
    //   )
    // );
    // setFilteredList((prevList) =>
    //   prevList.map((item) =>
    //     item.name === currentContraceptionItem?.name
    //       ? { ...data, isActivated: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
    //       : item
    //   )
    // );
    if (currentContraceptionItem) {
      await updateContraceptiondMasterAsync({
        id: currentContraceptionItem.id,
        name: data.name,
        description: data.description,
        isActivated: activeStatus,
      });
    }
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleConfirmDeleteDialog = async () => {
    // setContraceptionList((prevList) =>
    //   prevList.filter((_, i) => i !== deleteItemIndex)
    // );
    // setFilteredList((prevList) =>
    //   prevList.filter((_, i) => i !== deleteItemIndex)
    // );
    await deleteContraceptionMasterAsync(deleteItemIndex.toString());
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleAddClick = () => {
    setActiveStatus(true);
    setOpenAddDialog(true);
  };
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        {" "}
        <Box sx={{ p: "20px", width: 1 }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {`Contraception`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <MasterDataFiltering
              title="Contraception"
              searchText={searchText}
              searchStatus={searchStatus}
              searchTextLabel="ค้นหาชื่อฟิลด์ข้อมูล"
              handleSearchTextChange={handleTextInputChange}
              handleSearchStatusChange={handleSearchStatusChange}
              handleSearchClick={handleSearchClick}
              handleClearSearchClick={handleClearSearchClick}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              mt: 2,
              overflow: "auto",
              scrollbarWidth: "thin",
              p: "20px",
              boxSizing: "border-box",
            }}
          >
            {masterDataPermissionAddAble && (
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF366B",
                float: "right",
                height: "40px",
                fontSize: "14px",
                marginBottom: "20px",
                minWidth: "90px",
              }}
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              เพิ่ม
            </Button>
            )}
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCellValues.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            width: item.width,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredList.length > 0 && (
                    <TableBody>
                      {filteredList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "5px",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: item.isActivated
                                      ? "green"
                                      : "red",
                                  }}
                                />
                                {item.isActivated
                                  ? "ใช้งานได้"
                                  : "ปิดการใช้งาน"}
                              </div>
                            </TableCell>
                            <TableCell>
                              <Stack spacing={1} direction={"row"}>
                                <Box
                                  sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "secondary.main",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 14,
                                    cursor: masterDataPermissionEditAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents: masterDataPermissionEditAble
                                      ? "auto"
                                      : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionEditAble) {
                                      handleEditClick(item);
                                    }
                                  }}
                                >
                                  <EditIcon
                                    fontSize="inherit"
                                    color="secondary"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    border: "1px solid #7A8688",
                                    borderRadius: 2,
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",          
                                    color: "#7A8688",
                                    fontSize: 14,
                                    cursor: masterDataPermissionDeleteAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents:
                                      masterDataPermissionDeleteAble
                                        ? "auto"
                                        : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionDeleteAble) {
                                      handleDeleteClick(item.id);
                                    }
                                  }}
                                >
                                  <DeleteIcon
                                    fontSize="inherit"
                                    color="inherit"
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {filteredList.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                  }}
                >
                  {isLoading ? (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <CircularProgress size={20} />
                      {` กำลังโหลดข้อมูล...`}
                    </Box>
                  ) : (
                    "--ไม่พบข้อมูล--"
                  )}
                </Box>
              )}
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{ mt: 2 }}
              />
            </div>
          </Box>
        </Box>
        {/* Add  Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNewItem(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อฟิลด์ข้อมูล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    checked={activeStatus}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={isPendingCreateContraceptionMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              onSuccess={handleEditItem}
              defaultValues={{
                name: currentContraceptionItem?.name,
                description: currentContraceptionItem?.description,
              }}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อฟิลด์ข้อมูล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={
                      currentContraceptionItem?.isActivated ? true : false
                    }
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={isPendingUpdateContraceptionMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        />
        {/* Failure Dialog */}
        <ErrorDialog
          openDialog={isShowError}
          handleCloseDialog={() => {
            setIsShowError(false);
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }}
          title="เกิดข้อผิดพลาด"
          content={messageError}
        />
        {/* Session Expired Dialog */}
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            navigate("/");
            setIsTokenExpired(false);
          }}
        />
      </ThemeProvider>
    </Layout>
  );
};
