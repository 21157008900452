import { Box, Paper, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import { useEffect, useMemo, useState } from "react";
import { CircleIcon } from "../../icon";
import { userManagementService } from "../../service/userManagementService";
import { activityMET } from "../../sharedValue";
import { body14400 } from "../../style";
import { AllCollectData, ExerciseItem, PatientUser } from "../../type";
import { calculateAvgExerciseKcal } from "../../utils/calculateTotalDataCollection";

type Props = {
  patientUser: PatientUser;
  weight: number;
};

exporting(Highcharts);
exportData(Highcharts);

const DataNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        fontSize: 12,
        color: "#9AA1AE",
        pt: 4,
      }}
    >
      ไม่พบข้อมูลการออกกำลังกาย
    </Box>
  );
};
export const ExerciseCard = ({ patientUser, weight }: Props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [allCollectData, setAllCollectData] = useState<AllCollectData[]>([]);
  const [exerciseInfo, setExerciseInfo] = useState<ExerciseItem[]>([]);
  const [lastedKcal, setLastedKcal] = useState(0);

  useEffect(() => {
    const fetchAllCollectData = async () => {
      try {
        const allListData = await userManagementService.getAllCollectData(
          patientUser.id
        );
        if (allListData) {
          setAllCollectData(allListData);
        } else {
          setAllCollectData([]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAllCollectData();
  }, [patientUser.id]);
  const sortedAllCollectData = useMemo(() => {
    const sortedData = allCollectData?.sort((a, b) => {
      const dateA = dayjs(a.date);
      const dateB = dayjs(b.date);
      return dateB.diff(dateA);
    });
    return sortedData;
  }, [allCollectData]);
  useEffect(() => {
    let latestTotalExercise = 0;
    for (let record of sortedAllCollectData) {
      if (record.totalExercise > 0) {
        latestTotalExercise = record.totalExercise;
        setExerciseInfo(record.exerciseInfo);
        break;
      }
    }
    if (latestTotalExercise === 0) {
      setLastedKcal(0);
      setExerciseInfo([]);
    } else {
      setLastedKcal(latestTotalExercise);
    }
  }, [sortedAllCollectData]);

  const calculateAverages = (dataSets: ExerciseItem[][]) => {
    const totals: { [name: string]: { kiloCalorie: number; time: number } } =
      {};
    const counts: { [name: string]: number } = {};

    dataSets?.forEach((data) => {
      data?.forEach(({ name, kiloCalorie, time }) => {
        if (!totals[name]) {
          totals[name] = { kiloCalorie: 0, time: 0 };
          counts[name] = 0;
        }
        totals[name].kiloCalorie += kiloCalorie;
        totals[name].time += time;
        counts[name]++;
      });
    });

    const averages = Object.keys(totals).map((name) => ({
      name,
      kiloCalorie: totals[name].kiloCalorie / counts[name],
      time: totals[name].time / counts[name],
      counts: counts[name],
    }));

    return averages;
  };
  const processData = useMemo(() => {
    const sortedData = exerciseInfo?.sort(
      (a, b) => b.kiloCalorie - a.kiloCalorie
    );

    const top3 = sortedData?.slice(0, 3);

    const other = sortedData
      ?.slice(3)
      .reduce((acc, item) => acc + item.kiloCalorie, 0);

    const chartData = top3?.map((item, index) => ({
      name: item.name,
      y: item.kiloCalorie,
      color: ["#67B7DC", "#6794DC", "#2CDDC7"][index],
    }));

    if (other > 0) {
      chartData.push({
        name: "อื่นๆ",
        y: other,
        color: "#D2EAE7",
      });
    }

    return chartData;
  }, [exerciseInfo]);
  const processDataAvg = useMemo(() => {
    let dataSets: ExerciseItem[][] = [];
    allCollectData.map((data) => dataSets.push(data.exerciseInfo));
    const averages = calculateAverages(dataSets);

    const sortedData = averages.sort((a, b) => b.kiloCalorie - a.kiloCalorie);

    const top3 = sortedData.slice(0, 3);

    const other = sortedData
      .slice(3)
      .reduce((acc, item) => acc + item.kiloCalorie, 0);

    const chartData = top3.map((item, index) => ({
      name: item.name,
      y: item.kiloCalorie,
      color: ["#67B7DC", "#6794DC", "#2CDDC7"][index],
    }));

    if (other > 0) {
      chartData.push({
        name: "Other",
        y: other,
        color: "#D2EAE7",
      });
    }

    return chartData;
    // eslint-disable-next-line
  }, [allCollectData, exerciseInfo]);

  const options = {
    chart: {
      type: "pie",
      renderTo: "container",
      height: 220,
      style: {
        fontFamily: "Sarabun",
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "kcal",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      // pointFormat: "<b>{point.y} kcal</b>",
      pointFormatter: function (this: Highcharts.Point) {
        return `<b>${this.y?.toLocaleString()} kcal</b>`;
      },
    },
    legend: {
      useHTML: true,
      labelFormatter(this: Highcharts.Point): string {
        return `<b>${Number(this.y?.toFixed(2)).toLocaleString()} kcal</b> ${
          activityMET.find((ac) => ac.name === this.name)?.nameTh ?? "อื่นๆ"
        }`;
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        borderRadius: 8,
        dataLabels: [
          {
            enabled: false,
            distance: -15,
            format: "{point.percentage:.0f}%",
            style: {
              fontSize: "0.9em",
            },
          },
        ],
        showInLegend: true,
      },
      pie: {
        size: 120,
      },
    },
    series: [
      {
        type: "pie",
        colorByPoint: true,
        innerSize: "60%",
        data: activeTab === 1 ? processData : processDataAvg,
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "NutritionDataExport",
    },
  };

  return (
    <Paper elevation={4} sx={{ borderRadius: 2, height: 320 }}>
      <Box>
        <Box sx={{ p: 2, pb: 0 }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography style={body14400} sx={{ color: "#4D4D4D" }}>
              {activeTab === 1
                ? `การออกกำลังกายล่าสุด`
                : `ค่าเฉลี่ยการออกกำลังกาย`}
            </Typography>
            <Stack direction={"row"}>
              {activeTab === 1 ? (
                <CircleIcon fontSize="small" color="info" />
              ) : (
                <Box onClick={() => setActiveTab(1)} sx={{ cursor: "pointer" }}>
                  <CircleIcon fontSize="small" color="disabled" />
                </Box>
              )}
              {activeTab === 2 ? (
                <CircleIcon fontSize="small" color="info" />
              ) : (
                <Box onClick={() => setActiveTab(2)} sx={{ cursor: "pointer" }}>
                  <CircleIcon fontSize="small" color="disabled" />
                </Box>
              )}
            </Stack>
          </Stack>

          <Typography fontSize={24} fontWeight={700}>
            {activeTab === 1
              ? `${lastedKcal.toLocaleString()} kcal`
              : `${calculateAvgExerciseKcal(
                  allCollectData
                ).toLocaleString()} kcal`}
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={500}
            color={"#4D4D4D"}
            display={activeTab === 2 ? "flex" : "none"}
          >
            {`จำนวนวัน: ${allCollectData?.length} วัน`}
          </Typography>
        </Box>

        <div>
          {lastedKcal > 0 ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            <DataNotFound />
          )}
        </div>
      </Box>
    </Paper>
  );
};
