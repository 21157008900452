import { Avatar, Divider, Paper, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import COS from "../asset/logoApp.png";
import { EditProfile, Logout } from "../asset/iconSvg";
import { KeyboardArrowRightIcon } from "../icon";
import defaultUserImg from "../asset/defaultUser.png";
import { useEffectOnce } from "react-use";
import { useSelector } from "react-redux";
import { store } from "../redux/store";

export default function MenuAppBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const userDetail = store.getState().loginUser.userDetail;
  const userPermission = useSelector(
    (state: any) => state.loginUser.userPermission
  );
  const userId = useSelector((state: any) => state.loginUser.userId);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false); // Close the menu
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("ีuserId");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("userPermission");
    navigate("/");

    handleMenuClose();
  };

  const handleEditProfileClick = () => {
    navigate("/profile", {
      state: { user: localStorage.getItem("userId") },
    });
  };

  const backgroundColor = {
    backgroundColor: "white",
  };

  const blackStyles = {
    color: "black",
  };

  const logoStyles = {
    maxHeight: "95px",
    maxWidth: "80px",
    flex: "1",
    left: "0",
    paddingRight: "20px",
  };
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box sx={{}}>
      <AppBar position="static" sx={{ backgroundColor, width: 1 }}>
        <Toolbar>
          <img src={COS} alt="COS Logo" style={logoStyles} />
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              ...blackStyles,
            }}
          >
            Thai Metabolic and Bariatric Surgery Database Program
          </Typography>
          {
            <div>
              <Paper
                sx={{
                  px: 2,
                  py: 1,
                  borderRadius: isOpen ? "8px 8px 0px 0px" : "8px 8px 8px 8px",
                  cursor: "pointer",
                  width: 250,
                  boxSizing: "border-box",
                }}
                onClick={handleToggleMenu}
              >
                <Stack direction={"row"} spacing={2}>
                  <Avatar sx={{ width: 40, height: 40 }} src={defaultUserImg} />
                  <Box>
                    <Typography fontSize={14} fontWeight={700}>
                      {userId !== "1" ? userDetail?.firstName : "ADMIN"}{" "}
                      {userId !== "1" ? userDetail?.lastName : "ADMIN"}
                    </Typography>
                    <Typography fontSize={12} color={"#646D78"}>
                      {userPermission?.nameThai}
                    </Typography>
                  </Box>
                </Stack>
                {/* <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  sx={blackStyles}
                >
                  <AccountCircle />
                </IconButton> */}
              </Paper>
              {isOpen && (
                <Paper
                  ref={menuRef}
                  sx={{
                    boxSizing: "border-box",
                    position: "absolute",
                    width: 250,
                    zIndex: 1,
                  }}
                >
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 56,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={2}
                      sx={{
                        px: 2,
                        py: 1,
                        color: "#212121",
                        "&:hover": {
                          color: "#ffffff",
                          bgcolor: "primary.main",
                        },
                        cursor: "pointer",
                        borderRadius: 2,
                        width: 1,
                        mx: 2,
                      }}
                      onClick={handleEditProfileClick}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <EditProfile style={{ fill: "inherit" }} />
                        <Typography fontSize={14}>
                          จัดการข้อมูลส่วนตัว
                        </Typography>
                      </Stack>

                      <KeyboardArrowRightIcon sx={{ color: "white" }} />
                    </Stack>
                  </Box>

                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 56,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={2}
                      sx={{
                        px: 2,
                        py: 1,
                        color: "#212121",
                        "&:hover": {
                          color: "#ffffff",
                          bgcolor: "primary.main",
                        },
                        cursor: "pointer",
                        borderRadius: 2,
                        width: 1,
                        mx: 2,
                      }}
                      onClick={handleLogoutClick}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <Logout style={{ fill: "inherit" }} />
                        <Typography fontSize={14}>ออกจากระบบ</Typography>
                      </Stack>

                      <KeyboardArrowRightIcon sx={{ color: "white" }} />
                    </Stack>
                  </Box>
                </Paper>
              )}
              {/* <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                  zoom: 100 * (100 / 85) + "%",
                  top: "30px",
                  width: 200,
                  "& .MuiMenu-Paper": {
                    boxShadow: 0,
                  },
                }}
              >
                <MenuItem
                  sx={{
                    fontSize: 14,
                    color: "#212121",
                    display: "flex",
                    gap: 1,
                    "&:hover": {
                      color: "#ffffff",
                      bgcolor: "primary.main",
                    },
                  }}
                  onClick={handleEditProfileClick}
                >
                  <EditProfile style={{ fill: "inherit" }} />
                  จัดการข้อมูลส่วนตัว
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: 14,
                    color: "#212121",
                    display: "flex",
                    gap: 1,
                    "&:hover": {
                      color: "#ffffff",
                      bgcolor: "primary.main",
                    },
                  }}
                  onClick={handleLogoutClick}
                >
                  <Logout style={{ fill: "inherit" }} />
                  ออกจากระบบ
                </MenuItem>
              </Menu> */}
            </div>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
