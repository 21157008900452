import { AllCollectData } from "../type";

export const calculateAvgFoodKcal = (data: AllCollectData[]) => {
  const totalKcal = data.reduce((sum, entry) => sum + entry.totalCalories, 0);
  const averageFoodKcal = totalKcal / data.length;
  return isNaN(averageFoodKcal) ? 0 : Math.round(averageFoodKcal);
};

export const calculateAvgExerciseKcal = (data: AllCollectData[]) => {
  const totalKcal = data.reduce((sum, entry) => sum + entry.totalExercise, 0);
  const averageExerciseKcal = totalKcal / data.length;
  return isNaN(averageExerciseKcal) ? 0 : Math.round(averageExerciseKcal);
};
