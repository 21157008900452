import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { Layout } from "../Layout";
import { dashboardService } from "../service/dashboardService";
import {
  DailyHealthDashboard,
  DashboardData,
  DashboardDetail,
  NewRegistDashboard,
  UserPermission,
} from "../type";
import "./Dashboard.css";
import { store } from "../redux/store";

dayjs.locale("th");
exporting(Highcharts);
exportData(Highcharts);

Highcharts.setOptions({
  colors: [
    "#084FD7",
    "#64BDC6",
    "#EECA34",
    "#FE7135",
    "#FD2A2A",
    "#EE60E0",
    "#7B47E9",
    "#5D89DF",
    "#DF5353",
    "#FEB56A",
    "#6975FF",
  ],
});

const convertColumnDataFormat = (
  data: NewRegistDashboard | DailyHealthDashboard
) => {
  const daily = data.daily
    ?.sort((a, b) => a.date.localeCompare(b.date))
    .map((item) => [item.date, item.count]);

  const months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  const monthly = data.monthly
    ?.sort((a, b) => a.month.localeCompare(b.month))
    .map((item) => {
      const [year, month] = item.month.split("-");
      return [`${months[parseInt(month, 10) - 1]}${year}`, item.count];
    });

  const yearly = data.yearly
    ?.sort((a, b) => a.year.localeCompare(b.year))
    .map((item) => [item.year, item.count]);

  return {
    daily,
    monthly,
    yearly,
  };
};

const formatAgeLabel = (ageLabel: string): string => {
  return `${ageLabel
    .replace("<", "น้อยกว่า")
    .replace(">", "มากกว่า")
    .replace("ปี", " ปี")}`;
};
const surgeryGroup = [
  { label: "RYGB", name: "Roux-en-Y gastric bypass (RYGB)" },
  { label: "SG", name: "Sleeve Gastrectomy (SG)" },
  { label: "Sleeve-DJB", name: "Sleeve-DJB (Roux-en-Y)" },
  { label: "Loop", name: "Sleeve-DJB (Loop)" },
  { label: "Sleeve-PJB", name: "Sleeve-PJB" },
  { label: "SADI-S", name: "SADI-S" },
  { label: "Gastric Banding", name: "Gastric Banding" },
  { label: "Intragastric Balloon", name: "Intragastric Balloon" },
  { label: "Endoscopic Sleeve", name: "Endoscopic Sleeve Gastroplasty" },
  { label: "Other", name: "อื่นๆ" },
];

const menuItems = [
  { value: "ALL", label: "ภาพรวม" },
  {
    value: "Roux-en-Y gastric bypass (RYGB)",
    label: "Roux-en-Y gastric bypass (RYGB)",
  },
  { value: "Sleeve Gastrectomy (SG)", label: "Sleeve Gastrectomy (SG)" },
  { value: "Sleeve-DJB (Roux-en-Y)", label: "Sleeve-DJB (Roux-en-Y)" },
  { value: "Sleeve-DJB (Loop)", label: "Sleeve-DJB (Loop)" },
  { value: "Sleeve-PJB", label: "Sleeve-PJB" },
  { value: "SADI-S", label: "SADI-S" },
  { value: "Gastric Banding", label: "Gastric Banding" },
  { value: "Intragastric Balloon", label: "Intragastric Balloon" },
  {
    value: "Endoscopic Sleeve Gastroplasty",
    label: "Endoscopic Sleeve Gastroplasty",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const stackRef = useRef<HTMLDivElement>(null);
  const [ewlType, setEwlType] = useState("ALL");
  const [dashboardView, setDashboardView] = useState(
    store.getState().loginUser.userDetail?.affiliatedHospital
  );
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [newRegistType, setNewRegistType] = useState("Daily");
  const [healthDailyType, setHealthDailyType] = useState("Daily");
  const userPermission = useSelector((state: any) => state.userPermission);
  const [_userAnchorEl, setUserAnchorEl] = useState<any | null>(null);
  const [dashboardDetail, setDashboardDetail] = useState<DashboardDetail>();
  const [isShowError, setIsShowError] = useState(false);

  const queryFunction = async () => {
    const response = (await dashboardService.getDashboard()) as DashboardData;
    return response;
  };
  const {
    data: dashboardData,
    isLoading,
    refetch,
    isError,
  } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: queryFunction,
    enabled: false,
  });

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token || token === null) {
          setIsTokenExpired(true);
        } else {
          refetch();
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });
  useEffect(() => {
    // refetch();
    if (dashboardData) {
      if (dashboardView === "โรงพยาบาลทั้งหมด") {
        setDashboardDetail(dashboardData.allDashboard);
      } else {
        const currentHospital = dashboardData.hospitalSpecificDashboard.find(
          (d) => d.hospitalName === dashboardView
        );
        setDashboardDetail(currentHospital);
      }
    }
  }, [dashboardData, dashboardView]);
  useEffect(() => {
    if (isError) {
      setIsShowError(true);
    } else {
      setIsShowError(false);
    }
  }, [isError]);
  useEffect(() => {
    setDashboardView(store.getState().loginUser.userDetail?.affiliatedHospital);
  }, [store.getState().loginUser.userDetail?.affiliatedHospital]);
  const handleNewRegistTypeChange = (title: string) => {
    setNewRegistType(title);
  };
  const handleHealthDailyTypeChange = (title: string) => {
    setHealthDailyType(title);
  };

  const allPatientOptions = {
    chart: {
      type: "pie",
      height: 250,
      renderTo: "container",
      style: {
        fontFamily: "Sarabun",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          distance: -30,
          format: "{point.percentage:.2f}%",
          style: {
            fontSize: "0.8em",
            color: "white",
            textOutline: "none",
          },
          filter: {
            operator: ">",
            property: "percentage",
            value: 10,
          },
        },
        showInLegend: true,
      },
    },
    legend: {
      labelFormatter(this: Highcharts.Point): string {
        return `${this.name}: ${this.y}`;
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} คน ({point.percentage:.2f}%)</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        return `${this.name}`;
      },
    },
    series: [
      {
        name: "Patient",
        colorByPoint: true,
        data: dashboardDetail?.allPatient,
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "จำนวนคนไข้ทั้งหมด",
    },
  };
  const allTypeOptions = {
    chart: {
      type: "pie",
      height: 250,
      renderTo: "container",
      style: {
        fontFamily: "Sarabun",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          distance: -25,
          format: "{point.percentage:.2f}%",
          style: {
            fontSize: "0.8em",
            textOutline: "none",
          },
          filter: {
            operator: ">",
            property: "percentage",
            value: 10,
          },
        },
        showInLegend: true,
      },
    },
    legend: {
      useHTML: true,
      labelFormatter(this: Highcharts.Point): string {
        return `<div>${this.name} ${this.y}</div>`;
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} คน ({point.percentage:.2f}%)</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        return `${this.name}`;
      },
    },
    series: [
      {
        name: "Patient",
        colorByPoint: true,
        data: dashboardDetail?.allType,
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "จำนวนคนแบ่งตามประเภทผู้ป่วย",
    },
  };
  const newRegistrationOptions = {
    chart: {
      type: "column",
      height: 200,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },

    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} คน</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        if (newRegistType === "Daily") {
          const date = dayjs(this.name);
          return `${date.format("dddd")}, ${date.format("D MMMM BBBB")}`;
        } else {
          return `${this.name}`;
        }
      },
      backgroundColor: "white",
      borderWidth: 0,
      shadow: true,
      useHTML: true,
      padding: 8,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.1,
        pointWidth:
          newRegistType === "Daily" ? 5 : newRegistType === "Monthly" ? 10 : 15,
      },
      series: {
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "New Users",
        data:
          dashboardDetail &&
          (newRegistType === "Daily"
            ? convertColumnDataFormat(dashboardDetail.newRegist)?.daily
            : newRegistType === "Monthly"
            ? convertColumnDataFormat(dashboardDetail.newRegist)?.monthly
            : convertColumnDataFormat(dashboardDetail.newRegist)?.yearly),
        color:
          newRegistType === "Daily"
            ? "#084FD7"
            : newRegistType === "Monthly"
            ? "#64BDC6"
            : "#EECA34",
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "จำนวนคนลงทะเบียนใหม่",
    },
  };
  const dailyHealthOptions = {
    chart: {
      type: "column",
      height: 200,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },

    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} คน</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        if (healthDailyType === "Daily") {
          const date = dayjs(this.name);
          return `${date.format("dddd")}, ${date.format("D MMMM BBBB")}`;
        } else {
          return `${this.name}`;
        }
      },
      backgroundColor: "white",
      borderWidth: 0,
      shadow: true,
      useHTML: true,
      padding: 8,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.1,
        pointWidth:
          healthDailyType === "Daily"
            ? 5
            : healthDailyType === "Monthly"
            ? 10
            : 15,
      },
      series: {
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Health Daily Users",
        data:
          dashboardDetail &&
          (healthDailyType === "Daily"
            ? convertColumnDataFormat(dashboardDetail.dailyHealth).daily
            : healthDailyType === "Monthly"
            ? convertColumnDataFormat(dashboardDetail.dailyHealth).monthly
            : convertColumnDataFormat(dashboardDetail.dailyHealth).yearly),
        color:
          healthDailyType === "Daily"
            ? "#084FD7"
            : healthDailyType === "Monthly"
            ? "#64BDC6"
            : "#EECA34",
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "จำนวนผู้กรอกแบบสุขภาพประจำวัน",
    },
  };
  const ageOptions = {
    chart: {
      type: "pie",
      height: 270,
      width: null,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} คน ({point.percentage:.2f}%)</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        return `อายุ: ${this.name}`;
      },
    },
    plotOptions: {
      pie: {
        size: "60%",
        dataLabels: {
          distance: 5,
          style: {
            fontSize: "12px",
          },
          allowOverlap: true,
          formatter: function (
            this: Highcharts.PointLabelObject
          ): string | null {
            return Number(this?.y) > 0 ? this.point.name : null;
          },
        },
      },
      series: {
        cursor: "pointer",
        allowPointSelect: false,
        dataLabels: [
          {
            enabled: true,
            distance: 5,
            style: {
              fontWeight: 500,
            },
          },
          {
            enabled: true,
            distance: -25,
            format: "{point.percentage:.2f}%",
            style: {
              fontSize: "0.8em",
              textOutline: "none",
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
        showInLegend: false,
      },
    },
    series: [
      {
        name: "Age Groups",
        data: dashboardDetail?.age?.map((item) => ({
          ...item,
          name: formatAgeLabel(item.name),
        })),
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "จำนวนคนแบ่งตามช่วงอายุ",
    },
  };
  const bmiOptions = {
    chart: {
      type: "pie",
      height: 270,
      width: null,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} คน ({point.percentage:.2f}%)</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        return `BMI: ${this.name}`;
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: "60%",
        dataLabels: {
          distance: 5,
          style: {
            fontSize: "12px",
          },
          allowOverlap: true,
          formatter: function (
            this: Highcharts.PointLabelObject
          ): string | null {
            return Number(this?.y) > 0 ? this.point.name : null;
          },
        },
      },
      series: {
        cursor: "pointer",
        allowPointSelect: false,
        dataLabels: [
          {
            enabled: true,
            distance: 5,
            style: {
              fontWeight: 500,
            },
          },
          {
            enabled: true,
            distance: -25,
            format: "{point.percentage:.2f}%",
            style: {
              fontSize: "0.8em",
              textOutline: "none",
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
        showInLegend: false,
      },
    },
    series: [
      {
        name: "",
        colorByPoint: true,
        // innerSize: "40%",
        data: dashboardDetail?.bmi,
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "จำนวนคนแบ่งตามBMI",
    },
  };
  const comorbiditiesOptions = {
    chart: {
      type: "column",
      height: 270,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      shared: true,
      useHTML: true,
      pointFormat: `<span style="color:{point.color}">●</span> {series.name}: {point.y} คน<br/>`,
    },
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "จำนวน (คน)",
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.1,
        pointWidth: 10,
        color: "#DF5C8E",
      },
      series: {
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "First Visit",
        data: dashboardDetail?.firstVisitComor,
        color: "#5C8EDF",
      },
      {
        name: "Present",
        data: dashboardDetail?.lastVisitComor,
        color: "#DF5C8E",
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "จำนวนคนแบ่งตามComorbidities & Complication",
    },
  };
  const surgeryOptions = {
    chart: {
      type: "column",
      height: 270,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      categories: surgeryGroup.map((group) => group.label),
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "จำนวน (ครั้ง)",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} ครั้ง</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        return `${this.name}`;
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.1,
        pointWidth: 35,
        color: "#DF5C8E",
      },
      series: {
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "การผ่าตัด",
        data: dashboardDetail?.surgery.map((data) => {
          const group = surgeryGroup.find((group) => group.name === data.name);
          return { name: group?.label || data.name, y: data.y };
        }),
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "ข้อมูลจำนวนการผ่าตัดแบ่งตามชนิดการผ่าตัด",
    },
  };
  const EwlOptions = {
    chart: {
      height: 270,
      width: null,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: [
        "2WK",
        "1M",
        "3M",
        "6M",
        "9M",
        "1Y",
        "1Y 3M",
        "1Y 6M",
        "1Y 9M",
        "2Y",
        "2Y 6M",
        "3Y",
        "3Y 6M",
        "4Y",
        "4Y 6M",
        "5Y",
        "5Y+",
      ],
      title: {
        text: "",
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [
      {
        name: "EWL%",
        data: dashboardDetail?.excessWeightLoss
          ?.find((item) => item.type === ewlType)
          ?.data.map((val) => parseFloat(val.toFixed(2))),
      },
      {
        name: "TWL%",
        data: dashboardDetail?.totalWeightLoss
          ?.find((item) => item.type === ewlType)
          ?.data.map((val) => parseFloat(val.toFixed(2))),
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "ewl_vs_twl",
    },
  };

  return (
    <Layout activeTab="dashboard">
      <div
        style={{
          minHeight: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Select
            value={dashboardView || ""}
            size="small"
            onChange={(e) => {
              setUserAnchorEl(e.currentTarget);
              setDashboardView(e.target.value);
            }}
            sx={{ fontSize: "14px", width: 300 }}
            MenuProps={{
              sx: {
                zoom: 100 * (100 / 85) + "%",
                top: "0px",
              },
              PaperProps: {
                sx: {
                  maxHeight: "300px",
                  scrollbarWidth: "thin",
                  minWidth: "0px !important",
                },
              },
            }}
          >
            {/* {store.getState().loginUser.userDetail?.affiliatedHospital ===
              "โรงพยาบาลจุฬาลงกรณ์ สภากาชาดไทย" && (
              <MenuItem value={"โรงพยาบาลทั้งหมด"} sx={{ fontSize: 12 }}>
                โรงพยาบาลทั้งหมด
              </MenuItem>
            )} */}
            {dashboardData?.hospitalSpecificDashboard.map((d, index) => (
              <MenuItem
                key={`h${index}`}
                value={d.hospitalName}
                sx={{ fontSize: 12 }}
              >
                {d.hospitalName}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            height: "calc(100% - 70px - 16px)",
            bgcolor: "white",
            m: 2,
            overflow: "auto",
            scrollbarWidth: "thin",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              minWidth: 600,
              width: "calc(100% - 32px)",
            }}
          >
            <Box sx={{ borderBottom: "2px solid #D8D8D8" }}>
              <Box
                component={"span"}
                sx={{
                  display: "inline-block",
                  minWidth: "185px",
                  borderBottom: "4px solid #DF5C8E",
                  p: 1.5,
                  fontSize: 18,
                  fontWeight: 600,
                  ml: 1.5,
                  boxSizing: "border-box",
                }}
              >
                ภาพรวม : {dashboardView}
              </Box>
            </Box>
            <Stack spacing={2}>
              <Box
                id="dashboard-row-1"
                ref={stackRef}
                sx={{
                  width: 1,
                  display: "flex",
                  gap: 2,
                  height: 330,
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 280,
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนคนไข้ทั้งหมด
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        {`${
                          dashboardDetail?.allPatient.reduce(
                            (acc, cur) => acc + cur.y,
                            0
                          ) ?? 0
                        } คน`}
                      </Typography>
                    </Box>
                    {dashboardDetail &&
                    dashboardDetail.allPatient.reduce(
                      (acc, cur) => acc + cur.y,
                      0
                    ) !== 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={allPatientOptions}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 14,
                          color: "#9AA1AE",
                          py: 2,
                          height: "50px",
                        }}
                      >
                        {isLoading ? (
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <CircularProgress size={20} />
                            {` กำลังโหลดข้อมูล...`}
                          </Box>
                        ) : (
                          "--ไม่พบข้อมูล--"
                        )}
                      </Box>
                    )}
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 280,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนคนแบ่งตามประเภทผู้ป่วย
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        {`${
                          dashboardDetail?.allType.reduce(
                            (acc, cur) => acc + cur.y,
                            0
                          ) ?? 0
                        } คน`}
                      </Typography>
                    </Box>
                    {dashboardDetail &&
                    dashboardDetail.allType.reduce(
                      (acc, cur) => acc + cur.y,
                      0
                    ) !== 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={allTypeOptions}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 14,
                          color: "#9AA1AE",
                          py: 2,
                          height: "50px",
                        }}
                      >
                        {isLoading ? (
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <CircularProgress size={20} />
                            {` กำลังโหลดข้อมูล...`}
                          </Box>
                        ) : (
                          "--ไม่พบข้อมูล--"
                        )}
                      </Box>
                    )}
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 300,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนคนลงทะเบียนใหม่
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        {`${
                          dashboardDetail
                            ? newRegistType === "Daily"
                              ? dashboardDetail.newRegist.daily.reduce(
                                  (acc, cur) => acc + cur.count,
                                  0
                                ) ?? 0
                              : newRegistType === "Monthly"
                              ? dashboardDetail.newRegist.monthly.reduce(
                                  (acc, cur) => acc + cur.count,
                                  0
                                ) ?? 0
                              : dashboardDetail.newRegist.yearly.reduce(
                                  (acc, cur) => acc + cur.count,
                                  0
                                ) ?? 0
                            : 0
                        } คน`}
                      </Typography>
                    </Box>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={newRegistrationOptions}
                    />

                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr"}
                      gap={1}
                      sx={{ bgcolor: "#E8E9EE", borderRadius: 2, p: 1, mx: 2 }}
                    >
                      <Box
                        sx={{
                          bgcolor: newRegistType === "Daily" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleNewRegistTypeChange("Daily")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={newRegistType === "Daily" ? 700 : 500}
                        >
                          Daily
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: newRegistType === "Monthly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleNewRegistTypeChange("Monthly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={newRegistType === "Monthly" ? 700 : 500}
                        >
                          Monthly
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: newRegistType === "Yearly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleNewRegistTypeChange("Yearly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={newRegistType === "Yearly" ? 700 : 500}
                        >
                          Yearly
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 2 / 5,
                    minWidth: 500,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        Commodities & Complication
                      </Typography>
                    </Box>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={comorbiditiesOptions}
                    />
                  </Paper>
                </Box>
              </Box>
              <Box
                id="dashboard-row-2"
                sx={{
                  width: 1,
                  display: "flex",
                  gap: 2,
                  height: 330,
                  p: 2,
                  pt: 0,
                }}
              >
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 280,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        BMI (Pre-op)
                      </Typography>
                    </Box>
                    {dashboardDetail &&
                    dashboardDetail.bmi.reduce((acc, cur) => acc + cur.y, 0) !==
                      0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={bmiOptions}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 14,
                          color: "#9AA1AE",
                          py: 2,
                          height: "50px",
                        }}
                      >
                        {isLoading ? (
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <CircularProgress size={20} />
                            {` กำลังโหลดข้อมูล...`}
                          </Box>
                        ) : (
                          "--ไม่พบข้อมูล--"
                        )}
                      </Box>
                    )}
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 280,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        อายุ (ปี)
                      </Typography>
                    </Box>
                    {dashboardDetail &&
                    dashboardDetail.age.reduce((acc, cur) => acc + cur.y, 0) !==
                      0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ageOptions}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 14,
                          color: "#9AA1AE",
                          py: 2,
                          height: "50px",
                        }}
                      >
                        {isLoading ? (
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <CircularProgress size={20} />
                            {` กำลังโหลดข้อมูล...`}
                          </Box>
                        ) : (
                          "--ไม่พบข้อมูล--"
                        )}
                      </Box>
                    )}
                  </Paper>
                </Box>
                {/* <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 280,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        Comorbidities & Complication
                      </Typography>
                    </Box>
                    {dashboardDetail &&
                    dashboardDetail.comor.reduce(
                      (acc, cur) => acc + cur.y,
                      0
                    ) !== 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={comorbiditiesOptions}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 14,
                          color: "#9AA1AE",
                          py: 2,
                          height: "50px",
                        }}
                      >
                        {isLoading ? (
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <CircularProgress size={20} />
                            {` กำลังโหลดข้อมูล...`}
                          </Box>
                        ) : (
                          "--ไม่พบข้อมูล--"
                        )}
                      </Box>
                    )}
                  </Paper>
                </Box> */}
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 300,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนผู้กรอกแบบสุขภาพประจำวัน
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        {`${
                          dashboardDetail
                            ? healthDailyType === "Daily"
                              ? dashboardDetail.dailyHealth.daily.reduce(
                                  (acc, cur) => acc + cur.count,
                                  0
                                ) ?? 0
                              : healthDailyType === "Monthly"
                              ? dashboardDetail.dailyHealth.monthly.reduce(
                                  (acc, cur) => acc + cur.count,
                                  0
                                ) ?? 0
                              : dashboardDetail.dailyHealth.yearly.reduce(
                                  (acc, cur) => acc + cur.count,
                                  0
                                ) ?? 0
                            : 0
                        } คน`}
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={dailyHealthOptions}
                    />
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr"}
                      gap={1}
                      sx={{ bgcolor: "#E8E9EE", borderRadius: 2, p: 1, mx: 2 }}
                    >
                      <Box
                        sx={{
                          bgcolor: healthDailyType === "Daily" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleHealthDailyTypeChange("Daily")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={healthDailyType === "Daily" ? 700 : 500}
                        >
                          Daily
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: healthDailyType === "Monthly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleHealthDailyTypeChange("Monthly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={healthDailyType === "Monthly" ? 700 : 500}
                        >
                          Monthly
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: healthDailyType === "Yearly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleHealthDailyTypeChange("Yearly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={newRegistType === "Yearly" ? 700 : 500}
                        >
                          Yearly
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 2 / 5,
                    minWidth: 500,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        ข้อมูลจำนวนการผ่าตัดแบ่งตามชนิดการผ่าตัด
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={surgeryOptions}
                    />
                  </Paper>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  minWidth: 1400,
                  p: 2,
                  // boxSizing: "border-box",
                  pt: 0,
                }}
              >
                <Paper sx={{ borderRadius: 2, height: 1, width: 1 }}>
                  <Box sx={{ p: 2, pb: 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        ค่าเฉลี่ย %EWL, %TWL
                      </Typography>
                      <Select
                        id="ewlType"
                        value={ewlType}
                        size="small"
                        onChange={(e) => setEwlType(e.target.value)}
                        sx={{
                          fontSize: 14,
                          width: 250,
                        }}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                          },
                          PaperProps: {
                            sx: {
                              maxHeight: "200px",
                              scrollbarWidth: "thin",
                              minWidth: "0px !important",
                            },
                          },
                        }}
                      >
                        {menuItems.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            sx={{ fontSize: 12 }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={EwlOptions}
                  />
                </Paper>
              </Box>
            </Stack>
          </Box>
        </Box>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
      <ErrorDialog
        openDialog={isShowError}
        handleCloseDialog={() => {
          setIsShowError(false);
        }}
        title="เกิดข้อผิดพลาด"
        content={"เกิดข้อผิดพลาดไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง"}
      />
    </Layout>
  );
};

export default Dashboard;
