import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import {
  BariaticSurgeryData,
  EarlyComplicationType,
  OperationLocation,
  SubsequentOperation,
} from "../../../type";
import { userManagementService } from "../../../service/userManagementService";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};
interface BariatricSurgery {
  surgeryDate: string;
  editor?: string;
  operationTime: number | null;
  acuteWeightLoss: boolean;
  acuteWeightLossType: string;
  acuteWeightLossDay: number | null;
  preAwlWeight: number | null;
  postAwlWeight: number | null;
  surgicalTechnique: string;
  operationType: string;
  operationTypeOtherText: string;
  operationLocation: OperationLocation[];
  bougieSize: string;
  bpLimb: string;
  rouxLimb: string;
  ebl: string;
  subsequentOperation: SubsequentOperation;
  subsequentOperationOther: string[];
  earlyComplication: boolean;
  earlyComplicationType: EarlyComplicationType;
  earlyComplicationTypeOtherText: string;
  treatment: string[];
  reOperation: boolean;
}
const BariatricSurgeryLabels: { [key in keyof BariatricSurgery]: string } = {
  surgeryDate: "วันที่ผ่าตัด",
  editor: "Editor",
  operationTime: "Operation Time",
  acuteWeightLoss: "Acute Weight Loss",
  acuteWeightLossType: "Acute Weight Loss Type",
  acuteWeightLossDay: "Acute Weight Loss Day",
  preAwlWeight: "Pre AWL Weight",
  postAwlWeight: "Post AWL Weight",
  surgicalTechnique: "Surgical Technique",
  operationType: "Operation Type",
  operationTypeOtherText: "Operation Type Other",
  operationLocation: "Operation Location",
  bougieSize: "Bougie Size",
  bpLimb: "BP limb (cm)",
  rouxLimb: "Roux limb (cm)",
  ebl: "EBL(mL)",
  subsequentOperation: "Subsequent Operation",
  subsequentOperationOther: "Subsequent Operation Other",
  earlyComplication: "Early Complication",
  earlyComplicationType: "Early Complication Type",
  earlyComplicationTypeOtherText: "Early Complication Type Other",
  treatment: "Treatment",
  reOperation: "Re-Operation",
};

const earlyComplicationTypeLabels = {
  leakage: "Leakage",
  surgicalSiteInfection: "Surgical Site Infection",
  obstruction: "Obstruction",
  cardiovascularEvents: "Cardiovascular Events",
  bleeding: "Bleeding",
  pulmonaryComplication: "Pulmonary Complication",
  vte: "VTE",
  other: "Other",
};

const subsequentOperationLabels = {
  laparoscopicCholecystectomy: "Laparoscopic Cholecystectomy",
  laparoscopicHiatalHerniaRepair: "Laparoscopic Hiatal Hernia Repair",
  other: "Other",
};

export const BariatricSurgeryVisitLogDialog = ({
  open,
  handleCloseDialog,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [bariatricSurgeryHistory, setBariatricSurgeryHistory] = useState<
    BariaticSurgeryData[]
  >([]);
  const bariatricSurgeryKeys = Object.keys(
    BariatricSurgeryLabels
  ) as (keyof BariatricSurgery)[];

  const DetailBox = ({ label, value }: { label: string; value: string }) => (
    <Box
      display={"grid"}
      gridTemplateColumns={"1fr 1fr"}
      gap={2}
      sx={{ pb: 1 }}
    >
      <Typography
        sx={{
          textAlign: "right",
          fontSize: 14,
          color: "#646D78",
          minWidth: 250,
        }}
      >
        {label}
      </Typography>
      <Typography sx={{ textAlign: "left", fontSize: 14, minWidth: 250 }}>
        {value}
      </Typography>
    </Box>
  );

  useEffect(() => {
    const fetchData = async () => {
      const visitId = store.getState().patientUser?.selectedVisitId;
      if (visitId) {
        try {
          const bariatricSurgeryHistory: BariaticSurgeryData[] =
            await visitDataService.getBariatricSurgeryListLog(visitId);
          if (bariatricSurgeryHistory) {
            const updatedBariatricSurgeryHistory = await Promise.all(
              bariatricSurgeryHistory.map(async (entry) => {
                try {
                  if (entry.editorId) {
                    const userDetails =
                      await userManagementService.getUserDetail(
                        String(entry?.editorId)
                      );
                    entry.editorId = `${userDetails.firstName} ${userDetails.lastName}`;
                  } else {
                    entry.editorId = "-";
                  }
                } catch (error) {
                  console.error("Failed to fetch user details:", error);
                  entry.editorId = "-";
                }
                return entry;
              })
            );

            setBariatricSurgeryHistory(updatedBariatricSurgeryHistory);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>History Log</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Edit Date
                </TableCell>

                {bariatricSurgeryHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.createDateTime?.toString()).format(
                        "DD/MM/BBBB"
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {bariatricSurgeryKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      minWidth: 200,
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "1px solid #E5EBEB",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      bgcolor: "white",
                    }}
                  >
                    {BariatricSurgeryLabels[key]}
                  </TableCell>

                  {bariatricSurgeryHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          alignContent: "baseline",
                        }}
                      >
                        {(() => {
                          switch (key) {
                            case "surgeryDate":
                              return (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={dayjs(
                                    entry.surgeryDate?.toString()
                                  ).format("DD/MM/BBBB")}
                                />
                              );
                            case "editor":
                              return (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key] || ""}
                                  value={
                                    entry.editorId
                                      ? String(entry.editorId)
                                      : "-"
                                  }
                                />
                              );
                            case "operationLocation":
                              return entry.operationLocation ? (
                                Object.values(entry.operationLocation).map(
                                  (item, index) => (
                                    <DetailBox
                                      label={BariatricSurgeryLabels[key]}
                                      value={`${item.location}, ${
                                        item.technique
                                      }, ${
                                        item.techniqueDetail
                                          ? item.techniqueDetail
                                          : "-"
                                      } `}
                                    />
                                  )
                                )
                              ) : (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={`-`}
                                />
                              );
                            case "subsequentOperation":
                              return entry.subsequentOperation ? (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={Object.keys(entry.subsequentOperation)
                                    .filter(
                                      (key) =>
                                        entry.subsequentOperation[
                                          key as keyof typeof subsequentOperationLabels
                                        ]
                                    )
                                    .map(
                                      (key) =>
                                        subsequentOperationLabels[
                                          key as keyof typeof subsequentOperationLabels
                                        ]
                                    )
                                    .join(", ")}
                                />
                              ) : (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={`-`}
                                />
                              );
                            case "earlyComplicationType":
                              return entry.earlyComplicationType ? (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={Object.keys(
                                    entry.earlyComplicationType
                                  )
                                    .filter(
                                      (key) =>
                                        entry.earlyComplicationType[
                                          key as keyof typeof earlyComplicationTypeLabels
                                        ]
                                    )
                                    .map(
                                      (key) =>
                                        earlyComplicationTypeLabels[
                                          key as keyof typeof earlyComplicationTypeLabels
                                        ]
                                    )
                                    .join(", ")}
                                />
                              ) : (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={`-`}
                                />
                              );
                            case "subsequentOperationOther":
                              return (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={`${
                                    entry.subsequentOperationOther
                                      ? entry.subsequentOperationOther
                                      : "-"
                                  }`}
                                />
                              );
                            case "acuteWeightLoss":
                              return (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={`${
                                    entry.acuteWeightLoss ? "Yes" : "No"
                                  }`}
                                />
                              );
                            case "earlyComplication":
                              return (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={`${
                                    entry.earlyComplication ? "Yes" : "No"
                                  }`}
                                />
                              );
                            case "reOperation":
                              return (
                                <DetailBox
                                  label={BariatricSurgeryLabels[key]}
                                  value={`${entry.reOperation ? "Yes" : "No"}`}
                                />
                              );
                            default:
                              return (
                                <Table>
                                  <DetailBox
                                    label={BariatricSurgeryLabels[key] || ""}
                                    value={
                                      (entry as any)[
                                        key as keyof BariatricSurgery
                                      ]
                                        ? String(
                                            (entry as any)[
                                              key as keyof BariatricSurgery
                                            ]
                                          )
                                        : "-"
                                    }
                                  />
                                </Table>
                              );
                          }
                        })()}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={bariatricSurgeryHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};
