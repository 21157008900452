import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { VisitTab } from "../shared/visit/VisitTab";
import { PatientUser, PatientUserProfile, VisitData } from "../type";
import { VisitStatusBox } from "../shared/visit/VisitStatusBox/VisitStatusBox";
import { visitDataService } from "../service/visitDataService";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import save from "../asset/save.png";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  setEntryIds,
  setLatestEntryIds,
  setSelectedVisitDate,
  setSelectedVisitId,
} from "../redux/features/patientSlice";

import { SuccessDialog } from "../components/dialog/SuccessDialog";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { useSelector } from "react-redux";
import { WarningDialog } from "../components/dialog/WarningDialog";
import { store } from "../redux/store";

type Props = {
  patientUser: PatientUser;
  selectedPatientUserProfile: PatientUserProfile;
};

export const DoctorDetail = ({
  patientUser,
  selectedPatientUserProfile,
}: Props) => {
  const dispatch = useAppDispatch();
  const latestEndOdStudyEntryId = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.endOfStudyStatus
  );

  const [visitDataList, setVisitDataList] = useState<VisitData[]>([]);
  const [visitClicked, setVisitClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(-1);
  const [isVisitTabClicked, setIsVisitTabClicked] = useState(false);
  const [isShowErrorExistingVisit, setIsShowErrorExistingVisit] =
    useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [endOfStudyType, setEndOfStudyType] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [isUpdateVisitDate, setIsUpdateVisitDate] = useState(false);
  const tabList = [
    "Demographic Data",
    "Comorbidities & Complications",
    "Body composition & Laboratory",
    "Gynecologic conditions",
    "Investigation",
    "Pathology",
    "Medication And Nutritional Supplements",
    "Bariatric Surgery",
    "Late complication",
    "End of study",
  ];
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);

  const handleCloseErrorDialog = () => {
    setIsShowErrorExistingVisit(false);
    setOpenNoPermissionDialog(false);
    setOpenSuccessDialog(false);
  };

  const handleVisitTabClicked = (
    tabIndex: number,
    visitId: any,
    visitData: VisitData
  ) => {
    setIsVisitTabClicked(true);
    setSelectedTab(tabIndex);
    if (visitId !== null) {
      let entryIds: { [key: string]: any } = {};
      for (let key in visitData) {
        if (
          visitData[key] &&
          typeof visitData[key] === "object" &&
          "lasEntryId" in visitData[key]
        ) {
          entryIds[key] = visitData[key].lasEntryId;
        } else {
          entryIds[key] = null;
        }
      }
      dispatch(setSelectedVisitId(visitId));
      dispatch(setEntryIds(entryIds));
      if (visitData.visitDate !== null) {
        if(isUpdateVisitDate){
          dispatch(setSelectedVisitDate(selectedDate));
        }else {
          dispatch(setSelectedVisitDate(visitData.visitDate));
        }
      }
    }
  };

  const handleDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
  };

  const addRow = () => {
    setVisitClicked(false);
    setVisitDataList((prevVisitDataList) => [
      ...prevVisitDataList,
      {
        visitId: null,
        visitDate: dayjs().format("YYYY-MM-DD"),
        demographicDataStatus: null,
        comorbiditiesAndConditionsStatus: null,
        bodyCompositionAndLaboratoryStatus: null,
        gynecologicConditionsStatus: null,
        investigationStatus: null,
        pathologyStatus: null,
        medicationAndNutritionalSupplementsStatus: null,
        bariaticSurgeryStatus: null,
        lateComplicationStatus: null,
        endOfStudyStatus: null,
      },
    ]);
  };

  const indicateVisitStatusColor = (status: any) => {
    if (status === 1) {
      return "#FBC235";
    } else if (status === 2) {
      return "#26B465";
    } else {
      return "#FFFFFF";
    }
  };

  const saveNewVisit = async (visitId?: number) => {
    try {
      if (!patientDataPermissionEditAble) {
        setOpenNoPermissionDialog(true); 
        return;
      }
      const tempVisitData = visitDataList.find(
        (visitData) => visitData.visitId === null
      );

      if (
        visitDataList.find(
          (visitData) =>
            visitData.visitDate === selectedDate && visitData.visitId !== null
        )
      ) {
        setIsShowErrorExistingVisit(true);
        return;
      }

      if (tempVisitData) {
        const response = await visitDataService.saveNewVisit(
          patientUser.id,
          selectedDate
        );

        setVisitDataList((prevVisitDataList) => [
          ...prevVisitDataList?.filter(
            (visitData) => visitData.visitId !== null
          ),
          {
            ...tempVisitData,
            visitId: response.visitId, // replace with the actual visitId from the response
          },
        ]);
        setOpenSuccessDialog(true);
      } else if (selectedDate && visitId) {
        //TODO : edit
        const response = await visitDataService.updateVisitDate(
          visitId,
          selectedDate
        );
        if (response) {
          setOpenSuccessDialog(true);
          setIsUpdateVisitDate(true);
          setSelectedDate(selectedDate);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const findLatestOfEntryIdForEachTabs = async (visitDataList: any[]) => {
    const tabsKeys = [
      "demographicDataStatus",
      "comorbiditiesAndConditionsStatus",
      "bodyCompositionAndLaboratoryStatus",
      "gynecologicConditionsStatus",
      "investigationStatus",
      "pathologyStatus",
      "medicationAndNutritionalSupplementsStatus",
      "bariaticSurgeryStatus",
      "lateComplicationStatus",
      "endOfStudyStatus",
    ];

    const latestEntryIds: { [key: string]: any } = {};

    for (const key of tabsKeys) {
      const filteredVisits = visitDataList.filter(
        (visit) => visit[key] !== null
      );

      if (filteredVisits.length === 0) {
        latestEntryIds[key] = null;
      } else {
        filteredVisits?.sort(
          (a, b) =>
            new Date(b.visitDate).getTime() - new Date(a.visitDate).getTime()
        );
        latestEntryIds[key] = filteredVisits[0][key].lasEntryId;
      }
    }

    dispatch(setLatestEntryIds(latestEntryIds));
    if (latestEntryIds && latestEntryIds.endOfStudyStatus) {
      try {
        const endOfStudyInfo = await visitDataService.getEndOfStadyFromEntryId(
          latestEndOdStudyEntryId!
        );
        setEndOfStudyType(endOfStudyInfo.endOfStudyType);
      } catch (error) {
        console.error(error);
        setEndOfStudyType(0);
      }
    } else {
      setEndOfStudyType(0);
    }

    return latestEntryIds;
  };

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await visitDataService.getAllVisit(patientUser.id);
        if (response.length === 0) {
          setVisitDataList([]);
        }
        setVisitDataList(response);

        findLatestOfEntryIdForEachTabs(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchVisits();
  }, [patientUser, isVisitTabClicked, latestEndOdStudyEntryId]);

  const handleCancelTabClick = () => {
    setIsVisitTabClicked(false);
  };

  return isVisitTabClicked ? (
    <VisitTab
      selectedTabIndex={selectedTab}
      handleCancelClick={handleCancelTabClick}
    />
  ) : (
    <div
      style={{
        padding: "30px",
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <VisitStatusBox />
        {endOfStudyType !== 2 && patientDataPermissionEditAble && (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FF366B",
              float: "right",
              width: "140px",
              height: "40px",
              fontSize: "14px",
            }}
            startIcon={<AddIcon style={{ color: "white", padding: "0.5px" }} />}
            onClick={addRow}
          >
            Add Visits
          </Button>
        )}
      </div>
      <Box
        sx={{
          position: "relative",
          display: visitDataList ? "none" : "flex",
          flexDirection: "column",
          width: "calc(100% - 84px)",
          height: "calc(100vh - 72px)",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <CircularProgress />
        <Typography>กำลังโหลดข้อมูล...</Typography>
      </Box>
      <div
        style={{
          paddingTop: "20px",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "calc(100vh - 180px)", scrollbarWidth: "thin" }}
        >
          <Table stickyHeader aria-label="simple table" sx={{ width: "100%" }}>
            <TableHead style={{ backgroundColor: "#F9F9FC" }}>
              <TableRow>
                <TableCell
                  sx={{
                    width: "200px",
                    backgroundColor: "#F9F9FC",
                    textAlign: "center",
                    p: 2,
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    Data Visit History
                  </Typography>
                </TableCell>
                {tabList.map((tab, index) => (
                  <TableCell
                    key={index}
                    style={{
                      backgroundColor: "#F9F9FC",
                      width: "120px",
                      textAlign: "center",
                    }}
                  >
                    {tab}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {visitDataList.length > 0 && (
              <TableBody style={{ backgroundColor: "#F9F9FC" }}>
                {visitDataList
                  .slice()
                  .sort(
                    (a, b) =>
                      dayjs(b.visitDate).unix() - dayjs(a.visitDate).unix()
                  )
                  .map((visitData, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <DatePicker
                            maxDate={dayjs()}
                            value={dayjs(visitData.visitDate)}
                            onAccept={(newValue) => handleDateChange(newValue)}
                            sx={{
                              "& .MuiOutlinedInput-input": {
                                fontSize: 14,
                              },
                              width: 150,
                            }}
                            disabled= {patientDataPermissionEditAble ? false : true}
                          />
                          <img
                            src={save}
                            style={{ marginLeft: "10px" }}
                            onClick={() => saveNewVisit(visitData.visitId!)}
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.demographicDataStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    0,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.comorbiditiesAndConditionsStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    1,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.bodyCompositionAndLaboratoryStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    2,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.gynecologicConditionsStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    3,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.investigationStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    4,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.pathologyStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    5,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.medicationAndNutritionalSupplementsStatus
                              ?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    6,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.bariaticSurgeryStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    7,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.lateComplicationStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    8,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          bgcolor={indicateVisitStatusColor(
                            visitData.endOfStudyStatus?.status
                          )}
                          border="1px solid #DDDFE7"
                          width={20}
                          height={20}
                          sx={{ margin: "auto" }}
                          onClick={
                            visitData.visitId
                              ? () =>
                                  handleVisitTabClicked(
                                    9,
                                    visitData.visitId,
                                    visitData
                                  )
                              : undefined
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          {visitDataList.length === 0 && visitClicked === false && (
            <Box
              sx={{
                justifyContent: "center",
                fontSize: 14,
                color: "#9AA1AE",
                py: 2,
              }}
              marginTop={1}
            >
              <div style={{ textAlign: "center" }}>-- ไม่พบข้อมูล --</div>
            </Box>
          )}
        </TableContainer>
      </div>
      <ErrorDialog
        openDialog={isShowErrorExistingVisit}
        title="วันที่นี้มีการบันทึกข้อมูลแล้ว"
        content="กรุณาเลือกวันที่อื่น"
        handleCloseDialog={handleCloseErrorDialog}
      />
      <SuccessDialog
        openDialog={openSuccessDialog}
        title="บันทึกข้อมูลสำเร็จ"
        content=""
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
    </div>
  );
};
