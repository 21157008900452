import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HomeIcon from "@mui/icons-material/Home";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserPermission, VerticalTabs } from "../type";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserDetail,
  setUserId,
  setUserPermission,
  UserDetail,
} from "../redux/features/loginUserSlice";
import { useEffectOnce } from "react-use";
import { LoadingModal } from "../Pages/LoginScreen";

enum Routes {
  USER_TAB = "userTab",
  USER_PERMISSION = "userPermission",
  USER_HISTORY = "history",
  APPROVE_USER = "approveUser",
  MASTER_DATA = "masterData",
  LOG = "log",
  HOME = "home",
}

type Props = {
  activeTab: VerticalTabs;
};
const VerticalTab = ({ activeTab }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userAnchorEl, setUserAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<VerticalTabs>(activeTab);
  const [userDetails, setUserDetails] = useState<UserDetail | null>(null);
  const [userPermissions, setUserPermissions] = useState<UserPermission>();
  const userPermission = useSelector(
    (state: any) => state.loginUser.userPermission
  );

  const userId = localStorage.getItem("userId") ?? "";
  const dashBoardViewAble =
    userId === "1" ? true : userPermission?.dashboardPermission.viewAble;
  const userManagementViewAble =
    userId === "1" ? true : userPermissions?.userManagePermission?.viewAble;
  const userApproveViewAble =
    userId === "1" ? true : userPermissions?.userApprovePermission?.viewAble;
  const userPermissionPermissionViewAble =
    userId === "1" ? true : userPermissions?.userPermissionPermission?.viewAble;
  const userLogPermissionViewAble =
    userId === "1" ? true : userPermissions?.userLogPermission?.viewAble;
  const masterDataPermissionViewAble =
    userId === "1" ? true : userPermissions?.masterDataPermission.viewAble;
  const patientDataPermissionViewAble =
    userId === "1" ? true : userPermissions?.patientDataPermission.viewAble;

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: VerticalTabs
  ) => {
    setValue(newValue);
  };
  const zoomFactor = 0.85;
  const handleUserMenuClick = (event: any) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (route?: Routes) => {
    setUserAnchorEl(null);
    if (route) {
      navigate(`/${route}`);
    }
  };
  const handleClose = () => {
    setUserAnchorEl(null);
  };

  const tabStyles = {
    width: "111px",
    height: "100%",
  };

  const userMenuItems = [
    { label: "จัดการผู้ใช้งาน", route: Routes.USER_TAB },
    { label: "อนุมัติผู้ใช้งาน", route: Routes.APPROVE_USER },
    { label: "จัดการสิทธิ์การใช้งาน", route: Routes.USER_PERMISSION },
    { label: "ประวัติการใช้งานในระบบ", route: Routes.USER_HISTORY },
  ];

  useEffectOnce(() => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId") ?? "";
      const userDetailString = localStorage.getItem("userDetail");
      const userDetails = userDetailString
        ? JSON.parse(userDetailString)
        : null;
      setUserDetails(userDetails);
      const userPermissionString = localStorage.getItem("userPermission");
      const userPermissions = userPermissionString
        ? JSON.parse(userPermissionString)
        : null;

      setUserPermissions(userPermissions);
      dispatch(setUserDetail(userDetails));
      dispatch(setUserId(userId));
      dispatch(setUserPermission(userPermissions));
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  });

  return (
    <Box display="flex" style={tabStyles}>
      <LoadingModal open={loading} />
      <Tabs
        value={value}
        onChange={handleChange}
        orientation="vertical"
        variant="scrollable"
        sx={{
          backgroundColor: "#F8E1EA",
          height: "100%",
          "& .MuiTabs-indicator": {
            bgcolor: "unset",
          },
        }}
      >
        <Tab
          label={
            <Stack spacing={1} alignItems={"center"}>
              <HomeIcon
                fontSize="large"
                sx={{
                  color: activeTab === "home" ? "white" : "#DF5C8E",
                }}
              />
              <Typography
                component={"span"}
                fontSize={12}
                color={activeTab === "home" ? "white" : "#000"}
                fontWeight={600}
              >
                Home
              </Typography>
            </Stack>
          }
          component={Link}
          to="/home"
          style={{
            padding: "50px",
            height: "105px",
            backgroundColor: activeTab === "home" ? "#DF5C8E" : "",
          }}
          value={"home"}
        />
        {dashBoardViewAble && (
          <Tab
            label={
              <Stack spacing={1} alignItems={"center"}>
                <DashboardIcon
                  fontSize="large"
                  sx={{
                    color: activeTab === "dashboard" ? "white" : "#DF5C8E",
                  }}
                />
                <Typography
                  component={"span"}
                  fontSize={12}
                  color={activeTab === "dashboard" ? "white" : "#000"}
                  fontWeight={600}
                >
                  Dashboard
                </Typography>
              </Stack>
            }
            component={Link}
            to="/dashboard"
            style={{
              padding: "15px",
              height: "105px",
              backgroundColor: activeTab === "dashboard" ? "#DF5C8E" : "",
            }}
            value={"dashboard"}
          />
        )}
        {patientDataPermissionViewAble && (
          <Tab
            label={
              <Stack spacing={1} alignItems={"center"}>
                <SupervisedUserCircleIcon
                  fontSize="large"
                  sx={{
                    color: activeTab === "patient" ? "white" : "#DF5C8E",
                  }}
                />
                <Typography
                  component={"span"}
                  fontSize={12}
                  color={activeTab === "patient" ? "white" : "#000"}
                  fontWeight={600}
                >
                  ข้อมูลผู้ป่วย
                </Typography>
              </Stack>
            }
            component={Link}
            to="/patientDetail"
            style={{
              padding: "15px",
              height: "105px",
              backgroundColor: activeTab === "patient" ? "#DF5C8E" : "",
            }}
            value={"patient"}
          />
        )}
        {userLogPermissionViewAble && (
          <Tab
            label={
              <Stack spacing={1} alignItems={"center"}>
                <SummarizeIcon
                  fontSize="large"
                  sx={{ color: activeTab === "report" ? "white" : "#DF5C8E" }}
                />
                <Typography
                  component={"span"}
                  fontSize={12}
                  color={activeTab === "report" ? "white" : "#000"}
                  fontWeight={600}
                >
                  รายงาน
                </Typography>
              </Stack>
            }
            component={Link}
            to="/report"
            style={{
              padding: "15px",
              height: "105px",
              backgroundColor: activeTab === "report" ? "#DF5C8E" : "",
            }}
            value={"report"}
          />
        )}
        {(userManagementViewAble ||
          userPermissionPermissionViewAble ||
          userApproveViewAble ||
          userLogPermissionViewAble) && (
          <Tab
            label={
              <Stack spacing={1} alignItems={"center"}>
                <ManageAccountsIcon
                  fontSize="large"
                  sx={{
                    color: activeTab === "userPermission" ? "white" : "#DF5C8E",
                  }}
                />
                <Typography
                  component={"span"}
                  fontSize={12}
                  color={activeTab === "userPermission" ? "white" : "#000"}
                  fontWeight={600}
                >
                  ผู้ใช้งานและสิทธิ์
                </Typography>
              </Stack>
            }
            onClick={handleUserMenuClick}
            style={{
              padding: "15px",
              height: "105px",
              backgroundColor: activeTab === "userPermission" ? "#DF5C8E" : "",
            }}
            value={"userPermission"}
          />
        )}

        {masterDataPermissionViewAble && (
          <Tab
            value={"setting"}
            label={
              <Stack spacing={1} alignItems={"center"}>
                {
                  <SettingsIcon
                    fontSize="large"
                    sx={{
                      color: activeTab === "setting" ? "white" : "#DF5C8E",
                    }}
                  />
                }
                <Typography
                  component={"span"}
                  fontSize={12}
                  color={activeTab === "setting" ? "white" : "#000"}
                  fontWeight={600}
                >
                  ตั้งค่าข้อมูลหลัก
                </Typography>
              </Stack>
            }
            component={Link}
            to="/masterData"
            // onClick={handleSetingMenuClick}
            style={{
              padding: "15px",
              height: "105px",
              backgroundColor: activeTab === "setting" ? "#DF5C8E" : "",
            }}
          />
        )}
      </Tabs>
      <Menu
        id="simple-menu"
        anchorEl={userAnchorEl}
        keepMounted
        open={Boolean(userAnchorEl)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          userAnchorEl
            ? {
                top: userAnchorEl.getBoundingClientRect().top / zoomFactor,
                left:
                  userAnchorEl.getBoundingClientRect().left / zoomFactor + 110,
              }
            : undefined
        }
      >
        {userMenuItems.map((item) => {
          if (
            item.label === "จัดการผู้ใช้งาน" &&
            userManagementViewAble === false
          ) {
            return null;
          } else if (
            item.label === "อนุมัติผู้ใช้งาน" &&
            userApproveViewAble === false
          ) {
            return null;
          } else if (
            item.label === "จัดการสิทธิ์การใช้งาน" &&
            userPermissionPermissionViewAble === false
          ) {
            return null;
          } else if (
            item.label === "ประวัติการใช้งานในระบบ" &&
            userLogPermissionViewAble === false
          ) {
            return null;
          }
          return (
            <MenuItem
              key={item.label}
              onClick={() => handleMenuItemClick(item.route)}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
      {/* <Menu
        id="setting-simple-menu"
        anchorEl={settingAnchorEl}
        keepMounted
        open={Boolean(settingAnchorEl)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleClose}
      >
        {settingMenuItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => handleMenuItemClick(item.route)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu> */}
    </Box>
  );
};

export default VerticalTab;
