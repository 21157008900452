import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  FieldValues,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { useNavigate } from "react-router";
import defaultFoodImg from "../../../asset/default_food.jpg";
import { DeleteDialog } from "../../../components/dialog/DeleteDialog";
import { ErrorDialog } from "../../../components/dialog/ErrorDialog";
import {
  AddIcon,
  CancelOutlinedIcon,
  DeleteIcon,
  EditIcon,
} from "../../../icon";
import { Layout } from "../../../Layout";
import { store } from "../../../redux/store";
import { masterDataService } from "../../../service/masterDataService";
import { MasterDataFiltering } from "../../../shared/MasterDataFiltering";
import { labelForm, spacingForm, title16500 } from "../../../style";
import { theme2 } from "../../../theme";
import { generateImageUrlFromDocumentData } from "../../../utils/image-utils";

const tableCellValues = [
  { name: "ลำดับ", width: "5%", align: "center" },
  { name: "ชื่ออาหาร", width: "20%", align: "left" },
  { name: "หน่วยบริโภค", width: "10%", align: "left" },
  { name: "คำอธิบาย", width: "40%", align: "left" },
  { name: "จำนวนรายการ", width: "15%", align: "center" },
  { name: "เครื่องมือ", width: "10%", align: "center" },
];

type GetFoodDashboardResponse = {
  id: number;
  nameThai: string;
  measurementUnit: string;
  description: string;
  servingCount?: string;
};

type CreateFoodMasterPayload = {
  nameThai: string;
  measurementUnit: string;
  description: string;
  picture: {
    documentType: string;
    documentName: string;
    documentData: any;
  } | null;
};

export const FoodMaster = () => {
  const rowsPerPage = 10;
  const navigate = useNavigate();
  const filterRef = useRef<HTMLDivElement>(null);
  const [foodList, setFoodList] = useState<GetFoodDashboardResponse[]>([]);
  const [filteredList, setFilteredList] = useState<GetFoodDashboardResponse[]>(
    []
  );
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteFoodIndex, setDeleteFoodIndex] = useState(0);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isFetchData, setIsFetchData] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [messageError, setMessageError] = useState(
    "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
  );
  const [imageData, setImageData] = useState({
    documentName: "",
    documentType: "",
    documentData: [] as number[],
  });

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const masterDataPermissionAddAble =
    userId === "1" ? true : userPermission?.masterDataPermission.addAble;
  const masterDataPermissionEditAble =
    userId === "1" ? true : userPermission?.masterDataPermission.editAble;
  const masterDataPermissionDeleteAble =
    userId === "1" ? true : userPermission?.masterDataPermission.deleteAble;

  const queryFunction = async () => {
    const response = await masterDataService.getFoodMasterData();
    return response;
  };
  const {
    data: foodData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["foodMasterData"],
    queryFn: queryFunction,
    enabled: false,
  });
  useEffect(() => {
    if (error && error instanceof AxiosError) {
      if (error.response?.status === 401) {
        setIsTokenExpired(true);
      } else {
        setIsShowError(true);
      }
    }
  }, [error]);
  const {
    mutateAsync: createFoodMasterAsync,
    isPending: IsPendingCreateFoodMaster,
  } = useMutation({
    mutationFn: async ({
      nameThai,
      measurementUnit,
      description,
      picture,
    }: CreateFoodMasterPayload) => {
      const response = await masterDataService.createFoodMaster({
        nameThai: nameThai,
        measurementUnit: measurementUnit,
        description: description,
        picture: picture,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const { mutateAsync: deleteFoodMasterAsync } = useMutation({
    mutationFn: async (foodId: string) => {
      const response = await masterDataService.deleteFoodMaster(foodId);
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          if (
            error.response?.data.message ===
            "Cannot perform operation, data is in use"
          ) {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล เนื่องจากมีการใช้ข้อมูลทำให้ไม่สามารถลบได้"
            );
          } else {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }
          setIsShowError(true);
        }
      }
    },
  });
  useEffect(() => {
    refetch();
  }, [isFetchData]);
  useEffect(() => {
    if (foodData) {
      setFoodList(foodData);
      setFilteredList(foodData);
    } else {
      setFoodList([]);
      setFilteredList([]);
    }
  }, [isFetchData, foodData]);

  const handlePageChange = (_event: unknown, value: number) => {
    setPage(value);
  };
  const handleTextInputChange = (newValue: string) => {
    setSearchText(newValue);
  };
  const handleSearchClick = () => {
    const result = foodList?.filter((item) => {
      const matchesText = item.nameThai
        .toLowerCase()
        .includes(searchText.toLowerCase());
      return matchesText;
    });
    setPage(1);
    setFilteredList(result);
  };
  const handleClearSearchClick = () => {
    setSearchText("");
    setFilteredList(foodList);
  };
  const handleDeleteClick = (index: number) => {
    setDeleteFoodIndex(index);
    setOpenDeleteDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenDeleteDialog(false);
  };
  const handleConfirmDeleteDialog = async () => {
    // setFoodList((prevList) => prevList?.filter((_, i) => i !== deleteItemTemp));
    // setFilteredList((prevList) =>
    //   prevList?.filter((_, i) => i !== deleteItemTemp)
    // );
    await deleteFoodMasterAsync(deleteFoodIndex.toString());
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleAddNewItem = async (data: FieldValues) => {
    await createFoodMasterAsync({
      nameThai: data.name,
      measurementUnit: data.unit,
      description: data.description,
      picture: imagePreview
        ? {
            documentName: imageData.documentName,
            documentType: imageData.documentType,
            documentData: imageData.documentData,
          }
        : null,
    });
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onloadend = () => {
    //     setImagePreview(reader.result as string);
    //   };
    // }
    if (file) {
      const reader = new FileReader();

      setImageData((prev) => ({
        ...prev,
        documentName: file.name,
        documentType: file.type,
      }));

      reader.readAsArrayBuffer(file);
      reader.onloadend = () => {
        const arrayBuffer = reader.result as ArrayBuffer;

        const byteArray = new Uint8Array(arrayBuffer);

        setImageData((prev) => ({
          ...prev,
          documentData: Array.from(byteArray),
        }));

        // const base64String = URL.createObjectURL(file);
        // setImagePreview(base64String);
        setImagePreview(
          generateImageUrlFromDocumentData(Array.from(byteArray), file.type)
        );
      };
    }
  };
  const handleDeleteImage = () => {
    setImagePreview(null);
  };
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        <Box
          sx={{
            p: "20px",
            width: 1,
            height: "inherit",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {`อาหาร`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <MasterDataFiltering
              title="อาหาร"
              searchText={searchText}
              searchTextLabel="ค้นหาชื่ออาหาร"
              handleSearchTextChange={handleTextInputChange}
              handleSearchClick={handleSearchClick}
              handleClearSearchClick={handleClearSearchClick}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              // height: boxHeight,
              mt: 2,
              overflow: "auto",
              scrollbarWidth: "thin",
              p: "20px",
              boxSizing: "border-box",
            }}
          >
            {masterDataPermissionAddAble && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#FF366B",
                  float: "right",
                  height: "40px",
                  fontSize: "14px",
                  marginBottom: "20px",
                  minWidth: "90px",
                }}
                startIcon={<AddIcon />}
                onClick={() => setOpenAddDialog(true)}
              >
                เพิ่ม
              </Button>
            )}

            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCellValues.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            width: item.width,
                            textAlign: item.align,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredList.length > 0 && (
                    <TableBody>
                      {filteredList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index} style={{ padding: 0 }}>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{item.nameThai}</TableCell>
                            <TableCell>{item.measurementUnit}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {item.servingCount}
                            </TableCell>
                            <TableCell>
                              <Stack
                                spacing={1}
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Box
                                  sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "secondary.main",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 14,
                                    cursor: masterDataPermissionEditAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents: masterDataPermissionEditAble
                                      ? "auto"
                                      : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionEditAble) {
                                      console.log("edit");
                                      navigate(`${item.id}`);
                                    }
                                  }}
                                >
                                  <EditIcon
                                    fontSize="inherit"
                                    color="secondary"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    border: "1px solid #979797",
                                    borderRadius: 2,
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#7A8688",
                                    fontSize: 14,
                                    cursor: masterDataPermissionDeleteAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents:
                                      masterDataPermissionDeleteAble
                                        ? "auto"
                                        : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionDeleteAble) {
                                      handleDeleteClick(item.id);
                                    }
                                  }}
                                >
                                  <DeleteIcon
                                    fontSize="inherit"
                                    color="inherit"
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              {filteredList.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                  }}
                >
                  {isLoading ? (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <CircularProgress size={20} />
                      {` กำลังโหลดข้อมูล...`}
                    </Box>
                  ) : (
                    "--ไม่พบข้อมูล--"
                  )}
                </Box>
              )}
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{ mt: 2 }}
              />
            </div>
          </Box>
        </Box>
        {/* Add  Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNewItem(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่ออาหาร <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หน่วยบริโภค <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="unit"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Box
                  sx={{
                    border: "2px dashed #CBD0DC",
                    width: "180px",
                    height: "180px",
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundImage: imagePreview
                      ? ``
                      : `url(${defaultFoodImg})`,
                    backgroundSize: "cover",
                  }}
                >
                  {imagePreview ? (
                    <Box
                      sx={{
                        position: "relative",
                        height: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          cursor: "pointer",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <CancelOutlinedIcon sx={{ color: "#979797" }} />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography fontSize={16} fontWeight={500}>
                        เลือกไฟล์รูป
                      </Typography>
                      <Typography color={"#54575C"} fontWeight={500}>
                        JPEG, PNG, up to 50MB{" "}
                      </Typography>
                      <Typography color={"#54575C"} fontWeight={500}>
                        (ขนาดที่แนะนำ 180*180){" "}
                      </Typography>
                      {!imagePreview && (
                        <div style={{ marginTop: "8px" }}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={handleUploadClick}
                          />
                          <label htmlFor="contained-button-file">
                            <Stack direction={"row"} spacing={2}>
                              <Button
                                variant="contained"
                                component="span"
                                sx={{
                                  bgcolor: "white",
                                  color: "#54575C",
                                  "&:hover": {
                                    bgcolor: "white",
                                  },
                                }}
                              >
                                เลือกไฟล์
                              </Button>
                            </Stack>
                          </label>
                        </div>
                      )}
                    </Box>
                  )}
                </Box>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={IsPendingCreateFoodMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        />
        {/* Session Expired Dialog */}
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            navigate("/");
            setIsTokenExpired(false);
          }}
        />
        {/* Failure Dialog */}
        <ErrorDialog
          openDialog={isShowError}
          handleCloseDialog={() => {
            setIsShowError(false);
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }}
          title="เกิดข้อผิดพลาด"
          content={messageError}
        />
      </ThemeProvider>
    </Layout>
  );
};
