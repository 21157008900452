import { useEffect, useState } from "react";
import Frame_1672 from "../asset/Frame_1672.png";
import Button from "../asset/Button.png";
import { IconButton, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { WarningDialog } from "../components/dialog/WarningDialog";
import "../Pages/Register/Register.css";
import CloseIcon from '@mui/icons-material/Close';

export interface RegisterForm {
  fileDoctor: any | null;
  fileDoctorName: any | null;
  fileDoctorType: any | null;
  fileOther1: any | null;
  fileOther1Name: any | null;
  fileOther1Type: any | null;
  fileOther2: any | null;
  fileOther2Name: any | null;
  fileOther2Type: any | null;
}
interface DropZoneTestProps {
  onRegisterFormChange: (form: RegisterForm) => void;
}

const DropZoneTest : React.FC<DropZoneTestProps> = ({ onRegisterFormChange }) => {
  const [files, setFiles] = useState<string[]>([]);
  const [openUnsaveDataDialog, setOpenUnsaveDataDialog] = useState(false);
  const [registerForm, setRegisterForm] = useState<RegisterForm>({
    fileDoctor: null,
    fileDoctorName: null,
    fileDoctorType: null,
    fileOther1: null,
    fileOther1Name: null,
    fileOther1Type: null,
    fileOther2: null,
    fileOther2Name: null,
    fileOther2Type: null,
  });
  const [dialog, setDialog] = useState({title: "", content: ""});

  const handleCloseDialog = () => {
    setOpenUnsaveDataDialog(false);
  };
  const handleConfirmDialog = () => {
    setOpenUnsaveDataDialog(false);
  };

  useEffect(() => {
    onRegisterFormChange(registerForm);
  }, [registerForm, onRegisterFormChange]);

  const handleRegisterChange = (e: any) => {
    const { name, value } = e.target;
    if(name.toLowerCase().includes('file') && name.toLowerCase().includes('name') && value) {
      let nameArr = value.split('\\');
      let nameFile = nameArr[nameArr.length-1];
      setRegisterForm(prevState => ({
        ...prevState,
        [name]: nameFile
      }));
    } else {
      setRegisterForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    }    
  };

  function uploadFile(event: any, input: string) {
    // console.log("event.target.files =",event.target.files);
    if(Number((event.target.files?.[0]?.size / 1024 / 1024).toFixed(2)) > 3) {
      setDialog({title: "ขนาดไฟล์", content: "ขนาดไฟล์สูงสุดไม่เกิน 3 MB"});
      setOpenUnsaveDataDialog(true);
    // } else if(event.target.files?.[0].type !== 'application/pdf') {
    //   setDialog({title: "ประเภทไฟล์", content: "ประเภทไฟล์ต้องเป็น PDF เท่านั้น"});
    //   setOpenUnsaveDataDialog(true);
    } else {
      let reader = new FileReader();
      let fileByteArray = new Array();
      reader.readAsArrayBuffer(event.target.files?.[0]);
      reader.onloadend = function (event: any) {
        if (event.target.readyState == FileReader.DONE) {
          let arrayBuffer = event.target.result,
          array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
      }
      switch (input) {
        case "doctor":
          setRegisterForm({
            ...registerForm,
            fileDoctor: fileByteArray,
            fileDoctorType: event.target.files?.[0].type
          });
          handleRegisterChange(event);
          break;
        case "other1":
          setRegisterForm({
            ...registerForm,
            fileOther1: fileByteArray,
            fileOther1Type: event.target.files?.[0].type
          });
          handleRegisterChange(event);
          break;
        case "other2":
          setRegisterForm({
            ...registerForm,
            fileOther2: fileByteArray,
            fileOther2Type: event.target.files?.[0].type
          });
          handleRegisterChange(event);
          break;
        default:
      }
    }
  }

  function clearFile(id: string) {
    // (document.getElementById(id) as HTMLInputElement).value = null;
    switch (id) {
      case "fileDoctorName":
        registerForm.fileDoctorName = "";
        registerForm.fileDoctorType = "";
        registerForm.fileDoctor = "";
        handleRegisterChange({target: {name: "fileDoctorName", value: ""}});
        handleRegisterChange({target: {name: "fileDoctorType", value: ""}});
        handleRegisterChange({target: {name: "fileDoctor", value: ""}});
        break;
      case "fileOther1Name": 
        registerForm.fileOther1Name = "";
        registerForm.fileOther1Type = "";
        registerForm.fileOther1 = "";
        handleRegisterChange({target: {name: "fileOther1Name", value: ""}});
        handleRegisterChange({target: {name: "fileOther1Type", value: ""}});
        handleRegisterChange({target: {name: "fileOther1", value: ""}});
        break;
      case "fileOther2Name":
        registerForm.fileOther2Name = "";
        registerForm.fileOther2Type = "";
        registerForm.fileOther2 = "";
        handleRegisterChange({target: {name: "fileOther2Name", value: ""}});
        handleRegisterChange({target: {name: "fileOther2Type", value: ""}});
        handleRegisterChange({target: {name: "fileOther2", value: ""}});
        break;
      default:
    }
  }

  const styleList = {
    border: "1px solid #ccc",
    marginBottom: "10px"
  };

  const styleListError = {
    border: "1px solid #d32f2f",
    marginBottom: "10px"
  };

  return (
    <div style={{width: "40%", marginTop: "20px"}}>
      {/* <input {...getInputProps({ accept: ".png,.jpg,.jpeg,.csv" })} /> */}
      <ListItem
        style={!registerForm.fileDoctorName ? styleListError : styleList}
      >
        <ListItemAvatar>
          <img src={Frame_1672} alt="File Icon" />
        </ListItemAvatar>
        <ListItemText primary="บัตรประจำตัวแพทย์" secondary={registerForm.fileDoctorName} />
        {/* <div {...getRootProps()}> */}
          {!registerForm.fileDoctor ?
          <IconButton
            edge="end"
            aria-label="delete"
            component="label" // Add this line
          >
            <input
              type="file"
              hidden
              id="fileDoctorName"
              name="fileDoctorName"
              onChange={(event) => uploadFile(event, "doctor")}
            />
            <img src={Button} /> 
          </IconButton>:
          <CloseIcon onClick={() => clearFile("fileDoctorName")} style={{cursor: "pointer"}} />
          }
        {/* </div> */}
      </ListItem>
      <ListItem
        style={styleList}
      >
        <ListItemAvatar>
          <img src={Frame_1672} alt="File Icon" />
        </ListItemAvatar>
        <ListItemText primary="เอกสารอื่นๆ" secondary={registerForm.fileOther1Name} />
        {/* <div {...getRootProps()}> */}
          {!registerForm.fileOther1 ?
            <IconButton
              edge="end"
              aria-label="delete"
              component="label" // Add this line
            >
              <input
                type="file"
                hidden
                id="fileOther1Name"
                name="fileOther1Name"
                onChange={(event) => uploadFile(event, "other1")}
              />
              <img src={Button} />
            </IconButton> :
            <CloseIcon onClick={() => clearFile("fileOther1Name")} />
          }
        {/* </div> */}
      </ListItem>
      <ListItem
        style={styleList}
      >
        <ListItemAvatar>
          <img src={Frame_1672} alt="File Icon" />
        </ListItemAvatar>
        <ListItemText primary="เอกสารอื่นๆ" secondary={registerForm.fileOther2Name} />
        {/* <div {...getRootProps()}> */}
          {!registerForm.fileOther2 ?
          <IconButton
            edge="end"
            aria-label="delete"
            component="label" // Add this line
          >
            <input
              type="file"
              hidden
              id="fileOther2Name"
              name="fileOther2Name"
              onChange={(event) => uploadFile(event, "other2")}
            />
             <img src={Button} />
          </IconButton> :
          <CloseIcon onClick={() => clearFile("fileOther2Name")} style={{cursor: "pointer"}} />
          }
        {/* </div> */}
      </ListItem>
      <WarningDialog
        openDialog={openUnsaveDataDialog}
        title={dialog.title}
        content={dialog.content}
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleConfirmDialog}
      />
    </div>
  );
}

export default DropZoneTest;

