import axios from "axios";
import { MasterData } from "../../type";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getHospital = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/v1/datalist/affiliatedHospital`
    );
    const sortedHospitalList = response.data.sort(
      (a: MasterData, b: MasterData) => {
        if (a.nameThai === "โรงพยาบาลจุฬาลงกรณ์ สภากาชาดไทย") return -1;
        if (b.nameThai === "โรงพยาบาลจุฬาลงกรณ์ สภากาชาดไทย") return 1;
        return a.nameThai.localeCompare(b.nameThai, "th");
      }
    );
    return sortedHospitalList;
  } catch (error) {
    console.error("Error:", error);
  }
};
