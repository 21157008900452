import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Radio,
  DialogActions,
  Button,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  RadioGreyBox,
  TextGreyBox,
  TextWhiteBox,
  YesNoBox,
} from "../../../components/TextBox";
import { InvestigationUltrasound } from "./InvestigationUltrasound";
import { store } from "../../../redux/store";

interface InvestigationUltrasoundProps extends InvestigationUltrasound {
  open: boolean;
  onClose: (data: InvestigationUltrasound) => void;
  onSave: (data: InvestigationUltrasound) => void;
}

export const InvestigationUltrasoundDialog: React.FC<
  InvestigationUltrasoundProps
> = ({ open, onClose, onSave, fattyLiver, gallstone, cirrhosis, ascites }) => {
  const initialValues = useMemo(
    () => ({ fattyLiver, gallstone, cirrhosis, ascites }),
    [fattyLiver, gallstone, cirrhosis, ascites]
  );
  const [ultrasoundValues, setUltrasoundValues] =
    useState<InvestigationUltrasound>(initialValues);
  const [ultrasoundValuesTemp, setUltrasoundValuesTemp] =
    useState<InvestigationUltrasound>(initialValues);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffect(() => {
    setUltrasoundValues(initialValues);
    setUltrasoundValuesTemp(initialValues);
  }, [initialValues]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUltrasoundValues({
      ...ultrasoundValues,
      [event.target.name]: event.target.value === "yes" ? "yes" : "no",
    });
  };
  const handleClickCancle = () => {
    setUltrasoundValues(ultrasoundValuesTemp);
    onClose(ultrasoundValuesTemp);
  };
  const handleClickSave = () => {
    if (patientDataPermissionEditAble === false) {
      setUltrasoundValues(ultrasoundValuesTemp);
      onClose(ultrasoundValuesTemp);
      return;
    }
    onSave(ultrasoundValues);
  };

  return (
    <Dialog open={open} onClose={handleClickCancle}>
      <DialogTitle>Ultrasound</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div style={{ width: "550px", display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Stack spacing={1}>
              <Stack spacing={1} direction={"row"}>
                <TextWhiteBox>{``}</TextWhiteBox>
                <YesNoBox />
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Fatty Liver</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="fattyLiver"
                    defaultValue="no"
                  >
                    <Radio
                      checked={ultrasoundValues.fattyLiver === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={ultrasoundValues.fattyLiver === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Gallstone</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="gallstone"
                    defaultValue="no"
                  >
                    <Radio
                      checked={ultrasoundValues.gallstone === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={ultrasoundValues.gallstone === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Cirrhosis</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="cirrhosis"
                    defaultValue="no"
                  >
                    <Radio
                      checked={ultrasoundValues.cirrhosis === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={ultrasoundValues.cirrhosis === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
              <Stack spacing={1} direction={"row"}>
                <TextGreyBox>Ascites</TextGreyBox>
                <RadioGreyBox>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="ascites"
                    defaultValue="no"
                  >
                    <Radio
                      checked={ultrasoundValues.ascites === "yes"}
                      onChange={handleChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                    <Radio
                      checked={ultrasoundValues.ascites === "no"}
                      onChange={handleChange}
                      value="no"
                      style={{ color: "#098484" }}
                    />
                  </RadioGroup>
                </RadioGreyBox>
              </Stack>
            </Stack>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClickCancle}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};
