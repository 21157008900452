import { Box, Paper, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import { useEffect, useMemo, useState } from "react";
import { CircleIcon } from "../../icon";
import { body14400 } from "../../style";
import { AllCollectData, FoodItem, PatientUser } from "../../type";
import { userManagementService } from "../../service/userManagementService";
import dayjs from "dayjs";
import { calculateAvgFoodKcal } from "../../utils/calculateTotalDataCollection";
import {
  calculateAverageNutritionPercentages,
  calculateNutritionPercentages,
} from "../../utils/calculateNutritionPercentages";

type Props = {
  patientUser: PatientUser;
};

exporting(Highcharts);
exportData(Highcharts);

const DataNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        fontSize: 12,
        color: "#9AA1AE",
        pt: 4,
      }}
    >
      ไม่พบข้อมูลการอาหาร
    </Box>
  );
};

export const MealCard = ({ patientUser }: Props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [allCollectData, setAllCollectData] = useState<AllCollectData[]>([]);
  const [lastedFoodItems, setLastedFoodItems] = useState<{
    morning: FoodItem[];
    lunch: FoodItem[];
    dinner: FoodItem[];
    other: FoodItem[];
  }>();
  const [lastedKcal, setLastedKcal] = useState(0);

  useEffect(() => {
    const fetchAllCollectData = async () => {
      try {
        const allListData = await userManagementService.getAllCollectData(
          patientUser.id
        );
        if (allListData) {
          setAllCollectData(allListData);
        } else {
          setAllCollectData([]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAllCollectData();
  }, [patientUser.id]);
  const sortedAllCollectData = useMemo(() => {
    const sortedData = allCollectData?.sort((a, b) => {
      const dateA = dayjs(a.date);
      const dateB = dayjs(b.date);
      return dateB.diff(dateA);
    });
    return sortedData;
  }, [allCollectData]);
  useEffect(() => {
    let latestTotalFoodIntake = 0;
    for (let record of sortedAllCollectData) {
      if (record.totalCalories > 0) {
        latestTotalFoodIntake = record.totalCalories;
        setLastedFoodItems(record.foodIntake);
        break;
      }
    }
    if (latestTotalFoodIntake === 0) {
      setLastedKcal(0);
      setLastedFoodItems(undefined);
    } else {
      setLastedKcal(latestTotalFoodIntake);
    }
  }, [sortedAllCollectData]);
  const options = {
    chart: {
      type: "pie",
      renderTo: "container",
      height: 220,
      style: {
        fontFamily: "Sarabun",
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.0f}%</b>",
    },
    legend: {
      // align: "left",
      // verticalAlign: "top",
      // layout: "vertical",
      useHTML: true,
      labelFormatter(this: Highcharts.Point): string {
        return `<div><b>${this.percentage?.toFixed(0)}%</b> ${
          this.name
        }                
                </div>`;
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        borderRadius: 8,
        dataLabels: [
          // {
          //   enabled: true,
          //   distance: 20,
          //   format: "{point.name}",
          // },
          {
            enabled: false,
            distance: -15,
            format: "{point.percentage:.0f}%",
            style: {
              fontSize: "0.9em",
            },
          },
        ],
        showInLegend: true,
      },
      pie: {
        size: 120,
      },
    },
    series: [
      {
        type: "pie",
        colorByPoint: true,
        innerSize: "60%",
        data:
          activeTab === 1
            ? lastedFoodItems &&
              calculateNutritionPercentages({ data: lastedFoodItems })
            : calculateAverageNutritionPercentages(allCollectData),
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "NutritionDataExport",
    },
  };
  return (
    <Paper elevation={4} sx={{ borderRadius: 2, height: 320 }}>
      <Box>
        <Box sx={{ p: 2, pb: 0 }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography style={body14400} sx={{ color: "#4D4D4D" }}>
              {activeTab === 1 ? `อาหารที่ทานล่าสุด` : `ค่าเฉลี่ยอาหารที่ทาน`}
            </Typography>
            <Stack direction={"row"}>
              {activeTab === 1 ? (
                <CircleIcon fontSize="small" color="info" />
              ) : (
                <Box onClick={() => setActiveTab(1)} sx={{ cursor: "pointer" }}>
                  <CircleIcon fontSize="small" color="disabled" />
                </Box>
              )}
              {activeTab === 2 ? (
                <CircleIcon fontSize="small" color="info" />
              ) : (
                <Box onClick={() => setActiveTab(2)} sx={{ cursor: "pointer" }}>
                  <CircleIcon fontSize="small" color="disabled" />
                </Box>
              )}
            </Stack>
          </Stack>
          <Typography fontSize={24} fontWeight={700}>
            {activeTab === 1
              ? `${lastedKcal.toLocaleString()} kcal`
              : `${calculateAvgFoodKcal(allCollectData).toLocaleString()} kcal`}
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={500}
            color={"#4D4D4D"}
            display={activeTab === 2 ? "flex" : "none"}
          >
            {`จำนวนวัน: ${allCollectData?.length} วัน`}
          </Typography>
        </Box>

        <div>
          {activeTab === 1 ? (
            lastedKcal > 0 ? (
              <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
              <DataNotFound />
            )
          ) : calculateAvgFoodKcal(allCollectData) > 0 ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            <DataNotFound />
          )}
        </div>
      </Box>
    </Paper>
  );
};
