import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./UserPermission.css"; // Import a CSS file for styling
import AddIcon from "@mui/icons-material/Add";
import UserPermissionFiltering from "../../shared/UserPermissionFiltering";
import editIcon from "../../asset/editIcon.png";
import deleteIcon from "../../asset/deleteIcon.png";
import {
  Box,
  Button,
  CircularProgress,
  createMuiTheme,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Layout } from "../../Layout";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { userManagementService } from "../../service/userManagementService";
import { useEffectOnce } from "react-use";
import { DeleteIcon, EditIcon } from "../../icon";
import { UserPermission } from "../../type";
import { useSelector } from "react-redux";
import { DeleteDialog } from "../../components/dialog/DeleteDialog";

const theme = createMuiTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          "&.Mui-selected": {
            backgroundColor: "#DF5C8E",
            color: "#ffffff",
          },
        },
      },
    },
  },
});

const UserPermissionTab = () => {
  const navigate = useNavigate();
  const tableCellValues = [
    "ลำดับ",
    "ชื่อสิทธิ์การใช้งาน",
    "จำนวนผู้ใช้งาน",
    "สถานะ",
    "เครื่องมือ",
  ];
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [originUserPermissionList, setOriginUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [currentUserPermissionList, setCurrentUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfrimationDialog, setIsShowConfrimationDialog] =
    useState(false);
  const [comfrimToDelete, setComfrimToDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const totalPages = Math.ceil(userPermissionList.length / 10);
  const [isDeleteFailed, setIsDeleteFailed] = useState(false);
  const userDetail = useSelector((state: any) => state.loginUser.userDetail);
  const userPermissionStatus = useSelector(
    (state: any) => state.loginUser.userPermission
  );
  const userId = useSelector((state: any) => state.loginUser.userId);
  const permissionAddAble= userId === "1" ? true : userPermissionStatus?.userPermissionPermission.addAble;
  const permissionDeleteAble =
  userId === "1" ? true : userPermissionStatus?.userPermissionPermission.deleteAble;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);
        setOriginUserPermissionList(userPermissionList);
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
    setIsLoading(true);
    fetchData().then(() => setIsLoading(false));
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCurrentUserPermissionList(
          userPermissionList.slice(
            (currentPage - 1) * usersPerPage,
            currentPage * usersPerPage
          )
        );
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, [currentPage, userPermissionList]);

  const handleSearch = (data: { userPermission: string; status: string }) => {
    const { userPermission, status } = data;

    const filteredUsers = originUserPermissionList.filter((user) => {
      const isPermissionMatch =
        userPermission === "" ? true : user.nameThai.includes(userPermission);

      const isStatusMatch =
        status === ""
          ? true
          : status === (user.permissionActive ? "ใช้งานได้" : "ปิดการใช้งาน");
      return isPermissionMatch && isStatusMatch;
    });
    setUserPermissionList(filteredUsers);
    setCurrentPage(1);
    // setCurrentPage(1);
    // setCurrentUsers(
    //   filteredUsers.slice(
    //     0, usersPerPage
    //   )
    // );
  };

  const handleClearFiltering = () => {
    setUserPermissionList(originUserPermissionList);
    // setCurrentUsers(
    //   originalUserList.slice(
    //     (currentPage - 1) * usersPerPage,
    //     currentPage * usersPerPage
    //   )
    // );
  };

  const handleDelete = (index: number) => {
    setComfrimToDelete(true);
    setIsShowConfrimationDialog(true);
    setDeleteIndex(index);
  };

  const handleDeleteClick = async () => {
    try {
      await userManagementService.deleteUserPermission(deleteIndex.toString());
      const updatedUserPermissionList = [...userPermissionList];
      updatedUserPermissionList.map((userPermission, index) => {
        if (userPermission.id === deleteIndex) {
          updatedUserPermissionList.splice(index, 1);
        }
      });
      setUserPermissionList(updatedUserPermissionList);
      setIsShowConfrimationDialog(false);
    } catch (error) {
      setIsDeleteFailed(true);
    }
  };
  const handleCloseConfirmationDialog = () => {
    setIsShowConfrimationDialog(false);
    setComfrimToDelete(false);
  };

  return (
    <Layout activeTab="userPermission">
      <div className="user-filtering-container">
        <UserPermissionFiltering
          handleSearch={handleSearch}
          handleClearFiltering={handleClearFiltering}
        />
        <Box
          sx={{
            p: 3,
            overflowY: "auto",
            scrollbarWidth: "thin",
            backgroundColor: "white",
            // height: "calc(100vh - 300px)",
            mt: 2,
          }}
        >
          {permissionAddAble && (
            <Button
              variant="contained"
              className="grant-permission-button"
              style={{
                backgroundColor: "#FF366B",
                float: "right",
                fontSize: "15px",
                width: "180px",
                height: "40px",
                marginBottom: "20px",
              }}
              startIcon={
                <AddIcon style={{ color: "white", padding: "0.5px" }} />
              }
              onClick={() => navigate("/addUserPermission")}
            >
              เพิ่มสิทธิ์การใช้งาน
            </Button>
          )}

          <TableContainer component={Paper}>
            <Table className="pink-table-line">
              <TableHead>
                <TableRow className="pink-bottom-line">
                  {tableCellValues.map((value) => (
                    <TableCell style={{ fontSize: "16px" }}>{value}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUserPermissionList.map((webUserRole, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(currentPage - 1) * usersPerPage + index + 1}
                    </TableCell>
                    <TableCell>{webUserRole.nameThai}</TableCell>
                    <TableCell>{webUserRole.count}</TableCell>
                    <TableCell>
                      {webUserRole.permissionActive ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              marginRight: "5px",
                              height: "10px",
                              width: "10px",
                              borderRadius: "50%",
                              backgroundColor: "green",
                            }}
                          />
                          ใช้งานได้
                        </div>
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              marginRight: "5px",
                              height: "10px",
                              width: "10px",
                              borderRadius: "50%",
                              backgroundColor: "red",
                            }}
                          />
                          ปิดการใช้งาน
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <Stack spacing={1} direction={"row"}>
                        <Box
                          sx={{
                            border: 1,
                            borderRadius: 2,
                            borderColor: "#DF5C8E",
                            width: "25px",
                            height: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/grantUserPermission", {
                              state: { webUserRole: webUserRole },
                            })
                          }
                        >
                          <EditIcon
                            fontSize="small"
                            style={{ color: "#DF5C8E" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            border: "1px solid #979797",
                            borderRadius: 2,
                            width: "25px",
                            height: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: permissionDeleteAble ? "pointer" : "not-allowed",
                            pointerEvents: permissionDeleteAble ? "auto" : "none",
                          }}
                          onClick={() => {
                            if (userId === "1" || userPermissionStatus?.userPermissionPermission?.deleteAble !== false) {
                              handleDelete(webUserRole.id)
                            }
                          }}
                        >
                          <DeleteIcon fontSize="small" color="disabled" />
                        </Box>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: isLoading ? "flex" : "none",
              justifyContent: "center",
              fontSize: 20,
              color: "#9AA1AE",
              py: 8,
              border: "1px solid #ddd", // Add this line
              borderRadius: "4px",
            }}
          >
            <CircularProgress />
            <Typography>กำลังโหลดข้อมูล...</Typography>
          </Box>
          {userPermissionList.length === 0 && !isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: 20,
                color: "#9AA1AE",
                py: 2,
                border: "1px solid #ddd", // Add this line
                borderRadius: "4px",
              }}
            >
              --ไม่พบสิทธิ์การใช้งาน--
            </Box>
          )}
          <ThemeProvider theme={theme}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "30px",
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </ThemeProvider>
        </Box>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
      <DeleteDialog
        openDialog={isShowConfrimationDialog}
        title="ยืนยันการลบข้อมูล"
        content="คุณต้องการลบข้อมูลใช่หรือไม่"
        handleCancelClick={handleCloseConfirmationDialog}
        handleCloseDialog={handleCloseConfirmationDialog}
        handleConfirmClick={handleDeleteClick}
      />
      <ErrorDialog
        title="ลบข้อมูลไม่สำเร็จ"
        content="ไม่สามารถลบข้อมูลได้"
        openDialog={isDeleteFailed}
        handleCloseDialog={() => {
          setIsShowConfrimationDialog(false);
          setIsDeleteFailed(false);
        }}
      />
    </Layout>
  );
};

export default UserPermissionTab;
