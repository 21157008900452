import { Grid, Typography } from "@mui/material";
import React from "react";

export const BodyLabRow = ({
  title,
  value,
  detail,
  maximum,
  minimum,
  maximumWarning,
  minimumWarning,
}: {
  title: string | React.ReactNode;
  value?: string | number;
  detail?: string;
  maximum?: number;
  minimum?: number;
  maximumWarning?: boolean;
  minimumWarning?: boolean;
}) => {
  const getColor = () => {
    if (
      value === undefined ||
      maximum === undefined ||
      minimum === undefined ||
      minimumWarning === undefined ||
      maximumWarning === undefined
    ) {
      return "inherit";
    }

    const valueNumber = parseInt(value  as string);
    
    if (maximumWarning && minimumWarning) {
      if (
        valueNumber < minimum ||
        valueNumber > maximum
      ) {
        return "red";
      }
    }
    else if (maximumWarning && valueNumber > maximum) {
      return "red";
    }
    else if (
      minimumWarning && valueNumber < minimum
    ) {
      return "red";
    }

    return "inherit";
  };
  return (
    <Grid container direction="row" sx={{ pb: 1 }}>
      <Grid item xs={6}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{ color: getColor() }}>{value || "-"}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>{detail || "-"}</Typography>
      </Grid>
    </Grid>
  );
};
