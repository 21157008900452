import { addYears, format } from "date-fns";
import { th } from "date-fns/locale";

export const formatDateTh = (dateUtc?: string) => {
  if (!dateUtc) {
    return "";
  }
  const date = new Date(dateUtc);
  const yearTh = addYears(date, 543);
  return format(date, "dd/MM/", { locale: th }) + format(yearTh, "yy HH:mm น.");
};
