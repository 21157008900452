import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  DialogActions,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import infoIcon from "../../../asset/icn.png";
import { useEffect, useMemo, useState } from "react";
import { store } from "../../../redux/store";
import { set } from "date-fns";
import { Info } from "../../../asset/iconSvg";
import { tootlipPlacement } from "../../../style";

interface Hematology {
  Hb: string;
  Hct: string;
  MVC: string;
  Platelet: string;
}

interface HematologyProps extends Hematology {
  open: boolean;
  onClose: (data: Hematology) => void;
  onSave: (data: Hematology) => void;
}

export const HematologyDialog: React.FC<HematologyProps> = ({
  open,
  onClose,
  onSave,
  Hb,
  Hct,
  MVC,
  Platelet,
}) => {
  const initialValues = useMemo(
    () => ({ Hb, Hct, MVC, Platelet }),
    [Hb, Hct, MVC, Platelet]
  );
  const [hematologyValues, setHematologyValues] =
    useState<Hematology>(initialValues);
  const [hematologyValuesTemp, setHematologyValuesTemp] =
    useState<Hematology>(initialValues);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffect(() => {
    setHematologyValues(initialValues);
    setHematologyValuesTemp(initialValues);
  }, [initialValues]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHematologyValues({
      ...hematologyValues,
      [event.target.name]: event.target.value,
    });
  };
  const handleClickSave = () => {
    if(patientDataPermissionEditAble === true){
      onSave(hematologyValues);
    }
    else {
      handleCloseDialog();
    }
  };
  const handleCloseDialog = () => {
    setHematologyValues(hematologyValuesTemp);
    onClose(hematologyValuesTemp);
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Hematology</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Hb {" "}
              <Tooltip
                title="Hemoglobin"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="Hb"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={hematologyValues.Hb}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>g/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Hct {" "}
              <Tooltip
                title="Hematocrit"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="Hct"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={hematologyValues.Hct}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>%</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              MVC {" "}
              <Tooltip
                title="Mean Corpuscular Volume"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="MVC"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={hematologyValues.MVC}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>fl</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Platelet {" "}
              <Tooltip
                title="Platelets Counts"
                placement={tootlipPlacement}
                PopperProps={{
                  sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                }}
              >
                <span>
                  <Info width={15} height={15} color="#A0ABBA" />
                </span>
              </Tooltip>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="Platelet"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "450px" }}
                value={hematologyValues.Platelet}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>x 10^3 /uL</span>
            </div>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};
