import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { store } from "../../../redux/store";
import { CustomFieldItem } from "../../../Pages/MaterData/CustomFieldMaster";

interface CustomLab {
  customLabId: number;
  value: string;
}

interface OthersDialogProps {
  open: boolean;
  onClose: (data: CustomLab[]) => void;
  onSave: (data: CustomLab[]) => void;
  otherValues: CustomLab[];
  customFieldList: CustomFieldItem[];
}

export const OthersDialog: React.FC<OthersDialogProps> = ({
  open,
  onClose,
  onSave,
  otherValues,
  customFieldList,
}) => {
  const initialValues = useMemo(() => otherValues, [otherValues]);
  const [othersValues, setOthersValues] = useState<CustomLab[]>(initialValues);
  const [othersValuesTemp, setOthersValuesTemp] =
    useState<CustomLab[]>(initialValues);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffect(() => {
    setOthersValues(initialValues);
    setOthersValuesTemp(initialValues);
  }, [initialValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setOthersValues((prevValues) =>
      prevValues.map((item) =>
        item.customLabId === id
          ? { ...item, value: event.target.value }
          : item
      )
    );
  };

  const handleClickSave = () => {
    if (patientDataPermissionEditAble === true) {
      onSave(othersValues);
    } else {
      handleCloseDialog();
    }
  };

  const handleCloseDialog = () => {
    setOthersValues(othersValuesTemp);
    onClose(othersValuesTemp);
  };

  const getTitleAndUnit = (id: number) => {
    const data = customFieldList.find((item) => item.id === id);
    if (data) {
      return {
        title: data.name,
        unit: data.measurementUnit,
      };
    }
    return { title: "Unknown", unit: "" };
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Others</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div>
          {othersValues.map((otherValue, index) => {
            const { title, unit } = getTitleAndUnit(otherValue.customLabId);
            return (
              <Grid key={index} style={{ padding: "5px" }}>
                <p style={{ color: "#646D78" }}>{title}</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    name={`customLab-${otherValue.customLabId}`}
                    label="-- โปรดระบุข้อมูล --"
                    variant="outlined"
                    size="small"
                    style={{ width: "450px" }}
                    value={otherValue.value}
                    onChange={(event) => handleChange(event, otherValue.customLabId)}
                  />
                  <span style={{ marginLeft: "10px" }}>{unit}</span>
                </div>
              </Grid>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};
