import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { Info } from "../../../asset/iconSvg";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { tootlipPlacement } from "../../../style";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { LayoutTab } from "../LayoutTab";
import { StatusBottomBar } from "../StatusBottomBar";
import { DemographicHistoryDialog } from "./DemograhicHistoryDialog";
import { DemographicVisitLogDialog } from "./DemograhicVisitLogDialog";

export type DemographicDataResponse = {
  bariatricSurgeryHistoryHnRef: string | null;
  bmiPreOperation: number | null;
  cervicalCancerScreeningHistory: boolean;
  createDateTime: string;
  editorId: number;
  entryId: number;
  height: number | null;
  subjectId: number | null;
  visitDataStatus: number;
  visitDate: string;
  weightPreOperation: number | null;
};

type Props = {
  handleCancelClick: () => void;
};
export const Demographic = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;

  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  const [open, setOpen] = useState(false);
  const [openCancerScreen, setOpenCancerScreen] = useState(false);
  const [height, setHeight] = useState<any>(null);
  const [weight, setWeight] = useState<any>(null);
  const [originalHeight, setOriginalHeight] = useState<any>(null);
  const [originalWeight, setOriginalWeight] = useState<any>(null);
  const [previousHeight, setPreviousHeight] = useState<number | null>(null);
  const [previousWeight, setPreviousWeight] = useState<number | null>(null);
  const [demographicDataWeight, setDemographicDataWeight] = useState<
    string | ""
  >("");
  const [demographicDataHeight, setDemographicDataHeight] =
    useState<string>("");
  const [referToHN, setReferToHN] = useState<string>("");
  const [status, setStatus] = useState(2);
  const [cervicalCancerScreening, setCervicalCancerScreening] = useState<
    boolean | null
  >(null);
  const [cervicalCancerScreeningTemp, setCervicalCancerScreeningTemp] =
    useState<boolean | null>(false);

  const patientUser = useAppSelector((state) => state.patientUser.patientUser);
  const [demographicData, setDemographicData] =
    useState<DemographicDataResponse>();

  const selectedPatientUserProfile = useAppSelector(
    (state) => state.patientUser.selectedPatientUserProfile
  );
  const latestEntryIds = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.demographicDataStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.demographicDataStatus
  );

  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitLogDialog, setOpenVisitLogDialog] = useState(false);
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);
  const calculateAge = (birthDateString: any) => {
    const birthDate = new Date(birthDateString);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }
    const age = `${years} ปี ${months} เดือน ${days} วัน`;
    return age;
  };

  const bmi =
    weight && height ? (weight / (height / 100) ** 2).toFixed(2) : null;

  const bmiForDialog =
    demographicDataWeight && demographicDataHeight
      ? (
          Number(demographicDataWeight) /
          (Number(demographicDataHeight) / 100) ** 2
        ).toFixed(2)
      : null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (currentEntryId) {
      if (demographicDataHeight === "") {
        setDemographicDataHeight(height);
      }
      if (demographicDataWeight === "") {
        setDemographicDataWeight(weight);
      }
    } else if (latestEntryIds) {
      if (demographicDataHeight === "") {
        setDemographicDataHeight(originalHeight?.toString());
      }
      if (demographicDataWeight === "") {
        setDemographicDataWeight(originalWeight?.toString());
      }
    } else if (currentEntryId === null && latestEntryIds === null) {
      if (demographicDataHeight === "") {
        setDemographicDataHeight(previousHeight?.toString() ?? "");
      }
      if (demographicDataWeight === "") {
        setDemographicDataWeight(previousWeight?.toString() ?? "");
      }
      if (showErrorRequiredHeightField || showErrorRequiredWeightField) {
        setDemographicDataHeight("");
        setDemographicDataWeight("");
      }
    }
    setShowErrorRequiredHeightField(false);
    setShowErrorRequiredWeightField(false);
    setOpen(false);
  };

  const [showErrorRequiredHeightField, setShowErrorRequiredHeightField] =
    useState<boolean>(false);
  const [showErrorRequiredWeightField, setShowErrorRequiredWeightField] =
    useState<boolean>(false);
  const [heightIsNotNumber, setHeightIsNotNumber] = useState<boolean>(false);
  const [weightIsNotNumber, setWeightIsNotNumber] = useState<boolean>(false);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState<boolean>(false);

  const handleClickSaveBmi = async () => {
    const heightIsEmpty = demographicDataHeight === "";
    const weightIsEmpty = demographicDataWeight === "";
    const heightIsNotNumber = isNaN(Number(demographicDataHeight));
    const weightIsNotNumber = isNaN(Number(demographicDataWeight));

    setShowErrorRequiredHeightField(heightIsEmpty);
    setShowErrorRequiredWeightField(weightIsEmpty);
    setHeightIsNotNumber(heightIsNotNumber);
    setWeightIsNotNumber(weightIsNotNumber);

    if (
      !heightIsEmpty &&
      !weightIsEmpty &&
      !heightIsNotNumber &&
      !weightIsNotNumber
    ) {
      setHeight(demographicDataHeight);
      setWeight(demographicDataWeight);
      setOpen(false);
      dispatch(updateTabsIsDirty(true));
    } else {
      setOpen(true);
    }
  };
  const handleClickCancerScreenOpen = () => {
    setOpenCancerScreen(true);
  };

  const handleClickCancerScreenClose = () => {
    setCervicalCancerScreeningTemp(cervicalCancerScreening);
    setOpenCancerScreen(false);
    dispatch(updateTabsIsDirty(true));
  };

  const handleClickCancerScreenSave = () => {
    setCervicalCancerScreening(cervicalCancerScreeningTemp);
    setOpenCancerScreen(false);
    dispatch(updateTabsIsDirty(true));
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (height) {
      setPreviousHeight(height);
    }
    setDemographicDataHeight(value);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (weight) {
      setPreviousWeight(weight);
    }
    setDemographicDataWeight(value);
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };

  const handleSubmitData = async () => {
    if (patientDataPermissionEditAble === true) {
      const heightIsEmpty = height === "" || height === null;
      const weightIsEmpty = weight === "" || weight === null;
      const heightIsNotNumber = isNaN(Number(height));
      const weightIsNotNumber = isNaN(Number(weight));

      if (
        !heightIsEmpty &&
        !weightIsEmpty &&
        !heightIsNotNumber &&
        !weightIsNotNumber
      ) {
        await submitDataAsync();
        dispatch(updateTabsIsDirty(false));
      } else {
        setOpenIncompleteDataDialog(true);
      }
    } else {
      setOpenNoPermissionDialog(true);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };
  const handleCloseHistoryDialog = () => {
    // setOpenIncompleteDataDialog(false);
    setOpenHistoryDialog(false);
  };

  const handleCloseVisitLogDialog = () => {
    // setOpenIncompleteDataDialog(false);
    setOpenVisitLogDialog(false);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const demographicData =
          await visitDataService.getDemoGraphicFromEntryId(currentEntryId);
        setDemographicData(demographicData);
        setDemographicDataHeight(demographicData.height);
        setDemographicDataWeight(demographicData.weightPreOperation);
        setCervicalCancerScreening(
          demographicData.cervicalCancerScreeningHistory
        );
        setCervicalCancerScreeningTemp(
          demographicData.cervicalCancerScreeningHistory
        );
        setHeight(demographicData.height);
        setWeight(demographicData.weightPreOperation);
        setReferToHN(demographicData.bariatricSurgeryHistoryHnRef || "");
        setStatus(demographicData.visitDataStatus);
      } else if (latestEntryIds) {
        const demographicData =
          await visitDataService.getDemoGraphicFromEntryId(latestEntryIds);
        setDemographicData(demographicData);
        setDemographicDataHeight(demographicData.height);
        setOriginalHeight(demographicData.height);
        setOriginalWeight(demographicData.weightPreOperation);
        setCervicalCancerScreening(null);
        setDemographicDataWeight(demographicData.weightPreOperation);
        setReferToHN(demographicData.bariatricSurgeryHistoryHnRef || "");
      }
    };
    fetchData();
  });

  useEffect(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const demographicData =
          await visitDataService.getDemoGraphicFromEntryId(currentEntryId);
        setDemographicData(demographicData);
        setDemographicDataHeight(demographicData.height);
        setDemographicDataWeight(demographicData.weightPreOperation);
        setCervicalCancerScreening(
          demographicData.cervicalCancerScreeningHistory
        );
        setCervicalCancerScreeningTemp(
          demographicData.cervicalCancerScreeningHistory
        );
        setHeight(demographicData.height);
        setWeight(demographicData.weightPreOperation);
        setReferToHN(demographicData.bariatricSurgeryHistoryHnRef || "");
        setStatus(demographicData.visitDataStatus);
      } else if (latestEntryIds) {
        const demographicData =
          await visitDataService.getDemoGraphicFromEntryId(latestEntryIds);
        setDemographicData(demographicData);
        setDemographicDataHeight(demographicData.height);
        setOriginalHeight(demographicData.height);
        setOriginalWeight(demographicData.weightPreOperation);
        setCervicalCancerScreening(null);
        setDemographicDataWeight(demographicData.weightPreOperation);
        setReferToHN(demographicData.bariatricSurgeryHistoryHnRef || "");
      }
    };
    fetchData();
  }, [openNoPermissionDialog]);

  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveDemoGraphicData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        bariatricSurgeryHistoryHnRef: referToHN,
        height: height,
        weightPreOperation: weight,
        bmiPreOperation: bmi,
        cervicalCancerScreeningHistory: cervicalCancerScreening,
      });

      return response as DemographicDataResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          demographicDataStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      setOpenIncompleteDataDialog(true);
      console.log(error);
    },
  });

  const handleCloseErrorDialog = () => {
    setOpenIncompleteDataDialog(false);
    setOpenNoPermissionDialog(false);
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitLogDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Grid container columnGap={3} sx={{ my: 2 }} alignItems="stretch">
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <p style={{ color: "#646D78" }}>
                HN{" "}
                <Tooltip
                  title="หมายเลขของผู้ป่วยนอก (Hospital Number)"
                  placement={tootlipPlacement}
                  PopperProps={{
                    sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                  }}
                >
                  <span>
                    <Info width={15} height={15} color="#A0ABBA" />
                  </span>
                </Tooltip>
              </p>
              <Typography style={{ color: "#000000" }}>
                {patientUser?.hn ?? "-"}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={2}>
              <p style={{ color: "#646D78" }}>
                Sex{" "}
                <Tooltip
                  title="เพศของผู้ป่วย"
                  placement={tootlipPlacement}
                  PopperProps={{
                    sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                  }}
                >
                  <span>
                    <Info width={15} height={15} color="#A0ABBA" />
                  </span>
                </Tooltip>
              </p>
              <Typography style={{ color: "#000000" }}>
                {selectedPatientUserProfile?.sex}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <p style={{ color: "#646D78" }}>
                วันเกิด{" "}
                <Tooltip
                  title="วันเดือนปีเกิด (พ.ศ.) ของผู้ป่วย"
                  placement={tootlipPlacement}
                  PopperProps={{
                    sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                  }}
                >
                  <span>
                    <Info width={15} height={15} color="#A0ABBA" />
                  </span>
                </Tooltip>
              </p>
              <Typography style={{ color: "#000000" }}>
                {dayjs(selectedPatientUserProfile?.birthDate).format(
                  "DD/MM/BBBB"
                )}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <p style={{ color: "#646D78" }}>อายุ</p>
              <Typography style={{ color: "#098484" }}>
                {calculateAge(selectedPatientUserProfile?.birthDate)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container columnGap={3} sx={{ my: 2 }} alignItems="stretch">
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <p style={{ color: "#646D78" }}>
                Previous Bariatric Surgery{" "}
                <Tooltip
                  title="การเคยผ่าตัดกระเพาะอาหารเพื่อลดน้ำหนัก"
                  placement={tootlipPlacement}
                  PopperProps={{
                    sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                  }}
                >
                  <span>
                    <Info width={15} height={15} color="#A0ABBA" />
                  </span>
                </Tooltip>
              </p>
              <Typography style={{ color: "#000000" }}>
                {selectedPatientUserProfile?.bariatricSurgeryHistory
                  ? "Yes"
                  : "No"}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={2.5}>
              <p style={{ color: "#646D78" }}>Refer to HN</p>
              <TextField
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "296px" }}
                value={referToHN}
                onChange={(event) => {
                  setReferToHN(event.target.value);
                  dispatch(updateTabsIsDirty(true));
                }}
              />
            </Grid>
          </Grid>

          <p style={{ borderBottom: "1px solid #E3E7F0", padding: "10px" }}></p>
          <Grid container direction="row" spacing={5}>
            <Grid item>
              <Box
                height={200}
                width={350}
                my={4}
                alignItems="center"
                gap={4}
                sx={{ border: "2px solid #D8D8D8" }}
              >
                <Typography
                  style={{ padding: "10px", color: "#098484", float: "left" }}
                >
                  Body weight/BMI (Current visit)
                </Typography>
                <Typography
                  style={{
                    padding: "10px",
                    color: "#646D78",
                    float: "right",
                    cursor: "pointer",
                  }}
                  onClick={handleClickOpen}
                >
                  แก้ไข
                </Typography>
                <Divider sx={{ width: "100%" }} />
                <Box sx={{ px: 1 }}>
                  <Typography style={{ padding: "5px" }}>
                    ส่วนสูง (Height){" "}
                    <span style={{ paddingLeft: "80px", color: "#646D78" }}>
                      {height ? height : "-"}
                    </span>
                  </Typography>
                  <Typography style={{ padding: "5px" }}>
                    น้ำหนัก (Weight pre-op){" "}
                    <span style={{ paddingLeft: "23px", color: "#646D78" }}>
                      {weight ? weight : "-"}
                    </span>
                  </Typography>
                  <Typography style={{ padding: "5px" }}>
                    BMI{" "}
                    <span
                      style={{
                        paddingLeft: "158px",
                        color: bmi ? "#098484" : "#646D78",
                      }}
                    >
                      {bmi ? bmi : "Auto calcuate"}
                    </span>{" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box
                height={200}
                width={350}
                my={4}
                alignItems="center"
                gap={4}
                sx={{ border: "2px solid #D8D8D8" }}
              >
                <Typography
                  style={{ padding: "10px", color: "#098484", float: "left" }}
                >
                  Cancer Screen
                </Typography>
                <Typography
                  style={{
                    padding: "10px",
                    color: "#646D78",
                    float: "right",
                    cursor: "pointer",
                  }}
                  onClick={handleClickCancerScreenOpen}
                >
                  แก้ไข
                </Typography>
                <Divider sx={{ width: "100%" }} />
                <Box sx={{ px: 1 }}>
                  <Typography style={{ padding: "5px" }}>
                    Cervical cancer screening{" "}
                    <span style={{ paddingLeft: "23px" }}>
                      {cervicalCancerScreening === true
                        ? "Yes"
                        : cervicalCancerScreening === false
                        ? "No"
                        : "-"}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Body weight/BMI (Current visit)</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <div>
              <Grid style={{ padding: "10px" }}>
                <p style={{ color: "#646D78" }}>
                  ส่วนสูง (Height) <span style={{ color: "red" }}>*</span>{" "}
                  <Tooltip
                    title="ส่วนสูง (cm)"
                    placement={tootlipPlacement}
                    PopperProps={{
                      sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                    }}
                  >
                    <span>
                      <Info width={15} height={15} color="#A0ABBA" />
                    </span>
                  </Tooltip>
                </p>
                <TextField
                  variant="outlined"
                  size="small"
                  style={{ width: "500px" }}
                  onChange={handleHeightChange}
                  value={demographicDataHeight}
                  error={showErrorRequiredHeightField || heightIsNotNumber}
                />
                {showErrorRequiredHeightField ? (
                  <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
                ) : heightIsNotNumber ? (
                  <FormHelperText error>
                    โปรดระบุข้อมูลที่เป็นตัวเลข
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid style={{ padding: "10px" }}>
                <p style={{ color: "#646D78" }}>
                  น้ำหนัก (Weight pre-op){" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                  <Tooltip
                    title="น้ำหนักผู้ป่วยก่อนผ่าตัด (kg)"
                    placement={tootlipPlacement}
                    PopperProps={{
                      sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
                    }}
                  >
                    <span>
                      <Info width={15} height={15} color="#A0ABBA" />
                    </span>
                  </Tooltip>
                </p>
                <TextField
                  variant="outlined"
                  size="small"
                  style={{ width: "500px" }}
                  onChange={handleWeightChange}
                  value={demographicDataWeight}
                  error={showErrorRequiredWeightField || weightIsNotNumber}
                />
                {showErrorRequiredWeightField ? (
                  <FormHelperText error>โปรดระบุข้อมูล</FormHelperText>
                ) : weightIsNotNumber ? (
                  <FormHelperText error>
                    โปรดระบุข้อมูลที่เป็นตัวเลข
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid style={{ padding: "10px" }}>
                <Typography style={{ padding: "5px" }}>
                  BMI <span style={{ color: "#098484" }}>{bmiForDialog}</span>
                </Typography>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{ backgroundColor: "#7A8688", color: "white" }}
            >
              ยกเลิก
            </Button>
            <Button
              onClick={handleClickSaveBmi}
              style={{ backgroundColor: "#098484", color: "white" }}
            >
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openCancerScreen} onClose={handleClickCancerScreenClose}>
          <DialogTitle>Cancer Screen</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <div>
              <Grid item>
                <p style={{ color: "#646D78" }}>Cervical cancer screening</p>
                <FormControlLabel
                  value={cervicalCancerScreening}
                  control={
                    <Radio
                      checked={cervicalCancerScreeningTemp === true}
                      onChange={() => setCervicalCancerScreeningTemp(true)}
                      color="default"
                      style={{ color: "#098484" }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={cervicalCancerScreening}
                  control={
                    <Radio
                      checked={cervicalCancerScreeningTemp === false}
                      onChange={() => setCervicalCancerScreeningTemp(false)}
                      color="default"
                      style={{ color: "#098484" }}
                    />
                  }
                  label="No"
                />
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClickCancerScreenClose}
              style={{ backgroundColor: "#7A8688", color: "white" }}
            >
              ยกเลิก
            </Button>
            <Button
              onClick={handleClickCancerScreenSave}
              style={{ backgroundColor: "#098484", color: "white" }}
            >
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <WarningDialog
        openDialog={openIncompleteDataDialog}
        title="ข้อมูลไม่ครบถ้วน"
        content="กรุณาระบุข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน"
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
      <DemographicHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseHistoryDialog}
      />
      <DemographicVisitLogDialog
        open={openVisitLogDialog}
        handleCloseDialog={handleCloseVisitLogDialog}
      />
    </LayoutTab>
  );
};
