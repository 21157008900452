import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import { body14400, profileTitle } from "../../../style";
import { theme2 } from "../../../theme";
import { UserDetail } from "../../../Pages/UserProfile/UserProfile";
import { formatDateTh } from "../../../utils/date-utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
type Props = {
  userDetail: UserDetail;
  openDialog: boolean;
  handleCloseDialog: () => void;
};
export default function UserInformationDialog({
  openDialog,
  userDetail,
  handleCloseDialog,
}: Props) {
  return (
    <ThemeProvider theme={theme2}>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="profile-dialog-title"
        open={openDialog}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, py: 1, px: 2, bgcolor: "#F7F8FC" }}
          id="profile-dialog-title"
        >
          <Typography fontSize={18} fontWeight={500}>
            รายละเอียด
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={3} sx={{ pb: 6 }}>
            <Stack spacing={1}>
              <Typography component="label" style={profileTitle}>
                สังกัด
              </Typography>
              <Typography component="label" style={body14400} color={"#212121"}>
                {userDetail.affiliatedHospital}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography component="label" style={profileTitle}>
                ตำแหน่ง
              </Typography>
              <Typography component="label" style={body14400} color={"#212121"}>
                {userDetail.jobPosition}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography component="label" style={profileTitle}>
                Email Address
              </Typography>
              <Typography component="label" style={body14400} color={"#212121"}>
                {userDetail.email}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography component="label" style={profileTitle}>
                วันที่สมัครใช้งาน
              </Typography>
              <Typography component="label" style={body14400} color={"#212121"}>
                {formatDateTh(userDetail.registrationDate)}
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography component="label" style={profileTitle}>
                วันและเวลาที่อนุมัติ
              </Typography>
              <Typography component="label" style={body14400} color={"#212121"}>
                {formatDateTh(userDetail.approvalDate)}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              bgcolor: "white",
              height: "45px",
              display: "flex",
              justifyContent: "flex-end",
              width: "95%",
              pt: 1,
              borderTop: "1px solid #E3E7F0",
            }}
          >
            <Stack direction={"row"} spacing={1} height={35} sx={{ pr: 2 }}>
              <Button
                variant="contained"
                size="small"
                onClick={handleCloseDialog}
                sx={{
                  width: 100,
                  bgcolor: "#7A8688",
                  "&:hover": {
                    bgcolor: "#7A8688",
                  },
                }}
              >
                ปิด
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  );
}
