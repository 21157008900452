import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import check from "../../asset/check.png";
import { CancelIcon } from "../../icon";
import { DialogStyle } from "../../style";

type Props = {
  title: string;
  content: string;
  content2?: string;
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
};
export const DeleteDialog = ({
  title,
  content,
  content2,
  openDialog,
  handleCloseDialog,
  handleCancelClick,
  handleConfirmClick,
}: Props) => {
  return (
    <Dialog
      onClose={handleCloseDialog}
      open={openDialog}
      fullWidth={true}
      maxWidth={"xs"}
      {...DialogStyle}
    >
      <DialogTitle align="center">
        <CancelIcon color="error" sx={{ fontSize: 64 }} />
        <Typography fontWeight={700} fontSize={20}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          textAlign: "center",
        }}
      >
        <Stack>
          <Typography component={"span"} variant="body2" color={"#757575"}>
            {content}
          </Typography>
          {content2 && (
            <Typography variant="body2" color={"#757575"}>
              {content2}
            </Typography>
          )}
        </Stack>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            width: 1,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: "#098484",
              border: "1px solid #098484",
              "&:hover": {
                border: "1px solid #098484",
                bgcolor: "#fff",
              },
              width: "50%",
            }}
            onClick={handleCancelClick}
          >
            ยกเลิก
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#098484",
              "&:hover": {
                bgcolor: "#098484",
              },
              width: "50%",
            }}
            onClick={handleConfirmClick}
          >
            ลบ
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
