import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import {
  RadioGreyBox,
  TextGreyBox,
  TextWhiteBox,
  YesNoBox,
} from "../../../components/TextBox";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { title10Tab } from "../../../style";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { LayoutTab } from "../LayoutTab";
import { StatusBottomBar } from "../StatusBottomBar";
import { OtherAdd, OtherArrow, OtherRemove } from "../../../asset/iconSvg";
import { ComorbiditiesAndComplicationsHistoryDialog } from "./ComorbiditiesAndComplicationsHistoryDialog";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { masterDataService } from "../../../service/masterDataService";
import {
  ComplicationItem,
  ComplicationItem2,
} from "../../../Pages/MaterData/ComplicationMaster";
import { ComorbiditiesAndComplicationsVisitLogDialog } from "./ComorbiditiesAndComplicationsVisitLogDialog";

interface RadioGroupsState {
  [key: string]: string;
}

export type ComorbiditiesResponse = {
  entryId: number;
  editorId: string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  ht: boolean;
  dm: boolean;
  dlp: boolean;
  nafld: boolean;
  cva: boolean;
  cvd: boolean;
  gallstones: boolean;
  pcos: boolean;
  kneeOsteoarthritis: boolean;
  spondylosis: boolean;
  gerd: boolean;
  psych: boolean;
  caco: boolean;
  osa: boolean;
  other: boolean;
  otherText: string[];
  cpap: boolean;
};

export type NewComorbiditiesResponse = {
  entryId: number;
  editorId: string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  ht: boolean;
  dm: boolean;
  dlp: boolean;
  nafld: boolean;
  cva: boolean;
  cvd: boolean;
  gallstones: boolean;
  pcos: boolean;
  kneeOsteoarthritis: boolean;
  spondylosis: boolean;
  gerd: boolean;
  psych: boolean;
  caco: boolean;
  osa: boolean;
  other: boolean;
  otherText: string[];
  cpap: boolean;
  comorbiditiesAndComplicationList: ComplicationItem[];
};

export type NewComorbiditiesResponse2 = {
  entryId: number;
  editorId: string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  ht: boolean;
  dm: boolean;
  dlp: boolean;
  nafld: boolean;
  cva: boolean;
  cvd: boolean;
  gallstones: boolean;
  pcos: boolean;
  kneeOsteoarthritis: boolean;
  spondylosis: boolean;
  gerd: boolean;
  psych: boolean;
  caco: boolean;
  osa: boolean;
  other: boolean;
  otherText: string[];
  cpap: boolean;
  comorbiditiesAndComplicationList: ComplicationItem2[];
};

type Props = {
  handleCancelClick: () => void;
};

export const ComorbiditiesAndComplications = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;

  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);
  const [status, setStatus] = useState(2);
  const [otherDetails, setOtherDetails] = useState([""]);
  const [validationError, setValidationError] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openVisitLogDialog, setOpenVisitLogDialog] = useState(false);
  const latestEntryId = useAppSelector(
    (state) =>
      state.patientUser.latestEntryIds?.comorbiditiesAndConditionsStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.comorbiditiesAndConditionsStatus
  );
  const [radioGroups, setRadioGroups] = useState<RadioGroupsState>({});
  const [
    comorbiditiesAndComplicationList,
    setComorbiditiesAndComplicationList,
  ] = useState<ComplicationItem[]>([]);

  useEffectOnce(() => {
    const fetchData = async () => {
      const commorbiditiesAndComplicationList =
        await masterDataService.getActiveComplicationMasterData();
      setComorbiditiesAndComplicationList(commorbiditiesAndComplicationList);
    };
    fetchData();
  });

  useEffect(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const comorbiditiesData: NewComorbiditiesResponse2 =
          await visitDataService.getComorbiditiesFromEntryId(currentEntryId);
        const initialRadioGroups: RadioGroupsState = {};
        comorbiditiesData.comorbiditiesAndComplicationList.forEach((item) => {
          initialRadioGroups[item.id] = item.value ? "yes" : "no";
        });
        initialRadioGroups.other = comorbiditiesData.other ? "yes" : "no";
        setRadioGroups(initialRadioGroups);
        setStatus(comorbiditiesData.visitDataStatus);
        setOtherDetails(comorbiditiesData.otherText ?? [""]);
      } else if (latestEntryId) {
        const comorbiditiesData: NewComorbiditiesResponse2 =
          await visitDataService.getComorbiditiesFromEntryId(latestEntryId);
        const initialRadioGroups: RadioGroupsState = {};
        comorbiditiesData.comorbiditiesAndComplicationList.forEach((item) => {
          initialRadioGroups[item.id] = item.value ? "yes" : "no";
        });
        initialRadioGroups.other = comorbiditiesData.other ? "yes" : "no";

        setRadioGroups(initialRadioGroups);
        setStatus(comorbiditiesData.visitDataStatus);
        setOtherDetails(comorbiditiesData.otherText ?? [""]);
      } else {
        const initialRadioGroups: RadioGroupsState = {};
        comorbiditiesAndComplicationList.forEach((item) => {
          initialRadioGroups[item.id] = "no";
        });
        initialRadioGroups.other = "no";
        setRadioGroups(initialRadioGroups);
        setStatus(2);
        setOtherDetails([""]);
      }
    };
    fetchData();
  }, [openNoPermissionDialog, currentEntryId, latestEntryId]);

  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const selectedIds = comorbiditiesAndComplicationList
        .filter((item) => radioGroups[item.id] === "yes")
        .map((item) => item.id);

      const payload = {
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        comorbiditiesAndComplicationList: selectedIds,
        other: radioGroups.other === "yes" ? true : false,
        otherText: otherDetails,
      };

      const response = await visitDataService.saveComorbiditiesData(payload);
      return response as ComorbiditiesResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          comorbiditiesAndConditionsStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRadioGroups((prev) => ({
      ...prev,
      [name]: value,
    }));
    dispatch(updateTabsIsDirty(true));
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };

  const handleSubmitData = async () => {
    if (!patientDataPermissionEditAble) {
      setOpenNoPermissionDialog(true);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    if (radioGroups.other !== "no") {
      if (validateOtherRequiredFields()) {
        setValidationError(false);
        await submitDataAsync();
        dispatch(updateTabsIsDirty(false));
      } else {
        setValidationError(true);
      }
    } else {
      await submitDataAsync();
      setValidationError(false);
      dispatch(updateTabsIsDirty(false));
    }
  };

  const handleCloseDialog = () => {
    setOpenSubmitDialog(false);
    setOpenHistoryDialog(false);
    setOpenVisitLogDialog(false);
  };

  const handleOtherAdd = () => {
    setOtherDetails((prevDetails) => [...prevDetails, ""]);
    dispatch(updateTabsIsDirty(true));
  };

  const handleOtherRemove = (index: number) => {
    if (otherDetails.length !== 1) {
      const newDetails = otherDetails.filter((_, i) => i !== index);
      setOtherDetails(newDetails);
      dispatch(updateTabsIsDirty(true));
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenNoPermissionDialog(false);
  };

  const handleOtherChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDetails = [...otherDetails];
    newDetails[index] = event.target.value;
    setOtherDetails(newDetails);
    dispatch(updateTabsIsDirty(true));
  };

  const validateOtherRequiredFields = () => {
    for (let detail of otherDetails) {
      if (!detail.trim()) {
        return false;
      }
    }
    return true;
  };

  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => setOpenVisitLogDialog(true)}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Box
            width={1}
            my={2}
            alignItems="center"
            gap={4}
            sx={{ border: "2px solid #D8D8D8" }}
          >
            <Box
              sx={{
                px: 2,
                py: 1,
                borderBottom: "2px solid #D8D8D8",
              }}
            >
              <Typography style={title10Tab}>
                ประวัติโรคร่วมและโรคแทรกซ้อน
              </Typography>
            </Box>
            <div style={{ padding: "20px", width: "100%" }}>
              <Grid container direction="row" columnSpacing={4} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Stack spacing={2}>
                    <Stack spacing={1} direction={"row"}>
                      <TextWhiteBox>{``}</TextWhiteBox>
                      <YesNoBox />
                    </Stack>
                    {comorbiditiesAndComplicationList.map((item, index) => (
                      <Stack key={index} spacing={1} direction={"row"}>
                        <TextGreyBox>{`${item.name} (${item.shortName})`}</TextGreyBox>
                        <RadioGreyBox>
                          <RadioGroup
                            row
                            aria-label={`${item.name} (${item.shortName})`}
                            name={item.id.toString()}
                            value={radioGroups[item.id] || "no"}
                            onChange={handleChange}
                            sx={{
                              width: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Radio value="yes" style={{ color: "#098484" }} />
                            <Radio value="no" style={{ color: "#098484" }} />
                          </RadioGroup>
                        </RadioGreyBox>
                      </Stack>
                    ))}

                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคอื่นๆ</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="other"
                          name="other"
                          value={radioGroups.other || "no"}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    {radioGroups.other === "yes" && (
                      <Stack spacing={1}>
                        {otherDetails.map((detail, index) => {
                          return (
                            <Stack
                              key={index}
                              spacing={1}
                              direction={"row"}
                              // alignItems={"center"}
                            >
                              <Box sx={{ pt: 1 }}>
                                <OtherArrow />
                              </Box>

                              <TextField
                                required
                                sx={{ width: "62%" }}
                                value={detail}
                                onChange={(e) => handleOtherChange(index, e)}
                                error={validationError && !detail.trim()}
                                helperText={
                                  validationError && !detail.trim()
                                    ? "This field is required"
                                    : ""
                                }
                              />
                              <Box
                                sx={{ pt: 0.5 }}
                                onClick={() => handleOtherRemove(index)}
                              >
                                <OtherRemove />
                              </Box>
                              <Box sx={{ pt: 0.5 }} onClick={handleOtherAdd}>
                                <OtherAdd />
                              </Box>
                            </Stack>
                          );
                        })}
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </Box>
          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Box>
      <ComorbiditiesAndComplicationsHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <ComorbiditiesAndComplicationsVisitLogDialog
        open={openVisitLogDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
    </LayoutTab>
  );
};
