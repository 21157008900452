export const generateImageUrlFromDocumentData = (
  documentData: number[],
  documentType: string
) => {
  if (documentData) {
    const byteArray = new Uint8Array(documentData);
    const binaryString = byteArray.reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    );
    const base64String = btoa(binaryString);

    return `data:${documentType};base64,${base64String}`;
  } else return null;
};

export const generateImageUrlFromBase64 = (
  documentType: string,
  base64String: string
) => {
  return `data:${documentType};base64,${base64String}`;
};

export const convertBase64ToBytes = (base64String: string): number[] => {
  const binaryString = atob(base64String);
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return Array.from(byteArray);
};
