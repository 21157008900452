import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  Divider,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SelectChangeEvent,
  Checkbox,
} from "@mui/material";
import infoIcon from "../../../asset/icn.png";
import { useEffect, useState } from "react";
import { BariaticSurgeryData, EarlyComplicationType } from "../../../type";
import dayjs from "dayjs";
import { visitDataService } from "../../../service/visitDataService";
import React from "react";
import { updateTabsIsDirty } from "../../../redux/features/patientSlice";
import { useAppDispatch } from "../../../redux/hook";

interface BariatricSurgeryDialogViewMode {
  openViewMode: boolean;
  onClose: () => void;
  currentEntryId: number | null | undefined;
  selectedEntryId: number | null;
  viewBariatricSurgeryData: BariaticSurgeryData | null;
}

export const BariatricSurgeryDialogViewMode: React.FC<
  BariatricSurgeryDialogViewMode
> = ({
  openViewMode,
  onClose,
  currentEntryId,
  selectedEntryId,
  viewBariatricSurgeryData,
}) => {
  const dispatch = useAppDispatch();
  const [bariatricSurgeryData, setBariatricSurgeryData] =
    useState<BariaticSurgeryData>({
      entryId: null,
      editorId: null,
      createDateTime: "",
      visitDate: "",
      visitDataStatus: null,
      surgeryDate: "",
      operationTime: null,
      acuteWeightLoss: false,
      acuteWeightLossType: "",
      acuteWeightLossDay: null,
      preAwlWeight: null,
      postAwlWeight: null,
      surgicalTechnique: "",
      operationType: "",
      operationTypeOtherText: "",
      operationLocation: [],
      bougieSize: "",
      bpLimb: "",
      rouxLimb: "",
      ebl: "",
      subsequentOperation: {
        laparoscopicCholecystectomy: false,
        laparoscopicHiatalHerniaRepair: false,
        other: false,
      },
      subsequentOperationOther: [],
      earlyComplication: false,
      earlyComplicationType: {
        leakage: false,
        surgicalSiteInfection: false,
        obstruction: false,
        cardiovascularEvents: false,
        bleeding: false,
        pulmonaryComplication: false,
        vte: false,
        other: false,
      },
      earlyComplicationTypeOtherText: "",
      treatment: [],
      reOperation: false,
    });

  const surgericalTypeList1 = [
    "Roux-en-Y gastric bypass (RYGB)",
    "Sleeve-DJB (Roux-en-Y)",
    "Sleeve-PJB",
    "Gastric Banding",
    "Endoscopic Sleeve Gastroplasty",
  ];

  const surgericalTypeList2 = [
    "Sleeve Gastrectomy (SG)",
    "Sleeve-DJB (Loop)",
    "SADI-S",
    "Intragastric Balloon",
    "อื่นๆ",
  ];

  const sizeList = [
    { label: "Bougie size", value: "bougieSize", icon: infoIcon },
    { label: "BP limb (cm)", value: "bpLimb", icon: infoIcon },
    { label: "Roux limb (cm)", value: "rouxLimb", icon: infoIcon },
    { label: "EBL(mL)", value: "ebl", icon: infoIcon, required: true },
  ];

  const subsequentOperationList = [
    {
      label: "Laparoscopic cholecystectomy",
      value: "laparoscopicCholecystectomy",
    },
    {
      label: "Laparoscopic hiatal hernia repair",
      value: "laparoscopicHiatalHerniaRepair",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  const positionOfSurgerry = [
    {
      surgicalType: "Roux-en-Y gastric bypass (RYGB)",
      position: [
        {
          id: 1,
          label: "GJ anastomosis",
        },
        {
          id: 2,
          label: "JJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "Sleeve-DJB (Roux-en-Y)",
      position: [
        {
          id: 1,
          label: "DJ anastomosis",
        },
        {
          id: 2,
          label: "JJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "Sleeve-DJB (Loop)",
      position: [
        {
          id: 1,
          label: "DJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "SADI-S",
      position: [
        {
          id: 1,
          label: "DJ anastomosis",
        },
      ],
    },
    {
      surgicalType: "Intragastric Balloon",
      position: null,
    },
    {
      surgicalType: "Sleeve Gastrectomy (SG)",
      position: null,
    },
    {
      surgicalType: "Sleeve-PJB",
      position: [
        {
          id: 1,
          label: "JJ amastomosis",
        },
      ],
    },
    {
      surgicalType: "Gastric Banding",
      position: null,
    },
    {
      surgicalType: "Endoscopic Sleeve Gastroplasty",
      position: null,
    },
    {
      surgicalType: "others",
      position: null,
    },
  ];

  const techniqueList = [
    { label: "Linear", value: "Linear" },
    { label: "Hand sewn", value: "Hand sewn" },
    { label: "Circular", value: "Circular" },
  ];

  const positionOfSurgerryTable = ["ลำดับ", "ตำแหน่ง", "Technique"];

  const earlyComplicationsTypeList = [
    {
      label: "Leakage",
      value: "leakage",
    },
    {
      label: "Surgical Site Infection",
      value: "surgicalSiteInfection",
    },
    {
      label: "Obstruction",
      value: "obstruction",
    },
    {
      label: "Cardiovascular Events",
      value: "cardiovascularEvents",
    },
  ];

  const earlyComplicationsTypeList2 = [
    {
      label: "Bleeding",
      value: "bleeding",
    },
    {
      label: "Pulmonary Complication",
      value: "pulmonaryComplication",
    },
    {
      label: "VTE",
      value: "vte",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBariatricSurgeryData({
      ...bariatricSurgeryData,
      [event.target.name]: event.target.value,
    });
  };

  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const handleDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setBariatricSurgeryData((prevState) => ({
      ...prevState,
      surgeryDate: formattedDate,
    }));
    setSelectedDate(formattedDate);
  };
  const handleClickSave = () => {
    dispatch(updateTabsIsDirty(true));
  };
  const handleCloseDialog = () => {
    onClose();
  };

  const isShowPositionOfSurgerry = () => {
    return (
      bariatricSurgeryData.operationType !== "Intragastric Balloon" &&
      bariatricSurgeryData.operationType !== "Sleeve Gastrectomy (SG)" &&
      bariatricSurgeryData.operationType !== "Gastric Banding" &&
      bariatricSurgeryData.operationType !== "อื่นๆ" &&
      bariatricSurgeryData.operationType !== "Endoscopic Sleeve Gastroplasty"
    );
  };

  const isEntrySelected =
    selectedEntryId !== null || viewBariatricSurgeryData !== null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedEntryId) {
          const responseBariatricSurgery =
            await visitDataService.getBariatricSurgeryFromEntryId(
              selectedEntryId
            );
          setBariatricSurgeryData({
            entryId: responseBariatricSurgery.entryId,
            editorId: responseBariatricSurgery.editorId,
            createDateTime: responseBariatricSurgery.createDateTime,
            visitDate: responseBariatricSurgery.visitDate,
            visitDataStatus: responseBariatricSurgery.visitDataStatus,
            surgeryDate: responseBariatricSurgery.surgeryDate,
            operationTime: responseBariatricSurgery.operationTime,
            acuteWeightLoss: responseBariatricSurgery.acuteWeightLoss,
            acuteWeightLossType: responseBariatricSurgery.acuteWeightLossType,
            acuteWeightLossDay: responseBariatricSurgery.acuteWeightLossDay,
            preAwlWeight: responseBariatricSurgery.preAwlWeight,
            postAwlWeight: responseBariatricSurgery.postAwlWeight,
            surgicalTechnique: responseBariatricSurgery.surgicalTechnique,
            operationType: responseBariatricSurgery.operationType,
            operationTypeOtherText:
              responseBariatricSurgery.operationTypeOtherText,
            operationLocation: responseBariatricSurgery.operationLocation,
            bougieSize: responseBariatricSurgery.bougieSize,
            bpLimb: responseBariatricSurgery.bpLimb,
            rouxLimb: responseBariatricSurgery.rouxLimb,
            ebl: responseBariatricSurgery.ebl,
            subsequentOperation:
              responseBariatricSurgery.subsequentOperation === null
                ? {
                    laparoscopicCholecystectomy: false,
                    laparoscopicHiatalHerniaRepair: false,
                    other: false,
                  }
                : responseBariatricSurgery.subsequentOperation,
            subsequentOperationOther:
              responseBariatricSurgery.subsequentOperationOther,
            earlyComplication: responseBariatricSurgery.earlyComplication,
            earlyComplicationType:
              responseBariatricSurgery.earlyComplicationType === null
                ? {
                    leakage: false,
                    surgicalSiteInfection: false,
                    obstruction: false,
                    cardiovascularEvents: false,
                    bleeding: false,
                    pulmonaryComplication: false,
                    vte: false,
                    other: false,
                  }
                : responseBariatricSurgery.earlyComplicationType,
            earlyComplicationTypeOtherText:
              responseBariatricSurgery.earlyComplicationTypeOtherText,
            treatment: responseBariatricSurgery.treatment,
            reOperation: responseBariatricSurgery.reOperation,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [selectedEntryId]);

  useEffect(() => {
    if (selectedEntryId === null) {
      setBariatricSurgeryData((prevState) => ({
        ...prevState,
        acuteWeightLoss: false,
      }));
    }
  }, [selectedEntryId]);

  useEffect(() => {
    const initialSurgicalType = positionOfSurgerry.find(
      (item) => item.surgicalType === bariatricSurgeryData.operationType
    );

    // if (initialSurgicalType && initialSurgicalType.position) {
    //   const initialOperationLocations = initialSurgicalType.position.map(
    //     (position) => ({
    //       location: position.label,
    //       technique: "",
    //       techniqueDetail: "",
    //     })
    //   );
    //   setBariaticSurgeryData((prevState) => ({
    //     ...prevState,
    //     operationLocation: initialOperationLocations,
    //   }));
    // }
    if (initialSurgicalType && initialSurgicalType.position) {
      setBariatricSurgeryData((prevState) => {
        const existingOperationLocations = prevState.operationLocation || [];
        const updatedOperationLocations = initialSurgicalType.position.map(
          (position) => {
            const existingLocation = existingOperationLocations.find(
              (location) => location.location === position.label
            );
            return (
              existingLocation || {
                location: position.label,
                technique: "",
                techniqueDetail: "",
              }
            );
          }
        );
        return {
          ...prevState,
          operationLocation: updatedOperationLocations,
        };
      });
    }
  }, [bariatricSurgeryData.operationType]);

  const handleTechniqueChange = (
    event: SelectChangeEvent<string | null>,
    index: number
  ) => {
    const newOperationLocations = bariatricSurgeryData.operationLocation
      ? [...bariatricSurgeryData.operationLocation]
      : [];

    const selectedTechnique = event.target.value as string;

    newOperationLocations[index] = {
      ...newOperationLocations[index],
      technique: event.target.value as string,
      techniqueDetail:
        selectedTechnique === "Linear" || selectedTechnique === "Hand sewn"
          ? "-"
          : newOperationLocations[index]?.techniqueDetail,
    };

    setBariatricSurgeryData((prevState) => ({
      ...prevState,
      operationLocation: newOperationLocations,
    }));
  };
  return (
    <Dialog open={openViewMode} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Bariatric Surgery</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#098484" }}>
          Bariatric Surgery
        </Typography>
        <div>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              วันที่ผ่าตัด <img src={infoIcon} />
            </p>
            <Typography style={{ padding: "5px" }}>
              {new Date(bariatricSurgeryData.surgeryDate).toLocaleDateString(
                "th-TH"
              )}
            </Typography>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Op-Time (minutes) <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ padding: "5px" }}>
                {bariatricSurgeryData.operationTime}
              </Typography>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <Typography style={{ padding: "5px", color: "#646D78" }}>
              Acute weight loss <img src={infoIcon} />
            </Typography>
            <FormControlLabel
              value={
                isEntrySelected ? bariatricSurgeryData.acuteWeightLoss : null
              }
              control={<Radio color="default" style={{ color: "#098484" }} />}
              label="Yes"
              checked={bariatricSurgeryData.acuteWeightLoss === true}
            />
            <FormControlLabel
              value={
                isEntrySelected ? bariatricSurgeryData.acuteWeightLoss : null
              }
              control={<Radio color="default" style={{ color: "#098484" }} />}
              label="No"
              checked={bariatricSurgeryData.acuteWeightLoss === false}
            />
          </Grid>
          {bariatricSurgeryData.acuteWeightLoss && (
            <Grid style={{ padding: "5px" }}>
              <Typography style={{ padding: "5px", color: "#646D78" }}>
                Acute weight loss (Type) <img src={infoIcon} />
              </Typography>
              <FormControlLabel
                value={
                  isEntrySelected
                    ? bariatricSurgeryData.acuteWeightLossType
                    : null
                }
                control={<Radio color="default" style={{ color: "#098484" }} />}
                label="Admit"
                checked={bariatricSurgeryData.acuteWeightLossType === "Admit"}
              />
              <FormControlLabel
                value={bariatricSurgeryData.acuteWeightLossType}
                control={<Radio color="default" style={{ color: "#098484" }} />}
                label="Ambulatory"
                checked={
                  bariatricSurgeryData.acuteWeightLossType === "Ambulatory"
                }
              />
              <Typography style={{ padding: "5px", color: "#646D78" }}>
                ระบุจำนวนวัน <img src={infoIcon} />
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ padding: "5px" }}>
                  {bariatricSurgeryData.acuteWeightLossDay}
                </Typography>
              </div>
              <Divider
                sx={{ width: "95%" }}
                style={{ paddingBottom: "20px" }}
              />
              <Grid>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  Pre-AWL Weight <img src={infoIcon} />
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ padding: "5px" }}>
                    {bariatricSurgeryData.preAwlWeight}
                  </Typography>
                </div>
              </Grid>
              <Grid>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  Post-AWL Weight
                  <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ padding: "5px" }}>
                    {bariatricSurgeryData.postAwlWeight}
                  </Typography>
                </div>
              </Grid>
              <Grid>
                <Typography style={{ padding: "5px" }}>
                  Weight change (%){" "}
                  <span style={{ color: "#098484", paddingLeft: "50px" }}>
                    {bariatricSurgeryData.preAwlWeight !== null &&
                    bariatricSurgeryData.postAwlWeight !== null
                      ? (
                          ((bariatricSurgeryData.preAwlWeight -
                            bariatricSurgeryData.postAwlWeight) /
                            bariatricSurgeryData.postAwlWeight) *
                          100
                        ).toFixed(2) + "%"
                      : "Auto calculation"}{" "}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}

          <Typography style={{ padding: "5px", color: "#646D78" }}>
            Surgical Technique
            <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
          </Typography>
          <Grid style={{ padding: "5px" }}>
            <Grid item xs={4}>
              <FormControlLabel
                value={bariatricSurgeryData.surgicalTechnique}
                control={<Radio color="default" style={{ color: "#098484" }} />}
                label="Open"
                checked={bariatricSurgeryData.surgicalTechnique === "Open"}
                style={{ paddingRight: "190px" }}
              />
              <FormControlLabel
                value={bariatricSurgeryData.surgicalTechnique}
                control={<Radio color="default" style={{ color: "#098484" }} />}
                label="Laparoscopic-assisted"
                checked={
                  bariatricSurgeryData.surgicalTechnique ===
                  "Laparoscopic-assisted"
                }
              />
            </Grid>
            <FormControlLabel
              value={bariatricSurgeryData.surgicalTechnique}
              control={<Radio color="default" style={{ color: "#098484" }} />}
              label="Robotic-assisted"
              checked={
                bariatricSurgeryData.surgicalTechnique === "Robotic-assisted"
              }
            />
          </Grid>
          <div>
            <Typography style={{ padding: "5px", color: "#646D78" }}>
              ชนิดการผ่าตัด
              <span style={{ color: "red" }}>*</span> <img src={infoIcon} />
            </Typography>
            <Grid container style={{ padding: "5px" }}>
              <Grid item xs={5}>
                <RadioGroup value={bariatricSurgeryData.operationType}>
                  {surgericalTypeList1.map((option) => (
                    <FormControlLabel
                      control={
                        <Radio color="default" style={{ color: "#098484" }} />
                      }
                      label={option}
                      checked={bariatricSurgeryData.operationType === option}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={5}>
                <RadioGroup value={bariatricSurgeryData.operationType}>
                  {surgericalTypeList2.map((option) => (
                    <FormControlLabel
                      control={
                        <Radio color="default" style={{ color: "#098484" }} />
                      }
                      label={
                        option === "อื่นๆ" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {option}
                            {bariatricSurgeryData.operationType === "อื่นๆ" && (
                              <TextField
                                name="operationTypeOtherText"
                                variant="outlined"
                                size="small"
                                style={{ marginLeft: "10px", width: "300px" }}
                                value={
                                  bariatricSurgeryData.operationTypeOtherText
                                }
                                // onChange={(e) =>
                                //   setBariatricSurgeryData((prevState) => ({
                                //     ...prevState,
                                //     operationTypeOtherText: e.target.value,
                                //   }))
                                // }
                              />
                            )}
                          </div>
                        ) : (
                          option
                        )
                      }
                      checked={bariatricSurgeryData.operationType === option}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          </div>
          {!!bariatricSurgeryData.operationType &&
            isShowPositionOfSurgerry() && (
              <div>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  ตำแหน่ง
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TableContainer component={Paper}>
                  <Table style={{ border: "1px solid #E5EBEB" }}>
                    <TableHead>
                      <TableRow style={{ borderBottom: "1px solid #E5EBEB" }}>
                        {positionOfSurgerryTable.map((value) => (
                          <TableCell style={{ fontSize: "16px" }}>
                            {value}
                          </TableCell>
                        ))}
                        {bariatricSurgeryData.operationLocation.some(
                          (item) => item.technique === "Circular"
                        ) && (
                          <TableCell style={{ fontSize: "16px" }}>
                            Technique (ระบุข้อมูล)
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bariatricSurgeryData.operationLocation?.map(
                        (item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell style={{ width: "50xpx" }}>
                                {index + 1}
                              </TableCell>
                              <TableCell style={{ width: "120px" }}>
                                {item.location}
                              </TableCell>
                              <TableCell>{item.technique}</TableCell>
                              <TableCell style={{ width: "600px" }}>
                                {item.technique === "Circular" && (
                                  <Typography>
                                    {item.techniqueDetail}
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          {/* {bariatricSurgeryData.operationLocation && ( */}
          <div>
            {sizeList.map((size) => (
              <div>
                <Grid style={{ padding: "5px" }}>
                  <Typography style={{ padding: "5px", color: "#646D78" }}>
                    {size.label} <img src={size.icon} />
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography style={{ padding: "5px" }}>
                      {bariatricSurgeryData[
                        size.value as keyof BariaticSurgeryData
                      ]
                        ? String(
                            bariatricSurgeryData[
                              size.value as keyof BariaticSurgeryData
                            ]
                          )
                        : "-"}
                    </Typography>
                  </div>
                </Grid>
              </div>
            ))}
          </div>
        </div>
        <Grid style={{ padding: "5px" }}>
          <Typography style={{ padding: "5px", color: "#646D78" }}>
            Subsequent Operation <img src={infoIcon} />
          </Typography>
          <Grid container>
            {subsequentOperationList.map((subsequentOperation, index) => (
              <Grid item xs={5} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="medium"
                      name={subsequentOperation.value}
                      style={{ color: "#098484" }}
                      value={bariatricSurgeryData.subsequentOperation}
                      checked={
                        bariatricSurgeryData.subsequentOperation[
                          subsequentOperation.value as keyof typeof bariatricSurgeryData.subsequentOperation
                        ]
                      }
                    />
                  }
                  label={subsequentOperation.label}
                />
                {subsequentOperation.value === "other" &&
                  bariatricSurgeryData.subsequentOperation["other"] && (
                    <TextField
                      name="subsequentOperationOther"
                      variant="outlined"
                      size="small"
                      style={{ marginLeft: "10px", width: "300px" }}
                      value={bariatricSurgeryData.subsequentOperationOther}
                      // onChange={(event) => {
                      //   setBariatricSurgeryData((prevState) => ({
                      //     ...prevState,
                      //     subsequentOperationOther: [event.target.value], // Wrap the value in an array
                      //   }));
                      // }}
                    />
                  )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Divider sx={{ width: "95%" }} style={{ paddingBottom: "20px" }} />
        <div>
          <Typography style={{ padding: "5px", color: "#098484" }}>
            Early Complications
          </Typography>
          <Typography style={{ padding: "5px", color: "#646D78" }}>
            Early Complications <span style={{ color: "red" }}>*</span>{" "}
            <img src={infoIcon} />
          </Typography>
          <Grid style={{ padding: "5px" }}>
            <FormControlLabel
              value={bariatricSurgeryData.earlyComplication}
              control={<Radio color="default" style={{ color: "#098484" }} />}
              label="Yes"
              checked={bariatricSurgeryData.earlyComplication === true}
            />
            <FormControlLabel
              value={bariatricSurgeryData.earlyComplication}
              control={<Radio color="default" style={{ color: "#098484" }} />}
              label="No"
              checked={bariatricSurgeryData.earlyComplication === false}
            />
          </Grid>
        </div>
        {bariatricSurgeryData.earlyComplication && (
          <div>
            <Typography style={{ padding: "5px", color: "#646D78" }}>
              Early Complications Type <span style={{ color: "red" }}>*</span>{" "}
              <img src={infoIcon} />
            </Typography>
            <Grid container style={{ padding: "5px" }}>
              <Grid item xs={5}>
                {earlyComplicationsTypeList.map(
                  (earlyComplicationType, index) => (
                    <Grid item xs={12} key={index}>
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            size="medium"
                            name={earlyComplicationType.value}
                            style={{ color: "#098484" }}
                            value={bariatricSurgeryData.earlyComplicationType}
                            checked={
                              bariatricSurgeryData.earlyComplicationType[
                                earlyComplicationType.value as keyof EarlyComplicationType
                              ]
                            }
                          />
                        }
                        label={earlyComplicationType.label}
                      />
                    </Grid>
                  )
                )}
              </Grid>
              <Grid xs={4}>
                {earlyComplicationsTypeList2.map(
                  (earlyComplicationType, index) => (
                    <Grid item xs={12} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="medium"
                            name={earlyComplicationType.value}
                            style={{ color: "#098484" }}
                            value={bariatricSurgeryData.earlyComplicationType}
                            checked={
                              bariatricSurgeryData.earlyComplicationType[
                                earlyComplicationType.value as keyof EarlyComplicationType
                              ]
                            }
                          />
                        }
                        label={earlyComplicationType.label}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
            {bariatricSurgeryData.earlyComplicationType["leakage"] && (
              <div>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  Treatment
                </Typography>
                <TextField
                  name="treatment"
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: "10px", width: "300px" }}
                  value={bariatricSurgeryData.treatment}
                  // onChange={(event) => {
                  //   setBariatricSurgeryData((prevState) => ({
                  //     ...prevState,
                  //     treatment: [event.target.value],
                  //   }));
                  // }}
                />
              </div>
            )}
            {bariatricSurgeryData.earlyComplicationType["other"] && (
              <div>
                <Typography style={{ padding: "5px", color: "#646D78" }}>
                  ระบุข้อมูลเพิ่มเติม
                </Typography>
                <TextField
                  name="earlyComplicationTypeOtherText"
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: "10px", width: "300px" }}
                  value={bariatricSurgeryData.earlyComplicationTypeOtherText}
                  // onChange={(event) => {
                  //   setBariatricSurgeryData((prevState) => ({
                  //     ...prevState,
                  //     earlyComplicationTypeOtherText: event.target.value
                  //   }));
                  // }}
                />
              </div>
            )}
          </div>
        )}
        <div>
          <Typography style={{ padding: "5px", color: "#646D78" }}>
            Re-operation <span style={{ color: "red" }}>*</span>{" "}
            <img src={infoIcon} />
          </Typography>
          <Grid style={{ padding: "5px" }}>
            <FormControlLabel
              value={bariatricSurgeryData.reOperation}
              control={<Radio color="default" style={{ color: "#098484" }} />}
              label="Yes"
              checked={bariatricSurgeryData.reOperation === true}
            />
            <FormControlLabel
              value={bariatricSurgeryData.reOperation}
              control={<Radio color="default" style={{ color: "#098484" }} />}
              label="No"
              checked={bariatricSurgeryData.reOperation === false}
            />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ย้อนกลับ
        </Button>
      </DialogActions>
    </Dialog>
  );
};
