import { useState } from "react";
import Frame_1672 from "../asset/Frame_1672.png";
import Button from "../asset/Button.png";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { WarningDialog } from "../components/dialog/WarningDialog";
import "../Pages/Register/Register.css";
import CloseIcon from "@mui/icons-material/Close";

const Dropzone = ({
  registerForm,
  handleRegisterChange,
}: {
  registerForm: any;
  handleRegisterChange: any;
}) => {
  const [openUnsaveDataDialog, setOpenUnsaveDataDialog] = useState(false);
  const [dialog, setDialog] = useState({ title: "", content: "" });

  const handleCloseDialog = () => {
    setOpenUnsaveDataDialog(false);
  };
  const handleConfirmDialog = () => {
    setOpenUnsaveDataDialog(false);
  };

  function uploadFile(event: any, input: string) {
    if (Number((event.target.files?.[0].size / 1024 / 1024).toFixed(2)) > 3) {
      setDialog({ title: "ขนาดไฟล์", content: "ขนาดไฟล์สูงสุดไม่เกิน 3 MB" });
      setOpenUnsaveDataDialog(true);
      // } else if(event.target.files?.[0].type !== 'application/pdf') {
      //   setDialog({title: "ประเภทไฟล์", content: "ประเภทไฟล์ต้องเป็น PDF เท่านั้น"});
      //   setOpenUnsaveDataDialog(true);
    } else {
      let reader = new FileReader();
      let fileByteArray = new Array();
      reader.readAsArrayBuffer(event.target.files?.[0]);
      reader.onloadend = function (event: any) {
        if (event.target.readyState == FileReader.DONE) {
          let arrayBuffer = event.target.result,
            array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
      };
      switch (input) {
        case "doctor":
          registerForm.fileDoctor = fileByteArray;
          registerForm.fileDoctorType = event.target.files?.[0].type;
          handleRegisterChange(event);
          break;
        case "other1":
          registerForm.fileOther1 = fileByteArray;
          registerForm.fileOther1Type = event.target.files?.[0].type;
          handleRegisterChange(event);
          break;
        case "other2":
          registerForm.fileOther2 = fileByteArray;
          registerForm.fileOther2Type = event.target.files?.[0].type;
          handleRegisterChange(event);
          break;
        default:
      }
    }
  }

  function clearFile(id: string) {
    // (document.getElementById(id) as HTMLInputElement).value = null;
    switch (id) {
      case "fileDoctorName":
        registerForm.fileDoctorName = null;
        registerForm.fileDoctorType = null;
        registerForm.fileDoctor = null;
        handleRegisterChange({
          target: { name: "fileDoctorName", value: null },
        });
        handleRegisterChange({
          target: { name: "fileDoctorType", value: null },
        });
        handleRegisterChange({ target: { name: "fileDoctor", value: null } });
        break;
      case "fileOther1Name":
        registerForm.fileOther1Name = null;
        registerForm.fileOther1Type = null;
        registerForm.fileOther1 = null;
        handleRegisterChange({
          target: { name: "fileOther1Name", value: null },
        });
        handleRegisterChange({
          target: { name: "fileOther1Type", value: null },
        });
        handleRegisterChange({ target: { name: "fileOther1", value: null } });
        break;
      case "fileOther2Name":
        registerForm.fileOther2Name = null;
        registerForm.fileOther2Type = null;
        registerForm.fileOther2 = null;
        handleRegisterChange({
          target: { name: "fileOther2Name", value: null },
        });
        handleRegisterChange({
          target: { name: "fileOther2Type", value: null },
        });
        handleRegisterChange({ target: { name: "fileOther2", value: null } });
        break;
      default:
    }
  }

  const styleList = {
    border: "1px solid #ccc",
    marginBottom: "10px",
  };

  const styleListError = {
    border: "1px solid #d32f2f",
    marginBottom: "10px",
  };

  return (
    <div className="upload-section">
      {/* <input {...getInputProps({ accept: ".png,.jpg,.jpeg,.csv" })} /> */}
      <ListItem
        style={!registerForm.fileDoctorName ? styleListError : styleList}
      >
        <ListItemAvatar>
          <img src={Frame_1672} alt="File Icon" />
        </ListItemAvatar>
        <ListItemText
          primary="บัตรประจำตัวแพทย์"
          secondary={registerForm.fileDoctorName}
        />
        {/* <div {...getRootProps()}> */}
        {!registerForm.fileDoctor ? (
          <IconButton
            edge="end"
            aria-label="delete"
            component="label" // Add this line
          >
            <input
              type="file"
              hidden
              id="fileDoctorName"
              name="fileDoctorName"
              onChange={(event) => uploadFile(event, "doctor")}
            />
            <img src={Button} />
          </IconButton>
        ) : (
          <CloseIcon
            onClick={() => clearFile("fileDoctorName")}
            style={{ cursor: "pointer" }}
          />
        )}
        {/* </div> */}
      </ListItem>
      <ListItem style={styleList}>
        <ListItemAvatar>
          <img src={Frame_1672} alt="File Icon" />
        </ListItemAvatar>
        <ListItemText
          primary="เอกสารอื่นๆ"
          secondary={registerForm.fileOther1Name}
        />
        {/* <div {...getRootProps()}> */}
        {!registerForm.fileOther1 ? (
          <IconButton
            edge="end"
            aria-label="delete"
            component="label" // Add this line
          >
            <input
              type="file"
              hidden
              id="fileOther1Name"
              name="fileOther1Name"
              onChange={(event) => uploadFile(event, "other1")}
            />
            <img src={Button} />
          </IconButton>
        ) : (
          <CloseIcon onClick={() => clearFile("fileOther1Name")} />
        )}
        {/* </div> */}
      </ListItem>
      <ListItem style={styleList}>
        <ListItemAvatar>
          <img src={Frame_1672} alt="File Icon" />
        </ListItemAvatar>
        <ListItemText
          primary="เอกสารอื่นๆ"
          secondary={registerForm.fileOther2Name}
        />
        {/* <div {...getRootProps()}> */}
        {!registerForm.fileOther2 ? (
          <IconButton
            edge="end"
            aria-label="delete"
            component="label" // Add this line
          >
            <input
              type="file"
              hidden
              id="fileOther2Name"
              name="fileOther2Name"
              onChange={(event) => uploadFile(event, "other2")}
            />
            <img src={Button} />
          </IconButton>
        ) : (
          <CloseIcon
            onClick={() => clearFile("fileOther2Name")}
            style={{ cursor: "pointer" }}
          />
        )}
        {/* </div> */}
      </ListItem>
      <WarningDialog
        openDialog={openUnsaveDataDialog}
        title={dialog.title}
        content={dialog.content}
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleConfirmDialog}
      />
    </div>
  );
};

export default Dropzone;
