import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const reportService = {
  getDemographicReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw01`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getComplicationReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw02`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getLaboratoryReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw03`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getGynecologicReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw04`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getInvestigationReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw05`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getPathologyReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw06`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getMedicationReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw07`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getBariatricSurgeryReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw08`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getLateComplicationReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw09`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getEndOfStudyReport: async function (filterRequest: any) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/v1/report/raw10`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
